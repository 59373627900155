import { detect } from 'detect-browser';
/**
 * Scroll to the top
 */
export const scrollToTheTop = () => {
  const target = document.getElementById('pageScrollTarget');
  const browser = detect();
  if (target) {
    if (browser && (browser.name === 'edge' || browser.name === 'ie')) {
      target.scrollTop = 0;
      return;
    }
    target.scrollTo(0, 0);
    if (!!document.documentElement) {
      document.documentElement.scrollTop = 0;
    }
  }
};
