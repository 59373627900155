import { fieldValueTypes, IField } from '@pbl/pbl-react-core/lib/models/forms/types';
import { formatDayMonthYear } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import PhoneInputMask from '@pbl/pbl-react-web-components/lib/field/PhoneInputMask';
import { conformToMask } from 'react-text-mask';
import * as yup from 'yup';
import validation, { phoneMask } from './validation';

export interface INPNField<T> extends IField<T> {
  part: number;
  inputProps?: any;
  InputProps?: any;
  hasError?: boolean;
}
export type NoPurchaseNecessaryFields = INPNField<fieldValueTypes>[];

const firstNameField: INPNField<string> = {
  type: 'string',
  key: 'firstName',
  label: 'First Name',
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  inputProps: { maxLength: validation.maxNameLength },
  part: 1,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup
    .string()
    .required()
    .max(30)
    .matches(/.*\S.*/)
};

const lastNameField: INPNField<string> = {
  type: 'string',
  key: 'lastName',
  label: 'Last Name',
  maxLength: 30,
  part: 1,
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup
    .string()
    .required()
    .max(30)
    .matches(/.*\S.*/)
};

const dateOfBirthField: INPNField<string> = {
  type: 'dateOfBirth',
  key: 'dateOfBirth',
  label: 'Date of Birth *',
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  part: 1,
  minValue: validation.minDateOfBirth,
  maxValue: validation.maxDateOfBirth,
  get fieldValue() {
    return !!this.value ? formatDayMonthYear(this.value) : undefined;
  },
  validationSchema: yup
    .date()
    .required()
    .min(validation.minDateOfBirth)
    .max(validation.maxDateOfBirth)
};

const emailField: INPNField<string> = {
  type: 'email',
  key: 'email',
  label: 'Email',
  maxLength: 100,
  get placeholder(): string {
    return this.label;
  },
  isRequired: true,
  get fieldValue() {
    return this.value;
  },
  validationSchema: yup
    .string()
    .required()
    .email()
    .max(100),
  disabledAutofill: true,
  part: 1
};

const phoneField: INPNField<string> = {
  type: 'phone',
  key: 'phone',
  label: 'Phone Number',
  get placeholder(): string {
    return this.label;
  },
  isRequired: false,
  get fieldValue() {
    if (!!this.value && this.value.toString().length > 0) {
      return conformToMask(this.value, phoneMask, {}).conformedValue;
    }
    return this.value;
  },
  validationSchema: yup.string().matches(validation.phoneValidationRegex),
  InputProps: { inputComponent: PhoneInputMask as any },
  part: 2
};

export const fieldHasError = (fieldName: string, currentValue: string, expectedValue: string): boolean =>
  setHasError(fieldName, currentValue.toLowerCase().trim() !== expectedValue.toLowerCase().trim());

export const setHasError = (fieldName: string, hasError: boolean): boolean => {
  const field = npnFields.find(x => x.key === fieldName);
  if (field) field.hasError = hasError;
  return field?.hasError || false;
};

export declare type NoPurchaseNecessaryDataFields = 'email' | 'firstName' | 'lastName' | 'dateOfBirth' | 'phone';

export const npnFields: NoPurchaseNecessaryFields = [
  firstNameField,
  lastNameField,
  dateOfBirthField,
  emailField,
  phoneField
];
