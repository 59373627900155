import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';
import color from 'color';

const styles = (theme: Theme) =>
  createStyles({
    container,
    centered: {
      alignItems: 'center'
    },
    buttonStyle: {
      '&,&:hover,&:focus': {
        color: `${theme.palette.secondary.main} !important`
      },
      '&:hover,&:focus': {
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderColor: theme.palette.secondary.main
      },
      '&.Mui-disabled': {
        color: `${color(theme.palette.secondary.main).alpha(0.4).rgb().string()} !important`
      }
    },
    gridContainer: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 0
      }
    },
    tableBackground: {
      backgroundColor: color(theme.palette.primary.dark).alpha(0.7).rgb().string(),
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2)
      }
    },
    cell: {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing()
      }
    },
    historyCell: {
      padding: theme.spacing(3),
      textAlignLast: 'right',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing()
      }
    }
  });

export default styles;
