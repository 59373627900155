import { IDrawPage } from '@pbl/pbl-react-core/lib/models/draws/types';
import { GET_TRANSACTION_HISTORY, ILedgerInitialState, ITransactionHistory, IUserBalance, LedgerState, REDEEM_FROM_POINTS, TransactionHistoryList, USER_TOKENS } from '@pbl/pbl-react-core/lib/models/ledger/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: ILedgerInitialState = {
  loading: false,
  userTokens: {
    balance: 0,
    currency: '',
    userKey: '',
    id: null
  },
  transactionResponse: {
    id: '',
    timestamp: ''
  },
  errorMessage: null,
  transactionHistory: [{} as ITransactionHistory],
  page: {} as IDrawPage,
  transactionHistoryList: {} as TransactionHistoryList,
  userBalance: {} as IUserBalance,
  activitiesHistoryList: {} as TransactionHistoryList
};

export default (state: LedgerState = INITIAL_STATE, action: IDispatchAction): LedgerState => {
    switch (action.type) {
      case REQUEST(USER_TOKENS):
      case REQUEST(REDEEM_FROM_POINTS):
      case REQUEST(GET_TRANSACTION_HISTORY):
        return {
          ...state,
          loading: true
        };
      case SUCCESS(USER_TOKENS):
      case USER_TOKENS:
        return {
          ...state,
          loading: false,
          errorMessage: null,
          userTokens: action.payload
        };
      case SUCCESS(REDEEM_FROM_POINTS):
      case REDEEM_FROM_POINTS:
        return {
          ...state,
          loading: false,
          errorMessage: null,
          transactionResponse: action.payload
        };
      case SUCCESS(GET_TRANSACTION_HISTORY):
         return {
          ...state,
          loading: false,
          errorMessage: null,
          transactionHistory: action.payload.content,
          page: action.payload.page
        };
      case SUCCESS('authentication/LOGOUT'):
        return { ...INITIAL_STATE };
      case FAILURE(USER_TOKENS):
      case FAILURE(REDEEM_FROM_POINTS):
      case FAILURE(GET_TRANSACTION_HISTORY):
        return {
          ...state,
          loading: false,
          errorMessage: action.payload
        };
      default:
        return state;
    }
  };
