import {
  ALWAYS_VALUE_DISPLAY_NAME,
  IBaseCategory,
  IPreferenceCategory,
  IPreferenceOption,
  IPreferencesState,
  NO_ALERT_SELECTED_VALUE_DISPLAY_NAME,
  OFF_VALUE_DISPLAY_NAME,
  ON_VALUE_DISPLAY_NAME,
  PreferencesState,
  PREFERENCES_FETCH,
  PREFERENCES_FETCH_OPTIONS,
  PREFERENCES_LOAD,
  PREFERENCES_RESET,
  PREFERENCES_SAVE,
  PREFERENCES_TOGGLE,
  PREFERENCES_TOGGLE_ALL,
  PREFERENCES_UPDATE_SUB_CATEGORY
} from '@pbl/pbl-react-core/lib/models/preferences';
import _ from 'lodash';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IPreferencesState = {
  all: false,
  fetching: false,
  categories: [],
  tempState: {
    all: false,
    categories: []
  }
};

const valueTrue = {
  id: 1,
  displayName: 'on',
  extName: 1
};

const valueFalse = {
  id: 2,
  displayName: 'off',
  extName: 0
};

const toggle = (collection: IPreferenceCategory[], key: string) =>
  _.map(collection, category => {
    if (category.key === key) {
      return setValue(category, !category.booleanValue);
    }

    if (!_.isEmpty(category.subCategories)) {
      category.subCategories = _.map(category.subCategories, c => {
        if (c.key === key) {
          c.booleanValue = !c.booleanValue;
          if (!c.options || c.options.length === 0) {
            c.value = c.booleanValue ? valueTrue : valueFalse;
          }
        }
        return c;
      });

      category.booleanValue = checkAll(category.subCategories);
      if (!category.options || category.options.length === 0) {
        category.value = category.booleanValue ? valueTrue : valueFalse;
      }
    }

    return category;
  });

const setValue = (category: IPreferenceCategory, newValue: boolean) => {
  if (!_.isEmpty(category.subCategories)) {
    category.subCategories = _.map(category.subCategories, c => {
      c.booleanValue = newValue;
      const valueAlways = {
        id: 1,
        displayName: 'Always',
        extName: 1
      };

      const valueNoAlertSelected = {
        id: 0,
        displayName: 'No Alert Selected',
        extName: 0
      };

      c.options?.forEach(t => {
        if (t.displayName === valueAlways.displayName && t.extName === valueAlways.extName) {
          valueAlways.id = t.id;
          return;
        } else if (t.displayName === valueNoAlertSelected.displayName && t.extName === valueNoAlertSelected.extName) {
          valueNoAlertSelected.id = t.id;
          return;
        }
      });

      c.value = c.booleanValue
        ? c.options && c.options.length > 0
          ? valueAlways
          : valueTrue
        : c.options && c.options.length > 0
        ? valueNoAlertSelected
        : valueFalse;

      return c;
    });
  }
  if (!category.options || !category.options.length) {
    category.value = newValue ? valueTrue : valueFalse;
  }

  const booleanValue = newValue;
  return { ...category, booleanValue };
};

const mapToBoolean = (value: IPreferenceOption): boolean => {
  if (value.displayName && (value.displayName === ON_VALUE_DISPLAY_NAME || value.displayName === ALWAYS_VALUE_DISPLAY_NAME)) return true;
  return !(
    value.displayName &&
    (value.displayName === OFF_VALUE_DISPLAY_NAME || value.displayName === NO_ALERT_SELECTED_VALUE_DISPLAY_NAME)
  );
};

const mapTogglesToBoolean = (collection: IPreferenceCategory[]) =>
  _.map(collection, category => {
    category.booleanValue = mapToBoolean(category.value);

    if (!_.isEmpty(category.subCategories)) {
      category.subCategories = _.map(category.subCategories, c => {
        c.booleanValue = mapToBoolean(c.value);
        return c;
      });
    }

    return category;
  });

const checkAll = (collection: IBaseCategory[]): boolean => {
  if (_.isEmpty(collection)) {
    return false;
  }

  const counts = _.countBy(collection, ({ booleanValue }) => `${booleanValue}`);
  return !!counts['true'] && counts['true'] === collection.length;
};

export default (state: PreferencesState = INITIAL_STATE, action: IDispatchAction): PreferencesState => {
  switch (action.type) {
    case REQUEST(PREFERENCES_FETCH):
    case REQUEST(PREFERENCES_FETCH_OPTIONS):
    case REQUEST(PREFERENCES_SAVE):
      return {
        ...state,
        fetching: true
      };
    case SUCCESS(PREFERENCES_FETCH):
      const prefs = mapTogglesToBoolean(action.payload);
      return {
        ...state,
        all: checkAll(prefs),
        categories: prefs
      };
    case PREFERENCES_LOAD:
      return {
        ...state,
        tempState: {
          all: checkAll(action.payload),
          categories: action.payload
        }
      };
    case PREFERENCES_TOGGLE_ALL:
      return {
        ...state,
        tempState: {
          // @ts-ignore
          all: !state.tempState.all,
          // @ts-ignore
          categories: _.map(state.tempState.categories, c => setValue(c, !state.tempState.all))
        }
      };
    case PREFERENCES_TOGGLE:
      // @ts-ignore
      const categories = toggle(state.tempState.categories, action.payload);
      return {
        ...state,
        tempState: {
          categories,
          all: checkAll(categories)
        }
      };
    case PREFERENCES_UPDATE_SUB_CATEGORY: {
      if (!state.tempState) return { ...state };

      const tempCategories = _.map(state.tempState.categories, c => {
        if (c.key === action.payload.key) {
          return { ...c, ...action.payload };
        }
        return c;
      });
      return {
        ...state,
        tempState: {
          categories: tempCategories,
          all: checkAll(state.tempState.categories)
        }
      };
    }
    case SUCCESS(PREFERENCES_FETCH_OPTIONS):
      return {
        ...state,
        options: action.payload,
        fetching: false
      };
    case SUCCESS(PREFERENCES_SAVE):
      return {
        ...state,
        // @ts-ignore
        all: state.tempState.all,
        // @ts-ignore
        categories: _.cloneDeep(state.tempState.categories),
        fetching: false
      };
    case PREFERENCES_RESET:
      return {
        ...INITIAL_STATE
      };
    case FAILURE(PREFERENCES_FETCH):
    case FAILURE(PREFERENCES_FETCH_OPTIONS):
    case FAILURE(PREFERENCES_SAVE):
      return {
        ...state,
        error: action.payload.error,
        fetching: false
      };
    default:
      return state;
  }
};
