import { withStyles, WithStyles } from '@material-ui/core';
import MediaView from '@pbl/pbl-react-web-components/lib/footer/MediaView';
import constants from 'config/constants';
import { footerPages } from 'config/validation';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { scrollToTheTop } from 'utils/htmlUtil';
import styles from '../../assets/jss/shared/components/footer/FooterStyles';

export interface IMediaViewRendererProps extends WithStyles<typeof styles>, RouteComponentProps<IMatchParams> {}

interface IMediaViewRendererState {
  mediaUrl: string;
}

interface IMatchParams {
  pageName: footerPages;
}

class MediaViewRenderer extends React.Component<IMediaViewRendererProps, IMediaViewRendererState> {
  constructor(props) {
    super(props);
    this.state = {
      mediaUrl: ''
    };
  }

  public async componentWillMount() {
    const {
      match: { params }
    } = this.props;
    scrollToTheTop();
    await this.setElementFromUrl(params);
  }

  private async setElementFromUrl(params: IMatchParams) {
    if (params.pageName.length > 0) {
      const mediaUrl = this.getMediaUrlFromPageName(params.pageName);
      this.setState({ mediaUrl });
    }
  }

  public async componentWillReceiveProps(newProps) {
    if (newProps.match.params.pageName !== this.props.match.params.pageName) {
      scrollToTheTop();
      await this.setElementFromUrl(newProps.match.params);
    }
  }

  getMediaUrlFromPageName = (name: footerPages): string => {
    switch (name.toLowerCase()) {
      case 'about_us':
        document.title = 'About Us';
        return constants.ABOUT_US_PAGE;
      case 'faq':
        document.title = 'Arizona Lottery Players Club FAQs';
        return constants.FAQ_PAGE;
      case 'terms_conditions':
        document.title = 'TERMS & CONDITIONS';
        return constants.TERMS_PAGE;
      case 'terms_conditions_ll':
        document.title = 'TERMS & CONDITIONS';
        return constants.TERMS_PAGE_LL;
      case 'faq_ll':
        document.title = 'Arizona Lottery Players Club FAQs';
        return constants.FAQ_PAGE_LL;
      case 'privacy':
        document.title = 'PRIVACY POLICY';
        return constants.PRIVACY_PAGE;
      default:
        return constants.ABOUT_US_PAGE;
    }
  };

  public render() {
    return (
      <div className={this.props.classes.mediaView}>
        <MediaView mediaUrl={this.state.mediaUrl} />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(MediaViewRenderer));
