import {
  COUNT_FREE_ENTRY,
  DrawDetailState,
  FETCH_DRAW_ENTRY_DETAILS,
  FETCH_DRAW_PRIZES,
  FETCH_DRAW_PRIZES_WITH_CONTENT,
  FETCH_DRAW_PRIZES_WITH_WINNERS,
  FETCH_DRAW_WITH_ADDITIONAL_INFORMATION,
  FETCH_ELIGIBLE_GAMES,
  FETCH_LATEST_DRAW_HISTORY,
  IDrawDetailState,
  RESET_DRAW,
  SELECT_DRAW
} from '@pbl/pbl-react-core/lib/models/draws/types';
import { GamesList } from '@pbl/pbl-react-core/lib/models/games';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_DRAW_DETAIL_STATE: IDrawDetailState = {
  loading: false,
  draw: null,
  latestDrawHistory: null,
  drawPrizes: [],
  drawPrizeContentRetrieved: false,
  drawPrizesWithWinners: [],
  drawPrizeWinnersRetrieved: false,
  eligibleGames: new GamesList(),
  drawHistoryRetrieved: false,
  freeEntries: 0,
  giveawayDraw: null
};

export default (state: DrawDetailState = INITIAL_DRAW_DETAIL_STATE, action: IDispatchAction): DrawDetailState => {
  switch (action.type) {
    case SELECT_DRAW:
      return {
        ...state,
        draw: action.payload,
        latestDrawHistory: null,
        drawPrizes: [],
        drawPrizeContentRetrieved: false,
        drawPrizesWithWinners: [],
        drawPrizeWinnersRetrieved: false,
        drawHistoryRetrieved: false
      };
    case REQUEST(FETCH_DRAW_WITH_ADDITIONAL_INFORMATION):
    case REQUEST(FETCH_DRAW_PRIZES):
    case REQUEST(FETCH_DRAW_PRIZES_WITH_CONTENT):
    case REQUEST(FETCH_LATEST_DRAW_HISTORY):
    case REQUEST(FETCH_DRAW_PRIZES_WITH_WINNERS):
    case REQUEST(FETCH_ELIGIBLE_GAMES):
    case REQUEST(COUNT_FREE_ENTRY):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(FETCH_DRAW_WITH_ADDITIONAL_INFORMATION):
      return {
        ...state,
        loading: false,
        draw: action.payload
      };
    case SUCCESS(FETCH_DRAW_PRIZES):
      return {
        ...state,
        loading: false,
        errorMessage: '',
        drawPrizes: action.payload,
        drawPrizeContentRetrieved: false
      };
    case SUCCESS(FETCH_DRAW_PRIZES_WITH_CONTENT):
      return {
        ...state,
        loading: false,
        errorMessage: '',
        drawPrizes: action.payload,
        drawPrizeContentRetrieved: true
      };
    case SUCCESS(FETCH_LATEST_DRAW_HISTORY):
      return {
        ...state,
        loading: false,
        errorMessage: '',
        drawHistoryRetrieved: true,
        latestDrawHistory: action.payload
      };
    case SUCCESS(FETCH_DRAW_PRIZES_WITH_WINNERS):
      return {
        ...state,
        loading: false,
        errorMessage: '',
        drawPrizesWithWinners: action.payload,
        drawPrizeWinnersRetrieved: true
      };
    case SUCCESS(FETCH_ELIGIBLE_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: '',
        eligibleGames: action.payload
      };
    case SUCCESS(FETCH_DRAW_ENTRY_DETAILS):
      return {
        ...state,
        entries:
          action.payload.totalActiveEntries !== undefined
            ? action.payload.totalActiveEntries
            : action.payload.totalPrizeAmount
            ? action.payload.totalPrizeAmount
            : 0,
        activeEntries: action.payload.totalActiveEntries
      };
    case SUCCESS(COUNT_FREE_ENTRY):
      return {
        ...state,
        loading: false,
        freeEntries: action.payload
      };
    case FAILURE(FETCH_DRAW_WITH_ADDITIONAL_INFORMATION):
    case FAILURE(FETCH_DRAW_PRIZES):
    case FAILURE(FETCH_DRAW_PRIZES_WITH_CONTENT):
    case FAILURE(FETCH_LATEST_DRAW_HISTORY):
    case FAILURE(FETCH_DRAW_PRIZES_WITH_WINNERS):
    case FAILURE(FETCH_ELIGIBLE_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        drawHistoryRetrieved: true
      };
    case FAILURE(COUNT_FREE_ENTRY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case RESET_DRAW:
      return { ...INITIAL_DRAW_DETAIL_STATE };
    default:
      return state;
  }
};
