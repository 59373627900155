import { DispatchMethod } from 'redux/reducers';

const performDispatch = async (
  dispatch: DispatchMethod,
  type: string,
  asyncMethodCall: () => Promise<any>,
  errorCallback?: (error: any) => void
) => {
  try {
    await dispatch({
      type,
      payload: asyncMethodCall()
    });
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
};

export default performDispatch;
