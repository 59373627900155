import { createStyles, Theme } from '@material-ui/core';
import { coloredShadow, container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      ...container,
      marginTop: theme.spacing(2)
    },
    // @ts-ignore
    coloredShadow,
    drawsLink: {
      display: 'flex'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    gridRow: {
      marginTop: theme.spacing(2)
    },
    subtitle: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    alert: {
      marginLeft: theme.spacing(2)
    },
    imageCard: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing()
      },
      [theme.breakpoints.up('sm')]: {
        width: 445,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.only('xs')]: {
        width: 328
      }
    },
    image: {
      [theme.breakpoints.up('sm')]: {
        height: 445,
        width: 445
      },
      [theme.breakpoints.only('xs')]: {
        height: 328,
        width: 328
      }
    },
    rules: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    rulesLink: {
      display: 'flex',
      textDecoration: 'none'
    },
    prizesLink: {
      display: 'flex',
      textDecoration: 'none',
      marginTop: theme.spacing(2)
    },
    winnerStats: {
      marginTop: theme.spacing(2)
    },
    prizeWinners: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5)
    },
    oddRow: {
      backgroundColor: theme.palette.grey['200']
    },
    prizes: {
      marginTop: theme.spacing(2)
    },
    prizeSummary: {
      marginBottom: theme.spacing(2)
    },
    prizeContent: {
      marginBottom: theme.spacing(2)
    },
    prizeDescriptionLink: {
      display: 'flex',
      textDecoration: 'none',
      textTransform: 'uppercase'
    },
    enterTicketButton: {
      marginTop: theme.spacing(4)
    },
    eligibleGames: {
      marginTop: theme.spacing(4)
    },
    allEligibleGamesCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'stretch',
      alignItems: 'center',
      height: 432,
      [theme.breakpoints.down('lg')]: {
        height: 372
      },
      [theme.breakpoints.down('md')]: {
        height: 290
      },
      [theme.breakpoints.down('xs')]: {
        height: 432
      }
    },
    entriesBox: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing() - 2,
      border: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3)
      }
    },
    entriesButton: {
      borderRadius: 5,
      backgroundColor: theme.palette.action.disabledBackground,
      paddingLeft: 24,
      paddingRight: 24,
      marginRight: 16,
      marginBottom: 8
    }
  });

export default styles;
