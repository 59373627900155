import { Typography, withStyles, WithStyles } from '@material-ui/core';
import CardMaterial from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import { IHomeEarnActivity } from 'config/homeScreenEarnActivities';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface IRewardCardProps extends RouteComponentProps, WithStyles<typeof styles> {
  activity: IHomeEarnActivity;
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
}

const EarnCard: React.FunctionComponent<IRewardCardProps> = ({ activity, classes, history }) => {
  const navigateTo = () => {
    if (activity.navigateTo) {
      history.push(activity.navigateTo, activity.navigateState);
    } else return;
  };
  return (
    <CardMaterial className={classes.earnCard}>
      <CardActionArea className={classes.earnCardAction} onClick={navigateTo}>
        <CardContent className={classes.earnCardContent}>
          {activity.iconType === 'custom' ? (
            <Icon color="primary" className={classNames(activity.icon, classes.earnCardIcon)} />
          ) : (
            <Icon color="primary" className={classNames(classes.earnCardIcon)}>
              {activity.icon}
            </Icon>
          )}
          <div className={classes.earnCardContentText}>
            <Typography component={'p'} variant="h6" color="textPrimary">
              {activity.title}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </CardMaterial>
  );
};

export default withRouter(withStyles(styles)(EarnCard));
