import { Button, createStyles, Dialog, DialogActions, DialogContent, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { config } from '@pbl/pbl-react-web-components/lib/config/config';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IRootState } from 'redux/reducers';
import { fetchRecommendations } from 'redux/reducers/recommendations/actions';
import ForYouCard from 'shared/components/card/ForYouCard';
import { formatTemplate } from 'utils/GreetingsMessageUtil';

export interface IRecommendationDisplayProps {
  open: boolean;
  forYouTitle: string;
  forYouSubTitle: string;
  activityLink: string;
  isFirstNameEnabled: boolean;
  clickOK: (boolean) => void;
}

const styles: any = (theme: Theme) =>
  createStyles({
    paper: {
      alignSelf: 'center',
      maxHeight: '310px',
      maxWidth: '500px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '350px'
      }
    },
    dialogActions: {
      alignContent: 'center',
      alignSelf: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    title: {
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      alignItems: 'center',
      transform: 'Titlecase',
      paddingTop: theme.spacing(3),
      display: 'flex',
      maxWidth: '420px',
      flexDirection: 'column',
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    subtitle: {
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      alignItems: 'center',
      transform: 'Titlecase',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '420px',
      paddingLeft: '20px'
    },
    button: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    noRecommendationMessage: {
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      alignItems: 'center',
      transform: 'Titlecase',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '500px'
    }
  });

const RecommendationDialog: React.FC<IRecommendationDisplayProps> = ({
  open,
  clickOK,
  forYouTitle,
  forYouSubTitle
}) => {
  const theme = useTheme();
  const useStyles = makeStyles(styles);
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const account = useSelector((state: IRootState) => state.authentication);
  const recommendationsList = useSelector((state: IRootState) => state.recommendations.recommendations);
  const [recommendations, setRecommendations] = React.useState(recommendationsList);

  const handleClose = reason => {
    if (reason && reason === 'backdropClick') return;
    clickOK(false);
  };

  const navigateOnClick = (redirectUrl: string) => {
    history.push(redirectUrl);
  };

  React.useEffect(() => {
    dispatch(fetchRecommendations());
  }, [dispatch]);

  React.useEffect(() => {
    setRecommendations(recommendations);
  }, [recommendations, recommendationsList]);

  return (
    <Dialog maxWidth="md" open={open} className={'po-widget-dialog'}>
      <Typography variant="h1" align="center" className={classes.title}>
      {formatTemplate(account?.account, forYouTitle)}
      </Typography>
      {!!recommendations && recommendations.length > 0 ? (
        <Typography noWrap={false} variant="inherit" align="center" className={classes.subtitle}>
          {forYouSubTitle}
        </Typography>
      ) : (
        <Typography noWrap={false} variant="inherit" align="center" className={classes.noRecommendationMessage}>
          {t('home.noRecommendationMessage')}
        </Typography>
      )}
      <DialogContent className={classes.paper}>
        {recommendations && recommendations.length > 0
          ? recommendations
              .sort((a, b) => a.id - b.id)
              .map(item => (
                <ForYouCard
                  key={item.id}
                  onPress={navigateOnClick.bind(this, item.redirectUrl)}
                  image={item.imageUrl}
                  title={item.title}
                  description={item.description}
                />
              ))
          : null}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} color={config.internalConfig.BUTTON_BACKGROUND} variant="contained" className={'containedButton'}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendationDialog;
