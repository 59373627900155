import {
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  withTheme
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { IBaseCategory, IPreferenceCategory, IPreferenceOption } from '@pbl/pbl-react-core/lib/models/preferences';
import styles from 'assets/jss/modules/settings/preferences/components/PreferenceStyle';

interface IItemProps {
  key: string;
  label: string;
  booleanValue: boolean;
  subCategories?: IBaseCategory[];
  options?: IPreferenceOption[];
}

export interface IPreferenceProps extends WithStyles<typeof styles> {
  item: IItemProps;
  onToggle: (key: string) => void;
  indent?: number;
  theme: Theme;
  handlePreferenceDropdownChange: (subCategory: IPreferenceCategory, value: number) => void;
}

interface IPreferenceState {
  [key: string]: boolean;
}

class Preference extends React.Component<IPreferenceProps, IPreferenceState> {
  public state: IPreferenceState = {};

  public async componentDidMount() {
    await this.updateState();
  }

  public componentDidUpdate(prevProps: Readonly<IPreferenceProps>): void {
    if (
      prevProps.item.key === this.props.item.key &&
      (prevProps.item.booleanValue !== this.props.item.booleanValue || prevProps.item.subCategories !== this.props.item.subCategories)
    ) {
      this.updateState();
    }
  }
  public updateState() {
    const {
      item: { key, booleanValue, subCategories }
    } = this.props;

    const collapseState = _.reduce(
      subCategories,
      (result, subCategory) => ({
        ...result,
        // @ts-ignore
        [subCategory.key]: this.shouldExpand(subCategory.booleanValue, subCategory.subCategories)
      }),
      {
        [key]: this.shouldExpand(booleanValue, subCategories)
      }
    );

    this.setState(collapseState);
  }
  private handleChange = (item, event: any): void => {
    this.props.handlePreferenceDropdownChange(item, event.target.value);
  };

  public render() {
    const { item, indent } = this.props;
    return this.renderItem(item, indent || 1, null, true);
  }

  private renderItem = (item: IItemProps, indent: number, value?: any, isMainCategory?: boolean) => {
    const { classes, theme } = this.props;
    const { booleanValue, label, key, subCategories, options } = item;
    const hasSubCategories = !_.isEmpty(subCategories);

    // @ts-ignore
    const sortedSubCategories = _.sortBy(subCategories, c => !_.isEmpty(c.subCategories));
    let collapsible;
    let subContent;
    if (hasSubCategories) {
      subContent = (
        <List disablePadding={true} component={'div'}>
          {_.map(sortedSubCategories, subItem =>
            this.renderItem(
              {
                key: subItem.key,
                booleanValue: subItem.booleanValue || false,
                label: subItem.displayName ?? '',
                // @ts-ignore
                subCategories: subItem.subCategories,
                options: subItem.options
              },
              // @ts-ignore
              !_.isEmpty(subItem.subCategories) ? indent * 2 : indent,
              subItem.value,
              subItem['mainCat']
            )
          )}
        </List>
      );
    } else {
      collapsible = <span style={{ width: 24 }} />;
    }

    return (
      <React.Fragment key={`${key}_fragment`}>
        <ListItem
          component={'div'}
          className={classNames({
            [classes.item]: !hasSubCategories,
            [classes.groupItem]: hasSubCategories
          })}
          style={{
            marginLeft: 0,
            paddingLeft: !isMainCategory ? theme.spacing() * 4 : theme.spacing() * 2
          }}
        >
          <ListItemText
            disableTypography={true}
            primary={<Typography variant={hasSubCategories ? 'subtitle2' : 'body2'}>{label}</Typography>}
          />
          {options && options.length > 0 ? (
            <FormControl variant="filled" className={classes.formControl}>
              <Select labelId="pref-options" value={value.id} onChange={this.handleChange.bind(this, item)}>
                {options.map((opt, index: number) => (
                  <MenuItem key={index} value={opt.id}>
                    {opt.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Switch
                inputProps={{
                  'aria-label': item.label,
                  style: { padding: 0 }
                }}
                onChange={this.toggle.bind(this, key)}
                checked={booleanValue}
              />
              {collapsible}
            </div>
          )}
        </ListItem>
        <Divider
          className={classNames({
            [classes.groupDivider]: hasSubCategories
          })}
          style={{
            marginLeft: !isMainCategory ? theme.spacing() * 4 : theme.spacing() * 2,
            paddingLeft: 0
          }}
        />
        {subContent}
      </React.Fragment>
    );
  };

  private toggle = key => {
    this.props.onToggle(key);
  };

  private shouldExpand = (booleanValue: boolean, subCategories?: IBaseCategory[]) => {
    if (booleanValue) {
      return true;
    }

    if (!subCategories) {
      return false;
    }

    return _.findIndex(subCategories, val => !!val.booleanValue) >= 0;
  };
}

export default withStyles(styles)(withTheme(Preference));
