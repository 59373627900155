import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import * as FilterUtils from '@pbl/pbl-react-core/lib/models/filters/filsters-utils';
import { buildQueryFromCategory } from '@pbl/pbl-react-core/lib/models/games/filters';
import {
  ALL_RELATED_GAMES,
  FETCH_GAME_META,
  GAME_BY_ID,
  GAME_DRAWS,
  GAME_PRIZES,
  GET_FEATURED_GAME,
  GET_JACKPOT_RESULTS_FOR_GAME,
  GET_NEXT_JACKPOT_RESULT,
  IJackpotStatus,
  PUBLISHED_GAMES,
  RELATED_GAMES,
  RESET_GAME,
  RESET_STATE
} from '@pbl/pbl-react-core/lib/models/games/types';
import { GamesService, PromotionsService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import * as FilterActions from 'shared/components/filters/filters.actions';
import { GetStateMethod } from '..';
import { clearMessages, showMessageBar } from '../app/actions';

export const fetchPublishedGames =
  (page?: number, size?: number, sort?: string, type?: string, excludeFeatured?: boolean) => async (dispatch: any, getState: GetStateMethod) => {
    try {
      const {
        games: { filters, featuredGame }
      } = getState();

      const filterOutFeatured = !!excludeFeatured && !!featuredGame ? `&id.notIn=${featuredGame.id}` : '';

      await dispatch({
        type: PUBLISHED_GAMES,
        payload: GamesService.getPublishedGames(
          page,
          size,
          sort,
          FilterUtils.createQuery({ filters, buildQueryFromCategory }, constants.NEW_GAME_PERIOD, constants.UPCOMING_GAME_PERIOD).join('&') + filterOutFeatured,
          type
        )
      });
    } catch (error) {
      console.error(error);
      dispatch(showMessageBar({ message: error.payload ? error.payload.error : error.message, type: 'error' }));
    }
  };

export const fetchFeaturedGame = (gameType?: string, sort?: string, filter?: string) => async (dispatch: any) => {
  await dispatch({
    type: GET_FEATURED_GAME,
    payload: GamesService.getFeaturedGame(gameType, sort, filter)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchGame = (gameId: number) => async (dispatch: any) => {
  await dispatch({
    type: GAME_BY_ID,
    payload: GamesService.getPublishedGame(gameId)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchPrizes = (gameId: number) => async (dispatch: any) => {
  await dispatch({
    type: GAME_PRIZES,
    payload: GamesService.getPrizesForGame(gameId)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchDraws = (gameId: number, page?: number, sort?: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GAME_DRAWS,
      payload: GamesService.getDrawsForGame(gameId, constants.GAME_DRAWS_PER_PAGE, page, sort)
    });
  } catch (error) {
    console.error(error);
    dispatch(showMessageBar({ message: error.payload.error, type: 'error' }));
  }
};

export const fetchPromotionsForGame = (gameId: number, page?: number, sort?: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(GAME_DRAWS)
    });
    const drawIds = await GamesService.getAllDrawIdsForGame(gameId);
    let payload;
    if (drawIds && drawIds.length > 0) {
      payload = await PromotionsService.getAllPromotionsByDrawPaged(drawIds, page, constants.GAME_DRAWS_PER_PAGE, sort);
    }
    await dispatch({
      type: SUCCESS(GAME_DRAWS),
      payload
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GAME_DRAWS),
      payload: { error }
    });
  }
};

export const fetchRelatedGames = (gameId: number) => async (dispatch: any) => {
  await dispatch({
    type: RELATED_GAMES,
    payload: GamesService.getRelatedGames(gameId, constants.NUM_RELATED_GAMES)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const getAllRelatedGames = (gameId: number) => async (dispatch: any) => {
  await dispatch({
    type: ALL_RELATED_GAMES,
    payload: GamesService.getAllRelatedGames(gameId)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const resetState = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: RESET_STATE
  });
};

export const resetGameState = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: RESET_GAME
  });
};

export const toggleFilter = (entityName: string, sectionKey: string, filterKey: string) => (dispatch: any) => {
  dispatch(FilterActions.toggleFilter(entityName, sectionKey, filterKey));
};

export const clearFilter = () => (dispatch: any) => {
  dispatch(FilterActions.clearFilter('game'));
};

export const replaceFilters = (filters: IFilters) => (dispatch: any) => {
  dispatch(FilterActions.replaceFilers('game', filters));
};

export const fetchGameMeta = () => async (dispatch: any) => {
  try {
    const [gameTypes, gameTags, gameTagTypes, gameRetailPrices] = await Promise.all([
      GamesService.getGameTypes(),
      GamesService.getGameTags(),
      GamesService.getGameTagTypes(),
      GamesService.getGameRetailPrices()
    ]);
    dispatch({
      type: FETCH_GAME_META,
      payload: {
        gameTypes,
        gameTags,
        gameTagTypes,
        gameRetailPrices
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getLatestJackpotResultForTheGame = (gameId: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_NEXT_JACKPOT_RESULT,
    payload: GamesService.getLatestJackpotResultByStatus(gameId, IJackpotStatus.OPEN)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const getJackpotResultsForTheGame = (gameId: number, page: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_JACKPOT_RESULTS_FOR_GAME,
    payload: GamesService.getJackpotResultsForTheGame(gameId, IJackpotStatus.PAYABLE, page, constants.JACKPOT_RESULTS_PER_PAGE)
  }).catch((error: Error) => {
    console.error(error);
  });
};
