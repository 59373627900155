export interface IHomeEarnActivity {
  activityId: string;
  title: string;
  description: string;
  icon: string;
  iconType: 'material' | 'custom';
  navigateTo?: string;
  navigateState?: { [s: string]: any };
}

const earnActivities: IHomeEarnActivity[] = [
  {
    activityId: '0',
    title: 'Submit Ticket',
    description: 'Earn upto 1,000 points',
    icon: 'icon-barcode-Scan',
    iconType: 'custom',
    navigateTo: '/ticket-entry',
    navigateState: { from: '/' }
  },
  {
    activityId: '1',
    title: 'Invite Friends',
    description: 'Earn upto 1,000 points',
    icon: 'icon-share',
    iconType: 'custom',
    navigateTo: '/invite-friends'
  },
  {
    activityId: '2',
    title: 'Promo Code',
    description: 'Submit and earn points',
    icon: 'icon-promo-code',
    iconType: 'custom',
    navigateTo: '/promo-code'
  }
];

export default earnActivities;
