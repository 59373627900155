import { ISpinnerState, SpinnerState, TOGGLE_SPINNER } from '@pbl/pbl-react-core/lib/models/spinner';
import { DispatchMethod, IDispatchAction } from './index';

const INITIAL_STATE: ISpinnerState = {
  spinning: false,
  label: 'Loading'
};

export default (state: SpinnerState = INITIAL_STATE, action: IDispatchAction): SpinnerState => {
  switch (action.type) {
    case TOGGLE_SPINNER:
      return {
        ...state,
        spinning: action.payload.spinning || !state.spinning,
        label: action.payload.label || state.label
      };
    default:
      return state;
  }
};

export const toggleSpinner = (spinnerProps?: SpinnerState) => (dispatch: DispatchMethod) => {
  const tempProps = spinnerProps || {};
  const spinning = tempProps.spinning;
  const label = tempProps.label;

  dispatch({
    type: TOGGLE_SPINNER,
    payload: { spinning, label }
  });
};
