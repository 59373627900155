import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

export default (theme: Theme) =>
  createStyles({
    '@global': {
      html: {height: '100%'}
    },
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(-1),
      minHeight: '100%',
      textAlign: 'center',
    },
    titleText: {
      marginBottom: theme.spacing(3),
    },
    bottomSpace: {
      marginBottom: theme.spacing(6),
      whiteSpace: 'pre-line'
    },
    preLine: {
      whiteSpace: 'pre-line'
    }
  });
  