import { createStyles } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = () =>
  createStyles({
    root: {
      ...container
    },
    collectTable: {
      display: 'flex',
      padding: '16px 16px 16px 0px'
    },
    firstSec: {
      background: '#e9ecf0',
      display: 'flex',
      width: '48%',
      borderRadius: 20,
      alignItems: 'center'
    },
    secondSec: {
      background: '#0a548b',
      display: 'flex',
      width: '48%',
      borderRadius: 20,
      marginLeft: '16px',
      alignItems: 'center',
      '@media (max-width: 768px)': {
        marginTop: '16px',
        marginLeft: '0px'
      }
    },
    progressButton: {
      border: '1px solid #FFFFFF',
      borderRadius: '10px',
      color: '#FFFFFF',
      width: '70%',
      wordBreak: 'break-word'
    },
    linearColorPrimary: {
      backgroundColor: '#25394d',
      margin: '10px 10px 10px 0px',
      borderRadius: '15px',
      height: '8px'
    },
    linearBarColorPrimary: {
      backgroundColor: '#82d1f0'
    }
  });

export default styles;
