import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  InputAdornment,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  withTheme
} from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import { Address } from '@pbl/pbl-react-core/lib/models/forms';
import { AddressType, IProfileState, PhoneType } from '@pbl/pbl-react-core/lib/models/profile/types';
import { utcDateTimeFor } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import DateFieldValidator from '@pbl/pbl-react-web-components/lib/field/DateFieldValidator';
import PhoneInputMask from '@pbl/pbl-react-web-components/lib/field/PhoneInputMask';
import TextFieldValidator from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import { InformationDialog } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/settings/profile/ProfileScreenStyle';
import validation, { phoneMask } from 'config/validation';
import VerificationFlowComponent from 'modules/id-verification/VerificationFlowComponent';
import moment from 'moment';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { conformToMask } from 'react-text-mask';
import { IRootState } from 'redux/reducers';
import { getCities, getStates, resetCities, resetStates } from 'redux/reducers/address/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { getProfile, updateProfile } from 'redux/reducers/profile/actions';
import AddressComponent from 'shared/components/input/Address';
import SnackbarContent from '../../../components/Snackbar/SnackbarContent';
import {
  addressSchema,
  dateOfBirthValidator,
  firstNameValidator,
  lastNameValidator,
  phoneNumberValidator,
  profileSchema
} from './validations';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IProfileScreenProps extends StateProps, DispatchProps, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

class ProfileScreen extends React.Component<IProfileScreenProps> {
  public state = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    address: new Address(),
    apartmentNumber: '',
    phone: '',
    gender: null,
    formIsValid: false,
    formIsDirty: false,
    email: '',
    completeActivityPoints: 0,
    showModal: false,
    fieldsDisabled: false,
    showEditButton: false,
    openIdVerificationModal: false
  };

  private inputHandlers = [];

  public async componentDidMount(): Promise<void> {
    document.title = 'Profile';
    await this.props.getProfile();
    this.updateProfileState();
  }

  public componentWillUnmount() {
    this.props.setIsFormDirty(false);
    this.props.resetStates();
    this.props.resetCities();
  }

  public render() {
    const {
      classes,
      profile: {
        loading,
        userProfile: { completedDate }
      },
      theme,
      t,
      authorizedFlags
    } = this.props;
    const { formIsValid, formIsDirty, firstName, lastName, gender, dateOfBirth, address, phone, completeActivityPoints, fieldsDisabled, showModal, showEditButton } = this.state;
    const { idologyValid } = this.props.profile?.userProfile || ({} as IProfileState);
    let phoneMaskedValue = '';
    if (!!phone && phone.toString().length > 0) {
      phoneMaskedValue = conformToMask(phone, phoneMask, {}).conformedValue;
    }

    return (
      <div>
      <Grid container={true} spacing={3} direction="column">
        <InformationDialog open={showModal} onClose={this.closeModal} maxWidth={'sm'} hideButton={true} disableBackdropClick={true}>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" align={'center'} >
                {t('profile.editFields')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography variant="body2" align={'center'} >
                {t('profile.editFieldsBody')}
              </Typography>
            </Grid>
            </Grid>
            <Grid item={true} xs={12} className={classes.buttonContainer}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={this.closeModal}
                aria-label="Cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.editFields}
                aria-label="Continue"
                style={{ marginLeft: theme.spacing(2) }}
              >
                Continue
              </Button>
            </Grid>
        </InformationDialog>
        {this.state.openIdVerificationModal && (
          <VerificationFlowComponent
            onFlowClosed={this.onCloseIdentityVerificationModal}
            onIdentityVerified={this.onProfileVerified}
            onIdentityFailed={this.onCloseIdentityVerificationModal}
          />
        )}
        {this.props.address.loading ? <AppSpinner label={'Loading...'} /> : null}
        <Prompt when={this.props.formMetadata.isFormDirty} message={this.props.t('profile.promptMessage')} />
        {!completedDate && completeActivityPoints && completeActivityPoints > 0 ? (
          <Grid container={true} spacing={3} justify="space-between" className={classes.formRow}>
            <SnackbarContent
              message={`Complete your profile for ${completeActivityPoints.toPointsFormat()} Players Club Points!`}
              color="primary"
              icon="favorite"
            />
          </Grid>
        ) : null}
        <Grid container={true} direction="row" alignItems="center" style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          <Icon color="primary" fontSize="small" style={{ marginLeft: theme.spacing(), marginRight: theme.spacing() }}>
            person
          </Icon>
          <Typography variant="subtitle1" component="span">Personal Information</Typography>
        </Grid>
        <Grid container={true} spacing={3} justify="space-between" className={classes.formRow}>
          <Grid item={true} xs={12} sm={12} md={6}>
            <TextFieldValidator
              required={true}
              id="firstName"
              label="First Name"
              className={classes.field}
              value={firstName}
              onChange={this.handleTextFieldChange('firstName')}
              onBlur={this.onBlur}
              validationSchema={firstNameValidator}
              disabled={fieldsDisabled}
              variant={fieldsDisabled ? 'filled' : 'standard'}
              inputProps={{ maxLength: validation.maxNameLength, 'aria-required': true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { fieldsDisabled && (
                      <Lock/>)}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6}>
            <TextFieldValidator
              required={true}
              id="lastName"
              label="Last Name"
              className={classes.field}
              value={lastName}
              onChange={this.handleTextFieldChange('lastName')}
              onBlur={this.onBlur}
              validationSchema={lastNameValidator}
              disabled={fieldsDisabled}
              variant={fieldsDisabled ? 'filled' : 'standard'}
              inputProps={{ maxLength: validation.maxNameLength, 'aria-required': true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { fieldsDisabled && (
                      <Lock/>)}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid item={true}>
          <DateFieldValidator
            id={'dateOfBirth'}
            required={true}
            label="Date of birth"
            value={dateOfBirth}
            labelFunc={this.formatDate}
            onChange={this.handleDateChange}
            className={classes.field}
            validationSchema={dateOfBirthValidator}
            minDate={validation.minDateOfBirth}
            maxDate={validation.maxDateOfBirth}
            maxDateMessage={'user must be 21 or older'}
            error={moment(dateOfBirth).isBetween(validation.minDateOfBirth, validation.maxDateOfBirth) ? false : true}
            inputVariant={fieldsDisabled ? 'filled' : 'standard'}
            disabled={fieldsDisabled}
            inputProps={{ 'aria-required': true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  { fieldsDisabled && (
                    <Lock/>)}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <AddressComponent
          contentClass={classes.addressContent}
          address={address}
          handleTextFieldChange={this.handleAddressSelect}
          fieldVariant={'standard'}
          getStates={this.props.getStates}
          getCities={this.props.getCities}
          countries={this.props.address.countries}
          states={this.props.address.states}
          cities={this.props.address.cities}
          resetStates={this.props.resetStates}
          resetCities={this.props.resetCities}
          loadingCities={this.props.address.loading}
        />
        <Grid item={true}>
          <TextFieldValidator
            id="phone"
            label="Phone Number"
            className={classes.field}
            value={phoneMaskedValue}
            onBlur={this.onBlur}
            onChange={this.handleTextFieldChange('phone')}
            InputProps={{ inputComponent: PhoneInputMask as any }}
            validationSchema={phoneNumberValidator}
          />
        </Grid>
        <Grid item={true}>
          <FormLabel>Gender</FormLabel>
          <RadioGroup name="gender" row={true} value={gender || ''} onChange={this.handleTextFieldChange('gender')}>
            <FormControlLabel htmlFor={'female'} value="FEMALE" control={<Radio id={'female'}/>} label="Female" />
            <FormControlLabel htmlFor={'male'} value="MALE" control={<Radio id={'male'}/>} label="Male" />
            <FormControlLabel htmlFor={'neutral'} value="NEUTRAL" control={<Radio id={'neutral'}/>} label="Prefer not to say" />
          </RadioGroup>
        </Grid>
        <Grid item={true}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={loading || !formIsValid || !formIsDirty}
            onClick={this.handleSubmit}
            aria-label="Save"
          >
            Save
          </Button>
          { (!idologyValid && authorizedFlags?.includes('SWEEPSTAKES_V1')) && (<Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            style={{ marginLeft: theme.spacing(2) }}
            onClick={this.openIdVerificationModal}
            aria-label="Verify"
          >
            Verify
          </Button>)}
          { showEditButton && (
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.showModal}
              aria-label="Edit"
              style={{ marginLeft: theme.spacing(2) }}
              startIcon={<LockOpen />}
            >
              Edit
            </Button>)}
        </Grid>
      </Grid>
      </div>
    );
  }

  private handleTextFieldChange = (attr: string) => {
    if (!this.inputHandlers[attr]) {
      this.inputHandlers[attr] = async event => {
        const value = event.target.value;
        this.setState({ [attr]: value, formIsDirty: true }, () => {
          const { firstName, lastName, dateOfBirth, phone } = this.state;
          const formIsValid = profileSchema.isValidSync({ firstName, lastName, dateOfBirth, phone });
          this.setState({ formIsValid });
          this.props.setIsFormDirty(true);
        });
      };
    }
    return this.inputHandlers[attr];
  };

  private handleDateChange = dateOfBirth => {
    const { firstName, lastName, phone, address } = this.state;
    let addressIsValid = true;
    dateOfBirth = moment(dateOfBirth).format('YYYY-MM-DD');
    this.setState({ dateOfBirth }, () => {
      const formIsValid = profileSchema.isValidSync({ firstName, lastName, dateOfBirth, phone });
      if (address && address.country) {
        addressIsValid = addressSchema.isValidSync({
          address1: address.address1,
          city: address.city,
          zipCode: address.zipCode,
          country: address.country,
          state: address.state
        });
      }
      this.setState({ formIsValid: formIsValid && addressIsValid, formIsDirty: true });
    });
  };

  private formatDate = date => (date ? date.format('DD MMM YYYY') : '');

  private handleAddressSelect = (field: string, value: string) => {
    const { firstName, lastName, dateOfBirth, phone, address } = this.state;
    if (address.hasOwnProperty(field) && address[field] !== value) {
      address[field] = value;
      const formIsValid = profileSchema.isValidSync({ firstName, lastName, dateOfBirth, phone });
      const addressIsValid = addressSchema.isValidSync({
        address1: address.address1,
        city: address.city,
        zipCode: address.zipCode,
        country: address.country,
        state: address.state
      });
      this.setState({ address, formIsValid: formIsValid && addressIsValid, formIsDirty: true }, () => {
        this.props.setIsFormDirty(true);
      });
    }

  };

  private onBlur = () => {
    const { firstName, lastName, dateOfBirth, phone } = this.state;
    const formIsValid = profileSchema.isValidSync({ firstName, lastName, dateOfBirth, phone });
    this.setState({ formIsValid });
  };

  private updateProfileState = () => {
    const { profile } = this.props;
    const userProfile = profile.userProfile || ({} as IProfileState);
    const { firstName, lastName, birthDate, addresses, gender, phones, email, completeActivityPoints, idologyValid } = userProfile;
    const homeAddress = addresses.filter(x => x.addressTypeId === AddressType.HOME);
    const homePhone = phones
                        .filter(p => p.phoneTypeId === PhoneType.MOBILE && (p.phone?.trim()?.length || 0) > 0)
                        .sort((x, y) => y.id - x.id);
    const addressObject = homeAddress ? new Address(homeAddress[0]) : new Address();
    const apartmentNumber = addressObject ? addressObject.apartmentNumber : '';

    if (idologyValid) {
      this.setState({ fieldsDisabled: true, showEditButton: true });
    }

    this.setState({
      firstName: firstName || '',
      lastName: lastName || '',
      dateOfBirth: birthDate ? utcDateTimeFor(birthDate) : null,
      address: addressObject,
      gender: gender || '',
      phone: homePhone?.[0]?.phone,
      email,
      apartmentNumber: apartmentNumber || '',
      completeActivityPoints: completeActivityPoints || 0
    });
  };

  private handleSubmit = () => {
    let phone = this.state.phone;
    let gender = this.state.gender;
    phone = phone?.replace(/\D+/g, '');
    if (gender === '') {
      gender = null;
    }
    this.setState({ phone, gender }, () => {
      this.props.updateProfile(this.state);
      this.props.setIsFormDirty(false);
    });
  };

  private openIdVerificationModal = async () => {
    this.setState({ openIdVerificationModal: true });
  };

  private onCloseIdentityVerificationModal = async () => {
    this.setState({ openIdVerificationModal: false });
  };

  private onProfileVerified = async () => {
    await this.updateProfileState();
    this.setState({ openIdVerificationModal: false });
  };

  private closeModal = () => {
    this.setState({ showModal: false });
  }

  private showModal = () => {
    this.setState({ showModal: true });
  }

  private editFields = () => {
    this.setState({ fieldsDisabled: false, showModal: false, showEditButton: false });
  }
}

const mapStateToProps = ({ authentication, profile, formMetadata, address, feature: { authorizedFlags } }: IRootState) => ({
  authentication,
  profile,
  formMetadata,
  address,
  authorizedFlags
});

const mapDispatchToProps = {
  getProfile,
  updateProfile,
  setIsFormDirty,
  getStates,
  getCities,
  resetCities,
  resetStates
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation()(withTheme(withStyles(styles)(ProfileScreen))));
