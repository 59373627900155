import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import * as FilterUtils from '@pbl/pbl-react-core/lib/models/filters/filsters-utils';
import { customizeFilters } from '@pbl/pbl-react-core/lib/models/games/filters';
import { GamesState, IGame, IGamePage } from '@pbl/pbl-react-core/lib/models/games/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import FeaturedCard from '@pbl/pbl-react-web-components/lib/card/FeaturedCard';
import FilterSection from '@pbl/pbl-react-web-components/lib/filters/FilterSection';
import GameCard from '@pbl/pbl-react-web-components/lib/games/GameCard';
import NoResultsFound from '@pbl/pbl-react-web-components/lib/no-results/NoResultsFound';
import Pagination from '@pbl/pbl-react-web-components/lib/pagination/Pagination';
import Title from '@pbl/pbl-react-web-components/lib/title/Title';
import noImage from 'assets/img/no-image-featured.svg';
import styles from 'assets/jss/modules/games/GamesScreenStyle';
import constants from 'config/constants';
import _ from 'lodash';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import {
  fetchFeaturedGame,
  fetchGameMeta,
  fetchPublishedGames,
  replaceFilters,
  resetState,
  toggleFilter
} from 'redux/reducers/games/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

const FILTER_GAME_TYPES: String[] = constants.DEFAULT_GAME_TYPE.split(',');
const FILTER_GAME_STATUSES: String[] = ['new', 'active', 'ended'];
const FILTER_TOP_PRIZES: String[] = [
  't4999',
  'f5000-t50000',
  'f50000-t100000',
  'f100000-t250000',
  'f250000-t500000',
  'f500000',
  'f1000000',
  'other'
];

interface IMatchParams {
  gameTypeId: string;
}

export interface IGamesScreenProps extends PropsConnected, RouteComponentProps<IMatchParams>, WithTranslation, WithStyles<typeof styles> {
  games: GamesState;
  theme: Theme;
}

interface IGamesScreenState {
  activePage: number;
  itemsPerPage: number;
  showFilters: boolean;
  gameTypeId: string;
  sort: string;
  filter: string;
  excludeFeatured: boolean;
}

type PropsConnected = ConnectedProps<typeof connector>;

class GamesScreen extends React.Component<IGamesScreenProps, IGamesScreenState> {
  constructor(props: IGamesScreenProps) {
    super(props);

    const { authorizedFlags } = this.props;
    const enableQuickDraw = !!authorizedFlags && authorizedFlags.includes('QUICKDRAW_V1');
    if (enableQuickDraw) {
      FILTER_GAME_TYPES.push('7');
    }

    this.state = {
      activePage: 1,
      itemsPerPage: constants.GAMES_PER_PAGE,
      showFilters: false,
      gameTypeId: FILTER_GAME_TYPES.join(','),
      sort: 'playBegin,desc',
      filter: 'active.equals=true',
      excludeFeatured: true
    };
  }

  public async componentDidMount() {
    document.title = '';
    const { location, authorizedFlags } = this.props;

    const enableQuickDraw = !!authorizedFlags && authorizedFlags.includes('QUICKDRAW_V1');
    customizeFilters(this.props.games.filters, FILTER_GAME_STATUSES, FILTER_TOP_PRIZES, FILTER_GAME_TYPES, enableQuickDraw);

    const locationState: any = location && location.state ? location.state : {};

    if (locationState.message) {
      this.props.showMessageBar(locationState.message);
    }
    this.props.resetState();

    await Promise.all([this.props.fetchGameMeta(), this.props.fetchFeaturedGame(this.state.gameTypeId, `&sort=${this.state.sort}`, `&${this.state.filter}`)]);
    await this.filterGames();
  }

  public async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.location.reload();
    }
  }

  private async filterGames() {
    const {
      match: { params }
    } = this.props;
    const { filters } = this.props.games;
    const locationState: any = this.props.location && this.props.location.state ? this.props.location.state : {};

    if (locationState.filters !== undefined && locationState.filters !== null) {
      this.onFilterDone(locationState.filters);
    } else {
      const gameType = params.gameTypeId || this.state.gameTypeId;
      const gameTypeFilter = filters['GameType'];
      document.title = this.props.t('games.title');
      if (!!gameTypeFilter && !!gameTypeFilter.filters) {
        gameTypeFilter.filters.forEach(f => {
          f.isSelected = f.value === gameType;
          if (f.isSelected) {
            document.title = this.props.t(f.name);
          }
        });
      }
      this.onFilterDone(filters, this.state.excludeFeatured);
    }
  }

  public componentWillUnmount() {
    this.props.resetState();
  }

  public getNextPage = async (pageNumber: number) => {
    // @TODO check why selectedFilter is blank.
    const selectedFilter: any = undefined;
    const gameTypeId = !!selectedFilter ? selectedFilter.value : FILTER_GAME_TYPES.join(',');
    await this.props.fetchPublishedGames(pageNumber, this.state.itemsPerPage, this.state.sort, gameTypeId, this.state.excludeFeatured);
  };

  public getPagination = (page: IGamePage, classes: any): JSX.Element => (
    <Grid item={true} xs={12} className={classes.centered}>
      <Pagination
        first={page.first}
        last={page.last}
        activePage={page.number}
        totalPages={page.totalPages}
        onSelect={this.getNextPage}
        maxButtons={4}
        marginPagesDisplayed={0}
      />
    </Grid>
  );

  public selectGame = (game: IGame): void => {
    if (game.externalLink && game.gameTypeId !== 7) {
      window.open(game.externalLink, '_blank');
    } else if (game.gameTypeId === 2) {
      this.props.history.push(`/games-draw/${game.id}`);
    } else if (game.gameTypeId === 7) {
      this.props.history.push(`/games-quick-draw/${game.id}`);
    } else {
      this.props.history.push(`/games/${game.id}`);
    }
  };

  public getFeaturedGame = (): React.ReactNode => {
    const { featuredGame, filters } = this.props.games;

    const showFilters = FilterUtils.hasSelected(filters);

    return featuredGame && !_.isEmpty(featuredGame) && !showFilters ? (
      <FeaturedCard
        onButtonClick={this.selectGame.bind(this, featuredGame)}
        title={`Retail Price: ${featuredGame.retailPrice.toCurrencyFormat()}`}
        textDescription={featuredGame.description}
        buttonText="View Details"
        image={featuredGame.ticketFullImage}
        imageTitle={`${featuredGame.name}`}
        text={featuredGame.name}
        textFooter={this.props.t('games.topPrizeType.' + featuredGame.topPrizeType) + featuredGame.getTopPrize()}
        iconFooter={'icon-games-filled'}
        noImageAsset={noImage}
      />
    ) : null;
  };

  public renderGamesList = (): JSX.Element => {
    const {
      classes,
      games: { games, page, loading },
      t
    } = this.props;

    if (!loading && (_.isUndefined(page) || (page.number === 0 && games.length === 0))) {
      return <NoResultsFound title={t('games.noResults')} />;
    }

    return (
      <Grid container={true} spacing={4}>
        {page.totalElements > constants.GAMES_PER_PAGE && this.getPagination(page, classes)}
        {games &&
          games.map(game => (
            <Grid item={true} xs={12} sm={12} md={4} key={game.id}>
              <GameCard game={game} onPress={this.selectGame.bind(this, game)} mediaClass={classes.gameCardImage} />
            </Grid>
          ))}
        {page.totalElements > constants.GAMES_PER_PAGE && this.getPagination(page, classes)}
      </Grid>
    );
  };

  public render() {
    const { classes, history, location, t } = this.props;
    const { filters, loading } = this.props.games;
    const navigateToTicketEntry = () => {
      history.push('/ticket-entry', { from: location.pathname });
    };
    let content;
    if (loading) {
      content = <AppSpinner label={t('games.loading').toString()} />;
    } else {
      content = (
        <Grid container={true}>
          <ScrollToTopOnMount />
          <Grid item={true} xs={12}>
            <div className={classes.header}>
              <Title
                title={t('games.title')}
                titleColor={'textPrimary'}
                buttonText={t('games.enterTicket')}
                buttonTextTitle={t('games.ticketEntry')}
                icon={'icon-games-filled'}
                navigateToTicketEntry={navigateToTicketEntry}
              />
            </div>
          </Grid>

          {this.getFeaturedGame()}

          <FilterSection
            entityName="game"
            title={t('games.browse')}
            filterTitle={t('games.filter')}
            filters={filters}
            onOk={this.onFilterDone}
            onRemoveSelected={this.onRemoveSelectedFilter}
          />

          {this.renderGamesList()}
        </Grid>
      );
    }

    return <div className={classes.root}>{content}</div>;
  }

  private onFilterDone = (filters: IFilters, excludeFeatured?: boolean) => {
    const gameTypeFilter = filters['GameType'];
    let selectedFilter;

    if (gameTypeFilter && gameTypeFilter.filters) {
      selectedFilter = gameTypeFilter.filters.find(f => f.isSelected);
    }
    const gameTypeId = !!selectedFilter ? selectedFilter.value : FILTER_GAME_TYPES.join(',');

    this.setState({
      gameTypeId
    });
    if (gameTypeId === FILTER_GAME_TYPES.join(',') && this.props.location.pathname !== '/games') {
      this.props.history.push('/games');
    } else {
      this.props.replaceFilters(filters);
      this.props.fetchPublishedGames(0, this.state.itemsPerPage, this.state.sort, gameTypeId, excludeFeatured);
    }
  };

  private onRemoveSelectedFilter = (entityName: string, sectionKey: string, filterKey: string) => {
    this.props.toggleFilter(entityName, sectionKey, filterKey);
    const { filters } = this.props.games;
    if (sectionKey === 'GameType') {
      if (this.props.location.pathname !== '/games') {
        this.props.history.push({
          pathname: '/games',
          state: { filters }
        });
      } else {
        this.props.fetchPublishedGames(0, this.state.itemsPerPage, this.state.sort, FILTER_GAME_TYPES.join(','), this.state.excludeFeatured);
      }
    } else {
      this.props.fetchPublishedGames(0, this.state.itemsPerPage, this.state.sort, FILTER_GAME_TYPES.join(','), this.state.excludeFeatured);
    }
  };
}

const mapStateToProps = ({ games, feature: { authorizedFlags } }) => ({
  games,
  authorizedFlags
});

const mapDispatchToProps = {
  fetchPublishedGames,
  fetchFeaturedGame,
  resetState,
  toggleFilter,
  fetchGameMeta,
  replaceFilters,
  showMessageBar
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(GamesScreen)))));
