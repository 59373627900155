import { Grid, Icon, Link as LinkComponent, Tooltip } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/shared/components/footer/FooterStyles';
import classNames from 'classnames';
import { INavItem } from 'config/menu';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface ISocialMenuProps {
  mainMenu: INavItem[];
  isAuthenticated: boolean;
}

interface IProps extends ISocialMenuProps, WithStyles<typeof styles> {}

const SocialMenu: React.FunctionComponent<IProps> = ({ mainMenu, classes, isAuthenticated }) => {
  const renderMenuItem = (item: INavItem) => {
    // @ts-ignore
    const renderLink = (props: ButtonProps & { children?: React.ReactNode }) => <Link {...props} to={item.route} />;
    if (item.isExternal) {
      return (
        <Grid className={classes.listItemSocial} key={`${item.title}-${item.route}`}>
          <Tooltip title={item.title} aria-label={item.title}>
            <a
              href={item.route}
              target="_blank"
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: !!item.isActive
              })}
              color="primary"
            >
              <Icon color="inherit" className={classNames(item.icon, classes.navLinkIcon)} />
            </a>
          </Tooltip>
        </Grid>
      );
    } else {
      return (
        <Grid className={classes.listItemSocial} key={`${item.title}-${item.route}`}>
          <Tooltip title={item.title} aria-label={item.title}>
            <LinkComponent
              component={renderLink}
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: !!item.isActive
              })}
              color="primary"
            >
              <Icon color="inherit" className={classNames(item.icon, classes.navLinkIcon)} />
            </LinkComponent>
          </Tooltip>
        </Grid>
      );
    }
  };
  const menu = isAuthenticated ? mainMenu.filter(x => !x.hideOnAuth) : mainMenu.filter(x => !x.hideOnUnAuth);
  return (
    <Grid item={true} container={true} alignItems="center" justify="center">
      {menu.map(item => renderMenuItem(item))}
    </Grid>
  );
};
export default withStyles(styles)(SocialMenu);
