import { Card, CardMedia, Hidden, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { react_core_config } from '@pbl/pbl-react-core/lib/config/config';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import dateTimeFormatter from '@pbl/pbl-react-core/lib/utils/DateTimeFormatter';
import { formatLongMonthDayCommaYear } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import RedeemModal from '@pbl/pbl-react-web-components/lib/arcade/RedeemModal';
import {
  ArcadeHeader,
  ArcadeMessagePopup,
  DetailsLowerContent,
  DetailsUpperContent,
  GeoLocationMessagePopUp,
  PastDrawScreen,
  ReadMore,
  RewardError
} from '@pbl/pbl-react-web-components/lib/package';
import RedeemConfirmationModal from '@pbl/pbl-react-web-components/lib/reward-detail/RedeemConfirmationModal';
import noImage from 'assets/img/no-image-featured.svg';
import styles from 'assets/jss/modules/arcade/RewardsScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import NoPurchaseNecessary from 'modules/arcade/NoPurchaseNecessary';
import VerificationFlowComponent from 'modules/id-verification/VerificationFlowComponent';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearLoading, clearMessages, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { fetchDrawPrizesWithWinners } from 'redux/reducers/draws/actions';
import { fetchUserTokens } from 'redux/reducers/ledger/actions';
import {
  getProfile
} from 'redux/reducers/profile/actions';
import {
  claimFreeEntry,
  fetchAllEntries,
  fetchReward,
  fetchRewardWithFrequencyCap,
  getActiveDraw,
  getAllDraws,
  redeemReward,
  resetReward
} from 'redux/reducers/reward/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  rewardId: string;
}

export interface IRewardDetailsScreenProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    WithStyles<typeof styles>,
    RouteComponentProps<IMatchParams> {
  theme: Theme;
}

interface IRewardDetailsScreenState {
  loading: boolean;
  promotionId: number;
  showNPNForm: boolean;
  showError: boolean;
  showModalConfirmation: boolean;
  redeemQuantity: number;
  showModal: boolean;
  errorTitle: string;
  errorMessage: string;
  openIdVerificationModal: boolean;
  previousAction?: 'redeem' | 'claimFree';
  mGeoUserInRegion: boolean | undefined;
  mGeoLocationBlocked: boolean | undefined;
  mGeoLoaded: boolean | undefined;
  showGeoFencingMessage: boolean;
  showGeoLocationNotAvailableMessage: boolean;
  noLocationAvailable?: boolean;
}

class RewardDetailsScreen extends React.Component<IRewardDetailsScreenProps, IRewardDetailsScreenState> {
  constructor(props: IRewardDetailsScreenProps) {
    super(props);
    this.state = {
      loading: false,
      promotionId: 0,
      showNPNForm: false,
      showError: false,
      showModalConfirmation: false,
      redeemQuantity: 0,
      showModal: false,
      errorTitle: 'reward.errorTitle',
      errorMessage: '',
      openIdVerificationModal: false,
      mGeoUserInRegion: undefined,
      mGeoLocationBlocked: undefined,
      mGeoLoaded: undefined,
      showGeoFencingMessage: false,
      showGeoLocationNotAvailableMessage: false
    };
  }

  public async componentDidMount() {
    document.title = 'Lucky Lounge Details';
    await this.loadGeoJS();
    await this.getData();
  }

  public scrollIntoList(element) {
    const pastDraws = '#pastDraws';
    if (element.target.tagName === 'BUTTON' || element.target.tagName === 'SPAN') {
      document.querySelector(pastDraws)?.scrollIntoView();
    }
  }

  public componentWillUnmount() {
    this.props.resetReward();
    this.stopJS();
  }

  private stopJS = () => {
    if (window.mGeoJS) {
      window.mGeoJS.stop();
      console.warn('Unsubscribing from mGeoJS');
    }
  };

  private startGeoJS = async () => {

    // Reset the user in region state every time it starts.
    await this.setState({
      mGeoUserInRegion: undefined
    });
    // Starts the ping
    window.mGeoJS.start({ ping: true }).then(_ => {
      console.warn('mGeoJS started');
      if (this.state.mGeoUserInRegion === undefined) {
        this.setState({ mGeoUserInRegion: true });
      }
    });
  };

  private loadGeoJS = async () => {
    const mGeo = require('../../utils/mGeoJS');
    mGeo.default(async () => {
      if (window.mGeoJS) {
        this.setState({ mGeoLoaded: true });

        this.startGeoJS();

        window.mGeoJS.subscribe('outOfRegion', param => {
          this.stopJS();
          console.warn(`outOfRegion event: ${param}`, param);
          this.setState({ mGeoUserInRegion: false });
        });

        window.mGeoJS.subscribe('permissionDenied', () => {
          this.stopJS();
          console.warn('User is not allowed to redeem or claim free entry because location permission is denied');
          this.setState({ mGeoUserInRegion: false });
        });

        window.mGeoJS.subscribe('noGeoLocation', reason => {
          console.warn(`noGeoLocation event: '${reason}'`, reason);
          if ('DENIED' !== reason) {
            this.setState({ noLocationAvailable: true });
          } else {
            this.setState({ mGeoUserInRegion: false });
          }
        });
      }
    });
  };

  private validateGeoFencing = async () => {
    const { mGeoUserInRegion, mGeoLoaded, noLocationAvailable } = this.state;
    if (!mGeoLoaded || mGeoUserInRegion === undefined) {
      this.setState({ mGeoLocationBlocked: true });
      return;
    }

    // status can be GRANTED, DENIED OR  UNKNOWN
    const geoStatus = window.mGeoJS.getStatus();
    if (geoStatus !== 'GRANTED') {
      if (geoStatus === 'DENIED') {
        this.setState({ mGeoLocationBlocked: true });
      } else {
        this.setState({ showGeoLocationNotAvailableMessage: true });
      }
      return;
    }

    // if noGeoLocation was raised then show the proper message.
    if (noLocationAvailable) {
      this.setState({ showGeoLocationNotAvailableMessage: true });
      return;
    }

    // if outOfRegion or permissionDenied was raised then show the proper message.
    if (!mGeoUserInRegion) {
      this.setState({ mGeoLocationBlocked: false, showGeoFencingMessage: true });
      return;
    }
    // if everything is good so far, then geo fencing passed OK and must continue the the next stage
    this.onGeoValidated();
  };

  private showInvalidError = async () => {
    await this.navigateToArcades({
      message: `This reward is not available.`,
      type: 'warning',
      messageTimeout: 3000
    });
  };

  private async getData() {
    const {
      match: { params },
      isLoggedIn
    } = this.props;

    if (params.rewardId) {
      scrollToTheTop();
      if (isLoggedIn) {
        await this.props.getProfile();
        await this.props.fetchRewardWithFrequencyCap(params.rewardId);
      } else {
        await this.props.fetchReward(params.rewardId);
      }
      if (this.props.selectedReward) {
        await this.props.getActiveDraw(this.props.selectedReward.externalId);
        await this.props.getAllDraws(this.props.selectedReward.externalId, 0, constants.PAST_DRAWS_PER_PAGE);
        this.setState({ promotionId: this.props.selectedReward.externalId });
      } else {
        await this.showInvalidError();
      }
      if (isLoggedIn) {
        await this.props.fetchUserTokens();
        if (this.props.activeDraw) {
          await this.props.fetchAllEntries(this.props.activeDraw.id);
        }
      }
    } else {
      await this.showInvalidError();
    }
  }

  private navigateToArcades = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/lucky-lounge', { message });
  };

  private navigateToBuyScreen = (): void => {
    this.props.history.push('/lucky-lounge/store');
  };

  private navigateToHistory = (): void => {
    this.props.history.push('/lucky-lounge/history');
  };

  public navigateToLogin = (): void => {
    this.props.history.push('/login', { from: this.props.location });
  };
  private sendFreeClaim = async (recaptcha: string) => {
    const { selectedReward } = this.props;
    if (selectedReward) {
      await this.props.toggleLoading({
        spinning: true
      });
      await this.props.claimFreeEntry(selectedReward.id.toString(), recaptcha, this.showRedeemSuccess, this.showRedeemError);
    }
  };
  private showRedeemSuccess = async (response: boolean) => {
    if (response) {
      await this.props.clearLoading();
      await this.setState({ showModalConfirmation: true });
    } else {
      await this.showRedeemError('general');
    }
  };
  private closeModalConfirmationDialog = async () => {
    const { selectedReward } = this.props;
    this.setState({ showModalConfirmation: false });
    if (selectedReward) {
      await this.props.toggleLoading({
        spinning: true
      });
      setTimeout(async () => {
        await this.getData();
        await this.props.clearLoading();
      }, 1000);
    }
  };

  private redeem = async (): Promise<void> => {
    const { isLoggedIn, profile } = this.props;
    if (!isLoggedIn) {
      this.navigateToLogin();
      return;
    }
    if (!profile?.userProfile?.idologyValid) {
      this.setState({ openIdVerificationModal: true, previousAction: 'redeem' });
      return;
    }
    this.props.clearMessages();
    await this.setState({ previousAction: 'redeem' });
    this.validateGeoFencing();
  };

  private checkFreeClaim = async () => {
    const { isLoggedIn, profile } = this.props;
    if (!isLoggedIn) {
      this.navigateToLogin();
      return;
    }
    if (!profile?.userProfile?.idologyValid) {
      this.setState({ openIdVerificationModal: true, previousAction: 'claimFree' });
      return;
    }
    await this.setState({ previousAction: 'claimFree' });
    this.validateGeoFencing();
  };

  private onCloseIdentityVerificationModal = async () => {
    this.setState({ openIdVerificationModal: false });
  };

  private onGeoValidated = async () => {
    const { previousAction } = this.state;
    console.warn(previousAction);
    if (previousAction === 'redeem') {
      await this.setState({ showModal: true, redeemQuantity: 1 });
    } else if (previousAction === 'claimFree') {
      await this.setState({ showNPNForm: true });
    }
  };

  private onProfileVerified = async () => {
    const { previousAction } = this.state;
    await this.props.getProfile();
    if (previousAction === 'redeem') {
      this.redeem();
    } else if (previousAction === 'claimFree') {
      this.checkFreeClaim();
    }
    this.setState({ openIdVerificationModal: false, previousAction: undefined });
  };

  private onProfileVerificationFailed = async () => {
    this.setState({ openIdVerificationModal: false });
  };

  private requestRedeem = async () => {
    const { selectedReward } = this.props;
    if (!selectedReward) return;
    await this.props.toggleLoading({
      spinning: true
    });
    await this.setState({ showModal: false, redeemQuantity: this.state.redeemQuantity === 0 ? 1 : this.state.redeemQuantity });
    await this.props.redeemReward(selectedReward.id.toString(), this.state.redeemQuantity, this.showRedeemSuccess, this.showRedeemError);

  };
  private showRedeemError = async (errorMessage: any) => {
    await this.props.clearLoading();
    if (errorMessage.errorKey || (errorMessage.payload && errorMessage.payload.errorKey)) {
      await this.setState({ errorMessage: errorMessage.errorKey || errorMessage.payload.errorKey, showError: true });
    } else {
      await this.props.showMessageBar({
        message: 'error.general',
        type: 'error',
        messageTimeout: 10000
      });
    }
  };
  private goToPastDraws = (): void => {
    window.location.href = `#pastDraws`;
  };

  public getDrawsData = async pageNumber => {
    const newActivitiesSize = constants.PAST_DRAWS_PER_PAGE;
    await this.props.getAllDraws(this.state.promotionId, pageNumber, newActivitiesSize);
  };

  private onCloseNPN = () => {
    this.setState({ showNPNForm: false });
  };

  private onCloseModal = () => {
    this.setState({ showModal: false });
  };

  private closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  private onRedeemQuantityChange = (quantity: number) => {
    this.setState({ redeemQuantity: quantity });
  };

  private getDrawStartDate = () => {
    const { activeDraw } = this.props;
    if (activeDraw && activeDraw.start) {
      return !!react_core_config.internalConfig.dateFormatter?.drawStartEndDateFormat
        ? dateTimeFormatter.getFormattedDateFromDateString('drawStartEndDateFormat', activeDraw.start)
        : formatLongMonthDayCommaYear(activeDraw.start);
    }
    return 'TBD';
  };

  private getDrawEndDate = () => {
    const { activeDraw } = this.props;
    if (activeDraw && activeDraw.end) {
      return !!react_core_config.internalConfig.dateFormatter?.drawStartEndDateFormat
        ? dateTimeFormatter.getFormattedDateFromDateString('drawStartEndDateFormat', activeDraw.end)
        : formatLongMonthDayCommaYear(activeDraw.end);
    }
    return 'TBD';
  };

  private getDrawDate = () => {
    const { activeDraw } = this.props;
    if (activeDraw && activeDraw.draw) {
      return !!react_core_config.internalConfig.dateFormatter?.drawDrawDateFormat
        ? dateTimeFormatter.getFormattedDateFromDateString('drawDrawDateFormat', activeDraw.draw)
        : formatLongMonthDayCommaYear(activeDraw.draw);
    }
    return 'TBD';
  };

  private onClickFaq = () => {
    this.props.history.push('/media/faq_ll');
  };

  private onClickTerms = () => {
    this.props.history.push('/media/terms_conditions_ll');
  };
  private hideGeoFencingMessage = () => {
    this.startGeoJS();
    return this.setState({ mGeoLocationBlocked: false, showGeoFencingMessage: false, showGeoLocationNotAvailableMessage: false });
  };

  private failedGeoFencingModal = (): React.ReactNode => {
    const { t } = this.props;
    return <GeoLocationMessagePopUp open={this.state.showGeoFencingMessage}
                                    title={t('arcade.reward.geofencing.title')}
                                    text={t('arcade.reward.geofencing.message')}
                                    closeText={t('arcade.reward.geofencing.close')}
                                    onClickFaq={this.onClickFaq}
                                    onClickTerms={this.onClickTerms}
                                    onSubmit={this.hideGeoFencingMessage}
                                    onClose={this.hideGeoFencingMessage} />;
  };

  private geoLocationBlockedModal = (): React.ReactNode => {
    const { t } = this.props;
    return <ArcadeMessagePopup open={this.state.mGeoLocationBlocked }
                               title={t('arcade.reward.geofencing.blockedTitle')}
                               text={t('arcade.reward.geofencing.blockedMessage')}
                               closeText={t('arcade.reward.geofencing.close')}
                               onClose={this.hideGeoFencingMessage} />;
  };
  private geoLocationUnknownModal = (): React.ReactNode => {
    const { t } = this.props;
    return <ArcadeMessagePopup open={ this.state.showGeoLocationNotAvailableMessage }
                               title={t('store.geofencing.titleGeoLocationNotAvailable')}
                               text={t('store.geofencing.geoLocationNotAvailable')}
                               closeText={t('arcade.reward.geofencing.close')}
                               onClose={this.hideGeoFencingMessage} />;
  };

  public render() {
    const { classes, userTokens, history, selectedReward, t, entries, freeEntries, activeDraw, isLoggedIn, allDraws, page } = this.props;
    const { mGeoUserInRegion } = this.state;
    const onNavigate = () => {
      history.push('/lucky-lounge');
    };
    if (!selectedReward) return null;
    const drawStartDate = this.getDrawStartDate();
    const drawEndDate = this.getDrawEndDate();
    const drawDate = this.getDrawDate();
    const maxRedeemAvailable: number = selectedReward.frequencyCap
      ? Math.min(selectedReward.frequencyCap.remaining, Math.floor(userTokens.balance / selectedReward.amount))
      : Math.floor(userTokens.balance / selectedReward.amount);
    return (
      <div className={classes.container}>
        <ScrollToTopOnMount />
        {this.failedGeoFencingModal()}
        {this.geoLocationBlockedModal()}
        {this.geoLocationUnknownModal()}
        {this.state.showModal ? (
          <RedeemModal
            maxRedeemAvailable={maxRedeemAvailable}
            onRedeem={this.requestRedeem}
            onError={this.showRedeemError}
            onQuantityChange={this.onRedeemQuantityChange}
            redeemableTokens={userTokens.balance}
            open={this.state.showModal}
            quantity={this.state.redeemQuantity}
            amount={selectedReward.amount}
            rewardTerms={selectedReward.termsConditionsUrl}
            onClose={this.onCloseModal}
            hideRedeemableQuantity={true}
          />
        ) : null}
        {this.state.openIdVerificationModal && (
            <VerificationFlowComponent
              onFlowClosed={this.onCloseIdentityVerificationModal}
              onIdentityVerified={this.onProfileVerified}
              onIdentityFailed={this.onProfileVerificationFailed}
            />
          )}
        <Grid container={true} className={classes.gridContainer}>
          <Grid item={true} xs={12}>
            <ArcadeHeader
              navigateToBuyScreen={this.navigateToBuyScreen}
              navigateToHistory={this.navigateToHistory}
              onNavigate={onNavigate}
              balance={userTokens.balance}
              title={selectedReward.internalName}
              subTitle={t('arcade.reward.tokensPerEntryAmount', { amount: selectedReward.amount })}
              isLoggedIn={isLoggedIn}
            />
          </Grid>
          <Grid item={true} xs={12} className={classes.detailsContent}>
            <Card className={classes.card}>
              <CardMedia className={classes.media} image={selectedReward.imageUrl ? selectedReward.imageUrl : noImage} />
            </Card>
            <div className={classNames(classes.column, classes.detailsContainer)}>
              <DetailsUpperContent
                isLoggedIn={isLoggedIn}
                totalEntries={entries ? entries : freeEntries}
                onNavigate={this.goToPastDraws}
                drawEndDate={drawEndDate}
                drawDate={drawDate}
                drawStartDate={drawStartDate}
              />
              <Hidden mdDown={true}>
                <DetailsLowerContent
                  amount={selectedReward.amount}
                  summary={selectedReward.summary}
                  claimFree={this.checkFreeClaim}
                  redeem={this.redeem}
                  balance={isLoggedIn ? userTokens.balance : undefined}
                  disabled={!activeDraw || mGeoUserInRegion === undefined}
                />
              </Hidden>
            </div>
          </Grid>
          <Hidden lgUp={true}>
            <Grid item={true} xs={12}>
              <DetailsLowerContent
                amount={selectedReward.amount}
                summary={selectedReward.summary}
                claimFree={this.checkFreeClaim}
                redeem={this.redeem}
                freeEntries={freeEntries}
                isLoggedIn={isLoggedIn}
                balance={isLoggedIn ? userTokens.balance : undefined}
                disabled={!activeDraw}
              />
            </Grid>
          </Hidden>
          <Grid item={true} xs={12}>
            <Typography variant={'h6'} color={'textSecondary'} className={classes.descriptionTitle}>
              {t('arcade.reward.drawInformation')}
            </Typography>
            <ReadMore context={selectedReward.description ? selectedReward.description : ''} darkMode={true} />
          </Grid>
          <Grid onClick={this.scrollIntoList} id={`pastDraws`} className={classNames(classes.pastDraws)}>
            <PastDrawScreen
              allDraws={allDraws}
              isLoggedIn={isLoggedIn}
              page={page}
              dispatchFetchPage={this.getDrawsData}
              fetchDrawPrizesWithWinners={this.props.fetchDrawPrizesWithWinners}
              drawDetail={this.props.drawDetail}
            />
          </Grid>
        </Grid>
        <NoPurchaseNecessary open={this.state.showNPNForm} onClose={this.onCloseNPN} onFreeClaim={this.sendFreeClaim} />
        <RewardError
          visible={this.state.showError}
          onClose={this.closeErrorPopup}
          buttonStyle={classes.popupButtonStyle}
          title={this.state.errorTitle}
          message={this.state.errorMessage}
        />
        <RedeemConfirmationModal
          isVisible={this.state.showModalConfirmation}
          onClose={this.closeModalConfirmationDialog}
          maxWidth="sm"
          buttonStyle={classes.popupButtonStyle}
          textStyle={classes.textStyle}
          text={t('rewards.confirmationMessageLL')}
        />
      </div>
    );
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({
  authentication: { accessToken, account },
  ledger: { userTokens },
  reward: { selectedReward, activeDraw, allDraws, page, errorMessage, loading, freeEntries, entries },
  drawDetail,
  profile
}: IRootState) => ({
  userTokens,
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  selectedReward,
  activeDraw,
  entries,
  freeEntries,
  loading,
  allDraws,
  page,
  errorMessage,
  drawDetail,
  profile
});

const mapDispatchToProps = {
  showMessageBar,
  isAuthenticated,
  fetchUserTokens,
  fetchReward,
  fetchRewardWithFrequencyCap,
  getActiveDraw,
  resetReward,
  fetchAllEntries,
  getAllDraws,
  toggleLoading,
  clearLoading,
  claimFreeEntry,
  redeemReward,
  clearMessages,
  fetchDrawPrizesWithWinners,
  getProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation()(withRouter(withTheme(withStyles(styles)(RewardDetailsScreen)))));
