import { FeatureFlag } from '@pbl/pbl-react-web-components/lib/package';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type StateProps = ReturnType<typeof mapStateToProps>;

interface ISecuredRouteProps extends StateProps, RouteProps {
  secured?: boolean;
  feature?: string;
}

interface ICustomRouteProps extends StateProps, ISecuredRouteProps {
  routes?: ISecuredRouteProps[];
}

const CustomRoute: React.FunctionComponent<ICustomRouteProps> = ({
  secured,
  authenticated,
  component: Component,
  routes,
  path,
  authorizedFlags,
  feature,
  ...rest
}) => {

  // Route paths which are restricted on authentication
  const restrictedRoutesOnAuth = ['/login', '/register', '/register/:inviteCode?', '/forgot-password', '/forgot-password-sent', '/reset/finish'];
  // convert the nested route configs in to <Route />
  const renderProps = props => {
    if (secured && !authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      );
    }

    const isRestrictedRouteOnAuth = restrictedRoutesOnAuth.includes(props.match.path);

    if (authenticated && !secured && isRestrictedRouteOnAuth) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }} />
      );
    }

    return (
      // @ts-ignore
      <Component {...props} />
    );
  };

  return (
    <FeatureFlag authorizedFlags={authorizedFlags} flags={[feature ? feature : 'DEFAULT']} >
      <Route {...rest} render={renderProps} />
    </FeatureFlag>
  );
};

const mapStateToProps = ({ authentication: { accessToken, account }, feature: { authorizedFlags } }) => ({
  authenticated: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  authorizedFlags
});

export default connect(mapStateToProps)(CustomRoute);
