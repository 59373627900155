import { Grid, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import styles from 'assets/jss/modules/account-activation/AccountActivationScreenStyle';
import { IRootState } from 'redux/reducers';
import { activateAccount, reset } from 'redux/reducers/account-activation/actions';
import { getUrlParameter } from 'utils/urlUtils';
import ActivationContent from './components/ActivationContent';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IAccountActivationScreenProps extends RouteComponentProps, StateProps, DispatchProps, WithStyles<typeof styles> {}

class AccountActivationScreen extends React.Component<IAccountActivationScreenProps> {
  public componentDidMount(): void {
    document.title = 'Activation';
    const key = getUrlParameter('key', this.props.location.search);
    this.props.activateAccount(key);
  }

  public componentWillUnmount() {
    this.props.reset();
  }

  public render(): React.ReactNode {
    const { classes, loading } = this.props;
    return (
      <div className={classes.screen}>
        <div className={classes.container}>
          <Grid container={true} justify="center" item={true} xs={12}>
            <ActivationContent type={this.parseFailure()} loading={loading} />
          </Grid>
        </div>
      </div>
    );
  }

  private parseFailure = () => {
    const { failure, success } = this.props;

    if (success) {
      return 'success';
    }

    // @ts-ignore
    if (failure && failure.payload) {
      // @ts-ignore
      const message = failure.payload.errorKey;
      if (message === 'activated.already') {
        return 'active';
      } else if (message === 'error.activation.expired') {
        return 'expired';
      } else if (message === 'error.user.notfound') {
        return 'notFound';
      } else if (failure.payload.status === 500) {
        return failure.payload.title;
      }
    }

    return 'error';
  };
}

const mapStateToProps = ({ accountActivation: { success, failure, loading } }: IRootState) => ({
  success,
  failure,
  loading
});

const mapDispatchToProps = { activateAccount, reset };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountActivationScreen));
