import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';
import color from 'color';
import { popupButtonStyle } from 'shared/theme/sweepstakes';

const styles = (theme: Theme) =>
  createStyles({
    container,
    gridContainer: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 0
      }
    },
    column: {
      display: 'flex',
      flexDirection: 'column'
    },
    card: {
      height: 'max-content',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
      }
    },
    detailsContainer: {
      flex: 1
    },
    detailsContent: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    media: {
      height: 492,
      width: 492,
      [theme.breakpoints.down('lg')]: {
        height: 422,
        width: 422
      },
      [theme.breakpoints.down('md')]: {
        height: 340,
        width: 340
      },
      [theme.breakpoints.down('xs')]: {
        height: 384,
        width: '100%'
      }
    },
    descriptionTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    pastDraws: {
      backgroundColor: color(theme.palette.primary.dark)
        .alpha(0.7)
        .rgb()
        .string(),
      padding: theme.spacing(0),
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(3),
      borderRadius: 10,
      flex: 1
    },
    popupButtonStyle,
    textStyle: {
      color: theme.palette.grey['900']
    },
    icon: {
      fontSize: 40,
      color: theme.palette.error.main,
      marginRight: theme.spacing(2),
      textAlign: 'center'
    },
    infoDialog: {
      color: theme.palette.common.black,
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    infoButton: {
      minWidth: '10%'
    }
  });

export default styles;
