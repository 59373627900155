import { GET_RECOMMENDATIONS, RecommendationsType, RESET_STATE } from '@pbl/pbl-react-core/lib/models/recommendations/types';
import { LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import { RecommendationsService } from '@pbl/pbl-react-core/lib/services/recommendations-service';
import { nowUtc } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';

export const fetchRecommendations = (filter?: string[]) => async (dispatch: any) => {
  let filterParams: string[] = [];
  if (filter && filter.length > 0) {
    filterParams = filter;
  } else {
    filterParams.push('publishedState.equals=PUBLISHED');
    filterParams.push('tag.equals=WEB_FEATURED');
    filterParams.push(`start.lessThanOrEqual=${nowUtc().toISOString()}`);
  }
  try {
    dispatch({
      type: REQUEST(GET_RECOMMENDATIONS)
    });
    const payload = await RecommendationsService.getRecommendations(filterParams);
    const recommendationsList = await getRecommendations(payload);
    dispatch({
      type: SUCCESS(GET_RECOMMENDATIONS),
      payload: recommendationsList
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_RECOMMENDATIONS),
      payload: error
    });
    console.error(error);
  }
};

const getRecommendations = async payload => {
  const recommendationsList: any = [];
  if (payload && payload.length > 0) {
    await Promise.all(
      payload.map(async p => {
        if (!!p.end && p.end < nowUtc().toISOString()) {
          return;
        }
        if (p.recommendationType === RecommendationsType.ACTIVITY) {
          const activity = await LoyaltyService.getActivity(p.externalId.toString());
          if (!!activity && !!activity.freq_cap && !!activity.user_num_completions && activity.freq_cap >= activity.user_num_completions) {
            return;
          }
        }
        if (p.recommendationType === RecommendationsType.REWARD) {
          const reward = await LoyaltyService.getReward(p.externalId.toString());
       if (!!reward && reward.remaining_coupons === 0) {
            return;
          }
        }
        recommendationsList.push(p);
      })
    );
  }
  return recommendationsList;
};

export const reset = () => ({
  type: RESET_STATE
});
