import { createStyles, Grid, Theme, Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import { Address } from '@pbl/pbl-react-core/lib/models/forms';
import { IProfile, VerificationStatus } from '@pbl/pbl-react-core/lib/models/profile/types';
import { PlayerDetails } from '@pbl/pbl-react-core/lib/models/store/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import ArcadePopupButton from '@pbl/pbl-react-web-components/lib/arcade/ArcadePopupButton';
import PhoneInputMask from '@pbl/pbl-react-web-components/lib/field/PhoneInputMask';
import TextFieldValidator from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import { InformationDialog } from '@pbl/pbl-react-web-components/lib/package';
import idVerificationVerified from 'assets/img/sweepstakes/id-verification/graphics-id-verfication-id-verifed.svg';
import idVerificationError from 'assets/img/sweepstakes/id-verification/graphics-id-verfication-id-verification-error.svg';
import idVerificationInProgress from 'assets/img/sweepstakes/id-verification/graphics-id-verfication-id-verification-inprogress.svg';
import colors from 'assets/jss/colors';
import { dangerColor } from 'assets/jss/material-kit-pro-react.jsx';
import validation, { phoneMask } from 'config/validation';
import { addressSchema, phoneNumberValidator, ssnValidator } from 'modules/settings/profile/validations';
import React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { conformToMask } from 'react-text-mask';
import { IRootState } from 'redux/reducers';
import { getCities, getStates, resetCities, resetStates } from 'redux/reducers/address/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { getProfile } from 'redux/reducers/profile/actions';
import AddressComponent from 'shared/components/input/Address';
import { sweepstakesTheme } from 'shared/theme/sweepstakes';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IProfileModelProps extends StateProps, DispatchProps, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
  open: boolean;
  onClose: () => void;
  onSubmit: (playerDetails: PlayerDetails) => void;
  verificationStatus: VerificationStatus;
  maxAttempts: number;
  currentAttempts: number;
  showAlreadyExistsError: boolean;
}
const INITIAL_STATE = {
  address: new Address(),
  apartmentNumber: '',
  phone: '',
  gender: null,
  formIsValid: false,
  formIsDirty: false,
  email: '',
  completeActivityPoints: 0,
  stateCode: '',
  ssn: '',
  verificationStatus: {
    dataEntry: idVerificationInProgress,
    inProgress: idVerificationInProgress,
    error: idVerificationError,
    verified: idVerificationVerified,
    dataExpired: idVerificationError
  }
};
class IdentityVerificationModal extends React.Component<IProfileModelProps> {
  public state = INITIAL_STATE;

  private inputHandlers = [];

  public async componentDidMount(): Promise<void> {
    await this.props.getProfile();
    await this.updateProfileState();
    this.initialFormValidation();
  }

  public componentWillUnmount() {
    this.props.setIsFormDirty(false);
    this.props.resetStates();
    this.props.resetCities();
  }

  private closeModal = () => {
    this.setState({
      address: INITIAL_STATE
    });
    this.props.onClose();
  };

  public renderInProgress = () => {
    const { classes, t } = this.props;
    return (
      <>
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1" align={'center'} className={classes.colorBlack}>
            {t('store.idVerificationModal.inProgressText')}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1" align={'center'} className={classes.colorBlack}>
            {t('store.idVerificationModal.loading')}
          </Typography>
        </Grid>
      </>
    );
  };

  public renderError = (textMessage: string) => (
    <>
      <Grid item={true} xs={12}>
        <Typography variant="body2" align={'center'} style={{ whiteSpace: 'pre-line', color: dangerColor }}>
          {textMessage}
        </Typography>
      </Grid>
    </>
  );

  public renderErrorAtempts = (idVerificationFailed: string, textMessage: string) => (
    <>
      <Grid item={true} xs={12}>
        <Typography
          variant="body1"
          align={'center'}
          style={{ alignContent: 'center', color: '#e8503e', fontFamily: 'Jost', fontWeight: 'bold', fontSize: 18 }}
        >
          {idVerificationFailed}
        </Typography>
        <Typography
          variant="body2"
          align={'center'}
          style={{
            whiteSpace: 'pre-line',
            fontFamily: 'Jost',
            fontSize: 15,
            lineHeight: '20px',
            letterSpacing: 0.3,
            color: 'black',
            transform: 'titlecase'
          }}
        >
          {textMessage}
          <Trans i18nKey="store.idVerificationModal.faqMessage">
            a
            <Typography component={'a'} onClick={this.onClickLink} target="_blank" color={'primary'} style={{ cursor: 'pointer' }}>
              FAQ Page
            </Typography>
            a
          </Trans>
        </Typography>
      </Grid>
    </>
  );

  private onClickLink = async () => {
    await this.props.setIsFormDirty(false);
    const path = '/media/faq_ll';
    window.open(path, '_blank');
  };
  private onTermsClickLink = async () => {
    await this.props.setIsFormDirty(false);
    const path = '/media/terms_conditions_ll';
    window.open(path, '_blank');
  };

  public renderVerified = () => {
    const { t } = this.props;
    return (
      <>
        <Grid item={true} xs={12}>
          <Typography variant="body2" align={'center'} style={{ color: '#089d2f' }}>
            {t('store.idVerificationModal.idVerifiedText')}
          </Typography>
        </Grid>
      </>
    );
  };

  public renderDataEntry = () => {
    const { profile, classes, t, showAlreadyExistsError } = this.props;
    const { address, phone, ssn } = this.state;
    const userProfile = profile.userProfile || ({} as IProfile);

    let phoneMaskedValue = '';
    if (!!phone && phone.toString().length > 0) {
      phoneMaskedValue = conformToMask(phone, phoneMask, {}).conformedValue;
    }
    return (
      <>
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1" align={'center'} className={classes.colorBlack}>
            {t('store.idVerificationModal.personalInfoTitle')}
          </Typography>
          <Typography variant="body2" align={'center'} className={classes.colorBlackforSecondary}>
            {t('store.idVerificationModal.updateInfo')}
          </Typography>
        </Grid>
        <Grid container={true} spacing={4} className={classes.fieldContainer}>
          <Grid item={true} xs={12} sm={12} md={4}>
            <Typography className={classes.colorGrey} variant={'body1'}>
              {t('store.billing.firstName')}
            </Typography>
            <Typography variant="body2" className={classes.colorBlack}>
              {userProfile.firstName}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={4}>
            <Typography className={classes.colorGrey} variant={'body1'}>
              {t('store.billing.lastName')}
            </Typography>
            <Typography variant="body2" className={classes.colorBlack}>
              {userProfile.lastName}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={4}>
            <Typography className={classes.colorGrey} variant={'body1'}>
              {t('store.billing.dateOfBirth')}
            </Typography>
            <Typography variant="body2" className={classes.colorBlack}>
              {userProfile.birthDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} className={classes.whyToVerifyContainer}>
          <Typography variant="subtitle1" align={'center'} className={classes.colorBlack}>
            {t('store.idVerificationModal.whyToVerifyTitle')}
          </Typography>
          <Typography variant="body2" align={'center'} className={classes.whyToVerifyInfo}>
            {t('store.idVerificationModal.whyToVerifyInfo')}
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1" align={'center'} className={classes.colorBlack}>
            {t('store.idVerificationModal.addressInfoTitle')}
          </Typography>
          <Typography variant="body2" align={'center'} className={classes.colorBlackforSecondary}>
            {t('store.idVerificationModal.addressInfo')}
          </Typography>
        </Grid>
        <AddressComponent
          contentClass={classes.addressContent}
          fieldClass={classes.fieldClass}
          fieldAutocompleteClass={classes.fieldAutocompleteClass}
          address={address}
          handleTextFieldChange={this.handleAddressSelect}
          fieldVariant={'filled'}
          getStates={this.props.getStates}
          getCities={this.props.getCities}
          countries={this.props.address.countries}
          states={this.props.address.states}
          cities={this.props.address.cities}
          resetStates={this.props.resetStates}
          resetCities={this.props.resetCities}
          loadingCities={this.props.address.loading}
          showMandatory={true}
        />
        <Grid container={true} spacing={4} className={classes.fieldContainer}>
          <Grid item={true} xs={12} sm={12} md={6}>
            <TextFieldValidator
              id="phone"
              label="Phone Number"
              aria-label="Phone Number"
              className={classes.field}
              value={phoneMaskedValue}
              onBlur={this.onBlur}
              onChange={this.handleTextFieldChange('phone')}
              InputProps={{ inputComponent: PhoneInputMask as any }}
              validationSchema={phoneNumberValidator}
              variant="filled"
            />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6}>
            <TextFieldValidator
              id="ssn"
              label="SSN (Last 4 Digits of SSN)"
              aria-label="SSN (Last 4 Digits of SSN)"
              className={classes.field}
              onBlur={this.onBlur}
              onChange={this.handleTextFieldChange('ssn')}
              value={ssn}
              validationSchema={ssnValidator}
              variant="filled"
              inputProps={{ maxLength: validation.maxSSNLength }}
            />
          </Grid>
        </Grid>
        <Grid item={false} xs={12}>
          <Typography variant="body2" align={'center'} className={classes.ssnNotetext}>
            <Trans i18nKey="store.idVerificationModal.ssnNote">
              a
              <Typography
                component={'a'}
                onClick={this.onTermsClickLink}
                target="_blank"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                Terms and Conditions
              </Typography>
              a
            </Trans>
          </Typography>
        </Grid>
        {showAlreadyExistsError && (
          <Grid item={true} xs={12}>
            <Typography variant="body2" align={'center'} style={{ whiteSpace: 'pre-line', color: dangerColor }}>
              {t('store.idVerificationModal.alreadyExists')}
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  public render() {
    const { classes, verificationStatus, t, showAlreadyExistsError } = this.props;
    const remaining = this.props.maxAttempts - this.props.currentAttempts;
    return (
      <InformationDialog open={this.props.open} onClose={this.closeModal} maxWidth={'md'} hideButton={true} disableBackdropClick={true}>
        <Grid container={true} spacing={4}>
          {this.props.address.loading ? <AppSpinner label={'Loading...'} /> : null}
          <Grid item={true} xs={12}>
            <Typography variant="h6" align={'center'} className={classes.colorBlack}>
              {t('store.idVerificationModal.title')}
            </Typography>
          </Grid>
          <Grid
            item={true}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img alt="Id Verification" src={this.state.verificationStatus[verificationStatus]} />
          </Grid>
          {verificationStatus === 'dataEntry' && this.renderDataEntry()}
          {verificationStatus === 'inProgress' && this.renderInProgress()}
          {verificationStatus === 'error' &&
            this.renderErrorAtempts(
              t('store.idVerificationModal.idVerificationFailed'),
              remaining === 1
                ? t('store.idVerificationModal.errorTextRemaining1')
                : t('store.idVerificationModal.errorText', { remainingAttempts: remaining })
            )}
          {verificationStatus === 'verified' && this.renderVerified()}
          {verificationStatus === 'dataExpired' && this.renderError(t('store.idVerificationModal.dataExpired'))}
          <Grid item={true} className={classes.buttonContainer}>
            <ArcadePopupButton
              onSubmit={this.handleSubmit}
              className={classes.button}
              title={
                verificationStatus === 'error' || verificationStatus === 'verified' || verificationStatus === 'dataExpired'
                  ? t('store.idVerificationModal.continue')
                  : t('store.idVerificationModal.verify')
              }
              isDisabled={verificationStatus === 'inProgress' || !this.state.formIsValid || showAlreadyExistsError}
            />
          </Grid>
        </Grid>
      </InformationDialog>
    );
  }

  private handleTextFieldChange = (attr: string) => {
    if (!this.inputHandlers[attr]) {
      this.inputHandlers[attr] = async event => {
        const value = event.target.value;
        this.setState({ [attr]: value, formIsDirty: true }, () => {
          const formIsValid = this.validateForm();
          this.setState({ formIsValid });
          this.props.setIsFormDirty(true);
        });
      };
    }
    return this.inputHandlers[attr];
  };

  private handleAddressSelect = (field: string, value: string) => {
    const { address } = this.state;
    if (address.hasOwnProperty(field)) {
      address[field] = value;
    }
    if (field === 'stateCode') {
      this.setState({ stateCode: value });
    }
    const formIsValid = this.validateForm();
    this.setState({ address, formIsValid, formIsDirty: true }, () => {
      this.props.setIsFormDirty(true);
    });
  };

  private onBlur = () => {
    const formIsValid = this.validateForm();
    this.setState({ formIsValid });
  };

  private validateForm = () => {
    const { phone, address, ssn } = this.state;
    const formIsValid = phoneNumberValidator.isValidSync(phone);
    const formSsnIsValid = ssnValidator.isValidSync(ssn);
    const addressIsValid = addressSchema.isValidSync({
      address1: address.address1,
      city: address.city,
      zipCode: address.zipCode,
      country: address.country,
      state: address.state
    });
    return formIsValid && addressIsValid && formSsnIsValid;
  };

  private updateProfileState = () => {
    const { profile } = this.props;
    const userProfile = profile.userProfile || ({} as IProfile);
    const { addresses, gender, phones, email, completeActivityPoints } = userProfile;

    const addressObject = addresses && addresses.length > 0 ? new Address(addresses[0]) : new Address();
    const apartmentNumber = addressObject ? addressObject.apartmentNumber : '';

    this.setState({
      address: addressObject,
      gender: gender || '',
      phone: phones && phones.length > 0 ? phones[0].phone : '',
      email,
      apartmentNumber: apartmentNumber || '',
      completeActivityPoints: completeActivityPoints || 0
    });
  };

  private initialFormValidation = () => {
    const formIsValid = this.validateForm();
    this.setState({ formIsValid });
  };

  private handleSubmit = async () => {
    const { profile } = this.props;
    const userProfile = profile.userProfile || ({} as IProfile);
    let phone = this.state.phone;
    phone = phone.replace(/\D+/g, '');
    this.props.onSubmit({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: this.state.email,
      address1: this.state.address.address1,
      city: this.state.address.city,
      country: this.state.address.country,
      countryCode: this.state.address.countryCode,
      state: this.state.address.state,
      zip: this.state.address.zipCode,
      phone1: phone,
      dateOfBirth: userProfile.birthDate,
      stateCode: this.state.stateCode,
      ssnLast4: this.state.ssn
    });
    this.updateProfileState();
    this.initialFormValidation();
  };
}

const styles = (theme: Theme) =>
  createStyles({
    fieldContainer: {
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    field: {
      width: '100%'
    },
    addressContent: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    fieldClass: {
      flexBasis: '45.5%',
      color: theme.palette.common.black,
      margin: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        flexBasis: '45.3%'
      },
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%'
      }
    },
    fieldAutocompleteClass: {
      flexBasis: '45.5%',
      margin: theme.spacing(2),
      padding: 0,
      [theme.breakpoints.up('md')]: {
        flexBasis: '45.3%'
      },
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%'
      }
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: '100%',
      marginBottom: theme.spacing(3)
    },
    colorBlack: {
      color: theme.palette.common.black
    },
    whyToVerifyContainer: {
      backgroundColor: `${sweepstakesTheme.palette.secondary.main} !important`,
      borderRadius: '6px',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    whyToVerifyInfo: {
      color: theme.palette.common.black,
      fontStyle: 'italic',
      marginTop: theme.spacing(1)
    },
    ssnNotetext: {
      color: colors.themeColors.primaryColor.hex(),
      justifyContent: 'center'
    },
    colorBlackforSecondary: {
      color: theme.palette.common.black,
      fontStyle: 'italic'
    },
    colorGrey: {
      color: theme.palette.grey['500']
    },
    button: {
      backgroundColor: `${sweepstakesTheme.palette.secondary.main} !important`,
      color: `${sweepstakesTheme.palette.primary.dark} !important`,
      padding: '12px 16px'
    }
  });

const mapStateToProps = ({ authentication, profile, formMetadata, address }: IRootState) => ({
  authentication,
  profile,
  formMetadata,
  address
});

const mapDispatchToProps = {
  getProfile,
  setIsFormDirty,
  getStates,
  getCities,
  resetCities,
  resetStates
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation()(withTheme(withStyles(styles)(IdentityVerificationModal))));
