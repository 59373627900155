import { createStyles, Theme } from '@material-ui/core';
import color from 'color';

const styles = (theme: Theme) =>
  createStyles({
    groupItem: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing() / 2
    },
    item: {
      paddingTop: theme.spacing() + 4
    },
    groupDivider: {
      backgroundColor: color(theme.palette.common.black)
        .alpha(0.4)
        .rgb()
        .string()
    },
    formControl: {
      minWidth: 200,
      marginRight: theme.spacing() * 4
    }
  });

export default styles;
