import { IMenuItem } from 'config/menu';

const menuItems: IMenuItem[] = [
  {
    key: 'settings_profile',
    title: 'Profile',
    route: '/settings/profile'
  },
  {
    key: 'settings_preferences',
    title: 'Communication Preferences',
    route: '/settings/preferences'
  },
  {
    key: 'settings_interests',
    title: 'Interests',
    route: '/settings/interests'
  },
  {
    key: 'settings_account_security',
    title: 'Account & Security',
    route: '/settings/account-security',
    subMenu: [
      {
        key: 'settings_change_password',
        title: 'Change Password',
        route: '/settings/account-security/change-password'
      },
      {
        key: 'settings_deactivate_account',
        title: 'Deactivate Account',
        route: '/settings/account-security/deactivate-account'
      }
    ]
  },
  {
    key: 'settings_invite_friends',
    title: 'Invite Friends',
    route: '/settings/invite-friends'
  }
];

export default menuItems;
