import { FeatureState, GET_FEATURED_FLAGS, IFeatureState } from '@pbl/pbl-react-core/lib/models/feature-flagging/index';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_SCREEN_STATE: IFeatureState = {
  authorizedFlags: [],
  loading: false,
  firstLoad: true
};

export default (state: FeatureState = INITIAL_SCREEN_STATE, action: IDispatchAction): FeatureState => {
  switch (action.type) {
    case REQUEST(GET_FEATURED_FLAGS):
      return {
        ...state,
        loading: true
      };
    case GET_FEATURED_FLAGS:
      return {
        ...state,
        authorizedFlags: action.payload,
        loading: false
      };
    case SUCCESS(GET_FEATURED_FLAGS):
      return {
        ...state,
        authorizedFlags: action.payload,
        loading: false,
        firstLoad: false
      };
    case FAILURE(GET_FEATURED_FLAGS):
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
