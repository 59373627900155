import { AccountActivationState, ACTIVATE_ACCOUNT, RESET } from '@pbl/pbl-react-core/lib/models/account-activation';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';

const INITIAL_STATE = {
  success: false,
  loading: false,
  failure: {}
};

export default (state: AccountActivationState = INITIAL_STATE, action): AccountActivationState => {
  switch (action.type) {
    case REQUEST(ACTIVATE_ACCOUNT):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTIVATE_ACCOUNT):
      return {
        ...state,
        loading: false,
        failure: action.payload && action.payload.response && action.payload.response.data ? action.payload.response.data : action.payload
      };
    case SUCCESS(ACTIVATE_ACCOUNT):
      return {
        ...state,
        success: true,
        loading: false,
        failure: {}
      };
    case RESET:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
