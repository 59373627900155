const getPlatform = (): string => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'MacOS';
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    return 'iOS';
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'Windows';
  }
  if (/android/.test(userAgent)) {
    return 'Android';
  }
  if (/linux/.test(platform)) {
    return 'Linux';
  }
  if (/unix/.test(platform)) {
    return 'UNIX';
  }
  return platform;
};

const getDevice = (): string => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (!/mobile/i.test(userAgent)) {
    return 'Desktop';
  }
  if (/mobile/i.test(userAgent) && !/ipad|tablet/i.test(userAgent)) {
    return 'Mobile';
  }
  return 'Tablet';
};
export default { getPlatform, getDevice };
