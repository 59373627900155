import { CLAIM_FREE_ENTRY } from '@pbl/pbl-react-core/lib/models/draws/types';
import {
  FETCH_ENTRIES,
  FETCH_FREE_ENTRIES,
  GET_ACTIVE_DRAW,
  GET_ALL_DRAWS,
  GET_REWARD,
  GET_REWARD_LIST,
  GET_REWARD_WITH_FREQUENCY_CAP,
  REDEEM_REWARD,
  RESET_REWARD
} from '@pbl/pbl-react-core/lib/models/reward/types';
import { PromotionsService } from '@pbl/pbl-react-core/lib/services';
import { RewardService } from '@pbl/pbl-react-core/lib/services/reward-service';
import moment from 'moment-timezone';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { GetStateMethod } from 'redux/reducers';
import { clearMessages } from 'redux/reducers/app/actions';

export const fetchRewardList = () => async (dispatch: any) => {
  await dispatch({
    type: GET_REWARD_LIST,
    payload: RewardService.getRewardList('web')
  }).catch(error => {
    console.error(error);
  });
};
export const fetchReward = (rewardId: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_REWARD,
      payload: RewardService.fetchRewardDetails(rewardId)
    });
  } catch (error) {
    console.error(error);
  }
};
export const fetchRewardWithFrequencyCap = (rewardId: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_REWARD_WITH_FREQUENCY_CAP,
      payload: RewardService.getRewardWithFrequencyCap(rewardId)
    });
  } catch (error) {
    console.error(error);
  }
};
export const getActiveDraw = (promotionId: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_ACTIVE_DRAW,
      payload: PromotionsService.fetchActiveDraw(promotionId)
    });
  } catch (error) {
    console.error(error);
  }
};

export const getAllDraws = (promotionId: number, pageNumber: number, pageSize: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_ALL_DRAWS,
      payload: PromotionsService.getDrawsforPromotionId(promotionId, pageNumber, pageSize)
    });
  } catch (error) {
    console.error(error);
  }
};
export const fetchRewardFreeEntries = (drawId: number) => async (dispatch: any) => {
  dispatch({
    type: FETCH_FREE_ENTRIES,
    payload: PromotionsService.countFreeEntry(drawId)
  }).catch(error => {
    console.error(error);
  });
};

export const fetchAllEntries = (drawId: number) => async (dispatch: any, getState: GetStateMethod) => {
  const {
    reward: { allDraws }
  } = getState();
  const draw = allDraws.find(x => x.id === drawId);
  dispatch({
    type: FETCH_ENTRIES,
    payload: draw ? draw.entries : 0
  });
};

export const redeemReward = (rewardId: string, quantity: number, successCallback: (message: any) => void, errorCallback: (error: any) => void) => async (dispatch: any) => {
  try {
    const response = await RewardService.redeemReward(rewardId, quantity);
    dispatch({
      type: SUCCESS(REDEEM_REWARD),
      payload: response
    });
    successCallback(response);
  } catch (error) {
    errorCallback(error);
    dispatch({
      type: FAILURE(REDEEM_REWARD),
      payload: {
        error
      }
    });
  }
};

export const resetReward = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: RESET_REWARD
  });
};

export const claimFreeEntry =
  (id: string, recaptcha: string, successCallback: (message: any) => void, errorCallback: (error: any) => void) =>
  async (dispatch: any, getState: GetStateMethod) => {
    try {
      const {
        reward: { freeEntriesRequestSubmission }
      } = getState();
      const now = moment();
      if (freeEntriesRequestSubmission && now.diff(freeEntriesRequestSubmission, 'seconds') < 60) {
        errorCallback({ errorKey: 'error.reward.freeEntryTrace' });
        return;
      }
      dispatch({
        type: REQUEST(CLAIM_FREE_ENTRY)
      });

      const response = await RewardService.claimFreeEntry(id, recaptcha);
      dispatch({
        type: SUCCESS(CLAIM_FREE_ENTRY),
        payload: response
      });
      successCallback(response);
    } catch (error) {
      errorCallback(error);
      dispatch({
        type: FAILURE(CLAIM_FREE_ENTRY),
        payload: {
          error
        }
      });
    }
  };
