const mGeoJS = callback => {
  const script = document.createElement('script');
  script.src = '/js/mGeoJS/mGeoJS.min.js';
  script.id = 'mgeo-engine';
  document.body.appendChild(script);

  script.onload = () => {
    if (callback) callback();
  };
};
export default mGeoJS;
