import { Activity } from '@pbl/pbl-react-core/lib/models/loyalty';
import { ActivityChannelType, IActivity } from '@pbl/pbl-react-core/lib/models/loyalty/types';

const navigateToActivityDetails = (activity: IActivity | Activity, history): void => {
  if (activity.isPromoCode) {
    history.push(`/promo-code/${activity.id}`);
    return;
  }
  if (activity.isVideo || activity.isDocument || activity.isAudio || activity.isURL) {
    history.push(`/watch-video/${activity.id}`);
    return;
  }
  if (activity.isUploadPhoto) {
    history.push(`/upload-photo/${activity.id}`);
    return;
  }
  if (activity.activity_channel_id === ActivityChannelType.Survey) {
    if (activity.extra_data && activity.extra_data.legacy_survey) {
      history.push(`/survey/${activity.id}`);
    } else {
      history.push(`/survey/v2/${activity.id}`);
    }

    return;
  }
  if (activity.isProfileCompletion) {
    history.push(`/profile-completion/${activity.id}`);
    return;
  }
  if (activity.isInviteFriend) {
    history.push(`/invite-friends/${activity.id}`);
    return;
  }
  if (activity.isNotificationPreference) {
    history.push(`/email-signUp/${activity.id}`);
    return;
  }
  if (activity.isPoll) {
    history.push(`/poll/${activity.id}`);
    return;
  }
  if (activity.isExpressGame) {
    history.push(`/interactive/${activity.id}`);
    return;
  }
  if (activity.isQuiz) {
    history.push(`/quiz/${activity.id}`);
    return;
  }

  if (activity.isInformationOnly && activity.extra_data && activity.extra_data.deeplink_web) {
    history.push(`/information-list/${activity.extra_data.deeplink_web}`);
    return;
  }

  if (activity.isCollectThemAll && activity.extra_data && activity.extra_data.super_bonus_key) {
    history.push(`/collect-them-all/${activity.extra_data.super_bonus_key}`);
    return;
  }
};

export default { navigateToActivityDetails };
