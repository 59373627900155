import {
  GET_SUPER_BONUS_DETAILS,
  ISuperBonusDetails,
  SuperBonusState
} from '@pbl/pbl-react-core/lib/models/super-bonus/types';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';

const INITIAL_STATE: SuperBonusState = {
  success: false,
  loading: false,
  failure: {},
  superBonusDetails: {} as ISuperBonusDetails
};

export default (state: SuperBonusState = INITIAL_STATE, action): SuperBonusState => {
  switch (action.type) {
    case REQUEST(GET_SUPER_BONUS_DETAILS):
      return {
        ...state,
        loading: true,
        superBonusDetails: {} as ISuperBonusDetails
      };
    case SUCCESS(GET_SUPER_BONUS_DETAILS):
      return {
        ...state,
        success: true,
        loading: false,
        failure: {},
        superBonusDetails: action.payload
      };
    case FAILURE(GET_SUPER_BONUS_DETAILS):
      return {
        ...state,
        loading: false,
        failure: action.payload && action.payload.response && action.payload.response.data ? action.payload.response.data : action.payload,
        superBonusDetails: {} as ISuperBonusDetails
      };
    default:
      return state;
  }
};
