import { IExtraData } from '@pbl/pbl-react-core/lib/models/ledger/types';

const getEntries = (extraData: IExtraData[]) => {
  const transaction = extraData.find(a => a.key === 'transactionId');
  const paymentMethod = extraData.find(a => a.key === 'paymentMethod');
  if (transaction && paymentMethod) {
    return ` - ${paymentMethod.value} (${transaction.value})`;
  }
  const entryType = extraData.find(a => a.key === 'entryMethod');
  const entries = extraData.find(a => a.key === 'entries');
  if (entryType && entries) {
    const entryText = parseInt(entries.value, 10) > 1 ? 'Entries' : 'Entry';
    return entryType.value === 'REDEMPTION' ? ` - ${entries.value} ${entryText}` : ` - ${entries.value} Free ${entryText}`;
  }
  return '';
};

export { getEntries };
