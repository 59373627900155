import { Button, Grid, Icon, Typography, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import MDSpinner from 'react-md-spinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ScreenCard from '@pbl/pbl-react-web-components/lib/card/ScreenCard';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/account-activation/components/ActivationContentStyle';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IActivationMessage {
  title: string;
  message: string;
}

interface IActivationMessages {
  [key: string]: IActivationMessage;
}

interface IActivationContentProps extends RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  type: string;
  loading?: boolean;
}

const ACTIVATION_TYPES: IActivationMessages = {
  success: {
    message: 'activation.success.message',
    title: 'activation.success.title'
  },
  active: {
    message: 'activation.active.message',
    title: 'activation.active.title'
  },
  expired: {
    message: 'activation.expired.message',
    title: 'activation.expired.title'
  },
  notFound: {
    message: 'activation.notFound.message',
    title: 'activation.notFound.title'
  },
  error: {
    message: 'activation.error.message',
    title: 'activation.error.title'
  }
};

const ActivationContent: React.FunctionComponent<IActivationContentProps> = ({ type, loading, classes, history, t }) => {
  let title;
  let gotoArea;
  let displayBox;
  let button;

  if (!loading) {
    const navigateTo = path => () => {
      history.push(path);
    };

    const pass = type === 'active' || type === 'success';
    const icon = pass ? 'done' : 'error_outline';

    let content = ACTIVATION_TYPES[type];
    if (!content) {
      content = { title: 'activation.error.title', message: type };
    }
    title = t(content.title);

    displayBox = (
      <React.Fragment>
        <Icon
          className={classNames({
            [classes.icon]: true,
            [classes.iconSuccess]: pass,
            [classes.iconError]: !pass
          })}
        >
          {icon}
        </Icon>
        <Typography variant="body1" className={classes.gotoText}>
          {t(content.message)}
        </Typography>
      </React.Fragment>
    );

    gotoArea = (
      <Typography variant="body1" className={classes.gotoText}>
        Go to
      </Typography>
    );

    button = pass ? (
      <Button size="large" variant="outlined" color="primary" aria-label="Sign In" onClick={navigateTo('/login')}>
        Sign In
      </Button>
    ) : (
      <Button size="large" variant="outlined" color="primary" aria-label="Resend Activation" onClick={navigateTo('/activate-email')}>
        Resend Activation
      </Button>
    );
  } else {
    title = 'Account Activating';
    displayBox = (
      <div className={classes.loadingBox}>
        <MDSpinner size={28} singleColor={colors.themeColors.warningColor.hex()} />
      </div>
    );
  }

  return (
    <ScreenCard title={title}>
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={11} sm={9} lg={8}>
          <div className={classes.box}>{displayBox}</div>
        </Grid>
      </Grid>
      {gotoArea}
      {button}
    </ScreenCard>
  );
};

export default withStyles(styles)(withRouter(withTranslation()(ActivationContent)));
