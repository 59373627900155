import { Theme, WithStyles, withStyles, withTheme } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/modules/settings/account/AccountScreenStyle';
import UpdateEmailConfirmForm from 'modules/settings/account/components/UpdateEmailConfirmForm';
import UpdateEmailForm from 'modules/settings/account/components/UpdateEmailForm';
import { IRootState } from 'redux/reducers';
import { changePassword } from 'redux/reducers/email/actions';
import { currentPasswordValidation, emailValidationSchema } from './validations';

type PropsConnected = ConnectedProps<typeof connector>;

interface IChangeEmailScreenProps extends RouteComponentProps, PropsConnected, WithStyles<typeof styles> {
  theme: Theme;
}

interface IChangeEmailScreenState {
  newEmail: string;
  currentForm: number;
}

const INITIAL_STATE: IChangeEmailScreenState = {
  newEmail: '',
  currentForm: 1
};

class ChangeEmailScreen extends React.Component<IChangeEmailScreenProps, IChangeEmailScreenState> {
  public state: IChangeEmailScreenState = _.cloneDeep(INITIAL_STATE);

  public render(): React.ReactNode {
    const { account } = this.props;
    const { newEmail, currentForm } = this.state;

    return currentForm === 1 ? (
      <UpdateEmailForm oldEmail={account.email} onSubmit={this.onSubmit} validationSchema={emailValidationSchema} />
    ) : (
      <UpdateEmailConfirmForm
        oldEmail={account.email}
        newEmail={newEmail}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
        validationSchema={currentPasswordValidation}
      />
    );
  }

  private onSubmit = (newEmail: string) => () => {
    this.setState({ newEmail, currentForm: 2 });
  };

  private onCancel = () => {
    this.setState(_.cloneDeep(INITIAL_STATE), () => {
      const path = '/settings/account-security';
      this.props.history.push(path, {
        currentRoute: path,
        currentRouteName: 'Account & Security'
      });
    });
  };

  private onConfirm = (currentPassword: string) => () => {
    const { newEmail } = this.state;
    this.props.changePassword(newEmail, currentPassword);
  };
}

const mapStateToProps = ({ authentication: { account } }: IRootState) => ({ account });

const mapDispatchToProps = {
  changePassword
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(ChangeEmailScreen))));
