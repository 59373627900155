import { Theme, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { AppSpinner, InteractiveGame, LoadError } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import { RemoteConstants } from 'config/constants';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { clearMessages, showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { getGameConfig, getPlayForPointsActivity, updateGame } from 'redux/reducers/interactive-games/actions';
import { fetchActivities, fetchUserActivities, getActivity, setSelectedUserActivity } from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';
import styles from '../../assets/jss/modules/earn/EarnScreenStyle';

interface IMatchParams {
  activityId: string;
}
type PropsConnected = ConnectedProps<typeof connector>;

export interface IInteractiveGameProps extends PropsConnected, WithStyles<typeof styles> {
  theme: Theme;
}

type Props = IInteractiveGameProps & RouteComponentProps<IMatchParams>;

export interface IInteractiveGameState {
  fetchActivityCTTimeout: number;
  gameCompleted: boolean;
  refreshing: boolean;
  gameType: string;
}

const getSpinResultsFromExtraData = (extraData: string) => {
  try {
    if (!!extraData) {
      const obj = JSON.parse(extraData);
      if (!!obj.spinResults) {
        return JSON.stringify(obj.spinResults);
      }
    }
  } catch (e) {
    return undefined;
  }
};

class InteractiveGameScreen extends React.Component<Props, IInteractiveGameState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fetchActivityCTTimeout: 2000,
      gameCompleted: false,
      refreshing: false,
      gameType: 'prize'
    };
  }

  public async componentDidMount() {
    document.title = 'Interactive Game';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.length > 0) {
      await this.getData(params.activityId);
    } else {
      await this.showInvalidActivityError();
    }
  }

  public async getData(id) {
    if (this.props.isLoggedIn) {
      await this.props.fetchUserActivities();
    } else {
      await this.props.fetchActivities();
    }
    const { userActivities } = this.props.loyalty;
    const activity = userActivities.find(a => a.id === id);
    if (activity) {
      const fetchActivityCTTimeout = await RemoteConstants.getNumber('FETCH_ACTIVITY_CT_TIMEOUT');
      if (!!fetchActivityCTTimeout) {
        this.setState({ fetchActivityCTTimeout });
      }
      await this.props.setSelectedUserActivity(activity);
      if (this.props.loyalty.selectedActivity && this.props.isLoggedIn) {
        this.props.getGameConfig(this.props.loyalty.selectedActivity.extra_data, this.state.gameType);
      }
    } else {
      await this.showInvalidActivityError();
    }
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `This activity is not available.`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  private gameReplay = async () => {
    const { selectedActivity } = this.props.loyalty;

    await this.setState({ refreshing: true });
    await this.props.updateGame(this.props.interactiveGames.gameConfig.id);
    if (selectedActivity) {
      await this.props.getGameConfig(selectedActivity.extra_data, this.state.gameType);
    }
    await this.setState({ refreshing: false });
  };

  private getGameData = async (gameType?: string) => {
    await this.props.isAuthenticated();
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    if (!!gameType) {
      this.setState({ gameType });
    }
    if (this.props.loyalty.selectedActivity) {
      this.props.getGameConfig(this.props.loyalty.selectedActivity.extra_data, gameType ? gameType : this.state.gameType);
    }
  };

  public componentWillUnmount() {
    this.props.clearMessages();
  }

  private gameEnded = async () => {
    const { selectedActivity } = this.props.loyalty;

    await this.setState({ refreshing: true });

    if (this.state.gameCompleted) {
      this.props.updateGame(this.props.interactiveGames.gameConfig.id);
    }

    setTimeout(async () => {
      if (selectedActivity) {
        await this.props.getActivity(selectedActivity.id);
        await this.props.getPlayForPointsActivity(selectedActivity.extra_data);
      }
      await this.setState({ refreshing: false });
    }, this.state.fetchActivityCTTimeout);
  };

  private gameCompleted = () => {
    this.setState({ gameCompleted: true });
  };

  public render() {
    const { classes, history, isLoggedIn } = this.props;
    const { selectedActivity, loading, maximumLimitReached } = this.props.loyalty;
    const { refreshing, gameType } = this.state;
    const { gameConfig, playForPointsActivity } = this.props.interactiveGames;

    if (isLoggedIn && !gameConfig && !loading) {
      return <LoadError text="Failed to load details" />;
    }

    if (!selectedActivity) {
      return null;
    }

    const interactiveGameConfig = gameConfig.gameConfig;

    const gameUrl = selectedActivity.gameUrl || (interactiveGameConfig ? interactiveGameConfig.gameUrl : null);

    if (!gameUrl && isLoggedIn && !loading) {
      return <LoadError text="Failed to load details" />;
    }

    let spinResults;
    const gamePoints = gameConfig && gameConfig.prizes && gameConfig.prizes.length > 0 ? gameConfig.prizes[0].amount : 0;

    if (gameConfig && gameConfig.prizes && gameConfig.prizes.length > 0 && !!gameConfig.prizes[0].extraData) {
      spinResults = getSpinResultsFromExtraData(gameConfig.prizes[0].extraData);
    }
    const onNavigate = () => {
      history.push('/earn');
    };

    const renderTitle = () => (
      <DetailTitle
        title={selectedActivity.extra_data?.display_name ? selectedActivity.extra_data.display_name : selectedActivity.title}
        linkText={'Earn'}
        navigateTo={onNavigate}
      />
    );

    return (
      <div>
        <ScrollToTopOnMount />
        {loading && <AppSpinner label={'Loading Activity'} />}
        <Grid className={classes.container} container={true} spacing={2}>
          <InteractiveGame
            getGameData={this.getGameData}
            renderTitle={renderTitle}
            selectedUserActivity={selectedActivity}
            playForPointsActivity={playForPointsActivity}
            numPoints={selectedActivity.num_points}
            limitReached={maximumLimitReached}
            refreshing={refreshing}
            gameFace={gameConfig.face}
            gameConfig={interactiveGameConfig}
            gameUrl={gameUrl}
            // @ts-ignore
            gamePoints={gamePoints}
            spinResults={spinResults}
            gameType={gameType}
            gameMessage={gameConfig.message}
            onGameExitHandler={this.gameEnded}
            onGameEndHandler={this.gameCompleted}
            onGameReplayHandler={this.gameReplay}
            portraitMode={selectedActivity.portraitMode}
            isLoggedIn={this.props.isLoggedIn}
          />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty, interactiveGames }: IRootState) => ({
  loyalty,
  interactiveGames,
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email
});

const mapDispatchToProps = {
  getActivity,
  getGameConfig,
  showMessageBar,
  clearMessages,
  updateGame,
  setSelectedUserActivity,
  getPlayForPointsActivity,
  fetchActivities,
  fetchUserActivities,
  isAuthenticated
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withTheme(withStyles(styles)(InteractiveGameScreen))));
