import { createStyles, Theme } from '@material-ui/core';
import color from 'color';

import { container } from 'assets/jss/material-kit-pro-react.jsx';

export default (theme: Theme) =>
  createStyles({
    container: {
      ...container,
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    loginScreen: {
      backgroundColor: color(theme.palette.primary.main)
        .alpha(0.37)
        .rgb()
        .string(),
      minHeight: '100%',
      paddingBottom: theme.spacing(4)
    },
    loginCard: {
      marginTop: theme.spacing(4)
    },
    loginCardContent: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing()
      }
    },
    loginCardContentInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(2)
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(5)
      }
    },
    titleText: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 24,
        marginBottom: 16
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
        marginBottom: 32
      }
    },
    errorText: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 16
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: 32
      }
    }
  });
