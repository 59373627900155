import {
  CHANGE_PASSWORD,
  CHECK_PASSWORD,
  CLEAR_CURRENT_PASSWORD_VALID,
  IPasswordChangeState,
  PasswordChangeState,
  RESET_CHANGE_PASSWORD
} from '@pbl/pbl-react-core/lib/models/password';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IPasswordChangeState = {
  loading: false,
  errorMessage: null,
  success: false,
  failure: false,
  currentPasswordValid: null,
  checking: false
};

export default (state: PasswordChangeState = INITIAL_STATE, action: IDispatchAction): PasswordChangeState => {
  switch (action.type) {
    case FAILURE(CHANGE_PASSWORD):
      return {
        ...state,
        success: false
      };
    case SUCCESS(CHANGE_PASSWORD):
      return {
        ...state,
        success: true
      };
    case REQUEST(CHECK_PASSWORD):
      return {
        ...state,
        checking: true
      };
    case FAILURE(CHECK_PASSWORD):
      return {
        ...state,
        checking: false,
        currentPasswordValid: false
      };
    case SUCCESS(CHECK_PASSWORD):
      return {
        ...state,
        checking: false,
        currentPasswordValid: action.payload
      };
    case CLEAR_CURRENT_PASSWORD_VALID:
      return {
        ...state,
        currentPasswordValid: null
      };
    case RESET_CHANGE_PASSWORD:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
