import { nowUtc } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { RemoteConstants } from 'config/constants';
import moment from 'moment-timezone';

const displayOutageBanner = async () => {
  let showOutageBanner = false;
  let body = '';
  let displayTime = 30000; // default to 30 seconds.
  await RemoteConstants.sync();
  const outageBannerConfigString = RemoteConstants.getString('OUTAGE_BANNER_CONFIG');

  if (outageBannerConfigString) {
    const outageBannerConfig = JSON.parse(outageBannerConfigString);

    if (outageBannerConfig?.flag !== 'true' || !outageBannerConfig?.startDate) {
      return { showOutageBanner, body, displayTime };
    }

    body = outageBannerConfig.body;
    displayTime = outageBannerConfig.displayTime;

    const now = nowUtc().unix();
    const startDate = moment.utc(outageBannerConfig.startDate).unix();
    const isValidStartDate = now >= startDate;

    if (outageBannerConfig.flag === 'true' && isValidStartDate) {
      if (!!outageBannerConfig.endDate) {
        const endDate = moment.utc(outageBannerConfig.endDate).unix();
        const isValidEndDate = now <= endDate;
        if (isValidEndDate) {
          showOutageBanner = true;
        }
      } else {
        showOutageBanner = true;
      }
    }
  }
  return { showOutageBanner, body, displayTime };
};

export default displayOutageBanner;
