import { PropTypes } from '@material-ui/core';
import NavigationUtils from 'utils/NavigationUtils';

const onNavigate = (route, title) => () =>
  NavigationUtils.push(route, {
    currentRoute: route,
    currentRouteName: title
  });

type MenuPosition = 'full' | 'left' | 'right';
type Divider = 'top' | 'bottom';

export interface IMenuItem {
  key: string;
  title: string;
  titleStyle?: any;
  subTitle?: string;
  subTitleStyle?: any;
  icon?: string;
  iconColor?: PropTypes.Color;
  route?: string;
  hideOnAuth?: boolean;
  hideOnUnAuth?: boolean;
  isActive?: boolean;
  position?: MenuPosition;
  style?: any;
  divider?: Divider;
  onClick?: () => any;
  subMenu?: IMenuItem[];
  materialUiIcon?: boolean;
  feature?: string;
  featurePlaceholder?: any;
  showBadge?: boolean;
  showMobile?: boolean;
}

export interface INavItem extends IMenuItem {
  icon: string;
  isExternal?: boolean;
}

export interface IMenu {
  profile: IMenuItem[];
  sideMenu: IMenuItem[];
  mobileSideMenu: IMenuItem[];
  mainMenu: INavItem[];
  footerMenu: IMenuItem[];
  footerSocialMenu: INavItem[];
  footerMenuAZ: IMenuItem[];
}

const profile: IMenuItem[] = [
  {
    key: 'profile',
    title: '_', // title is derived from user login
    subTitle: 'View Profile',
    onClick: onNavigate('/settings/profile', 'Profile'),
    divider: 'bottom',
    hideOnUnAuth: true
  },
  {
    key: 'preferences',
    title: 'Communication Preferences',
    onClick: onNavigate('/settings/preferences', 'Communication Preferences'),
    hideOnUnAuth: true
  },
  {
    key: 'interests',
    title: 'Interests',
    onClick: onNavigate('/settings/interests', 'Interests'),
    hideOnUnAuth: true
  },
  {
    key: 'invite',
    title: 'Invite Friends',
    onClick: onNavigate('/settings/invite-friends', 'Invite Friends'),
    divider: 'bottom',
    hideOnUnAuth: true
  },
  {
    key: 'players club history',
    title: 'Players Club History',
    onClick: onNavigate('/history', 'History'),
    hideOnUnAuth: true
  },
  {
    key: 'lucky lounge history',
    title: 'Lucky Lounge History',
    onClick: onNavigate('/lucky-lounge/history', 'TransactionHistory'),
    hideOnUnAuth: true,
    divider: 'bottom',
    feature: 'SWEEPSTAKES_V1'
  },
  {
    key: 'account',
    title: 'Account & Security',
    onClick: onNavigate('/settings/account-security', 'Account & Security'),
    hideOnUnAuth: true
  },
  {
    key: 'sign-out',
    title: 'Sign Out',
    position: 'right',
    divider: 'top',
    icon: 'icon-sign-out',
    hideOnUnAuth: true
  },
  {
    key: 'login',
    title: 'Sign In',
    route: '/login',
    hideOnAuth: true
  },
  {
    key: 'register',
    title: 'Register',
    route: '/register',
    hideOnAuth: true
  }
];

const sideMenu: IMenuItem[] = [
  {
    key: 'home',
    title: 'HOME',
    route: '/'
  },
  {
    key: 'ticket-entry',
    title: 'ENTER TICKET',
    route: `/ticket-entry`,
    hideOnUnAuth: true
  },
  {
    key: 'feedback',
    title: 'SEND US FEEDBACK',
    route: '/feedback'
  },
  {
    key: 'faqs',
    title: 'FAQs',
    route: '/media/faq'
  }
];

const mainMenu: INavItem[] = [
  {
    key: 'earn',
    title: 'EARN',
    route: '/earn',
    icon: 'icon-earn'
  },
  {
    key: 'rewards',
    title: 'REWARDS',
    route: '/rewards',
    icon: 'icon-rewards'
  },
  {
    key: 'draws',
    title: 'PROMOTIONS',
    route: '/promotion',
    icon: 'icon-draws'
  },
  {
    key: 'games',
    title: 'GAMES',
    route: '/games',
    subMenu: [
      {
        key: 'all-games',
        title: 'ALL GAMES',
        route: '/games'
      },
      {
        key: 'scratcher',
        title: 'SCRATCHERS',
        route: '/games/types/1'
      },
      {
        key: 'fast-play',
        title: 'FAST PLAY',
        route: '/games/types/5'
      },
      {
        key: 'draw',
        title: 'DRAW GAMES',
        route: '/games/types/2'
      },
      {
        key: 'quick-draw',
        title: 'QUICK DRAW',
        route: '/games/types/7'
      }
    ],
    icon: 'icon-games-filled'
  },
  {
    key: 'lucky-lounge',
    title: 'PLAY',
    route: '/lucky-lounge',
    icon: 'icon-lucky-lounge',
    feature: 'SWEEPSTAKES_V1',
    featurePlaceholder: {
      key: 'history',
      title: 'HISTORY',
      route: '/history',
      icon: 'icon-history',
      hideOnUnAuth: true
    }
  }
];

const mobileSideMenu: IMenuItem[] = [
  {
    key: 'mobile-home',
    title: 'Home',
    route: '/'
  },
  {
    key: 'mobile-earn',
    title: 'Earn',
    route: '/earn',
    icon: 'icon-earn'
  },
  {
    key: 'mobile-rewards',
    title: 'Rewards',
    route: '/rewards',
    icon: 'icon-rewards'
  },
  {
    key: 'mobile-draws',
    title: 'Promotions',
    route: '/promotion',
    icon: 'icon-draws'
  },
  {
    key: 'mobile-games',
    title: 'Games',
    route: '/games',
    subMenu: [
      {
        key: 'all-games',
        title: 'ALL GAMES',
        route: '/games'
      },
      {
        key: 'scratcher',
        title: 'SCRATCHERS',
        route: '/games/types/1'
      },
      {
        key: 'fast-play',
        title: 'FAST PLAY',
        route: '/games/types/5'
      },
      {
        key: 'draw',
        title: 'DRAW GAMES',
        route: '/games/types/2'
      },
      {
        key: 'quick-draw',
        title: 'QUICK DRAW',
        route: '/games/types/7'
      }
    ],
    icon: 'icon-games-filled'
  },
  {
    key: 'mobile-lucky-lounge',
    title: 'Play',
    route: '/lucky-lounge',
    feature: 'SWEEPSTAKES_V1',
    featurePlaceholder: {
      key: 'mobile-history',
      title: 'History',
      route: '/history',
      hideOnUnAuth: true
    }
  },
  {
    key: 'ticket-entry',
    title: 'Enter Ticket',
    route: `/ticket-entry`,
    hideOnUnAuth: true
  },
  {
    key: 'mobile-feedback',
    title: 'Send Us Feedback',
    route: '/feedback'
  },
  {
    key: 'faqs',
    title: 'FAQs',
    route: '/media/faq'
  }
];

const footerMenu: IMenuItem[] = [
  {
    key: 'faqs',
    title: 'FAQs',
    route: '/media/faq'
  },
  {
    key: 'terms',
    title: 'Terms and Conditions',
    route: '/media/terms_conditions'
  },
  {
    key: 'privacy',
    title: 'Privacy Policy',
    route: '/media/privacy'
  },
  {
    key: 'contact-us',
    title: 'Send Us Feedback',
    route: '/feedback'
  }
];

const footerMenuAZ: IMenuItem[] = [
  {
    key: 'faqs',
    title: 'FAQs',
    route: '/media/faq_ll'
  },
  {
    key: 'terms',
    title: 'Terms and Conditions',
    route: '/media/terms_conditions_ll'
  },
  {
    key: 'privacy',
    title: 'Privacy Policy',
    route: '/media/privacy'
  },
  {
    key: 'contact-us',
    title: 'Send Us Feedback',
    route: '/feedback'
  }
];

const footerSocialMenu: INavItem[] = [
  {
    key: 'facebook',
    title: 'Facebook',
    route: 'https://www.facebook.com/ArizonaLottery',
    icon: 'fab fa-facebook',
    isExternal: true
  },
  {
    key: 'instagram',
    title: 'Instagram',
    route: 'https://www.instagram.com/azlottery/',
    icon: 'fab fa-instagram',
    isExternal: true
  },
  {
    key: 'twitter',
    title: 'Twitter',
    route: 'https://twitter.com/azlottery?ref_src=twsrc%255Egoogle%257Ctwcamp%255Eserp%257Ctwgr%255Eauthor',
    icon: 'fab fa-x-twitter',
    isExternal: true
  },
  {
    key: 'youtube',
    title: 'Youtube',
    route: 'https://www.youtube.com/user/arizonalottery',
    icon: 'fab fa-youtube',
    isExternal: true
  },
  {
    key: 'linkedin',
    title: 'LinkedIn',
    route: 'https://www.linkedin.com/company/arizona-lottery/',
    icon: 'fab fa-linkedin',
    isExternal: true
  }
];

const menu: IMenu = {
  profile,
  sideMenu,
  mobileSideMenu,
  mainMenu,
  footerMenu,
  footerMenuAZ,
  footerSocialMenu
};

// @ts-ignore
export default menu;
