import {
  COUNT_FREE_ENTRY,
  FETCH_DETAILS_PAGE_PROGRESSIVE_PRIZES,
  FETCH_DRAWS_BY_PROMOTION,
  FETCH_DRAW_ENTRY_DETAILS,
  FETCH_DRAW_PRIZES,
  FETCH_DRAW_PRIZES_WITH_CONTENT,
  FETCH_DRAW_PRIZES_WITH_WINNERS,
  FETCH_DRAW_WITH_ADDITIONAL_INFORMATION,
  FETCH_ELIGIBLE_GAMES,
  FETCH_HOME_PAGE_PROGRESSIVE_PRIZES,
  FETCH_HOME_PAGE_PROGRESSIVE_PROMOTIONS,
  FETCH_HOME_PAGE_PROMOTIONS,
  FETCH_LATEST_DRAW_HISTORY,
  FETCH_PROGRESSIVE_PRIZES,
  FETCH_PROMOTIONS,
  FETCH_PROMOTION_DETAILS,
  IDraw,
  IPromotion,
  LOAD_MORE,
  PromotionType,
  RESET_DRAW,
  RESET_PROMOTION,
  SELECT_DRAW,
  SELECT_PROMOTION
} from '@pbl/pbl-react-core/lib/models/draws/types';
import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { DrawsService, PromotionsService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import { GetStateMethod } from 'redux/reducers';
import * as FilterActions from 'shared/components/filters/filters.actions';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const fetchActiveDraw = async (promotionId: number, active: boolean): Promise<any> => {
  try {
    if (active) {
      const activeDraws = await PromotionsService.fetchActiveDraws(promotionId);
      if (activeDraws.length > 1) {
        return activeDraws.find(t => t.prizes.find(p => !!p.multipler || !!p.constant));
      }
      if (activeDraws.length === 0) {
        return fetchActiveDraw(promotionId, false);
      }
      return activeDraws;
    }

    const draws = await PromotionsService.fetchPublishedDrawsByPromotion(promotionId);
    if (draws && draws.length > 0) {
      return draws[draws.length - 1];
    }
    return undefined;
  } catch (e) {
    return active ? fetchActiveDraw(promotionId, false) : undefined;
  }
};

const fetchProgressivePrize = async (id: number, draw: any | undefined, forPromotion: boolean): Promise<any> => {
  try {
    if (forPromotion) {
      return (await PromotionsService.fetchProgressivePrize(id)) || (await fetchProgressivePrize(id, draw, false));
    }

    const d = draw || (await fetchActiveDraw(id, true));
    if (d) {
      try {
        const prizes = await PromotionsService.fetchPrizesByDraw(d.id);
        return prizes && prizes.length > 0 ? prizes[0] : undefined;
      } catch (e) {}
    }

    return undefined;
  } catch (e) {
    return forPromotion ? fetchProgressivePrize(id, draw, false) : undefined;
  }
};

export const fetchPromotions = (page: number) => async (dispatch: any) => {
  const pageZero: boolean = page === 0;
  try {
    dispatch({
      type: REQUEST(pageZero ? FETCH_PROMOTIONS : LOAD_MORE)
    });
    const promotions = await PromotionsService.fetchPromotions({ page, size: pageZero ? 10 : 9 }, undefined, !pageZero);
    if (promotions && promotions.content && promotions.content.length > 0) {
      promotions.content.map(async item => {
        if (item.promotionType === PromotionType.PROGRESSIVE) {
          const draw = await fetchActiveDraw(item.id, true);
          if (draw) {
            item.progressiveDrawDate = draw.draw;
            item.progressiveEntryEndDate = draw.end;
            item.progressiveDrawNumber = draw.drawNumber;
          }
          item.progressivePrize = await fetchProgressivePrize(item.id, draw, true);
          return item;
        }
      });
    }
    dispatch({
      type: SUCCESS(pageZero ? FETCH_PROMOTIONS : LOAD_MORE),
      payload: promotions
    });
  } catch (error) {
    dispatch({
      type: FAILURE(pageZero ? FETCH_PROMOTIONS : LOAD_MORE),
      payload: { error }
    });
  }
};

export const loadMore = (page: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: LOAD_MORE,
      payload: PromotionsService.fetchPromotions({ page })
    });
  } catch (error) {}
};

export const fetchPromotionDetails = (id: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: REQUEST(FETCH_PROMOTION_DETAILS)
    });
    const promotion = await PromotionsService.fetchPromotionDetails(id);
    if (promotion && promotion.promotionType === PromotionType.PROGRESSIVE) {
      const draw = await fetchActiveDraw(promotion.id, true);
      if (draw) {
        promotion.progressiveDrawDate = draw.draw;
        promotion.progressiveEntryEndDate = draw.end;
        promotion.progressiveDrawNumber = draw.drawNumber;
      }
      promotion.progressivePrize = await fetchProgressivePrize(promotion.id, draw, true);
      return dispatch({
        type: SUCCESS(FETCH_PROMOTION_DETAILS),
        payload: promotion
      });
    }
    dispatch({
      type: SUCCESS(FETCH_PROMOTION_DETAILS),
      payload: promotion
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_PROMOTION_DETAILS),
      payload: {
        error
      }
    });
  }
};

export const fetchHomePagePromotions = () => async (dispatch: any) => {
  await dispatch({
    type: FETCH_HOME_PAGE_PROMOTIONS,
    payload: PromotionsService.fetchSecondChancePromotions({ page: 0, size: constants.HOME_SCREEN_DRAW_COUNT })
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchHomePageProgressivePromotions = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_HOME_PAGE_PROGRESSIVE_PROMOTIONS)
    });

    const response = await PromotionsService.fetchProgressivePromotions({ page: 0, size: constants.HOME_SCREEN_PROGRESSIVE_DRAW_COUNT });
    if (response && response.content && response.content.length > 0) {
      const content: any[] = [];
      for (const item of response.content) {
        const draw = await fetchActiveDraw(item.id, true);
        if (draw) {
          item.progressiveDrawDate = draw.draw;
          item.progressiveEntryEndDate = draw.end;
          item.progressiveDrawNumber = draw.drawNumber;
        }
        item.progressivePrize = await fetchProgressivePrize(item.id, draw, true);
        content.push(item);
      }

      response.content = content;
    }

    dispatch({
      type: SUCCESS(FETCH_HOME_PAGE_PROGRESSIVE_PROMOTIONS),
      payload: response
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_HOME_PAGE_PROGRESSIVE_PROMOTIONS),
      payload: {
        error
      }
    });
  }
};

export const fetchHomePageProgressivePrizes = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      draws: { homeProgressivePromotions }
    } = getState();
    dispatch({
      type: REQUEST(FETCH_HOME_PAGE_PROGRESSIVE_PRIZES)
    });
    homeProgressivePromotions.map(async promotion => {
      const prize = await fetchProgressivePrize(promotion.id, undefined, true);

      if (promotion.progressivePrize && promotion.progressivePrize.drawId !== prize.drawId) {
        const draw = await fetchActiveDraw(promotion.id, true);
        if (draw) {
          promotion.progressiveDrawDate = draw.draw;
          promotion.progressiveEntryEndDate = draw.end;
          promotion.progressiveDrawNumber = draw.drawNumber;
        }
      }
      promotion.progressivePrize = prize;
      return promotion;
    });
    dispatch({
      type: SUCCESS(FETCH_HOME_PAGE_PROGRESSIVE_PRIZES),
      payload: homeProgressivePromotions
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_HOME_PAGE_PROGRESSIVE_PRIZES),
      payload: {
        error
      }
    });
  }
};

export const fetchProgressivePrizes = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      draws: { promotions, featuredPromotion }
    } = getState();
    dispatch({
      type: REQUEST(FETCH_PROGRESSIVE_PRIZES)
    });
    if (!!featuredPromotion && featuredPromotion.promotionType === PromotionType.PROGRESSIVE) {
      const prize = await fetchProgressivePrize(featuredPromotion.id, undefined, true);
      if (featuredPromotion.progressivePrize && featuredPromotion.progressivePrize.drawId !== prize.drawId) {
        const draw = await fetchActiveDraw(featuredPromotion.id, true);
        if (draw) {
          featuredPromotion.progressiveDrawDate = draw.draw;
          featuredPromotion.progressiveEntryEndDate = draw.end;
          featuredPromotion.progressiveDrawNumber = draw.drawNumber;
        }
      }
      featuredPromotion.progressivePrize = prize;
    }
    promotions.map(async promotion => {
      if (promotion.promotionType === PromotionType.PROGRESSIVE) {
        const prize = await fetchProgressivePrize(promotion.id, undefined, true);
        if (promotion.progressivePrize && promotion.progressivePrize.drawId !== prize.drawId) {
          const draw = await fetchActiveDraw(promotion.id, true);
          if (draw) {
            promotion.progressiveDrawDate = draw.draw;
            promotion.progressiveEntryEndDate = draw.end;
            promotion.progressiveDrawNumber = draw.drawNumber;
          }
        }
        promotion.progressivePrize = prize;
      }
      return promotion;
    });
    dispatch({
      type: SUCCESS(FETCH_PROGRESSIVE_PRIZES),
      payload: { promotions, featuredPromotion }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_PROGRESSIVE_PRIZES),
      payload: error
    });
  }
};

export const fetchDetailsPageProgressivePrizes = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      draws: { selectedPromotion }
    } = getState();
    dispatch({
      type: REQUEST(FETCH_DETAILS_PAGE_PROGRESSIVE_PRIZES)
    });
    if (selectedPromotion.promotionType === PromotionType.PROGRESSIVE) {
      const prize = await fetchProgressivePrize(selectedPromotion.id, undefined, true);
      if (selectedPromotion.progressivePrize && selectedPromotion.progressivePrize.drawId !== prize.drawId) {
        const draw = await fetchActiveDraw(selectedPromotion.id, true);
        if (draw) {
          selectedPromotion.progressiveDrawDate = draw.draw;
          selectedPromotion.progressiveEntryEndDate = draw.end;
          selectedPromotion.progressiveDrawNumber = draw.drawNumber;
        }
      }
      selectedPromotion.progressivePrize = prize;
      return selectedPromotion;
    }
    dispatch({
      type: SUCCESS(FETCH_DETAILS_PAGE_PROGRESSIVE_PRIZES),
      payload: { selectedPromotion }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_DETAILS_PAGE_PROGRESSIVE_PRIZES),
      payload: error
    });
  }
};

export const toggleFilter = (entityName: string, sectionKey: string, filterKey: string) => (dispatch: any) => {
  dispatch(FilterActions.toggleFilter(entityName, sectionKey, filterKey));
};

export const clearFilter = () => (dispatch: any) => {
  dispatch(FilterActions.clearFilter('draw'));
};

export const replaceFilers = (filters: IFilters) => (dispatch: any) => {
  dispatch(FilterActions.replaceFilers('draw', filters));
};

export const fetchDrawsByPromotion = (id: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: FETCH_DRAWS_BY_PROMOTION,
      payload: PromotionsService.fetchPublishedDrawsByPromotion(id)
    });
  } catch (error) {}
};

export const fetchDrawEntries = (drawId: number) => async (dispatch: any) => {
  await dispatch({
    type: FETCH_DRAW_ENTRY_DETAILS,
    payload: DrawsService.fetchDrawEntryDetails(drawId)
  });
};

export const selectDraw = (draw: IDraw) => async (dispatch: any) => {
  await dispatch({
    type: SELECT_DRAW,
    payload: draw
  });
};
export const selectPromotion = (promotion: IPromotion) => async (dispatch: any) => {
  await dispatch({
    type: SELECT_PROMOTION,
    payload: promotion
  });
};
export const fetchDrawWithAdditionalInformation = (drawId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_DRAW_WITH_ADDITIONAL_INFORMATION),
      payload: null
    });

    const response = await PromotionsService.fetchDrawWithAdditionalInformation(drawId);
    dispatch({
      type: SUCCESS(FETCH_DRAW_WITH_ADDITIONAL_INFORMATION),
      payload: response
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_DRAW_WITH_ADDITIONAL_INFORMATION),
      payload: {
        error
      }
    });
  }
};

export const fetchDrawPrizes = (drawId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_DRAW_PRIZES),
      payload: drawId
    });

    const response = await PromotionsService.getPrizesByDrawId(drawId);
    dispatch({
      type: SUCCESS(FETCH_DRAW_PRIZES),
      payload: response
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_DRAW_PRIZES),
      payload: {
        error
      }
    });
  }
};

export const fetchDrawPrizesWithContent = (drawId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_DRAW_PRIZES_WITH_CONTENT),
      payload: null
    });

    const response = await PromotionsService.getPrizesWithContentForDraw(drawId);
    dispatch({
      type: SUCCESS(FETCH_DRAW_PRIZES_WITH_CONTENT),
      payload: response
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_DRAW_PRIZES_WITH_CONTENT),
      payload: {
        error
      }
    });
  }
};

export const fetchDrawPrizesWithWinners = (drawId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_DRAW_PRIZES_WITH_WINNERS),
      payload: null
    });

    const response = await PromotionsService.fetchPrizesWithWinners(drawId);
    dispatch({
      type: SUCCESS(FETCH_DRAW_PRIZES_WITH_WINNERS),
      payload: response
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_DRAW_PRIZES_WITH_WINNERS),
      payload: {
        error
      }
    });
  }
};

export const fetchLatestDrawHistory = (drawId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_LATEST_DRAW_HISTORY),
      payload: null
    });

    const response = await DrawsService.fetchLatestDrawHistory(drawId);
    dispatch({
      type: SUCCESS(FETCH_LATEST_DRAW_HISTORY),
      payload: response
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_LATEST_DRAW_HISTORY),
      payload: {
        error
      }
    });
  }
};

export const fetchEligibleGames = (id: number) => async (dispatch: any) => {
  await dispatch({
    type: FETCH_ELIGIBLE_GAMES,
    payload: DrawsService.fetchGamesByPromotion(id)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const fetchRewardFreeEntries = (drawId: number) => async (dispatch: any) => {
  dispatch({
    type: COUNT_FREE_ENTRY,
    payload: PromotionsService.countFreeEntry(drawId)
  }).catch(error => {
    console.error(error);
  });
};

export const resetPromotion = () => (dispatch: any) => {
  dispatch({
    type: RESET_PROMOTION
  });
};

export const resetDraw = () => (dispatch: any) => {
  dispatch({
    type: RESET_DRAW
  });
};
