import { Grid, Theme, withTheme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import ArcadePointsSection from '@pbl/pbl-react-web-components/lib/arcade/ArcadePointsSection';
import SuccessPayment from '@pbl/pbl-react-web-components/lib/store/SuccessPayment';
import logo from 'assets/img/sweepstakes/logos-lucky-lounge-vertical-gold.png';
import styles from 'assets/jss/modules/store/StoreScreenStyle';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { fetchUserTokens } from 'redux/reducers/ledger/actions';
import { clearStorage } from 'redux/reducers/store/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';

type PropsConnected = ConnectedProps<typeof connector>;

interface IStoreScreenProps extends PropsConnected, RouteComponentProps<any>, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
}

interface IStoreScreenStore {
  loading: boolean;
}

class StoreSuccessPayment extends React.Component<IStoreScreenProps, IStoreScreenStore> {
  constructor(props: IStoreScreenProps) {
    super(props);
    this.state = {
      loading: true
    };
  }
  public async componentDidMount() {
    document.title = 'Players Club Store';
    scrollToTheTop();
    this.props.fetchUserTokens();
  }
  private playersClubArcadeClick = (): void => {
    this.props.history.push('/lucky-lounge');
  };
  private playersClubStoreClick = (): void => {
    this.props.history.push('/lucky-lounge/store');
  };
  private homeClick = (): void => {
    this.props.history.push('/');
  };
  private navigateToHistory = (): void => {
    this.props.history.push('/lucky-lounge/history');
  };

  public componentWillUnmount() {
    this.props.clearStorage();
  }

  public render() {
    const { classes, store, userTokens, location } = this.props;
    const items = store.selectedItems || [];
    const total = store.total || 0;
    const locationState: any = location?.state ?? {};
    return (
      <div className={classes.container}>
        <Grid container={true} spacing={4}>
          <ScrollToTopOnMount />
          <Grid item={true} xs={12} className={classes.header}>
            <img alt={'Lucky Lounge Logo'} src={logo} className={classes.logo} />
            <ArcadePointsSection balance={userTokens.balance} onClick={this.playersClubStoreClick} navigateToHistory={this.navigateToHistory} />
          </Grid>
          <Grid item={true} xs={12}>
            <SuccessPayment
              items={items}
              total={total}
              transactionId={locationState.transactionId ?? ''}
              playersClubArcadeClick={this.playersClubArcadeClick}
              playersClubStoreClick={this.playersClubStoreClick}
              homeClick={this.homeClick}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { accessToken, account }, store, ledger: { userTokens } }: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  store,
  userTokens
});

const mapDispatchToProps = {
  clearStorage,
  fetchUserTokens
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(StoreSuccessPayment)))));
