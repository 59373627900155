import { Button, Grid, Icon, Typography, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ScreenCard from '@pbl/pbl-react-web-components/lib/card/ScreenCard';
import styles from 'assets/jss/modules/password-reset/components/PasswordResetSuccessStyle';

interface IActivationContentProps extends RouteComponentProps, WithStyles<typeof styles> {}

const ActivationContent: React.FunctionComponent<IActivationContentProps> = ({ classes, history }) => {
  // @ts-ignore
  const navigateTo = path => event => {
    history.push(path);
  };

  return (
    <ScreenCard title="Reset Your Password">
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={11} sm={9} lg={8}>
          <div className={classes.box}>
            <Icon
              className={classNames({
                [classes.icon]: true,
                [classes.iconSuccess]: true
              })}
            >
              done
            </Icon>
            <Typography variant="body1" className={classes.gotoText}>
              Your password has been updated successfully
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Typography variant="body1" className={classes.gotoText}>
        Go to
      </Typography>
      <Button
        size="large"
        variant="outlined"
        color="primary"
        aria-label="Sign In"
        onClick={navigateTo('/login')}
        className={classes.button}
      >
        Sign In
      </Button>
      <Button
        size="large"
        variant="outlined"
        color="primary"
        aria-label="Resend Activation"
        onClick={navigateTo('/')}
        className={classes.button}
      >
        home
      </Button>
    </ScreenCard>
  );
};

export default withStyles(styles)(withRouter(ActivationContent));
