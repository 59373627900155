import {
  APP_CLEAR_LOADING,
  APP_CLEAR_MESSAGES,
  APP_LOADING,
  APP_SHOW_MESSAGE_BAR,
  CLEAR_APP_STATE,
  IErrorParams,
  ISpinnerParams
} from '@pbl/pbl-react-core/lib/models/app';
import { DispatchMethod } from 'redux/reducers';
import NavigationUtils from 'utils/NavigationUtils';

interface IReactivation {
  show?: boolean;
}

export const toggleLoading = (params: ISpinnerParams = {}) => (dispatch: DispatchMethod) => {
  const { spinning, label } = params;

  dispatch({
    type: APP_LOADING,
    payload: { spinning, label }
  });
};

export const clearLoading = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: APP_CLEAR_LOADING
  });
};

export const showMessageBar = (params: IErrorParams = {}) => (dispatch: DispatchMethod) => {
  const { message, messageTimeout, type, messageParams } = params;

  dispatch({
    type: APP_SHOW_MESSAGE_BAR,
    payload: { message, messageTimeout, type, messageParams }
  });
};

export const showReactivationDialog = (params: IReactivation) => (dispatch: DispatchMethod) => {
  const { show } = params;

  dispatch({
    type: APP_SHOW_MESSAGE_BAR,
    payload: { show }
  });
};

export const clearMessages = () => ({
  type: APP_CLEAR_MESSAGES
});

export const resetApp = () => async (dispatch: any) => {
  /*
   * Persisted states have to be cleared prior to calling CLEAR_APP_STATE.
   * Otherwise redux-persist will rehydrate with the saved states
   */

  await dispatch({ type: CLEAR_APP_STATE }); // clear app state

  NavigationUtils.replace('/redirect-back', { redirect: NavigationUtils.currentRoute() });
};
