import { Button, Grid, Theme, WithStyles, withStyles, withTheme } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IField } from '@pbl/pbl-react-core/lib/models/forms/types';
import { FieldRenderer } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/settings/account/AccountScreenStyle';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { changePassword, checkPassword, clearCurrentPasswordValid, resetChangePassword } from 'redux/reducers/password/actions';
import PasswordStrengthIndicator from 'shared/components/password/PasswordStrengthIndicator';
import { currentPasswordValidation, passwordValidation, passwordValidatorSchema } from './validations';

type PropsConnected = ConnectedProps<typeof connector>;

interface IChangePasswordScreenProps extends RouteComponentProps, PropsConnected, WithStyles<typeof styles> {
  theme: Theme;
}

interface IChangePasswordScreenState {
  currentPassword: string;
  newPassword?: string;
  confirmPassword?: string;

  [key: string]: any;
}

class ChangePasswordScreen extends React.Component<IChangePasswordScreenProps, IChangePasswordScreenState> {
  public state: IChangePasswordScreenState = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  public componentDidMount() {
    document.title = 'Change Password';
    this.props.clearCurrentPasswordValid();
  }

  public componentDidUpdate(prevProps: Readonly<IChangePasswordScreenProps>): void {
    if (this.props.success && prevProps.success !== this.props.success) {
      this.props.resetChangePassword();
      this.setState({ currentPassword: '', newPassword: '' });
      this.props.history.push(`/`);
    }
  }

  public render(): React.ReactNode {
    const { classes, checking, currentPasswordValid } = this.props;
    const { currentPassword, newPassword, confirmPassword } = this.state;

    // @ts-ignore
    const passwordErrors = passwordValidatorSchema.validate(newPassword, {
      list: true
    });

    // @ts-ignore
    const activeButton =
      passwordErrors &&
      passwordErrors.length === 0 &&
      currentPasswordValid &&
      currentPassword !== '' &&
      newPassword !== '' &&
      confirmPassword !== '' &&
      confirmPassword === newPassword;

    const currentPasswordField: IField<string> = {
      type: 'password',
      key: 'currentPassword',
      label: 'Current Password',
      get placeholder(): string {
        return this.label;
      },
      value: currentPassword,
      disabled: checking,
      isRequired: true,
      showPassword: true,
      secureTextEntry: true,
      get fieldValue() {
        return this.value;
      },
      validationSchema: currentPasswordValidation
    };

    const newPasswordField: IField<string> = {
      type: 'password',
      key: 'newPassword',
      label: 'New Password',
      get placeholder(): string {
        return this.label;
      },
      value: newPassword,
      isRequired: true,
      showPassword: true,
      secureTextEntry: true,
      get fieldValue() {
        return this.value;
      },
      validationSchema: passwordValidation
    };

    const confirmPasswordField: IField<string> = {
      type: 'password',
      key: 'confirmPassword',
      label: 'Confirm Password',
      get placeholder(): string {
        return this.label;
      },
      value: confirmPassword,
      isRequired: true,
      showPassword: true,
      secureTextEntry: true,
      get fieldValue() {
        return this.value;
      },
      validationSchema: passwordValidation
    };

    return (
      <Grid container={true} spacing={3} direction="column">
        <Grid item={true}>
          <FieldRenderer
            field={currentPasswordField}
            onChange={this.handleCurrentPasswordChange}
            className={classes.field}
            inputProps={{
              autoComplete: 'current-password',
              error: currentPasswordValid !== null && !currentPasswordValid,
              helperText: currentPasswordValid !== null && !currentPasswordValid ? 'Invalid Password' : null,
              fullWidth: true,
              onBlur: this.onCheckPassword,
              variant: 'filled'
            }}
          />
        </Grid>

        <Grid item={true}>
          <FieldRenderer
            field={newPasswordField}
            onChange={this.handleNewPasswordChange}
            className={classes.field}
            inputProps={{
              autoComplete: `new-password`,
              fullWidth: true,
              variant: 'filled'
            }}
          />
        </Grid>

        <Grid item={true}>
          <FieldRenderer
            field={confirmPasswordField}
            onChange={this.handleConfirmPasswordChange}
            className={classes.field}
            inputProps={{
              autoComplete: `confirm password`,
              error: !!confirmPassword && confirmPassword?.length > 0 ? confirmPassword !== newPassword : false,
              helperText: 'New and Confirm Password do not match',
              fullWidth: true,
              variant: 'filled'
            }}
          />
        </Grid>

        <PasswordStrengthIndicator
          password={newPassword}
          // @ts-ignore
          passwordErrors={passwordErrors}
        />

        <Grid item={true} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            aria-label="Change Password"
            onClick={this.onChangePassword}
            disabled={!activeButton}
          >
            Change Password
          </Button>
        </Grid>
      </Grid>
    );
  }

  private readonly validCharactersRegex = /[^a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?/\\|"`='~-]/g;

  private handleCurrentPasswordChange = (value: any) => {
    if (value === '') {
      this.props.clearCurrentPasswordValid();
    }
    const validCharacters = value.replace(this.validCharactersRegex, '');
    this.setState({ currentPassword: validCharacters });
  };

  private handleNewPasswordChange = (value: any) => {
    const validCharacters = value.replace(this.validCharactersRegex, '');
    this.setState({ newPassword: validCharacters });
  };

  private handleConfirmPasswordChange = (value: string) => {
    const validCharacters = value.replace(this.validCharactersRegex, '');
  this.setState({ confirmPassword: validCharacters });
  };

  private onChangePassword = () => {
    const { currentPassword, newPassword } = this.state;
    if (currentPassword === newPassword) {
      this.props.showMessageBar({
        type: 'error',
        message: 'password.samePassword'
      });
      return;
    }
    this.props.changePassword(currentPassword || '', newPassword || '');
  };

  private onCheckPassword = () => {
    const { currentPassword } = this.state;
    this.props.clearCurrentPasswordValid();
    if (currentPassword) {
      this.props.checkPassword(currentPassword);
    }
  };
}

const mapStateToProps = ({ passwordChange: { success, checking, currentPasswordValid } }: IRootState) => ({
  success,
  checking,
  currentPasswordValid
});

const mapDispatchToProps = {
  changePassword,
  checkPassword,
  clearCurrentPasswordValid,
  resetChangePassword,
  showMessageBar
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(ChangePasswordScreen))));
