import { FETCH_SWEEPSTAKE_DRAWS } from '@pbl/pbl-react-core/lib/models/draws/types';
import { Reward, util } from '@pbl/pbl-react-core/lib/models/loyalty';
import {
  ACTIVITY_COMPLETED,
  ACTIVITY_DETAILS,
  CREDIT_USER_POINTS,
  FETCH_INTEREST_ACTIVITY_TO_ACTIVITIES,
  FETCH_REWARD_BARCODE_DATA,
  FETCH_REWARD_ENTRY_DETAILS,
  GET_ACTIVITY_MAPPING,
  GET_ACTIVITY_MAPPINGS,
  GET_CAMPAIGN,
  GET_INVITE_ACCEPTED_ACTIVITY,
  GET_INVITE_FRIENDS_ACTIVITY,
  GET_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY,
  IActivity,
  IActivityCompleted,
  IPhoto,
  REDEEM_REWARD,
  RESET_ERROR,
  RESET_SELECTED_ACTIVITY,
  RESET_SELECTED_REWARD,
  RESET_STATE,
  REWARD,
  REWARDS,
  REWARDS_CATEGORIES,
  SET_SELECTED_ACTIVITY,
  SET_SELECTED_REWARD,
  UPLOAD_PHOTO,
  USER_ACTIVITIES,
  USER_POINTS,
  USER_REWARDS,
  VALIDATE_PROMO_CODE
} from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { DrawsService, IDrawsRequest, InventoryService, LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import * as _ from 'lodash';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import performDispatch from 'utils/dispatch';
import { clearMessages, showMessageBar } from '../app/actions';

export const fetchUserPoints = () => async (dispatch: any) => {
  await dispatch({
    type: USER_POINTS,
    payload: LoyaltyService.getUserPoints()
  }).catch(error => {
    console.error(error);
  });
};

export const fetchUserRewards = () => async (dispatch: any) => {
  await dispatch({
    type: USER_REWARDS,
    payload: LoyaltyService.getUserRewards()
  }).catch(error => {
    console.error(error);
  });
};

export const creditUserPoints = (params?: any) => async dispatch => {
  const { activityId, headers } = params;
  try {
    await performDispatch(dispatch, CREDIT_USER_POINTS, () => LoyaltyService.creditUserPointsV1(activityId, headers));
  } catch (error) {
    dispatch(showMessageBar({ message: error.payload.error, type: 'error' }));
    console.error(error);
  }
};

export const fetchRewardCategories = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(REWARDS_CATEGORIES)
    });
    const payload = await LoyaltyService.getRewardCategories();
    const sorted = _.orderBy(payload, 'name', 'desc');
    dispatch({
      type: SUCCESS(REWARDS_CATEGORIES),
      payload: sorted
    });
  } catch (error) {
    dispatch({
      type: FAILURE(REWARDS_CATEGORIES),
      payload: error
    });
    console.error(error);
  }
};

export const fetchRewards = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: REWARDS,
      payload: LoyaltyService.getRewards()
    });
  } catch (e) {
    console.error(e);
  }
};

export const fetchReward = (rewardId: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: REWARD,
      payload: LoyaltyService.getReward(rewardId)
    });
  } catch (e) {
    console.error(e);
  }
};

export const setSelectedReward = (reward: Reward) => (dispatch: any) => {
  dispatch({
    type: SET_SELECTED_REWARD,
    payload: reward
  });
};

export const resetSelectedReward = () => (dispatch: any) => {
  dispatch({
    type: RESET_SELECTED_REWARD
  });
};

export const redeemReward = (rewardId: string, quantity: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(REDEEM_REWARD)
    });
    const payload = await LoyaltyService.redeemRewardFromLoyalty(rewardId, quantity);
    const codes = util.mapCodes(payload.codes);
    if (codes && codes.length === 1 && codes[0].codeType === 'COUPON' && !!codes[0].couponKey) {
      await dispatch(getBarcodeData(codes[0].couponKey, codes[0].barcodeValue, codes[0].humanReadable));
    }
    dispatch({
      type: SUCCESS(REDEEM_REWARD),
      payload: {
        digital_download: payload.digital_download,
        codes
      }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(REDEEM_REWARD),
      payload: error
    });
    console.error(error);
  }
};

export const fetchUserActivities = () => async (dispatch: any) => {
  dispatch(clearMessages());
  await dispatch({
    type: USER_ACTIVITIES,
    payload: LoyaltyService.getUserActivities()
  }).catch(error => {
    dispatch(showMessageBar({ message: error.payload ? error.payload.error : error.message, type: 'error' }));
  });
};

export const fetchUserActivitiesWithInterests = () => async (dispatch: any) => {
  dispatch(clearMessages());
  await dispatch({
    type: USER_ACTIVITIES,
    payload: LoyaltyService.getUserActivities()
  }).catch(error => {
    dispatch(showMessageBar({ message: error.payload ? error.payload.error : error.message, type: 'error' }));
  });
  await dispatch(getActivityByKey(constants.INTERESTS_SURVEY.ACTIVITY_KEY, FETCH_INTEREST_ACTIVITY_TO_ACTIVITIES)).catch(error => {
    dispatch(showMessageBar({ message: error.payload ? error.payload.error : error.message, type: 'error' }));
  });
};

export const fetchActivities = () => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(USER_ACTIVITIES)
    });
    const payload = await LoyaltyService.getActivities();
    dispatch({
      type: SUCCESS(USER_ACTIVITIES),
      payload
    });
  } catch (error) {
    dispatch({
      type: FAILURE(USER_ACTIVITIES),
      payload: error
    });
    dispatch(showMessageBar({ message: error.payload ? error.payload.error : error.message, type: 'error' }));
  }
};
export const setSelectedUserActivity = (activity: IActivity) => (dispatch: any) => {
  dispatch({
    type: SET_SELECTED_ACTIVITY,
    payload: activity
  });
};

export const getCampaign = (activityId: string) => (dispatch: any) => {
  dispatch({
    type: GET_CAMPAIGN,
    payload: LoyaltyService.getCampaign(activityId)
  }).catch(error => {
    console.error(error);
  });
};

export const resetSelectedActivity = () => (dispatch: any) => {
  dispatch({
    type: RESET_SELECTED_ACTIVITY
  });
};

export const validatePromoCode = (
  promoCode: string,
  campaignId?: string,
  campaignGroupId?: string,
  callbackFunc?: (args: any) => void
) => async (dispatch: any) => {
  try {
    const response = await LoyaltyService.postUserCodeCampaign(promoCode, campaignId, campaignGroupId);

    // @ts-ignore
    if (response && response.num_points) {
      const userPoints = await LoyaltyService.getUserPoints();
      if (callbackFunc) {
        callbackFunc({
          num_points: response.num_points,
          userPoints: userPoints.totalPoints,
          message: response.message
        });
      }
    } else if (callbackFunc) {
      callbackFunc(response);
    }

    dispatch({
      type: VALIDATE_PROMO_CODE,
      payload: Promise.resolve(response)
    });
  } catch (error) {
    if (callbackFunc) {
      callbackFunc(error);
    }
  }
};

export const setActivityCompleted = (payload: IActivityCompleted) => (dispatch: any) => {
  dispatch({
    type: ACTIVITY_COMPLETED,
    payload
  });
};

export const resetActivityCompleted = () => (dispatch: any) => {
  dispatch({
    type: ACTIVITY_COMPLETED,
    payload: null
  });
};

export const uploadPhotoForActivity = (
  activityId: string,
  photo: IPhoto,
  cancelToken: any,
  onUploadProgress: (progressEvent: any) => void,
  onSuccess: (response: { mediaKey: string }) => void,
  onError: () => void
) => (dispatch: any): void => {
  dispatch({
    type: REQUEST(UPLOAD_PHOTO),
    payload: null
  });

  LoyaltyService.uploadPhoto(
    activityId,
    photo,
    cancelToken,
    onUploadProgress,
    async (response): Promise<void> => {
      dispatch({
        type: SUCCESS(UPLOAD_PHOTO),
        payload: null
      });
      onSuccess(response);
    },
    (error): void => {
      dispatch({
        type: FAILURE(UPLOAD_PHOTO),
        payload: error.payload
      });
      onError();
    }
  );
};

export const resetState = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: RESET_STATE,
    payload: null
  });
};

export const resetError = () => (dispatch: any) => {
  dispatch({
    type: RESET_ERROR,
    payload: null
  });
};

export const getActivity = (activityId: string) => (dispatch: any) => {
  dispatch({
    type: ACTIVITY_DETAILS,
    payload: LoyaltyService.getActivity(activityId)
  }).catch(error => {
    console.error(error);
  });
};

export const getActivityByKey = (activityKey: string, actionKey: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(actionKey)
    });
    const payload = await LoyaltyService.getActivityByKey(activityKey);
    dispatch({
      type: SUCCESS(actionKey),
      payload
    });
  } catch (e) {
    dispatch({
      type: FAILURE(actionKey),
      payload: e
    });
  }
};

export const getInviteFriendsActivity = () => async (dispatch: any) => {
  dispatch(getActivityByKey(constants.INVITE_FRIENDS_ACTIVITY_KEY, GET_INVITE_FRIENDS_ACTIVITY));
};

export const getInviteAcceptedActivity = () => async (dispatch: any) => {
  dispatch(getActivityByKey(constants.INVITE_ACCEPTED_ACTIVITY_KEY, GET_INVITE_ACCEPTED_ACTIVITY));
};

export const getPromoCodeActivity = () => async (dispatch: any) => {
  dispatch(getActivityByKey(constants.HOME_PROMO_CODE_ACTIVITY_KEY, SET_SELECTED_ACTIVITY));
};

export const getPromotionsEmailActivity = () => async (dispatch: any) => {
  dispatch(getActivityByKey(constants.PROMOTIONS_EMAIL_OPT_IN_ACTIVITY_KEY, GET_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY));
};

export const getActivityMapping = (activityKey: string) => (dispatch: any) => {
  dispatch({
    type: GET_ACTIVITY_MAPPING,
    payload: LoyaltyService.getActivityMapping(activityKey)
  }).catch(error => {
    console.error(error);
  });
};

export const getActivityMappings = () => (dispatch: any) => {
  dispatch({
    type: GET_ACTIVITY_MAPPINGS,
    payload: LoyaltyService.getActivityMappings()
  }).catch(error => {
    console.error(error);
  });
};

export const fetchSweepstakeDraws = ({ page, size, sort }: IDrawsRequest) => async (dispatch: any) => {
  try {
    const sweepStakes = await DrawsService.fetchSweepStakeDraws({ page, size, sort });
    dispatch({
      type: FETCH_SWEEPSTAKE_DRAWS,
      payload: sweepStakes
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchRewardEntries = (rewardId: string) => async (dispatch: any) => {
  dispatch({
    type: FETCH_REWARD_ENTRY_DETAILS,
    payload: LoyaltyService.fetchRewardEntryDetails(rewardId)
  }).catch(error => {
    console.error(error);
  });
};

export const getBarcodeData = (couponKey: string, barcodeValue: string, humanReadable: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(FETCH_REWARD_BARCODE_DATA)
    });
    const payload = await InventoryService.getBarcodeData(couponKey);
    dispatch({
      type: SUCCESS(FETCH_REWARD_BARCODE_DATA),
      payload: {
        ...payload,
        barcodeImageURL: constants.BASE_URL + util.createBarcodeImageUrl(couponKey, barcodeValue),
        downloadCouponImageURL: constants.BASE_URL + util.createCouponDownloadUrl(couponKey, barcodeValue, humanReadable),
        humanReadable
      }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(FETCH_REWARD_BARCODE_DATA),
      payload: error
    });
    console.error(error);
  }
};
