import { Button, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { ISuperBonusState } from '@pbl/pbl-react-core/lib/models/super-bonus/types';
import { GamesService } from '@pbl/pbl-react-core/lib/services/games-service';
import { AppSpinner, DetailTitle, RelatedGames, RelatedGamesDialog } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/collect-them-all/CollectThemAllScreenStyle';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { IRootState } from 'redux/reducers';
import { fetchActivities, fetchUserActivities } from 'redux/reducers/loyalty/actions';
import { getSuperBonusDetails } from 'redux/reducers/super-bonus/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';
import background2 from '../../assets/img/collect-them-all/claim-it-all-2-lg.png';
import background1 from '../../assets/img/collect-them-all/claim-it-all-lg.png';
import CollectThemAll from './collect-them-all';

const useStyles = makeStyles(styles);

interface ICollectThemAllProps extends ConnectedProps<typeof connector> {
  loggedIn: boolean;
}

const CollectThemAllScreen: React.FC<ICollectThemAllProps> = props => {
  const { id } = useParams() as { id: string | undefined };
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const [superBonus, setSuperBonus] = React.useState<ISuperBonusState | undefined>(undefined);
  const [relatedGameList, setRelatedGameList] = React.useState<any>(undefined);
  const superBonusObj = useSelector((state: IRootState) => state.superBonus);
  const [showRelatedGamesDialog, setShowRelatedGamesDialog] = React.useState<boolean>(false);

  useEffect(() => {
    document.title = 'Collect Them All Activity';
    if (id) dispatch(getSuperBonusDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    const games = superBonus?.superBonusDetails?.games?.map(game => game.gameId);
    if (!!games) {
      getRelatedGames(games);
    }
  }, [superBonus]);

  const getRelatedGames = async games => {
    const gameIds: any = await GamesService.getPublishedGamesByIds(games);
    const listOfGames = gameIds.parsedGames;
    if (props.loggedIn) {
      for (const game of listOfGames) {
        const listOfEntries = superBonus?.superBonusDetails?.games;
        if (listOfEntries) {
          const gameIndex = listOfEntries?.findIndex(s => s.gameId === game.id.toString());
          game.currentEntries = listOfEntries[gameIndex]?.currentEntries;
        }
      }
    }
    gameIds.parsedGames = listOfGames.sort((a, b) => (a.currentEntries > b.currentEntries ? -1 : 1));

    setRelatedGameList(gameIds);
  };
  useEffect(() => {
    setSuperBonus(superBonusObj);
  }, [superBonusObj, superBonus]);

  const navigateBackToEarn = () => {
    history.push('/earn');
  };

  const showRelatedGames = () => {
    setShowRelatedGamesDialog(true);
  };

  const hideRelatedGamesDialog = () => {
    setShowRelatedGamesDialog(false);
  };

  const navigateToTicketEntry = () => {
    history.push('/ticket-entry', { from: location.pathname });
  };

  const selectGame = (game: any) => {
    scrollToTheTop();
    history.push(`/games/${game.id}`);
  };

  const renderEligibleGames = (superBonusGames: any) => {
    if (!superBonusGames || superBonusGames?.parsedGames.length === 0) return null;
    const filteredGames = superBonusGames.parsedGames?.slice(0, 3);
    return (
      <RelatedGames
        relatedGames={filteredGames}
        // tslint:disable-next-line:jsx-no-bind
        selectGame={selectGame}
        showRelatedGames={showRelatedGames}
        title={'Eligible Games'}
      />
    );
  };
  const entries = superBonus?.superBonusDetails?.numberOfBucketsFilled ? superBonus?.superBonusDetails?.numberOfBucketsFilled : 0;
  const totalEntries = superBonus?.superBonusDetails?.superBonusDTO?.minBuckets
    ? superBonus?.superBonusDetails?.superBonusDTO?.minBuckets
    : 0;

  if (superBonus?.loading) {
    return <AppSpinner label={'Loading...'} />;
  }

  const theme = superBonus?.superBonusDetails?.superBonusDTO?.theme;
  const iconTheme = theme ? JSON.parse(theme)?.icon : null;

  return (
    <>
      <ScrollToTopOnMount />
      <RelatedGamesDialog
        title={'Eligible Games'}
        relatedGames={relatedGameList?.parsedGames}
        selectGame={selectGame}
        handleClose={hideRelatedGamesDialog}
        openDialog={showRelatedGamesDialog}
      />

      <Grid className={classes.root} container={true} spacing={2}>
        <DetailTitle
          title={superBonus?.superBonusDetails?.superBonusDTO?.name}
          linkText={'Earn'}
          buttonText={'ENTER TICKET'}
          onPress={navigateToTicketEntry}
          navigateTo={navigateBackToEarn}
        />
        <CollectThemAll
          description={superBonus?.superBonusDetails?.superBonusDTO?.description}
          endDate=""
          imageUrl={superBonus?.superBonusDetails?.superBonusDTO?.image}
          rulesDocument={superBonus?.superBonusDetails?.superBonusDTO?.rulesDocument}
          startDate=""
          title={superBonus?.superBonusDetails?.superBonusDTO?.name}
          totalEntries=""
          navigateToTicketEntry={navigateToTicketEntry}
        />
        {props.loggedIn && (
          <Grid className={classes.collectTable} container={true}>
            <Grid className={classes.firstSec} item={true} xs={12} md={5} lg={5}>
              <Grid style={{ width: '20%', margin: '10px 0px 10px 10px' }}>
                <img
                  src={iconTheme?.toLowerCase() === 'secondary' ? background2 : background1}
                  alt="Claim it all"
                  style={{ height: '100%', width: '100%' }}
                />
              </Grid>
              <Grid style={{ width: '80%', margin: '10px', alignContent: 'center' }}>
                <Typography variant="body1" style={{ color: 'black', fontWeight: 'bold' }}>
                  {t('collectThemAll.infoTitle')}
                </Typography>
                <Typography>{t('collectThemAll.infoDescription')}</Typography>
              </Grid>
            </Grid>

            <Grid className={classes.secondSec} item={true} xs={12} md={5} lg={5}>
              <Grid style={{ width: '20%', margin: '10px' }}>
                <Typography variant="h4" style={{ color: 'white' }}>
                  {entries}/{totalEntries}
                </Typography>
                <Typography variant="caption" style={{ color: '#82d1f0' }}>
                  {t('collectThemAll.collected')}
                </Typography>
              </Grid>

              <Grid style={{ width: '80%', margin: '10px' }}>
                <Typography style={{ color: 'white' }}>{t('collectThemAll.progressTracker')}</Typography>
                <Typography>
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    value={(entries * 100) / totalEntries}
                    classes={{
                      colorPrimary: classes.linearColorPrimary,
                      barColorPrimary: classes.linearBarColorPrimary
                    }}
                  />
                </Typography>
                <Button className={classes.progressButton} onClick={showRelatedGames}>
                  {t('collectThemAll.viewProgressBtn')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {renderEligibleGames(relatedGameList)}
      </Grid>
    </>
  );
};

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty: { selectedActivity } }: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    selectedActivity,
    loggedIn
  };
};

const mapDispatchToProps = {
  fetchActivities,
  fetchUserActivities
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CollectThemAllScreen);
