/**
 * Appends REQUEST asyc action type
 */

export const REQUEST = (actionType: string): string => `${actionType}_PENDING`;

/**
 * Appends SUCCESS asyc action type
 */

export const SUCCESS = (actionType: string): string => `${actionType}_FULFILLED`;

/**
 * Appends FAILURE asyc action type
 */

export const FAILURE = (actionType: string): string => `${actionType}_REJECTED`;
