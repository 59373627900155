import { Reward } from '@pbl/pbl-react-core/lib/models/loyalty';
import { IRewardCategory } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { History } from 'history';

// Return is navigation successful
const navigateToRewardDetails = (reward: Reward, history: History): boolean => {
  if (reward.isInformationOnly && reward.extra_data?.deeplink_web) {
    history.push(`/information-list/${reward.extra_data.deeplink_web}`);
    return true;
  }

  return false;
};

// Create new hardcode Category
const emulatedCategories = {
  TOKENS: 'Tokens'
};
// Config featureflag for category
const categoriesFeatureFlagMap = {
  [emulatedCategories.TOKENS]: 'SWEEPSTAKES_V1'
};

const orderCategories = (categories: IRewardCategory[]) => {
  let categoriesCopy: IRewardCategory[] = JSON.parse(JSON.stringify(categories));

  const tokensCategory = categoriesCopy.find(category => category.name === emulatedCategories.TOKENS);
  categoriesCopy = categoriesCopy.filter(category => category.name !== emulatedCategories.TOKENS);

  if (tokensCategory) {
    categoriesCopy.push(tokensCategory);
  }

  return categoriesCopy;
};

const filterCategoriesByFeatureFlags = (categories: IRewardCategory[], featureFlags: string[]) => {
  const filterdCategories: IRewardCategory[] = [];

  categories.forEach(category => {
    const featureFlag = categoriesFeatureFlagMap[category.name];

    if ((!!featureFlag && featureFlags.includes(featureFlag)) || !featureFlag) {
      filterdCategories.push(category);
    }
  });

  return filterdCategories;
};

export default { navigateToRewardDetails, filterCategoriesByFeatureFlags, orderCategories };
