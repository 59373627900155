import { createStyles, Theme } from '@material-ui/core';
import colors from 'assets/jss/colors';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      paddingTop: theme.spacing(),
      paddingLeft: theme.spacing()
    },
    strength: {
      fontWeight: 'bold'
    },
    strong: {
      backgroundColor: colors.themeColors.successColor.hex()
    },
    medium: {
      backgroundColor: colors.themeColors.warningColor.hex()
    },
    weak: {
      backgroundColor: theme.palette.error.dark
    },
    strongBar: {
      backgroundColor: colors.themeColors.successColor.hex().getLowEmphasis()
    },
    mediumBar: {
      backgroundColor: colors.themeColors.warningColor.hex().getLowEmphasis()
    },
    weakBar: {
      backgroundColor: theme.palette.error.light.getLowEmphasis()
    },
    errorCheckbox: {
      color: colors.themeColors.successColor.hex()
    },
    errorCheckboxUncheck: {
      '& span': {
        color: theme.palette.error.light
      }
    }
  });
export default styles;
