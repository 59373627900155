import { RemoteConstants } from 'config/constants';

const getPointsTokensDeepLink = async () => {
  let pointsDeeplink = '/';
  let tokensDeeplink = '/';
  let title = '';
  let showFirstName = true;
  let subtitle = '';
  await RemoteConstants.sync();
  const BUTTONS_CONFIG = RemoteConstants.getString('BALANCES_BUTTONS_CONFIG');

  if (!!BUTTONS_CONFIG) {
    const buttonsConfig = JSON.parse(BUTTONS_CONFIG);
    if (buttonsConfig.points.WEB_DEEPLINK) {
      pointsDeeplink = buttonsConfig.points.WEB_DEEPLINK;
    }
    if (buttonsConfig.tokens.WEB_DEEPLINK) {
      tokensDeeplink = buttonsConfig.tokens.WEB_DEEPLINK;
    }
    if (buttonsConfig.forYou) {
      title = buttonsConfig.forYou.title;
      showFirstName = buttonsConfig.forYou.showFirstName;
      subtitle = buttonsConfig.forYou.subtitle;
    }
  }
  return { pointsDeeplink, tokensDeeplink, title, showFirstName, subtitle };
};

export default getPointsTokensDeepLink;
