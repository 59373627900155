import { ERROR_RESET } from '@pbl/pbl-react-core/lib/models/survey/types';
import {
  GET_SURVEY_DETAILS,
  GET_USER_RESPONSE,
  ISurveyQuestionResponse,
  RESET_SURVEY,
  SUBMIT_SURVEY_RESPONSE,
  SurveyDetailsState,
  UPDATE_SURVEY_RESPONSE
} from '@pbl/pbl-react-core/lib/models/survey/v2/types';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { IDispatchAction } from '../../';

const INITIAL_STATE: SurveyDetailsState = {
  loading: false,
  errorMessage: null
};

export default (state: SurveyDetailsState = INITIAL_STATE, action: IDispatchAction): SurveyDetailsState => {
  switch (action.type) {
    case REQUEST(GET_SURVEY_DETAILS):
    case REQUEST(SUBMIT_SURVEY_RESPONSE):
    case REQUEST(GET_USER_RESPONSE):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_SURVEY_DETAILS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activityId: action.payload.activity_id,
        survey: action.payload
      };
    case SUCCESS(GET_USER_RESPONSE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        // @ts-ignore
        surveyPost: { activityId: state.activityId, survey_response: [...action.payload.survey_response] },
        surveyResults: action.payload
      };
    case SUCCESS(SUBMIT_SURVEY_RESPONSE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        surveyPostResult: action.payload
      };
    case FAILURE(GET_SURVEY_DETAILS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(SUBMIT_SURVEY_RESPONSE):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(GET_USER_RESPONSE):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case UPDATE_SURVEY_RESPONSE:
      let currentResponses = state.surveyPost ? state.surveyPost.survey_response : [];
      const answer: ISurveyQuestionResponse = action.payload;
      currentResponses = currentResponses.filter(x => x.question_id !== answer.question_id);
      currentResponses.push(answer);
      return {
        ...state,
        surveyPost: {
          activityId: state.activityId ? state.activityId : '',
          survey_response: [...currentResponses]
        }
      };
    case ERROR_RESET:
      return {
        ...state,
        errorMessage: action.payload
      };
    case RESET_SURVEY:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
