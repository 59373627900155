import {
  ERROR_QUIZ_RESET,
  FETCH_QUIZ_DETAILS,
  IQuizAnswer,
  IQuizState,
  QUIZ_RESET,
  SUBMIT_QUIZ,
  UPDATE_QUIZ_POST_RESPONSE
} from '@pbl/pbl-react-core/lib/models/quiz/types';
import { IDispatchAction } from '..';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: IQuizState = {
  loading: false,
  errorMessage: null,
  quiz: null,
  quizPostResponse: null,
  quizPostSuccess: null
};

export default (state: IQuizState = INITIAL_STATE, action: IDispatchAction): IQuizState => {
  switch (action.type) {
    case REQUEST(FETCH_QUIZ_DETAILS):
    case REQUEST(SUBMIT_QUIZ):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(FETCH_QUIZ_DETAILS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        quiz: action.payload
      };
    case SUCCESS(SUBMIT_QUIZ):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        quizPostSuccess: action.payload
      };
    case FAILURE(FETCH_QUIZ_DETAILS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(SUBMIT_QUIZ):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case UPDATE_QUIZ_POST_RESPONSE:
      // @ts-ignore
      let currentResponses = !!state.quizPostResponse && !!state.quizPostResponse.responses ? state.quizPostResponse.responses : [];
      const answer: IQuizAnswer = action.payload.answer;
      currentResponses = currentResponses.filter(x => x.id !== answer.id);
      if (!!answer && !!answer.value && answer.value !== '') {
        currentResponses.push(answer);
      }
      return {
        ...state,
        quizPostResponse: {
          quizId: action.payload.quizId,
          responses: [...currentResponses]
        }
      };
    case ERROR_QUIZ_RESET:
      return {
        ...state,
        errorMessage: null
      };
    case QUIZ_RESET:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
