import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    ticketContainer: {
      marginTop: theme.spacing(2)
    },
    ticketBottomContainer: {
      backgroundColor: '#ffffff',
      marginTop: theme.spacing(4),
      padding: theme.spacing(8),
      borderRadius: '5px',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)'
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    ticketTitle: {
      textAlign: 'center'
    },
    disclaimerText2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  });

export default styles;
