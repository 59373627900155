import { createMuiTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import background from 'assets/img/sweepstakes/background.png';
import colors from 'assets/jss/colors';
import color from 'color';
import { defaultConfig } from 'shared/theme/default';

const primaryBtnStyles = {
  padding: '12px 16px',
  backgroundColor: 'rgba(0,0,0,0.7)',
  border: `2px solid ${colors.themeSweepstakesColors.primaryColor.hex()}`,
  borderRadius: 8,
  boxShadow: `0 0 10px 0 ${colors.themeSweepstakesColors.secondaryColor.hex()}`,
  '&:hover': {
    boxShadow: `0 0 20px 0 ${colors.themeSweepstakesColors.secondaryColor.hex()}`,
    backgroundColor: 'rgba(0,0,0,1)'
  },
  '@media (hover: none)': {
    backgroundColor: 'rgba(0,0,0,0.7) !important'
  }
};
const secondaryBtnStyles = {
  padding: '10px 16px',
  backgroundColor: 'rgba(0,0,0,0.7)',
  border: `2px solid #FFFFFF`,
  borderRadius: 8,
  boxShadow: `0 0 10px 0 ${colors.themeSweepstakesColors.secondaryColor.hex()}`,
  '&:hover': {
    boxShadow: `0 0 20px 0 ${colors.themeSweepstakesColors.secondaryColor.hex()}`,
    backgroundColor: 'rgba(0,0,0,1)'
  },
  '@media (hover: none)': {
    backgroundColor: 'rgba(0,0,0,0.7) !important'
  }
};
const textButtonStyles = {
  padding: '14px 16px',
  textTransform: 'none'
};
export const popupButtonStyle = {
  backgroundColor: `${colors.themeSweepstakesColors.secondaryColor.hex()} !important`,
  color: color(colors.themeSweepstakesColors.black).alpha(0.87).rgb().toString(),
  boxShadow: `0 0 10px 0 ${colors.themeSweepstakesColors.secondaryColor.hex()}`,
  borderColor: 'transparent',
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    borderColor: 'transparent',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
  },
  '&:hover': {
    boxShadow: `0 0 10px 1px ${colors.themeSweepstakesColors.secondaryColor.hex()}`,
    backgroundColor: '#e3d0a0'
  }
};
// @ts-ignore
const options: ThemeOptions = {
  ...defaultConfig,
  ...{
    overrides: {
      MuiPickersYear: {
        root: {
          color: colors.themeColors.black.hex(),
          '&:hover': {
            color: colors.themeColors.primaryColor.hex()
          }
        },
        yearSelected: {
          color: colors.themeColors.primaryColor.hex()
        }
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: 'rgba(255, 255, 255, 0.74)'
        },
        toolbarBtnSelected: {
          color: colors.themeColors.white.hex()
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: colors.themeColors.primaryColor.hex()
        }
      },
      MuiPickersCalendarHeader: {
        transitionContainer: {
          color: colors.themeColors.black.hex()
        },
        dayLabel: {
          color: 'rgba(12, 19, 26, 0.7)'
        }
      },
      MuiPickersDay: {
        day: {
          color: colors.themeColors.black.hex()
        },
        daySelected: {
          backgroundColor: colors.themeColors.primaryColor.hex(),
          color: colors.themeColors.white.hex(),
          '&:hover': {
            backgroundColor: colors.themeColors.primaryColor.hex()
          }
        },
        dayDisabled: {
          color: 'rgba(12, 19, 26, 0.7)'
        },
        current: {}
      },
      MuiPickersModal: {
        dialogAction: {
          color: colors.themeColors.primaryColor.hex()
        }
      },
      MuiButton: {
        containedPrimary: {
          color: colors.themeSweepstakesColors.secondaryColor.hex(),
          ...primaryBtnStyles,
          '&.Mui-disabled': {
            color: colors.themeSweepstakesColors.secondaryColor.hex(),
            ...primaryBtnStyles,
            opacity: 0.5
          }
        },
        containedSecondary: {
          color: colors.themeSweepstakesColors.textSecondaryColor.hex(),
          ...secondaryBtnStyles,
          '&.Mui-disabled': {
            color: colors.themeSweepstakesColors.textSecondaryColor.hex(),
            ...secondaryBtnStyles,
            opacity: 0.5
          }
        },
        textPrimary: {
          color: color(colors.themeSweepstakesColors.black).alpha(0.87).rgb().toString(),
          ...textButtonStyles,
          '&.Mui-disabled': {
            color: colors.themeSweepstakesColors.black.hex(),
            ...textButtonStyles,
            opacity: 0.5
          }
        },
        textSecondary: {
          color: colors.themeSweepstakesColors.secondaryColor.hex(),
          ...textButtonStyles,
          '&.Mui-disabled': {
            color: colors.themeSweepstakesColors.secondaryColor.hex(),
            ...textButtonStyles,
            opacity: 0.5
          }
        }
      },
      MuiInputBase: {
        input: {
          color: 'rgba(0, 0, 0, 1)'
        }
      },
      MuiFormLabel: {
        root: {
          color: 'rgba(12, 19, 26, 0.7)',
          '&.Mui-focused': {
            color: colors.themeColors.primaryColor.hex()
          },
          '&.Mui-disabled': {
            color: 'rgba(12, 19, 26, 0.7)'
          },
          '&.Mui-error': {
            color: colors.themeColors.dangerColor.hex()
          }
        }
      },
      MuiFilledInput: {
        underline: {
          '&.Mui-focused:after': {
            borderBottom: `2px solid ${colors.themeColors.primaryColor.hex()}`
          },
          '&.Mui-error:after': {
            borderBottom: `2px solid ${colors.themeColors.dangerColor.hex()}`
          }
        },
        root: {
          backgroundColor: 'rgba(233, 236, 240, 1)',
          '&.Mui-focused': {
            backgroundColor: 'rgba(233, 236, 240, 1)'
          }
        }
      },
      MuiAutocomplete: {
        option: {
          color: 'rgba(0, 0, 0, 1)'
        },
        noOptions: {
          color: 'rgba(0, 0, 0, 0.54)'
        }
      },
      MuiCheckbox: {
        root: {
          color: colors.themeColors.primaryColor.hex()
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: colors.themeColors.primaryColor.hex()
          }
        }
      },
      MuiSlider: {
        root: {
          color: colors.themeColors.primaryColor.hex()
        }
      }
    },
    palette: {
      type: 'light',
      tonalOffset: 0.2,
      background: { paper: '#FFFFFF', default: '#000000', image: background },
      contrastThreshold: 3,
      grey: {
        '50': '#fafafa',
        '100': '#f5f5f5',
        '200': '#eeeeee',
        '300': '#e0e0e0',
        '400': '#bdbdbd',
        '500': '#9e9e9e',
        '600': '#757575',
        '700': '#616161',
        '800': '#424242',
        '900': 'rgba(0, 0, 0, 1)',
        A700: '#616161',
        A100: '#d5d5d5',
        A400: '#303030',
        A200: 'rgba(215, 220, 224, 1)'
      },
      text: {
        primary: colors.themeSweepstakesColors.white.hex(),
        secondary: '#f3f4c5',
        disabled: colors.themeSweepstakesColors.white.hex(),
        hint: colors.themeSweepstakesColors.white.hex()
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      secondary: {
        main: colors.themeSweepstakesColors.secondaryColor.hex(),
        dark: colors.themeColors.primaryColor.hex()
      },
      common: { black: colors.themeSweepstakesColors.black.hex(), white: colors.themeSweepstakesColors.white.hex() },
      error: {
        main: colors.themeColors.dangerColor.hex(),
        contrastText: colors.themeColors.white.hex()
      },
      action: {
        hoverOpacity: 0.08,
        hover: 'rgba(0, 0, 0, 0.08)',
        selected: 'rgba(0, 0, 0, 0.14)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
        disabled: 'rgba(0, 0, 0, 0.26)',
        active: 'rgba(0, 0, 0, 0.54)'
      },
      primary: {
        main: colors.themeSweepstakesColors.primaryColor.hex(),
        dark: '#36322B'
      },
      warning: {
        main: colors.themeSweepstakesColors.warningColor.hex(),
        dark: '#b18919'
      }
    }
  }
};
export const sweepstakesTheme: Theme = createMuiTheme(options);
