import { GET_ADVERTISEMENTS, GET_ADVERTISEMENT_BY_ID, GET_TAGS } from '@pbl/pbl-react-core/lib/models/media/types';
import { MediaService } from '@pbl/pbl-react-core/lib/services';
import { showMessageBar } from 'redux/reducers/app/actions';

export const getAdvertisements = (page?: number, size?: number, sort?: string, filter?: string[]) => async (dispatch: any) => {
  let filterParams: string[] = [];
  if (filter && filter.length > 0) {
    filterParams = filter;
  } else {
    filterParams.push('published.equals=TRUE');
    filterParams.push('tag.equals=WEB_FEATURED');
  }
  try {
    const response = await MediaService.getAnnouncements(page, size, sort, filterParams);
    await dispatch({
      type: GET_ADVERTISEMENTS,
      payload: Promise.resolve(response)
    });
  } catch (error) {
    await dispatch(showMessageBar({ message: error.payload, type: 'error' }));
    console.error(error);
  }
};

export const getAdvertisement = (advertisementId: number) => async (dispatch: any) => {
  try {
    dispatch({
      type: GET_ADVERTISEMENT_BY_ID,
      payload: MediaService.getAdvertisement(advertisementId)
    });
  } catch (error) {
    await dispatch(showMessageBar({ message: error.payload.error, type: 'error' }));
    console.error(error);
  }
};

export const getTags = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_TAGS,
      payload: MediaService.getTags()
    });
  } catch (error) {
    await dispatch(showMessageBar({ message: error.payload.error, type: 'error' }));
    console.error(error);
  }
};
