import { Theme, withTheme } from '@material-ui/core';
import { MessageBar as Bar } from '@pbl/pbl-react-web-components/lib/package';
import colors from 'assets/jss/colors';
import React from 'react';
import { connect } from 'react-redux';
import { clearMessages } from 'redux/reducers/app/actions';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IMessageBarProps extends StateProps, DispatchProps {
  theme: Theme;
}

class MessageBar extends React.Component<IMessageBarProps> {
  private closeTimeout;

  public componentDidMount(): void {
    const { messageTimeout, type } = this.props;
    if ((type === 'error' || type === 'outage_warning') && messageTimeout && messageTimeout > 0) {
      this.closeTimeout = setTimeout(this.onClose, messageTimeout);
    }
  }

  public componentWillUnmount(): void {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }

  render(): React.ReactNode {
    return this.props.showMessage ? (
      <Bar
        type={this.props.type}
        message={this.props.message}
        showMessage={this.props.showMessage}
        successColor={colors.themeColors.successColor.hex()}
        warningColor={colors.themeColors.warningColor.hex()}
        clearMessages={this.props.type !== 'outage_warning' ? this.props.clearMessages : null}
        messageParams={this.props.messageParams}
        messageTimeout={null}
      />
    ) : null;
  }

  private onClose = () => this.props.clearMessages();
}

const mapStateToProps = ({ app: { showMessage, message, type, messageTimeout, messageParams } }) => ({
  showMessage,
  message,
  type,
  messageTimeout,
  messageParams
});
const mapDispatchToProps = {
  clearMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(MessageBar));
