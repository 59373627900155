import { ERROR_RESET, FETCH_POLL_DETAILS, PollState, RESET, SUBMIT_POLL } from '@pbl/pbl-react-core/lib/models/poll/types';
import { IDispatchAction } from '..';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: PollState = {
  loading: false,
  errorMessage: null,
  poll: null,
  pollPostResponse: null
};

export default (state: PollState = INITIAL_STATE, action: IDispatchAction): PollState => {
  switch (action.type) {
    case REQUEST(FETCH_POLL_DETAILS):
    case REQUEST(SUBMIT_POLL):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(FETCH_POLL_DETAILS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        poll: action.payload
      };
    case SUCCESS(SUBMIT_POLL):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        pollPostResponse: action.payload
      };
    case FAILURE(FETCH_POLL_DETAILS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(SUBMIT_POLL):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case ERROR_RESET:
      return {
        ...state,
        errorMessage: null
      };
    case RESET:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
