import { AccountService, AuthService as Auth } from '@pbl/pbl-react-core/lib/services';
import axios from 'axios';
import decode from 'jwt-decode';
import constants from '../config/constants';
const GET_SESSION_API = `/auth/get/session`;

interface ILogin {
  username: string;
  password: string;
  rememberMe: boolean;
}

class AuthService {
  public static async login(body: ILogin) {
    try {
      return await Auth.login(body, { clientId: constants.CLIENT_ID, clientSecret: constants.CLIENT_SECRET });
    } catch (e) {
      throw e;
    }
  }

  public static async logout() {
    try {
      await Auth.logout();
    } catch (e) {
      throw e;
    }
  }

  public static async unSuspend(email: string) {
    try {
      await AccountService.sendAccountReactivationInstructions(email);
    } catch (e) {
      throw e;
    }
  }

  public static async isAccountActive(email: string) {
    try {
      await AccountService.isAccountActive(email);
    } catch (e) {
      throw e;
    }
  }

  public static decodeToken(token: string) {
    return decode(token);
  }

  public static async isAuthenticated() {
    let response = false;
    await Auth.checkSession().then(res => {
      response = res;
    });
    return response;
  }

  public static updateLocalStorage = (accessToken, refreshToken, userId) => {
    window.localStorage.setItem('playon.accessToken', accessToken);
    window.localStorage.setItem('playon.refreshToken', refreshToken);
    window.localStorage.setItem('playon.userId', userId);
  };

  public static removeLocalStorage = () => {
    window.localStorage.removeItem('playon.accessToken');
    window.localStorage.removeItem('playon.refreshToken');
  };

  public static fetchFromLocalStorage = () => {
    const accessToken = window.localStorage.getItem('playon.accessToken');
    const refreshToken = window.localStorage.getItem('playon.refreshToken');
    const userId = window.localStorage.getItem('playon.userId');
    if (!!accessToken && !!refreshToken && !!userId) {
      return { accessToken, refreshToken, userId };
    }
    return {};
  };

  public static checkSession = async (): Promise<{accessToken: string; refreshToken?: string; userId: string} | undefined> => {
    try {
      const response = await axios.get(GET_SESSION_API, {
        baseURL: constants.BASE_URL,
        withCredentials: true
      });
      const { access_token: accessToken, refresh_token: refreshToken, login: userId } = response.data;
      if (!!accessToken) {
        AuthService.updateLocalStorage(accessToken, refreshToken, userId);
        return { accessToken, refreshToken, userId };
      }
    } catch (e) {
    }
    return undefined;
  };
}

export { AuthService };
