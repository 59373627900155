import { createStyles, Theme } from '@material-ui/core';
import colors from 'assets/jss/colors';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flex: '0 0 auto',
      flexDirection: 'column'
    },
    appBar: {
      background: theme.palette.primary.main
    },
    userPointBalance: {
      background: theme.palette.primary.dark,
      display: 'flex',
      padding: theme.spacing(),
      color: theme.palette.primary.contrastText,
      justifyContent: 'center'
    },
    userGreetings: {
      background: theme.palette.primary.dark,
      display: 'flex',
      padding: theme.spacing(),
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        marginLeft: theme.spacing(3)
      },
      color: theme.palette.primary.contrastText
    },
    userPointBalanceHeader: {
      background: theme.palette.primary.dark
    },
    userPointBalanceContent: {
      ...container,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.primary.dark,
      paddingRight: '30px',
      paddingLeft: '30px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
      }
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(),
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
      }
    },
    forYouBtn: {
      whiteSpace: 'nowrap',
      borderRadius: 10,
      marginLeft: theme.spacing(1),
      height: 30,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    userPointBalanceBtn: {
      whiteSpace: 'nowrap',
      borderRadius: 10,
      marginLeft: theme.spacing(1),
      height: 30,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      backgroundColor: `${theme.palette.primary.dark} !important`
    },
    pointsEarnedImg: {
      height: 24
    },
    favoriteIcon: {
      color: theme.palette.error.main,
      fontSize: 20,
      marginTop: 5
    },
    toolBar: {
      ...container
    },
    grow: {
      flexGrow: 1
    },
    sectionDesktop: {
      display: 'none',
      borderRadius: '5px',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    logo: {
      height: `3.7em`,
      [theme.breakpoints.down('lg')]: {
        height: `3em`
      },
      [theme.breakpoints.down('xs')]: {
        height: `2.7em`
      }
    },
    list: {
      WebkitBoxAlign: 'center',
      MsFlexAlign: 'center',
      alignItems: 'center',
      WebkitBoxOrient: 'horizontal',
      WebkitBoxDirection: 'normal',
      MsFlexDirection: 'row',
      flexDirection: 'row',
      marginTop: '0px',
      display: 'flex',
      paddingLeft: '0',
      marginBottom: '0',
      listStyle: 'none',
      padding: '0'
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    listItemMobile: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0'
    },
    badgeActive: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      fontFamily: 'Jost'
    },
    badgeInActive: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      fontFamily: 'Jost'
    },
    navLink: {
      position: 'relative',
      padding: '0.90rem',
      textDecoration: 'none',
      margin: '0px',
      marginLeft: theme.spacing() / 4,
      display: 'inline-flex'
    },
    navLinkIcon: {
      '&.MuiIcon-root': {
        height: 'auto'
      },
      marginRight: theme.spacing() / 2,
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      }
    },
    navLinkText: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    navLinkActive: {
      '&, &:hover, &:focus,&:active ': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default
      }
    },
    navLinkActiveLL: {
      '&, &:hover, &:focus,&:active ': {
        color: colors.themeSweepstakesColors.backgroundColor.hex(),
        backgroundColor: theme.palette.background.default
      }
    },
    navLinkDisabled: {
      '&, &:hover, &:focus,&:active ': {
        color: 'inherit',
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      }
    },
    skipLink:{
      position: "absolute",
      top: "-40px",
      left: "0",
      background: "#000000",
      color: "white",
      padding: "8px",
      zIndex: 100,
      "&:focus": {
        backgroundColor: "transparent"
      }
    }
  });

export default styles;
