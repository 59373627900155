import { Button, Grid, withStyles, WithStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import FeaturedSlide from 'modules/home/components/FeaturedSlide';
import React, { ReactNode } from 'react';
import Carousel, { Settings } from 'react-slick';

export interface IAdvertisementContent {
  id: number;
  title: string;
  image: string;
  btnText: string;
  url: string;
  target: string;
  backgroundImage?: string;
  textColor?: string;
}

interface IProps extends WithStyles<typeof styles> {
  content: IAdvertisementContent[];
}

const FeaturedSlider: React.FunctionComponent<IProps> = ({ classes, content }) => {
  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Button className={classes.slickButton} onClick={onClick} aria-label="Previous Slider">
        <ArrowBackIosIcon color={'primary'} fontSize={'large'} />
      </Button>
    );
  };

  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Button className={classNames(classes.slickButton, classes.slickButtonNext)} onClick={onClick} aria-label="Next Slider">
        <ArrowForwardIosIcon color={'primary'} fontSize={'large'} />
      </Button>
    );
  };
  const settings: Settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnDotsHover: true,
    swipeToSlide: true,
    pauseOnFocus: true,
    fade: true,
    lazyLoad: 'ondemand',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots: ReactNode) => <ul>{dots}</ul>,
    customPaging: (index: number) => (
      <Button className={classes.slickDot} aria-label={'Slider number ' + index + 1} disableRipple={true}>
        {'Slider number ' + index + 1}
      </Button>
    ),
    beforeChange(currentSlide: number, nextSlide: number) {
      const element = document.querySelectorAll<HTMLDivElement>('.slick-slide');
      element[nextSlide].style.visibility = 'visible';
      setTimeout(() => {
        if (element[currentSlide]) {
          element[currentSlide].style.visibility = 'hidden';
        }
      }, 3000);
    }
  };

  const interleaveCards = (): React.ReactNode[] => {
    const interleavedCards: React.ReactNode[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < content.length; i++) {
      interleavedCards.push(<FeaturedSlide key={`advertisement-${content[i].id}`} item={content[i]} />);
    }
    return interleavedCards;
  };

  return (
    <div className={classes.carouselSection} id="carousel">
      <div className={classes.carouselContainer}>
        <Grid container={true}>
          <Grid item={true} xs={12} className={classes.marginAuto}>
            <Carousel {...settings}>{interleaveCards()}</Carousel>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(FeaturedSlider);
