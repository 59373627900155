import { Theme, withTheme } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface IStoreScreenProps extends RouteComponentProps<any> {
  theme: Theme;
}

class PaymentResponseScreen extends React.Component<IStoreScreenProps> {
  private QS = require('qs');
  constructor(props: IStoreScreenProps) {
    super(props);
    this.state = {
      loading: true
    };
  }
  public async componentDidMount() {
    const params = this.QS.parse(this.props.location.search, { ignoreQueryPrefix: true });
    console.warn('Nuvei params', this.props.location.search);
    if (params && Object.keys(params).length > 0 && params.ppp_status	=== 'OK') {
      this.sendPostMsg('success', params);
    } else {
      this.sendPostMsg('failed', params);
    }
   }

   private sendPostMsg(result, parameters) {
    window.parent.postMessage(
      {
        type: 'PaymentResponseScreen',
        result,
        parameters
      },
      '*'
    );
  }

  public render() {
    return (
      <div>
        Processing...
      </div>
    );
  }
}
export default withTheme(PaymentResponseScreen);
