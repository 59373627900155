import { Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/shared/components/menu/AppBarStyle';
import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'redux/reducers';
import UserBalanceButton from 'shared/components/menu/UserBalanceButton';
import displayGreetingsMessage, { formatTemplate } from 'utils/GreetingsMessageUtil';

interface IProps extends StateProps, WithStyles<typeof styles> {
  firstName: string;
  points: number;
  isHomeScreen: boolean;
  showTokens: boolean;
  tokens: number;
  onClickForYou: any;
  loadingPoints: boolean;
}

const UserPointBalance: React.FunctionComponent<IProps> = ({
  classes,
  points,
  account,
  firstName,
  birthDate,
  registrationDate,
  isHomeScreen,
  showTokens,
  tokens,
  loadingPoints,
  loading
}) => {
  const [greetMessage, setGreetMessage] = React.useState('');
  const [isFirstNameEnabled, setIsFirstNameEnabled] = React.useState<boolean>();
  const [greetFontColor, setFontColor] = React.useState('');
  const [greetFontSize, setFontSize] = React.useState<number>();
  const [greetFontFamily, setFontFamily] = React.useState('');
  React.useEffect(() => {
    (async () => {
      const { message, showFirstName, fontColor, fontSize, fontFamily } = await displayGreetingsMessage({
        firstName,
        birthDate,
        registrationDate
      });
      setGreetMessage(message);
      setIsFirstNameEnabled(showFirstName);
      setFontColor(fontColor);
      setFontSize(fontSize);
      setFontFamily(fontFamily);
    })();
  }, [isHomeScreen]);

  const getCustomMessage = () => {
    if (account !== null) {
      if (formatTemplate(account, greetMessage) === greetMessage) {
        return isFirstNameEnabled ? `${greetMessage} ${firstName}` : greetMessage;
      } else {
        return formatTemplate(account, greetMessage);
      }
    }
  };

  if (isHomeScreen) {
    return (
      <div className={classes.userPointBalanceHeader}>
        <div className={classes.userPointBalanceContent}>
          <div className={classes.userGreetings}>
            <Typography
              variant="body2"
              style={{
                fontSize: greetFontSize,
                fontFamily: greetFontFamily,
                lineHeight: `${greetFontSize}px`,
                color: greetFontColor
              }}
            >
              {getCustomMessage()}
            </Typography>
          </div>
          <div>
            <UserBalanceButton points={points} tokens={tokens} showTokens={showTokens} />
          </div>
        </div>
      </div>
    );
  } else {
    if (loadingPoints || loading) {
      return (
        <div className={classes.userPointBalance}>
        <Typography color="inherit" variant="caption">
          {'Loading...'}
        </Typography>
      </div>);
    }
      return (
        <div className={classes.userPointBalance}>
          <Typography color="inherit" variant="h6">
            {points.toPointsFormat()}
          </Typography>
          <Typography color="inherit" variant="caption">
            &nbsp;{points.getPointsUnit()}
          </Typography>
        </div>
      );
  }
};
const mapStateToProps = ({ loyalty: { loading }, authentication: { account } }: IRootState) => ({
  firstName: account ? `${account.firstName}` : '',
  birthDate: account ? account.birthDate : '',
  registrationDate: account ? account.registrationDate : '',
  account,
  loading
});
type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(withStyles(styles)(UserPointBalance));
