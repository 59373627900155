import { Button, Grid, Typography, withStyles, WithStyles } from '@material-ui/core';
import Icon from '@pbl/pbl-react-web-components/lib/icon/Icon';
import styles from 'assets/jss/modules/user-registration/UserRegistrationScreenStyle';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}

const Confirmation: React.FunctionComponent<IProps> = ({ classes, history }: IProps) => {
  const navigateLogin = () => {
    history.push('/login');
  };
  const navigateResendLink = () => {
    history.push('/activate-email');
  };

  return (
    <Grid item={true} xs={12} container={true} alignItems="center" justify="center">
      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow}>
        <Typography variant={'subtitle1'} color={'textSecondary'} align={'center'} component="span">
          Verify your account
        </Typography>
      </Grid>
      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow}>
        <Icon iconType="custom" iconName="icon-mail" className={classes.confirmIcon} color="primary" />
      </Grid>
      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow}>
        <Typography variant={'body1'} color={'textSecondary'} align={'center'}>
          We have sent you an email confirmation
        </Typography>
      </Grid>
      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow}>
        <Typography variant={'body1'} color={'textPrimary'} align={'center'} style={{ fontWeight: 'bold' }}>
          Activate your account within 30 minutes of receiving the email
        </Typography>
      </Grid>

      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow}>
        <Typography variant={'body1'} color={'textSecondary'} align={'center'}>
          If you haven't received the email
        </Typography>
        <Button variant={'text'} color={'primary'} onClick={navigateResendLink} className={classes.textButton}>
          click here to re-send activation
        </Button>
      </Grid>
      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow} />
      <Grid item={true} xs={12} container={true} alignItems="center" justify="center" className={classes.confirmRow}>
        <Button variant={'outlined'} color={'primary'} onClick={navigateLogin} className={classes.button}>
          Sign In
        </Button>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Confirmation));
