import { Button, Divider, Typography, withStyles, WithStyles } from '@material-ui/core';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import { RemoteConstants } from 'config/constants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {}
interface HomePageDescriptionObj {
  content1: string;
  content2: string;
}
const IntroSection: React.FunctionComponent<IProps> = ({ classes, history }) => {
  const navigate = () => {
    history.push(`/ticket-entry`);
  };
  const { t } = useTranslation();

const homePageDescription = RemoteConstants.getString('HOME_PAGE_DESCRIPTION');
let content1 = t('home.intro.content1');
let content2 = t('home.intro.content2');
if (homePageDescription) {
  const homePageDescriptionObj: HomePageDescriptionObj = JSON.parse(homePageDescription);
  content1 = homePageDescriptionObj.content1;
  content2 = homePageDescriptionObj.content2;
}

  return (
    <div className={classes.introSection}>
        <Typography className={classes.introSectionTitle} variant={`h5`} component={'h1'} align={`center`}>
          {t('home.intro.title')}
        </Typography>
      <Typography className={classes.introSectionContent} variant={`body1`} component={`p`} align={`center`}>
        {content1}
      </Typography>
      <Typography className={classes.introSectionContent} variant={`body1`} component={`p`} align={`center`}>
        {content2}
      </Typography>
      <div className={classes.introSectionButtonContainer}>
        <Button
          className={classes.introSectionButton}
          title={t('home.intro.ticket-entry')}
          variant={`contained`}
          aria-label={t('home.intro.ticket-entry')}
          onClick={navigate}
          color="secondary"
          size="large"
        >
          {t('home.intro.ticket-entry')}
        </Button>
      </div>
      <Divider variant={`fullWidth`} />
    </div>
  );
};

export default withStyles(styles)(withRouter(IntroSection));
