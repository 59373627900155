import constants from 'config/constants';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxLogger from 'redux-logger';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const defaultMiddlewares = [thunkMiddleware, promise];

if (constants.CONSOLE_DEBUG) {
  defaultMiddlewares.push(reduxLogger);
}

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['authentication']
};
// @ts-ignore
const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, undefined, composeWithDevTools(applyMiddleware(...defaultMiddlewares)));

export const persistentStore = persistStore(store);
