import { Button, Grid, Theme, Typography, withTheme } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import plugImage from 'assets/img/404.svg';

interface INotFoundScreenProps extends RouteComponentProps {
  theme: Theme;
}

class NotFoundScreen extends React.Component<INotFoundScreenProps> {
  public componentDidMount(): void {
    document.title = 'Page Not Found';
  }

  public render() {
    const { theme } = this.props;

    return (
      <Grid container={true} justify="center">
        <Grid item={true} xs={12} sm={8} md={6} lg={5} xl={4}>
          <Grid container={true}>
            <Grid item={true} xs={9}>
              <Grid container={true} direction="column" spacing={8} style={{ padding: theme.spacing(2) }}>
                <Grid item={true}>
                  <Typography
                    variant="h6"
                    style={{
                      color: theme.palette.text.primary.getHighEmphasis(),
                      marginTop: theme.spacing(2)
                    }}
                  >
                    Page Not Found!
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.palette.text.primary.getMediumEmphasis(),
                      marginTop: theme.spacing(4),
                      marginBottom: theme.spacing(4)
                    }}
                  >
                    The page you tried cannot be found or doesn't exist.
                  </Typography>
                </Grid>

                <Grid item={true} xs={6}>
                  <Button color="primary" variant="outlined" size="large" onClick={this.onNavigate('/')} style={{ width: '100%' }}>
                    Home
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button color="primary" variant="outlined" size="large" onClick={this.onNavigate('/register')} style={{ width: '100%' }}>
                    Register
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <Button color="primary" variant="outlined" size="large" onClick={this.onNavigate('/login')} style={{ width: '100%' }}>
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={3}>
              <img src={plugImage} alt="page not found" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private onNavigate = (route: string) => () => {
    this.props.history.push(route);
  };
}

export default withTheme(withRouter(NotFoundScreen));
