import { Button, Hidden, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { ArcadeHeader } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/arcade/ArcadesScreenStyle';
import exploreConfig from 'config/explore-lucky-lounge';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

export interface IScreenProps extends RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
}

class ExploreScreen extends React.Component<IScreenProps> {
  private title = 'Explore Lucky Lounge';
  public async componentDidMount() {
    document.title = this.title;
  }

  public render() {
    const { classes, history, t } = this.props;
    const onNavigate = () => {
      history.push('/lucky-lounge');
    };
    const navigateToBuyScreen = (): void => {
      history.push('/lucky-lounge/store');
    };
    return (
      <div className={classes.container}>
        <ScrollToTopOnMount />
        <Grid container={true} className={classes.gridContainer}>
          <Grid item={true} xs={12}>
            <ArcadeHeader onNavigate={onNavigate} title={this.title} isLoggedIn={false} disableHistoryButton={true} navigateToBuyScreen={navigateToBuyScreen} />
          </Grid>
          {exploreConfig.map((item, index) => (
            <Grid item={true} xs={12} key={index}>
              <Typography variant="h5" color={'textPrimary'} className={classes.exploreTitle}>
                {item.header}
              </Typography>
              <Hidden only={['xs', 'sm', 'md']}>
                <img aria-hidden="true" src={item.image} alt={item.header} className={classes.image} />
              </Hidden>
              <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                <img aria-hidden="true" src={item.imageTablet} alt={item.header} className={classes.image} />
              </Hidden>
              <Hidden only={['md', 'lg', 'xl']}>
                <img aria-hidden="true" src={item.imageMobile} alt={item.header} className={classes.image} />
              </Hidden>
              <Typography color={'textPrimary'}>
                <div dangerouslySetInnerHTML={{ __html: item.body }} className={classes.exploreContent} />
              </Typography>
            </Grid>
          ))}
          <Grid item={true} className={classes.playLLBtn}>
          <Button variant={'contained'} size={'large'} color={'primary'} onClick={onNavigate}>
            {t('arcade.button.play')}
          </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(withRouter(withTheme(withStyles(styles)(ExploreScreen))));
