import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const UserActivatedScreen: React.FC<RouteComponentProps> = ({ history }) => {
  React.useEffect(() => {
    setTimeout(() => {
      history.push('/login', {
        message: {
          message: 'Your user account has been activated. Please sign in.',
          type: 'success',
          messageTimeout: 20000
        }
      });
    }, 10);
  }, [history]);

  return null;
};

export default withRouter(UserActivatedScreen);
