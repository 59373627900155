import { IGamePage, RESET_GAME, RESET_STATE } from '@pbl/pbl-react-core/lib/models/games/types';
import {
  GET_JACKPOT_GAME_RESULTS_GROUP,
  GET_LAST_24H_QUICK_DRAW_RESULT,
  GET_LATEST_QUICK_DRAW_RESULT,
  GET_QUICK_DRAW_CONFIG,
  IQuickDrawInitialState,
  IQuickDrawJackpotGameResult,
  IQuickDrawJackpotGameResultGrouped,
  IQuickDrawLatestResult,
  QuickDrawState
} from '@pbl/pbl-react-core/lib/models/quickdraw/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: IQuickDrawInitialState = {
  loading: false,
  errorMessage: null,
  page: {} as IGamePage,
  jackpotPage: {} as IGamePage,
  quickDrawLatestResult: {} as IQuickDrawLatestResult,
  quickDrawjackpotResultsGrouped: [] as IQuickDrawJackpotGameResultGrouped[],
  quickDrawResult: [] as IQuickDrawJackpotGameResult[],
  quickDrawConfig: undefined
};

export default (state: QuickDrawState = INITIAL_STATE, action: any): QuickDrawState => {
  switch (action.type) {
    case REQUEST(GET_JACKPOT_GAME_RESULTS_GROUP):
      return {
        ...state,
        loading: true
      };
    case REQUEST(GET_QUICK_DRAW_CONFIG):
      return {
        ...state,
        quickDrawConfig: undefined
      };
    case REQUEST(GET_LATEST_QUICK_DRAW_RESULT):
      return {
        ...state,
        loading: true
      };
    case REQUEST(GET_LAST_24H_QUICK_DRAW_RESULT):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_JACKPOT_GAME_RESULTS_GROUP):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        jackpotPage: action.payload.page,
        quickDrawjackpotResultsGrouped: action.payload.content
      };
    case SUCCESS(GET_LATEST_QUICK_DRAW_RESULT):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        quickDrawLatestResult: action.payload
      };
    case SUCCESS(GET_QUICK_DRAW_CONFIG):
      return {
        ...state,
        quickDrawConfig: action.payload
      };
    case SUCCESS(GET_LAST_24H_QUICK_DRAW_RESULT):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        quickDrawResult: action.payload.content
      };
    case FAILURE(GET_JACKPOT_GAME_RESULTS_GROUP):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        jackpotPage: {} as IGamePage,
        quickDrawjackpotResultsGrouped: [] as IQuickDrawJackpotGameResultGrouped[]
      };
    case FAILURE(GET_LATEST_QUICK_DRAW_RESULT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        quickDrawLatestResult: {} as IQuickDrawLatestResult
      };
    case FAILURE(GET_LAST_24H_QUICK_DRAW_RESULT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        quickDrawResult: [] as IQuickDrawJackpotGameResult[]
      };
    case RESET_STATE:
      return {
        ...INITIAL_STATE
      };
    case RESET_GAME:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        page: {} as IGamePage,
        jackpotPage: {} as IGamePage,
        quickDrawLatestResult: {} as IQuickDrawLatestResult,
        quickDrawjackpotResultsGrouped: [] as IQuickDrawJackpotGameResultGrouped[]
      };
    default:
      return state;
  }
};
