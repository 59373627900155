import {
  AppState,
  APP_CLEAR_LOADING,
  APP_CLEAR_MESSAGES,
  APP_LOADING,
  APP_SHOW_MESSAGE_BAR,
  IAppState
} from '@pbl/pbl-react-core/lib/models/app';
import { IDispatchAction } from 'redux/reducers';

const INITIAL_STATE: IAppState = {
  spinning: false,
  showMessage: false,
  message: null,
  type: 'error'
};

export default (state: AppState = INITIAL_STATE, action: IDispatchAction): AppState => {
  switch (action.type) {
    case APP_LOADING:
      return {
        ...state,
        spinning: action.payload.spinning || !state.spinning,
        label: action.payload.label || state.label
      };
    case APP_SHOW_MESSAGE_BAR:
      return {
        ...state,
        message: action.payload.message,
        showMessage: true,
        messageTimeout: action.payload.messageTimeout,
        messageParams: action.payload.messageParams,
        type: action.payload.type
      };
    case APP_CLEAR_MESSAGES:
      return {
        ...state,
        message: null,
        showMessage: false,
        messageTimeout: null,
        type: 'error'
      };
    case APP_CLEAR_LOADING:
      return {
        ...state,
        spinning: false,
        label: undefined
      };
    default:
      return state;
  }
};
