import { formMetadataState, SET_FORM_DIRTY } from '@pbl/pbl-react-core/lib/models/form-metadata';
import { IDispatchAction } from '../index';

const INITIAL_STATE: formMetadataState = {
  isFormDirty: false
};

export default (state: formMetadataState = INITIAL_STATE, action: IDispatchAction): formMetadataState => {
  switch (action.type) {
    case SET_FORM_DIRTY:
      return { ...state, isFormDirty: action.payload };
    default:
      return state;
  }
};
