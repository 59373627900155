import { Grid, Theme, WithStyles, withStyles, withTheme } from '@material-ui/core';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import styles from 'assets/jss/modules/settings/interests/InterestsScreenStyle';
import InterestsContent from 'modules/settings/interests/components/InterestsContent';
import InterestsContentV2 from 'modules/settings/interests/components/v2/InterestsContent';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { fetchInterests, reset } from 'redux/reducers/survey/action';

type PropsConnected = ConnectedProps<typeof connector>;

interface IInterestsScreenProps extends PropsConnected, RouteComponentProps, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}
export interface IInterestsScreenState {
  loading: boolean;
  isLegacySurvey: boolean;
}

class InterestsScreen extends React.Component<IInterestsScreenProps, IInterestsScreenState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isLegacySurvey: false
    };
  }
  public async componentDidMount() {
    document.title = 'Interests';
    await this.getData();

    const {
      survey: { survey }
    } = this.props;

    // Legacy survey
    if (survey) {
      this.setState({ isLegacySurvey: true });
    }
  }

  public componentDidUpdate = () => {
    window.onbeforeunload = this.props.formMetadata.isFormDirty ? () => true : null;
  };

  public componentWillUnmount() {
    this.props.reset();
    this.props.setIsFormDirty(false);
  }

  public render() {
    const { loading, isLegacySurvey } = this.state;
    return (
      <Grid container={true} spacing={3} direction="column">
        <Prompt when={this.props.formMetadata.isFormDirty} message={this.props.t('interests.promptMessage')} />
        {loading ? <AppSpinner label={'Loading Interests'} /> : isLegacySurvey ? <InterestsContent /> : <InterestsContentV2 />}
      </Grid>
    );
  }

  private getData = async () => {
    await this.setState({ loading: true });
    await this.props.fetchInterests();
    await this.setState({ loading: false });
  };
}

const mapStateToProps = ({ formMetadata, survey }: IRootState) => ({
  formMetadata,
  survey
});

const mapDispatchToProps = {
  fetchInterests,
  reset,
  setIsFormDirty
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(InterestsScreen)))));
