import { CREDIT, DISMISS_DAILY_BONUS, GET_ACTIVITY, GET_ACTIVITY_WITH_FREQ_CAP } from '@pbl/pbl-react-core/lib/models/activity/types';
import { ActivityService } from '@pbl/pbl-react-core/lib/services';
import moment from 'moment';
import { FAILURE, SUCCESS } from 'redux/action-type.util';
import { GetStateMethod } from 'redux/reducers';

export const fetchActivity = (key: string, successCallback: (IActivity) => void) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const activity = await ActivityService.fetchActivity(key);
    const activities = getState().activityState.activities ?? {};
    activities[activity.key] = activity;
    await dispatch({
      type: SUCCESS(GET_ACTIVITY),
      payload: { activities }
    });
    successCallback(activity);
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY),
      payload: error
    });
    console.error(error);
  }
};

export const credit = (key: string, onSuccess: () => void) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const activity = await ActivityService.credit(key);
    const activities = getState().activityState.activities ?? {};
    activities[activity.key] = activity;
    await dispatch({
      type: SUCCESS(CREDIT),
      payload: { activities }
    });
    onSuccess();
  } catch (error) {
    dispatch({
      type: FAILURE(CREDIT),
      payload: error
    });
    console.error(error);
  }
};

export const fetchActivityWithFrecCap = (key: string) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const activity = await ActivityService.fetchActivityWithFreqCap(key);
    const activities = getState().activityState.activities ?? {};
    activities[activity.key] = activity;
    await dispatch({
      type: SUCCESS(GET_ACTIVITY_WITH_FREQ_CAP),
      payload: { activities }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ACTIVITY_WITH_FREQ_CAP),
      payload: error
    });
    console.error(error);
  }
};

export const dismissDailyBonus =
  (dismissed = true) =>
  async (dispatch: any) => {
    await dispatch({
      type: DISMISS_DAILY_BONUS,
      payload: {
        dailyBonusRedemption: {
          dismissed,
          dateDismissed: moment.now()
        }
      }
    });
};
