import {
  ERROR_RESET,
  FETCH_USER_REPONSE,
  ISurveyAnswer,
  ISurveyJson,
  ISurveyPostResponse,
  RESET,
  SUBMIT_SURVEY,
  SurveyState,
  UPDATE_SURVEY_POST_RESPONSE,
  USER_SURVEY
} from '@pbl/pbl-react-core/lib/models/survey/types';
import { IDispatchAction } from '..';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: SurveyState = {
  loading: false,
  activityId: null,
  errorMessage: null,
  survey: null,
  surveyPostResponse: null,
  surveyPostSuccess: null,
  submitErrorMessage: null,
  resultErrorMessage: null
};

export default (state: SurveyState = INITIAL_STATE, action: IDispatchAction): SurveyState => {
  switch (action.type) {
    case REQUEST(USER_SURVEY):
    case REQUEST(SUBMIT_SURVEY):
    case REQUEST(FETCH_USER_REPONSE):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(USER_SURVEY):
      const response: {
        survey: ISurveyJson;
        surveyPostResponse: ISurveyPostResponse;
      } = action.payload;

      return {
        ...state,
        loading: false,
        errorMessage: null,
        survey: response.survey,
        // @ts-ignore
        surveyPostResponse: response.surveyPostResponse
      };
    case SUCCESS(FETCH_USER_REPONSE):
      const userResponses: ISurveyAnswer[] = action.payload;
      const userPostResponse = {
        ...state.surveyPostResponse,
        responses: userResponses.map(question => ({
          id: question.id,
          value: question.value
        }))
      };
      return {
        ...state,
        loading: false,
        // @ts-ignore
        surveyPostResponse: userPostResponse
      };
    case SUCCESS(SUBMIT_SURVEY):
      return {
        ...state,
        loading: false,
        surveyPostSuccess: action.payload
      };
    case FAILURE(USER_SURVEY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(SUBMIT_SURVEY):
      return {
        ...state,
        loading: false,
        submitErrorMessage: action.payload
      };
    case FAILURE(FETCH_USER_REPONSE):
      return {
        ...state,
        loading: false,
        resultErrorMessage: action.payload
      };
    case UPDATE_SURVEY_POST_RESPONSE:
      let currentResponses = (state.surveyPostResponse as ISurveyPostResponse).responses;
      const answer: ISurveyAnswer = action.payload;
      currentResponses = currentResponses.filter(x => x.id !== answer.id);
      currentResponses.push(answer);
      return {
        ...state,
        surveyPostResponse: {
          surveyId: (state.surveyPostResponse as ISurveyPostResponse).surveyId,
          responses: [...currentResponses]
        }
      };
    case ERROR_RESET:
      return {
        ...state,
        errorMessage: action.payload,
        submitErrorMessage: action.payload,
        surveyPostSuccess: action.payload,
        resultErrorMessage: action.payload
      };
    case RESET:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
