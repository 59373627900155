import constants from 'config/constants';
import React from 'react';

export type CaptchaActionType = 'REGISTER' | 'LOGIN' | 'CONTACT_US' | 'CHECKOUT' | 'REDEEM';

interface IProps {
  visible?: boolean;
  action: CaptchaActionType;
  onChange?: (value: any) => any;
}

interface IState {
  scriptId: string;
  key: string;
  isReady: boolean;
}

const isBrowser = typeof window !== 'undefined';

// @ts-ignore
const isLoaded = () => isBrowser && typeof window.grecaptcha !== 'undefined';

// @ts-ignore
const isReady = () => isLoaded() && typeof window.grecaptcha.execute === 'function';

const scriptId = 'google-recaptcha-script-tag';

class ReCaptcha extends React.Component<IProps, IState> {
  private readyCheck: number | null = null;

  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      scriptId,
      key: constants.GOOGLE_RECAPTCHA_SITE_KEY,
      isReady: isReady()
    };
  }

  public componentDidMount = async () => {
    const badge = document.getElementsByClassName('grecaptcha-badge');
    if (!!badge && badge.length > 0) {
      // @ts-ignore
      badge[0].style.visibility = 'visible';

      if (isBrowser) {
        this.readyCheck = this.state.isReady ? null : window.setInterval(this.updateReadyState, isLoaded() ? 0 : 1000);
      }
      await this.setup();
    } else {
      const scriptID = await this.loadScript();
      if (scriptID === this.state.scriptId) {
        if (isBrowser) {
          this.readyCheck = this.state.isReady ? null : window.setInterval(this.updateReadyState, isLoaded() ? 0 : 1000);
        }
        await this.setup();
      }
    }
  };

  public componentWillUnmount = async () => {
    if (!!this.readyCheck) {
      clearInterval(this.readyCheck);
    }
    this.readyCheck = null;
    await this.removeScript();
  };

  public render(): React.ReactNode {
    return null;
  }

  private loadScript = async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.state.key}`;
      script.id = this.state.scriptId;
      script.async = true;
      script.onload = () => {
        resolve(this.state.scriptId);
      };
      script.onerror = e => {
        reject(e);
      };
      document.body.appendChild(script);
    });

  private removeScript = async () => {
    const badge = document.getElementsByClassName('grecaptcha-badge');
    if (!!badge && badge.length > 0) {
      // @ts-ignore
      badge[0].style.visibility = 'hidden';
    }
  };

  private setup = async () => {
    // @ts-ignore
    if (this.state.isReady) {
      // @ts-ignore
      const token = await window.grecaptcha.execute(this.state.key, { action: this.props.action });
      if (!!token && !!this.props.onChange) {
        this.props.onChange(token);
      }
    }
  };

  private setReady = async () => {
    this.setState({ isReady: true });
    await this.setup();
  };

  private updateReadyState = async () => {
    if (isLoaded()) {
      // @ts-ignore
      window.grecaptcha.ready(this.setReady);
      if (this.readyCheck) {
        clearInterval(this.readyCheck);
        this.readyCheck = null;
      }
    }
  };
}

export default ReCaptcha;
