import { createStyles, Theme } from '@material-ui/core';
// @ts-ignore
const isIE = !!document.documentMode;

const styles = (theme: Theme) =>
  createStyles({
    popupMenu: {
      '& div ul': {
        width: isIE ? 223 : 'inherit'
      }
    },
    menuItemWithSubTitle: {
      paddingBottom: 24,
      paddingTop: 24
    },
    navLink: {
      color: 'inherit',
      position: 'relative',
      padding: '0.90rem',
      textDecoration: 'none',
      margin: '0px',
      marginLeft: theme.spacing() / 4,
      display: 'inline-flex',
      '&:hover,&:focus': {
        color: 'inherit'
      }
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    menuWithSubmenu: {
      textAlign: 'left',
      marginLeft: '-8px',
      marginRight: 'auto',
      display: 'table',
    },
    navLinkIcon: {
      '&.MuiIcon-root': {
        height: 'auto'
      }
    },
    dividerTop: {
      borderTopColor: theme.palette.divider,
      borderTopWidth: 1,
      borderTopStyle: 'solid'
    },
    dividerBottom: {
      borderBottomColor: theme.palette.divider,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid'
    }
  });

export default styles;
