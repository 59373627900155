import { ACTIVATE_ACCOUNT, RESET } from '@pbl/pbl-react-core/lib/models/account-activation';
import { AccountService } from '@pbl/pbl-react-core/lib/services';

export const activateAccount = key => ({
  type: ACTIVATE_ACCOUNT,
  payload: AccountService.activateAccount(key)
});

export const reset = () => ({
  type: RESET
});
