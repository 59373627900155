import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { Reward } from '@pbl/pbl-react-core/lib/models/loyalty';
import { IRewardCategory } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import RewardCard from './RewardCard';
import SectionHeading from './SectionHeading';

interface IBanner {
  showBanner?: boolean;
  bannerText?: string;
  styleType?: string;
}

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  rewards: Reward[];
  category: IRewardCategory[];
  banner?: IBanner;
  showSoldOutTag?: boolean;
}

const RewardsSection: React.FunctionComponent<IProps> = ({ classes, rewards, t, category, showSoldOutTag }) => {
  let rewardsToRender: Reward[] = rewards.filter((_1, index) => index < constants.HOME_SCREEN_REWARD_COUNT);
  // if there are no featured rewards
  if (rewardsToRender.length < 1) {
    rewardsToRender = rewards.filter((_1, index) => index < constants.HOME_SCREEN_REWARD_COUNT);
  }

  return (
    <div className={classNames(classes.rewardsSection)}>
      <SectionHeading
        title={t('home.rewardsSectionTitle')}
        linkRoute="/rewards"
        linkTitle={t('home.rewardsSectionLink')}
        linkLabel={t('home.rewardsSectionLinkLabel')}
      />
      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {rewardsToRender.map(reward => {
          const categoryItem = category.find(obj => {
            return Number(obj.id) === reward?.category_id;
          });
          let banner = {};
          if (showSoldOutTag) {
            if (categoryItem?.name === 'Coupons') {
              if (reward.remaining_coupons === 0) {
                banner = {
                  showBanner: true,
                  bannerText: t('reward.soldOut'),
                  styleType: 'GrayedOut'
                };
              }
            }
          }
          return (
            <Grid key={reward.id} xs={12} md={4} item={true} className={classes.sectionCard}>
              <RewardCard reward={reward} banner={banner} categoryName={categoryItem?.name} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(RewardsSection));
