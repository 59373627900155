import { createStyles, Theme } from '@material-ui/core';
import { coloredShadow, container } from 'assets/jss/material-kit-pro-react.jsx';
import colors from 'assets/jss/colors';
import color from 'color';

const styles = (theme: Theme) =>
  createStyles({
    container,
    // @ts-ignore
    coloredShadow,
    centered: {
      display: 'flex',
      alignItems: 'center'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    gridRow: {
      marginTop: theme.spacing(2)
    },
    subtitle: {
      display: 'flex',
      flexDirection: 'row'
    },
    button: {
      paddingTop: theme.spacing() + 4,
      paddingBottom: theme.spacing() + 4,
      width: '100%'
    },
    earnIcon: {
      marginRight: theme.spacing(),
      fontSize: 36
    },

    alert: {
      marginLeft: theme.spacing(2)
    },
    imageCard: {
      [theme.breakpoints.up('sm')]: {
        width: 445,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.only('xs')]: {
        width: 328
      }
    },
    bordered: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: colors.themeColors.grayColor.toString(),
      borderRadius: '6px'
    },
    image: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: colors.themeColors.grayColor.toString(),
      borderRadius: '6px',
      display: 'flex',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        height: 445,
        width: 445
      },
      [theme.breakpoints.only('xs')]: {
        height: 328,
        width: 328
      }
    },
    uploadCard: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: colors.themeColors.grayColor.toString(),
      borderRadius: '6px',
      [theme.breakpoints.up('md')]: {
        width: 623,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
      },
      [theme.breakpoints.only('sm')]: {
        width: 492
      },
      [theme.breakpoints.only('xs')]: {
        width: 320
      }
    },

    uploadGrid: {
      display: 'flex',
      padding: theme.spacing(),
      marginTop: 0,

      [theme.breakpoints.up('sm')]: {
        height: 445,
        flexDirection: 'row'
      },
      [theme.breakpoints.only('xs')]: {
        height: 656,
        flexDirection: 'column'
      }
    },

    previewGridWrapper: {
      [theme.breakpoints.only('xs')]: {
        maxWidth: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        maxHeight: 429
      },
      [theme.breakpoints.only('xs')]: {
        maxHeight: 624,
        maxWidth: '100%'
      }
    },

    uploadedImg: {
      [theme.breakpoints.up('sm')]: {
        maxHeight: 382
      },
      [theme.breakpoints.only('xs')]: {
        maxHeight: 582
      }
    },

    previewGrid: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      backgroundColor: color(colors.themeColors.grayColor.hex())
        .alpha(0.2)
        .rgb()
        .string(),
      borderRadius: '6px',
      color: theme.palette.text.primary,
      height: '95%'
    },
    previewTextWrapper: {
      // position:'absolute',
      width:'100%'
    },
    previewText: {
      backgroundColor: 'rgba(0,0,0,0.77)',
      textAlign: 'center',
      color: '#ffffff',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      padding: '5px 0px'
    },
    selectGrid: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        maxWidth: '100%'
      }
    },
    progressText: {
      textAlign: 'center',
      paddingTop: theme.spacing()
    },
    cancelledText: {
      textAlign: 'center',
      paddingBottom: theme.spacing(2)
    },
    pointsText: {
      justifyContent: 'center',
      padding: theme.spacing(2),
      color: theme.palette.text.primary
    },
    errorText: {
      paddingBottom: theme.spacing(2)
    },
    uploadDetails: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: colors.themeColors.grayColor.toString(),
      borderRadius: '6px',
      [theme.breakpoints.up('sm')]: {
        height: 445,
        width: 445
      },
      [theme.breakpoints.only('xs')]: {
        height: 328,
        width: 328
      }
    },
    rules: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    rulesLink: {
      display: 'flex',
      textDecoration: 'none'
    },
    selected: {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[6]
    },
    prizeSummary: {
      marginBottom: theme.spacing(2)
    },
    prizeContent: {
      marginBottom: theme.spacing(2)
    },
    prizeDescriptionLink: {
      display: 'flex',
      textDecoration: 'none',
      textTransform: 'uppercase'
    },
    enterTicketButton: {
      marginTop: theme.spacing(4)
    },
    eligibleGames: {
      marginTop: theme.spacing(4)
    },
    allEligibleGamesCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'stretch',
      alignItems: 'center',
      height: 432,
      [theme.breakpoints.down('lg')]: {
        height: 372
      },
      [theme.breakpoints.down('md')]: {
        height: 290
      },
      [theme.breakpoints.down('xs')]: {
        height: 432
      }
    }
  });

export default styles;
