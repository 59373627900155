import {
  GET_INSTAGRAM_ACCESS_TOKEN,
  GET_PROFILE,
  GET_TWITTER_OAUTH_TOKEN,
  GET_TWITTER_REQUEST_TOKEN,
  IProfile,
  ProfileState,
  RENEW_IDOLOGY_URL,
  RESET,
  SOCIAL_CONNECT,
  UPDATE_PROFILE,
  VERIFY_PROFILE,
  VERIFY_STATUS
} from '@pbl/pbl-react-core/lib/models/profile/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: ProfileState = {
  userProfile: {} as IProfile,
  error: null,
  loading: false,
  updateSucceeded: false,
  twitterRequestToken: null,
  updateFailed: false,
  validating: false,
  validationSucceeded: null,
  hasSeenTicketCheckerInstructions: false
};

export default (state: ProfileState = INITIAL_STATE, action: IDispatchAction): ProfileState => {
  switch (action.type) {
    case REQUEST(GET_PROFILE):
    case REQUEST(GET_TWITTER_REQUEST_TOKEN):
    case REQUEST(GET_TWITTER_OAUTH_TOKEN):
    case REQUEST(GET_INSTAGRAM_ACCESS_TOKEN):
    case REQUEST(UPDATE_PROFILE):
      return {
        ...state,
        loading: true
      };
    case FAILURE(GET_PROFILE):
      return {
        ...state,
        loading: false,
        error: action.payload.message
      };
    case FAILURE(UPDATE_PROFILE):
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        updateSucceeded: false
      };
    case SUCCESS(GET_PROFILE):
      return {
        ...state,
        userProfile: action.payload,
        loading: false
      };
    case SUCCESS(UPDATE_PROFILE):
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
        updateSucceeded: true
      };
    case SUCCESS(VERIFY_PROFILE):
        return {
          ...state,
          loading: false,
          updateSucceeded: true,
          verifyId: action.payload
        };
    case SUCCESS(VERIFY_STATUS):
        return {
          ...state,
          verifyStatus: action.payload
        };
    case SUCCESS(RENEW_IDOLOGY_URL):
      return {
        ...state,
        verifyId: action.payload
      };
    case RESET:
      return {
        ...INITIAL_STATE
      };
    case SUCCESS(SOCIAL_CONNECT):
      return {
        ...state
      };
    case SUCCESS(GET_TWITTER_REQUEST_TOKEN):
      return {
        ...state,
        twitterRequestToken: action.payload.oauth_token,
        loading: false
      };
    case SUCCESS(GET_INSTAGRAM_ACCESS_TOKEN):
    case SUCCESS(GET_TWITTER_OAUTH_TOKEN):
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
