import { Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import styles from 'assets/jss/modules/settings/unsubscribe/UnsubscribeScreenStyle';
import React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IUnsubscribeScreenProps extends PropsConnected, WithStyles<typeof styles>, WithTranslation, RouteComponentProps {}
type PropsConnected = ConnectedProps<typeof connector>;

class UnsubscribeScreen extends React.Component<IUnsubscribeScreenProps> {
  public componentDidMount(): void {
    document.title = 'Unsubscribed';
    scrollToTheTop();
  }

  private onClick = async () => {
    await this.props.isAuthenticated();
    if (!this.props.loggedIn) {
      this.props.history.push('/login', { from: '/settings/preferences' });
      return;
    }
    const path = '/settings/preferences';
    this.props.history.push(path, {
      currentRoute: path,
      currentRouteName: 'Communication Preferences'});
  };

  public render(): React.ReactNode {
    const { t, classes } = this.props;
    const query = new URLSearchParams(window.location.search);
    const isSuccess = query.get('success') === 'true';
    return (
      <div className={classes.root}>
        {isSuccess ? (
          <div>
            <Typography variant="h4" className={classes.titleText}>
              {t('preferences.unsubscribe.title')}
            </Typography>
            <Typography variant="body1" className={classes.bottomSpace}>
              {t('preferences.unsubscribe.confirmation')}
            </Typography>
            <Typography variant="body1">{t('preferences.unsubscribe.message1')}</Typography>
            <Typography variant="body1" className={classes.preLine}>
              <Trans i18nKey="preferences.unsubscribe.message2">
                a
                <Typography
                  component={'a'}
                  color={'primary'}
                  variant="body2"
                  aria-label="Communication Preferences"
                  onClick={this.onClick}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  Communication Preferences
                </Typography>
                a
              </Trans>
            </Typography>
          </div>
        ) : (
          <div>
            <Typography variant="h4" className={classes.titleText}>
              {t('preferences.unsubscribe.error')}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty }: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    loyalty,
    loggedIn
  };
};

const mapDispatchToProps = {
  isAuthenticated
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(UnsubscribeScreen)))));
