import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IGame } from '@pbl/pbl-react-core/lib/models/games/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import QuickDrawGameDetails from '@pbl/pbl-react-web-components/lib/games/quick-draw/QuickDrawGameDetails';
import QuickDrawGameTabs from '@pbl/pbl-react-web-components/lib/games/quick-draw/QuickDrawGameTabs';
import { RelatedGames, RelatedGamesDialog } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import styles from 'assets/jss/modules/games/GamesScreenStyle';
import constants, { RemoteConstants } from 'config/constants';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { selectPromotion } from 'redux/reducers/draws/actions';
import { fetchGame, fetchPromotionsForGame, fetchRelatedGames, getAllRelatedGames, resetGameState } from 'redux/reducers/games/actions';

import {
  getLatestQuickDrawWithWinnerResultByGame,
  getQuickDrawConfig,
  getQuickDrawJackpotResultsGrouped
} from 'redux/reducers/quickdraw/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  gameId: string;
}

export interface IDrawGameDetailScreenProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    WithStyles<typeof styles>,
    RouteComponentProps<IMatchParams> {
  theme: Theme;
}

interface IDrawGameDetailScreenState {
  showModal: boolean;
  gameId: number;
  showNextDrawContainer: boolean;
}

const SHOW_NEXT_DRAW_CONTAINER_KEY = `QUICK_DRAW_SHOW_NEXT_DRAW_CONTAINER`;

class QuickDrawGameDetailScreen extends React.Component<IDrawGameDetailScreenProps, IDrawGameDetailScreenState> {
  constructor(props: IDrawGameDetailScreenProps) {
    super(props);
    this.state = {
      showModal: false,
      gameId: 0,
      showNextDrawContainer: RemoteConstants.getBoolean(SHOW_NEXT_DRAW_CONTAINER_KEY)
    };
  }

  public componentDidMount = async () => {
    document.title = 'Game Details';
    const showNextDrawContainer = RemoteConstants.getBoolean(SHOW_NEXT_DRAW_CONTAINER_KEY);
    this.setState({ showNextDrawContainer });
    const {
      match: { params }
    } = this.props;
    if (params.gameId.length > 0) {
      scrollToTheTop();
      await this.loadGame(params.gameId);
    } else {
      await this.showInvalidGameError();
    }
  };

  public componentWillUnmount = async () => {
    await this.props.resetGameState();
  };

  public selectGame = async (game: IGame) => {
    scrollToTheTop();
    if (game.gameTypeId === 7) {
      this.props.history.push(`/games-quick-draw/${game.id}`);
    } else {
      this.props.history.push(`/games/${game.id}`);
    }
    await this.props.resetGameState();
    await this.loadGame(game.id);
  };

  public render(): React.ReactNode {
    const { classes, history, location } = this.props;
    const { selectedGame, loading, relatedGames, allRelatedGames, nextJackpotResult, jackpotPage } = this.props.games;

    const { quickDrawjackpotResultsGrouped, quickDrawLatestResult, quickDrawConfig } = this.props.quickDraws;
    const quickDrawJackpotPage = this.props.quickDraws.jackpotPage;
    const perPageOptions: number[] = [10, 25, 50, 75, 100];

    const onNavigate = () => {
      history.push('/games');
    };
    const navigateToTicketEntry = () => {
      history.push('/ticket-entry', { from: location.pathname });
    };
    if (!selectedGame) {
      return null;
    }
    return (
      <div className={classes.root}>
        <Grid container={true}>
          {loading && <AppSpinner label={'Loading Game...'} />}
          <RelatedGamesDialog
            title={'Related Games'}
            relatedGames={allRelatedGames}
            selectGame={this.selectGame}
            handleClose={this.onModalClose}
            openDialog={this.state.showModal}
          />
          <DetailTitle
            title={selectedGame.name}
            linkText={'Games'}
            navigateTo={onNavigate}
            buttonText={'Enter Ticket'}
            onPress={navigateToTicketEntry}
          />
          <QuickDrawGameDetails
            showNextDrawContainer={this.state.showNextDrawContainer}
            quickDrawConfig={quickDrawConfig}
            selectedGame={selectedGame}
            latestJackpotResult={quickDrawLatestResult}
            nextJackpotResult={nextJackpotResult}
          />
          <QuickDrawGameTabs
            gameId={this.state.gameId}
            jackpotResultsGrouped={quickDrawjackpotResultsGrouped}
            draws={this.props.games.draws}
            drawsPage={this.props.games.drawsPage}
            jackpotPage={!!jackpotPage && jackpotPage.hasOwnProperty('totalElements') ? jackpotPage : quickDrawJackpotPage}
            getNextDrawsPage={this.getNextDrawsPage}
            getNextJackpotResultsPage={this.getNextJackpotResultsPage}
            onDrawClicked={this.onDrawClicked}
            topPrizeType={selectedGame.topPrizeType}
            perPageOptions={perPageOptions}
            quickDrawConfig={quickDrawConfig}
          />
          <RelatedGames
            relatedGames={relatedGames}
            selectGame={this.selectGame}
            showRelatedGames={this.onModalOpen}
            title={this.props.t('gameDetails.relatedGamesTitle')}
          />
        </Grid>
      </div>
    );
  }

  private loadGame = async gameId => {
    await this.setState({ gameId });
    await Promise.all([
      this.props.getQuickDrawConfig(gameId),
      this.props.fetchGame(gameId),
      this.props.getQuickDrawJackpotResultsGrouped(gameId, 0, constants.JACKPOT_RESULTS_PER_PAGE),
      this.props.getLatestQuickDrawWithWinnerResultByGame(gameId),
      this.props.fetchRelatedGames(gameId),
      this.props.getAllRelatedGames(gameId)
    ]);
  };

  public getNextDrawsPage = async (pageNumber: number) => {
    await this.props.fetchPromotionsForGame(this.state.gameId, pageNumber);
  };
  public getNextJackpotResultsPage = async (pageNumber: number) => {
    await this.props.getQuickDrawJackpotResultsGrouped(this.state.gameId, pageNumber, constants.JACKPOT_RESULTS_PER_PAGE);
  };
  private showInvalidGameError = async () => {
    await this.navigateToGames({
      message: `This game is not available.`,
      type: 'warning',
      messageTimeout: 3000
    });
  };

  private navigateToGames = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/games', { message });
  };
  private onModalOpen = () => {
    this.setState({ showModal: true });
  };
  private onModalClose = () => {
    this.setState({ showModal: false });
  };

  private onDrawClicked = (promotion: IPromotion): void => {
    this.props.history.push('/promotion/' + promotion.id);
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ games, quickDraws }) => ({
  games,
  quickDraws
});

const mapDispatchToProps = {
  fetchGame,
  fetchPromotionsForGame,
  fetchRelatedGames,
  getAllRelatedGames,
  resetGameState,
  showMessageBar,
  selectPromotion,
  getQuickDrawJackpotResultsGrouped,
  getLatestQuickDrawWithWinnerResultByGame,
  getQuickDrawConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTheme(withTranslation()(withRouter(withStyles(styles)(QuickDrawGameDetailScreen)))));
