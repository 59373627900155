import { Theme, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { EarnPointsModal, PromoCode } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  fetchActivities,
  fetchUserActivities,
  fetchUserPoints,
  getCampaign,
  getPromoCodeActivity,
  resetActivityCompleted,
  resetSelectedActivity,
  setActivityCompleted,
  setSelectedUserActivity,
  validatePromoCode
} from 'redux/reducers/loyalty/actions';
import { scrollToTheTop } from 'utils/htmlUtil';
import styles from '../../assets/jss/modules/earn/PromoCodeScreenStyle';

interface IMatchParams {
  activityId: string;
}
type PropsConnected = ConnectedProps<typeof connector>;

export interface IPromoCodeProps extends PropsConnected, WithTranslation, RouteComponentProps<IMatchParams>, WithStyles<typeof styles> {
  theme: Theme;
}

export interface IPromoCodeState {
  modalVisibleStatus: boolean;
  pointsEarned: number;
  totalPoints: number;
  displayImage: boolean;
  submitting: boolean;
  tosChecked: boolean;
  errorModalVisible: boolean;
  useEllipsis: boolean;
}

export const INITIAL_STATE: IPromoCodeState = {
  modalVisibleStatus: false,
  pointsEarned: 0,
  displayImage: false,
  totalPoints: 0,
  submitting: false,
  tosChecked: false,
  errorModalVisible: false,
  useEllipsis: true
};

class PromoCodeScreen extends React.Component<IPromoCodeProps, IPromoCodeState> {
  constructor(props: IPromoCodeProps) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  public async componentDidMount() {
    document.title = 'Promo Code Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params && params.activityId && params.activityId.length > 0) {
      await this.getData(params.activityId);
    } else {
      this.props.getPromoCodeActivity();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IPromoCodeProps>): void {
    const { loyalty } = this.props;
    if (
      loyalty &&
      prevProps.loyalty &&
      loyalty.selectedActivity &&
      JSON.stringify(prevProps.loyalty.selectedActivity) !== JSON.stringify(loyalty.selectedActivity)
    ) {
      this.getData(loyalty.selectedActivity.id);
    }
  }

  public componentWillUnmount() {
    this.props.resetSelectedActivity();
  }

  public async getData(id) {
    if (this.props.loggedIn) {
      await this.props.fetchUserActivities();
    } else {
      await this.props.fetchActivities();
    }
    const { userActivities, selectedActivity } = this.props.loyalty;
    const activity = userActivities.find(a => a.id === id);
    if (activity) {
      await this.props.setSelectedUserActivity(activity);
      await this.props.getCampaign(activity.id);
    } else if (selectedActivity) {
      await this.props.getCampaign(selectedActivity.id);
    } else {
      await this.showInvalidActivityError();
    }
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `This activity is not available.`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  private openTos = () => {
    const { loyalty } = this.props;
    if (loyalty && loyalty.currentCampaign && loyalty.currentCampaign.tos_link_url) {
      window.open(loyalty.currentCampaign.tos_link_url);
    }
  };

  public validatePromoCode = async (promoCode: string) => {
    await this.props.isAuthenticated();
    if (!this.props.loggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    const { currentCampaign, selectedActivity } = this.props.loyalty;
    if (this.props.loyalty.currentCampaign || (selectedActivity && selectedActivity.campaignGroupId)) {
      this.setState({ submitting: true });
      await this.props.validatePromoCode(
        promoCode,
        currentCampaign ? currentCampaign.id : undefined,
        selectedActivity ? selectedActivity.campaignGroupId : undefined,
        this.validatePromoCodeResponse
      );
      await this.props.fetchUserPoints();
    }
  };

  public validatePromoCodeResponse = async (response: any) => {
    if (response && response.error) {
      this.setState({
        submitting: false,
        errorModalVisible: true
      });
      this.props.showMessageBar({
        type: 'error',
        message: response.error && response.error.message ? response.error.message : response.error
      });
    } else if (response && response.num_points) {
      this.setState({ submitting: false });
      this.props.setActivityCompleted({
        pointsEarned: response.num_points,
        activity: this.props.loyalty.selectedActivity,
        campaignTitle: response.message
      });
    } else if (response && response.message) {
      this.setState({
        submitting: false,
        errorModalVisible: true
      });
      this.props.showMessageBar({
        type: 'error',
        message: response.message
      });
    } else {
      this.setState({
        submitting: false,
        errorModalVisible: true
      });
      this.props.showMessageBar({
        type: 'error',
        message: 'error.general'
      });
    }
  };

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    this.props.history.push('/earn');
  };

  public render() {
    const { classes, history } = this.props;
    const { selectedActivity, currentCampaign, activityCompleted, maximumLimitReached } = this.props.loyalty;
    if (!selectedActivity) {
      return null;
    }
    const points =
      !!activityCompleted && activityCompleted.pointsEarned > 0
        ? activityCompleted.pointsEarned
        : selectedActivity.totalBonusPoints || selectedActivity.num_points;
    const onNavigate = () => {
      history.push('/earn');
    };
    const renderTitle = () => <DetailTitle title={selectedActivity.title} linkText={'Earn'} navigateTo={onNavigate} bonusPoints={selectedActivity.totalBonusPoints}
                                           noneBonusPoints={selectedActivity.num_points} description={'EARN POINTS'}/>;
    return currentCampaign != null || selectedActivity.campaignGroupId !== null ? (
      <div>
        {activityCompleted !== null ? (
          <EarnPointsModal
            pointEarned={points}
            isVisible={!!activityCompleted}
            onClose={this.onDismissEarnPointsModal}
            message={
              activityCompleted.campaignTitle ? this.props.t('codeCampaign.title', { title: activityCompleted.campaignTitle }) : undefined
            }
            campaignTitle={activityCompleted.campaignTitle ? activityCompleted.campaignTitle : ''}
          />
        ) : null}
        <Grid className={classes.container} container={true} spacing={2}>
          <PromoCode
            renderTitle={renderTitle}
            numPoints={selectedActivity.num_points}
            validatePromoCode={this.validatePromoCode}
            termsOfConditions={currentCampaign?.tos_link_url}
            openTermsOfConditions={this.openTos}
            selectedUserActivity={selectedActivity}
            submitting={this.state.submitting}
            limitReached={maximumLimitReached}
            bonusPoints={selectedActivity.totalBonusPoints}
          />
        </Grid>
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ authentication: { account, accessToken }, loyalty }: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    loyalty,
    loggedIn
  };
};

const mapDispatchToProps = {
  getPromoCodeActivity,
  setSelectedUserActivity,
  setActivityCompleted,
  resetActivityCompleted,
  showMessageBar,
  resetSelectedActivity,
  fetchUserPoints,
  isAuthenticated,
  getCampaign,
  validatePromoCode,
  fetchActivities,
  fetchUserActivities
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(withTranslation()(connector(withStyles(styles)(withTheme(PromoCodeScreen)))));
