import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...container,
      paddingLeft: 0,
      paddingRight: 0
    },
    field: {
      width: '100%',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    },
    fieldAutocomplete: {
      width: '100%',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      paddingBottom: theme.spacing()
    }
  });

export default styles;
