import EmailSignUpScreen from 'modules/earn/email-sign-up-screen';
import ProfileCompletionScreen from 'modules/earn/profile-completion-screen';
import InviteScreen from 'modules/invite/invite-screen';
import AccountScreen from 'modules/settings/account/account-screen';
import ChangeEmailScreen from 'modules/settings/account/change-email-screen';
import ChangePasswordScreen from 'modules/settings/account/change-password-screen';
import DeactivateAccountScreen from 'modules/settings/account/deactivate-account-screen';
import InterestsScreen from 'modules/settings/interests/interests-screen';
import PreferencesScreen from 'modules/settings/preferences/preferences-screen';
import ProfileScreen from 'modules/settings/profile/profile-screen';

const settingsRoutes = [
  { path: '/settings/profile', name: 'Profile', component: ProfileScreen, secured: true },
  { path: '/settings/preferences', name: 'Communication Preferences', component: PreferencesScreen, secured: true },
  {
    path: '/settings/account-security',
    name: 'Account & Security',
    component: AccountScreen,
    secured: true,
    exact: true
  },
  {
    path: '/settings/account-security/change-password',
    name: 'Change Password',
    component: ChangePasswordScreen,
    secured: true
  },
  {
    path: '/settings/account-security/change-email',
    name: 'Change Email Address',
    component: ChangeEmailScreen,
    secured: true
  },
  {
    path: '/settings/account-security/deactivate-account',
    name: 'Deactivate Account',
    component: DeactivateAccountScreen,
    secured: true
  },
  { path: '/settings/interests', name: 'Interests', component: InterestsScreen, secured: true },
  { path: '/settings/invite-friends', name: 'Invite Friends', component: InviteScreen, secured: true },
  { path: '/settings/email-sign-up', name: 'Email Sign-up', component: EmailSignUpScreen, secured: true },
  { path: '/settings/profile-completion', name: 'Email Sign-up', component: ProfileCompletionScreen, secured: true }
];

export default settingsRoutes;
