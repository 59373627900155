import { GET_CITIES, GET_COUNTRIES, GET_STATES, RESET_CITIES, RESET_STATES } from '@pbl/pbl-react-core/lib/models/address';
import { AddressService } from '@pbl/pbl-react-core/lib/services';

export const getAllCountries = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_COUNTRIES,
      payload: AddressService.getAllCountriesV1()
    });
  } catch (e) {
    console.error(e);
  }
};

export const getStates = (id: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_STATES,
      payload: AddressService.getStatesV1(id)
    });
  } catch (e) {
    console.error(e);
  }
};

export const getCities = (id: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_CITIES,
      payload: AddressService.getCitiesV1(id)
    });
  } catch (e) {
    console.error(e);
  }
};

export const resetStates = () => (dispatch: any) => {
  dispatch({
    type: RESET_STATES
  });
};

export const resetCities = () => (dispatch: any) => {
  dispatch({
    type: RESET_CITIES
  });
};
