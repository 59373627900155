import { GET_ADVERTISEMENTS, GET_ADVERTISEMENT_BY_ID, GET_TAGS, IMediaState } from '@pbl/pbl-react-core/lib/models/media/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IMediaState = {
  loading: false,
  errorMessage: null,
  advertisements: [],
  tags: []
};

export type MediaState = Readonly<IMediaState>;

export default (state: MediaState = INITIAL_STATE, action: IDispatchAction) => {
  switch (action.type) {
    case REQUEST(GET_ADVERTISEMENTS):
    case REQUEST(GET_ADVERTISEMENT_BY_ID):
    case REQUEST(GET_TAGS):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_ADVERTISEMENTS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        advertisements: action.payload.content
      };
    case SUCCESS(GET_ADVERTISEMENT_BY_ID):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        advertisements: [action.payload]
      };
    case SUCCESS(GET_TAGS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        tags: action.payload
      };
    case FAILURE(GET_ADVERTISEMENTS):
    case FAILURE(GET_ADVERTISEMENT_BY_ID):
    case FAILURE(GET_TAGS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
