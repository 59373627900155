import { Theme, WithStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { HistoryActivity, HistoryDraw, HistoryReward, TicketEntryHistory } from '@pbl/pbl-react-core/lib/models/history';
import { DateFilterIndex } from '@pbl/pbl-react-core/lib/models/history/types';
import { util } from '@pbl/pbl-react-core/lib/models/loyalty';
import { formatLongMonthDayCommaYear, now, utcDateTimeFor, zonedDateTimeFor, zonedDateTimeForTimestamp } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import History from '@pbl/pbl-react-web-components/lib/history/history-screen';
import RewardGroupDialog from '@pbl/pbl-react-web-components/lib/history/RewardGroupDialog';
import {
  FilterSection,
  PrizesDialog,
  RewardCouponModal,
  SelectedFiltersSection,
  WinnersDialog
} from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/history/HistoryScreenStyle';
import constants, { RemoteConstants } from 'config/constants';
import _ from 'lodash';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { fetchDrawPrizesWithContent, fetchDrawPrizesWithWinners } from 'redux/reducers/draws/actions';
import {
  fetchDrawsByIds,
  fetchSecondChanceTicketHistory,
  fetchUserActivitiesHistory,
  fetchUserDrawsHistory,
  fetchUserRewardsHistory,
  fetchUserTicketEntriesHistory,
  filterUserActivitiesHistory,
  getPendingPointsForTicketEntries,
  replaceFilers,
  resetState,
  setSelectedHistoryItem,
  toggleFilter
} from 'redux/reducers/history/actions';
import { DEFAULT_ACTIVITIES_PER_PAGE, DEFAULT_PAGE, resetPaging, updatePaging } from 'redux/reducers/history/pagination/actions';
import { endGame, fetchPlayableDigitalRevealGames, startGame } from 'redux/reducers/interactive-games/actions';
import { fetchUserPoints, getBarcodeData } from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';

export interface IHistoryScreenProps extends RouteComponentProps, StateProps, DispatchProps, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

interface IHistoryScreenState {
  loading: boolean;
  rewardsCurrentPage: number;
  ticketsCurrentPage: number;
  hidePagination: boolean;
  showModal: boolean;
  showWinnerDialog: boolean;
  showPrizesDialog: boolean;
  draw: HistoryDraw;
  rewardsGroup: HistoryReward[];
  openTicketPastHistory: boolean;
  openActivityPastHistory: boolean;
  openRewardsGroupDialog: boolean;
  openRedeemCouponDialog: boolean;
  selectedRewardData: HistoryReward;
  usePromotionService: boolean;
}

const perPageOptions: number[] = [10, 25, 50, 75, 100];

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({
  feature: { authorizedFlags },
  loyalty,
  loyalty: { selectedReward, barcodeData },
  history,
  historyPagination,
  drawDetail,
  interactiveGames: { digitalRevealGames, digitalRevealGamesPaging }
}: IRootState) => ({
  loyalty,
  historyData: history,
  historyPagination,
  drawDetail,
  selectedReward,
  barcodeData,
  digitalRevealGames,
  digitalRevealGamesPaging,
  authorizedFlags
});

const mapDispatchToProps = {
  fetchUserPoints,
  fetchUserActivitiesHistory,
  filterUserActivitiesHistory,
  fetchUserRewardsHistory,
  fetchUserTicketEntriesHistory,
  fetchDrawsByIds,
  setSelectedHistoryItem,
  resetState,
  toggleFilter,
  replaceFilers,
  getPendingPointsForTicketEntries,
  fetchUserDrawsHistory,
  fetchDrawPrizesWithWinners,
  fetchDrawPrizesWithContent,
  fetchSecondChanceTicketHistory,
  getBarcodeData,
  fetchPlayableDigitalRevealGames,
  startGame,
  endGame,
  updatePaging,
  resetPaging
};

class HistoryScreen extends React.Component<IHistoryScreenProps, IHistoryScreenState> {
  constructor(props: IHistoryScreenProps) {
    super(props);
    this.state = {
      loading: false,
      rewardsCurrentPage: 0,
      ticketsCurrentPage: 0,
      hidePagination: false,
      showModal: false,
      showWinnerDialog: false,
      showPrizesDialog: false,
      draw: {} as HistoryDraw,
      rewardsGroup: [] as HistoryReward[],
      openActivityPastHistory: false,
      openTicketPastHistory: false,
      openRewardsGroupDialog: false,
      openRedeemCouponDialog: false,
      selectedRewardData: {} as HistoryReward,
      usePromotionService: false
    };
  }

  public async getData() {
    this.props.resetState();

    this.setState({ loading: true });
    try {
      await Promise.all([
        this.props.fetchUserPoints(),
        this.props.fetchUserActivitiesHistory(DEFAULT_PAGE, DEFAULT_ACTIVITIES_PER_PAGE),
        this.props.fetchUserRewardsHistory({
          size: constants.HISTORY_ITEMS_PER_PAGE,
          page: DEFAULT_PAGE,
          dateStart: 0,
          dateEnd: 0
        }),
        this.props.fetchUserTicketEntriesHistory({
          page: DEFAULT_PAGE
        }),
        this.props.getPendingPointsForTicketEntries(),
        this.props.fetchUserDrawsHistory(DEFAULT_PAGE, this.state.usePromotionService),
        this.props.fetchPlayableDigitalRevealGames(DEFAULT_PAGE)
      ]);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  public async componentDidMount() {
    document.title = 'Players Club History';
    const usePromotionService = RemoteConstants.getBoolean('USE_PROMOTION_SERVICE_FOR_DRAW_HISTORY');
    if (!!usePromotionService) {
      await this.setState({ usePromotionService });
    }
    await this.getData();
  }

  public componentWillUnmount() {
    this.props.resetState();
  }

  public getActivitiesData = async (pageNumber, size, reset = false) => {
    const { historyPagination } = this.props;
    const filters = this.getFilterDateValue(this.props.historyData.filters);

    const newPage = reset ? DEFAULT_PAGE : pageNumber;
    const newActivitiesSize = reset ? DEFAULT_ACTIVITIES_PER_PAGE : size || historyPagination.activities.pageSize;

    this.props.updatePaging('activities', newPage, newActivitiesSize);

    if (!filters.startDate || filters.startDate === 0 || !filters.endDate || filters.endDate === 0) {
      this.props.fetchUserActivitiesHistory(newPage, newActivitiesSize, reset);
      return;
    }

    const dateStart = filters.startDate.clone();
    const dateEnd = filters.endDate.clone();
    await this.props.filterUserActivitiesHistory(
      dateStart.utc().startOf('day').unix(),
      dateEnd.utc().endOf('day').unix(),
      newPage,
      newActivitiesSize,
      reset
    );
  };

  public getRewardsData = async (pageNumber: number, size: number) => {
    const filters = this.getFilterDateValue(this.props.historyData.filters);
    const newPage = pageNumber ? pageNumber : DEFAULT_PAGE;
    if (!!filters.startDate && !!filters.endDate) {
      this.setState({ rewardsCurrentPage: DEFAULT_PAGE });
      const dateStart = filters.startDate.clone();
      const dateEnd = filters.endDate.clone();
      await this.props.fetchUserRewardsHistory({
        size,
        page: newPage,
        dateStart: dateStart.utc().startOf('day').unix(),
        dateEnd: dateEnd.utc().startOf('day').unix()
      });
      this.setState({ rewardsCurrentPage: newPage });
      return;
    }
    await this.props.fetchUserRewardsHistory({
      size,
      page: newPage,
      dateStart: 0,
      dateEnd: 0
    });
    this.setState({ rewardsCurrentPage: newPage });
  };
  public getTicketsData = async (pageNumber, reset = false) => {
    const filters = this.getFilterDateValue(this.props.historyData.filters);
    let dateStart;
    let dateEnd;
    if (filters.startDate !== null && filters.endDate !== null) {
      dateStart = filters.startDate.clone();
      dateEnd = filters.endDate.clone();
    }

    const newPage = reset ? DEFAULT_PAGE : pageNumber;

    await this.props.fetchUserTicketEntriesHistory(
      {
        page: newPage,
        dateStart: dateStart
          ? dateStart
              // @ts-ignore
              .utc()
              .startOf('day')
              .toISOString()
          : undefined,
        dateEnd: dateEnd
          ? dateEnd
              // @ts-ignore
              .utc()
              .endOf('day')
              .toISOString()
          : undefined
      },
      reset
    );
    this.setState({ ticketsCurrentPage: newPage });
  };

  public getDrawsData = async pageNumber => {
    await this.props.fetchUserDrawsHistory(pageNumber, this.state.usePromotionService);
  };

  public getDigitalRevealGames = async pageNumber => {
    await this.props.fetchPlayableDigitalRevealGames(pageNumber);
  };

  private hideWinnersDialog = (): void => {
    this.setState({ showWinnerDialog: false });
  };
  private openWinnersPopup = async selectedDraw => {
    // await this.props.fetchDrawPrizesWithWinners(selectedDraw.id);
    await this.props.fetchDrawPrizesWithContent(selectedDraw.id);
    await this.props.fetchSecondChanceTicketHistory(selectedDraw.id, this.state.usePromotionService);
    await this.setState({ draw: selectedDraw, showWinnerDialog: true });
  };

  private hidePrizesDialog = (): void => {
    this.setState({ showPrizesDialog: false });
  };

  private showPrizesDialog = (): void => {
    this.setState({ showPrizesDialog: true });
  };
  private onViewPrizeDetailsAndImagesClicked = (): void => {
    this.hideWinnersDialog();
    this.showPrizesDialog();
  };

  public setSelectedTicketEntry = async (ticket: TicketEntryHistory) => {
    await this.props.setSelectedHistoryItem(ticket);
    await this.props.fetchDrawsByIds(ticket);
    return this.props.historyData.selectedHistoryItem;
  };

  private openRewardsGroupDialog = (rewards: HistoryReward[]): void => {
    this.setState({ rewardsGroup: rewards, openRewardsGroupDialog: true });
  };

  private openRedeemCouponCodeModal = async (reward: HistoryReward): Promise<void> => {
    const codess: string[] = [];
    const barcodeData = !!reward.codes[0].code ? reward.codes[0].code : '';
    codess.push(barcodeData);
    const codes = util.mapCodes(codess);
    if (codes && codes.length === 1 && codes[0].codeType === 'COUPON' && !!codes[0].couponKey) {
      await this.props.getBarcodeData(codes[0].couponKey, codes[0].barcodeValue, codes[0].humanReadable);
    }

    this.setState({ selectedRewardData: reward, openRedeemCouponDialog: true });
  };

  private hideRedeemCouponCodeModal = () => {
    this.setState({ openRedeemCouponDialog: false });
  };

  public hideRewardsGroupDialog = () => {
    this.setState({ openRewardsGroupDialog: false });
  };

  public render() {
    const { loyalty, historyData, historyPagination, classes, authorizedFlags } = this.props;
    const { loading, draw, rewardsCurrentPage } = this.state;
    const { activitiesLastPage, rewardsLastPage } = historyData;
    const {
      drawDetail: { drawPrizes, drawPrizeContentRetrieved, drawPrizesWithWinners }
    } = this.props;
    const drawSecondChanceTicketHistory = _.reverse(_.orderBy(historyData.drawSecondChanceTicketHistory, [o => o.entryTime], ['asc']));
    const showEInstantTab = !!authorizedFlags && authorizedFlags.includes('EINSTANT_V1');
    return (
      <div className={classes.root}>
        <ScrollToTopOnMount />
        <WinnersDialog
          title={draw ? draw.name : ''}
          open={this.state.showWinnerDialog}
          drawEndDate={draw && draw.end ? formatLongMonthDayCommaYear(draw.end) : 'TBD'}
          drawDate={draw && draw.draw ? formatLongMonthDayCommaYear(draw.draw) : 'TBD'}
          drawPrizesWithWinners={drawPrizesWithWinners}
          onClose={this.hideWinnersDialog}
          drawStartDate={draw ? formatLongMonthDayCommaYear(draw.start) : ''}
          onViewPrizeDetailsClicked={this.onViewPrizeDetailsAndImagesClicked}
          isSweepStake={false}
          secondChanceTicketHistory={drawSecondChanceTicketHistory}
          entries={draw.totalPrizeAmount}
        />
        <PrizesDialog
          title={draw ? draw.name + ' Prize Details' : ''}
          open={this.state.showPrizesDialog}
          onClose={this.hidePrizesDialog}
          drawPrizeContentRetrieved={drawPrizeContentRetrieved}
          drawPrizes={drawPrizes}
        />
        <RewardGroupDialog
          open={this.state.openRewardsGroupDialog}
          drawEntriesOnly={true}
          onClose={this.hideRewardsGroupDialog}
          historyRewards={this.state.rewardsGroup}
        />
        {!!this.state.selectedRewardData && this.state.openRedeemCouponDialog && (
          // @ts-ignore
          <RewardCouponModal
            open={this.state.openRedeemCouponDialog}
            // @ts-ignore
            barcodeData={this.props.barcodeData}
            selectedReward={!!this.state.selectedRewardData ? this.state.selectedRewardData : ''}
            historyReward={!!this.state.selectedRewardData ? this.state.selectedRewardData : ''}
            onClose={this.hideRedeemCouponCodeModal}
          />
        )}
        <History
          loading={loading}
          historyTicketEntries={historyData.historyTicketEntries}
          historyTicketPage={this.props.historyData.ticketHistoryPage}
          // @ts-ignore
          historyRewardsPage={{
            lastPage: rewardsLastPage,
            currentPage: rewardsCurrentPage,
            totalPages: historyData.historyPagination?.pages,
            size: constants.HISTORY_ITEMS_PER_PAGE
          }}
          redeemablePoints={loyalty.userPoints.redeemablePoints}
          historyActivities={historyData.historyActivities}
          historyRewards={historyData.historyRewards}
          getTicketsData={this.getTicketsData}
          getActivitiesData={this.getActivitiesData}
          getRewardsData={this.getRewardsData}
          getDrawsData={this.getDrawsData}
          setSelectedHistoryItem={this.setSelectedTicketEntry}
          historyActivityPage={{
            lastPage: activitiesLastPage,
            currentPage: historyPagination.activities.pageNumber,
            size: historyPagination.activities.pageSize
          }}
          perPageOptions={perPageOptions}
          DEFAULT_ACTIVITIES_PER_PAGE={DEFAULT_ACTIVITIES_PER_PAGE}
          HISTORY_ITEMS_PER_PAGE={constants.HISTORY_ITEMS_PER_PAGE}
          showPaginationInRewards={constants.SHOW_PAGINATION_IN_REWARDS}
          renderFilterSection={this.renderFilterSection}
          renderFilterSelectedSection={this.renderFilterSelectedSection}
          hidePagination={this.state.hidePagination}
          pendingPoints={historyData.pendingPoints}
          historyDraws={historyData.historyDraws}
          historyDrawPage={historyData.drawHistoryPage}
          navigateToDrawDetails={this.openWinnersPopup}
          navigateToRewardsHistoryDetails={this.openRewardsGroupDialog}
          navigateToViewAndDownloadBarcode={this.openRedeemCouponCodeModal}
          groupRewards={false}
          drawEntriesOnly={true}
          identifier={'activity'}
          errorMessage={historyData.errorMessage}
          showDigitalRevealTab={true}
          digitalRevealGames={this.props.digitalRevealGames}
          digitalRevealPage={this.props.digitalRevealGamesPaging}
          getDigitalRevealGames={this.getDigitalRevealGames}
          onStartDigitalGame={this.startDigitalRevealGame}
          onEndDigitalGame={this.endDigitalRevealGame}
          showEInstantTab={showEInstantTab}
          onActivityRowClick={this.onActivityRowClick}
        />
      </div>
    );
  }

  private onActivityRowClick = (activity: HistoryActivity) => {
    const { base_extra_data, date_awarded } = activity;

    const collectThemAllSuperBonusKey = base_extra_data?.super_bonus_key;
    const activityRewardedDate = !!date_awarded ? zonedDateTimeForTimestamp(date_awarded * 1000) : null;
    const superBonusStartDate = zonedDateTimeFor(utcDateTimeFor(base_extra_data.superbonus_start_date).format());
    const superBonusEndDate = zonedDateTimeFor(utcDateTimeFor(base_extra_data.superbonus_end_date).format());
    const currentDate = now();

    const isCollectThemAllEnded = superBonusEndDate && superBonusEndDate < currentDate;
    const isCollectThemAllStarted = superBonusStartDate && superBonusStartDate < currentDate;
    // Collect them all activity record
    if (!!collectThemAllSuperBonusKey && !isCollectThemAllEnded && base_extra_data?.is_collect_them_all) {
      this.props.history.push('/collect-them-all/' + collectThemAllSuperBonusKey);
    }

    // Collect them all related record
    const isRewardedBetweenCollectThemAll =
      activityRewardedDate &&
      superBonusStartDate &&
      superBonusEndDate &&
      activityRewardedDate >= superBonusStartDate &&
      activityRewardedDate <= superBonusEndDate;

    if (!!collectThemAllSuperBonusKey && isRewardedBetweenCollectThemAll && !isCollectThemAllEnded && isCollectThemAllStarted) {
      this.props.history.push('/collect-them-all/' + collectThemAllSuperBonusKey);
    }
  };

  private startDigitalRevealGame = (token?: string) => {
    this.props.startGame(token || '');
  };

  private endDigitalRevealGame = async (token?: string) => {
    await this.props.endGame(token || '');

    setTimeout(() => {
      const refresh = async () => {
        await Promise.all([
          this.props.fetchPlayableDigitalRevealGames(DEFAULT_PAGE),
          this.props.fetchUserDrawsHistory(DEFAULT_PAGE, this.state.usePromotionService),
          this.props.fetchUserActivitiesHistory(DEFAULT_PAGE, DEFAULT_ACTIVITIES_PER_PAGE)
        ]);
      };
      refresh();
    }, 1000);
  };

  private renderFilterSection = (): React.ReactNode => {
    const filters = this.props.historyData.filters;
    if (filters === null) {
      return null;
    }

    return (
      <FilterSection
        entityName="history"
        title="Filter History"
        filterTitle="Filter History"
        filters={filters}
        onOk={this.onFilterDone}
        onRemoveSelected={this.onRemoveSelectedFilter}
        onlyfilter={true}
      />
    );
  };
  private renderFilterSelectedSection = (): React.ReactNode => {
    const filters = this.props.historyData.filters;
    if (filters === null) {
      return null;
    }

    return <SelectedFiltersSection filters={filters} entityName="history" onRemoveSelected={this.onRemoveSelectedFilter} />;
  };
  private getFilterDateValue = filters => {
    if (filters !== null && filters[DateFilterIndex].filters.filter(x => x.isSelected).length > 0) {
      const filterSelected = filters[DateFilterIndex].filters.filter(x => x.isSelected)[0];
      if (!!filterSelected.customDateRangeStart && !!filterSelected.customDateRangeEnd) {
        return {
          startDate: filterSelected.customDateRangeStart,
          endDate: filterSelected.customDateRangeEnd
        };
      }
    }
    return {
      startDate: null,
      endDate: null
    };
  };

  private onFilterDone = async (filters: IFilters) => {
    this.setState({ loading: true });
    await this.props.replaceFilers(filters);
    await this.getActivitiesData(DEFAULT_PAGE, DEFAULT_ACTIVITIES_PER_PAGE, true);
    await this.getTicketsData(DEFAULT_PAGE, true);
    await this.getRewardsData(DEFAULT_PAGE, constants.HISTORY_ITEMS_PER_PAGE);
    this.filterChanged(filters);
    this.setState({ loading: false });
  };

  private filterChanged = (filters: IFilters) => {
    let showIndicator = false;
    if (!filters) {
      return;
    }

    Object.keys(filters).forEach(key => {
      // @ts-ignore
      showIndicator = showIndicator || filters[key].filters.filter(x => x.isSelected).length > 0;
    });
    // this.setState({ hidePagination: showIndicator });
  };

  private onRemoveSelectedFilter = (entityName: string, sectionKey: string, filterKey: string) => {
    this.props.toggleFilter(entityName, sectionKey, filterKey);
    this.setState({ hidePagination: false });
    this.props.fetchUserActivitiesHistory(DEFAULT_PAGE, DEFAULT_ACTIVITIES_PER_PAGE);
    this.getRewardsData(DEFAULT_PAGE, constants.HISTORY_ITEMS_PER_PAGE);
    this.props.fetchUserTicketEntriesHistory({ page: DEFAULT_PAGE });
    this.props.resetPaging();
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withRouter(withTheme(withStyles(styles)(withTranslation()(HistoryScreen)))));
