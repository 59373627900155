import { makeStyles, Step, StepLabel, Stepper, WithStyles, withStyles, withTheme } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { StepperSteps } from '@pbl/pbl-react-core/lib/models/user-registration/types';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/user-registration/UserRegistrationScreenStyle';
import clsx from 'clsx';
import React from 'react';

interface IProps extends WithStyles<typeof styles>, ThemedComponentProps {
  onPress: (position: StepperSteps) => void;
  activeStep: StepperSteps;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 10
  },
  active: {
    '& $line': {
      backgroundColor:  colors.themeColors.primaryColor.hex()
    }
  },
  completed: {
    '& $line': {
      backgroundColor:  colors.themeColors.primaryColor.hex()
    }
  },
  line: {
    height: '3px',
    backgroundColor: colors.themeColors.white.hex()
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: colors.themeColors.grayColor.hex(),
    zIndex: 1,
    color: colors.themeColors.white.hex(),
    width: 22,
    height: 22,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: colors.themeColors.primaryColor.hex()
  },
  completed: {
    backgroundColor: colors.themeColors.primaryColor.hex(),
    backgroundImage: colors.themeColors.primaryColor.hex()
  }
});

const ColorlibStepIcon = props => {
  const clsxSteps = useColorlibStepIconStyles();
  const { active, completed } = props;
  const stepChecks = { 1: '1', 2: '2', 3: '3' };

  return (
    <div
      className={clsx(clsxSteps.root, {
        [clsxSteps.active]: active,
        [clsxSteps.completed]: completed
      })}
    >
      {stepChecks[String(props.icon)]}
    </div>
  );
};

const getSteps = (): string[] => ['', '', ''];

const RegistrationStepper: React.FunctionComponent<IProps> = ({ activeStep, onPress, classes }: IProps) => {
  const steps = getSteps();
  return (
    <div>
      <Stepper alternativeLabel={true} activeStep={activeStep} className={classes.stepper} connector={<ColorlibConnector />}>
        {steps.map((label, index) => {
          const props = {};
          const buttonProps = {};
          const onButtonPress = () => {
            onPress(index as StepperSteps);
          };
          return (
            <Step onClick={onButtonPress} key={index} {...props}>
              <StepLabel {...buttonProps} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default withTheme(withStyles(styles)(RegistrationStepper));
