import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { IGame } from '@pbl/pbl-react-core/lib/models/games/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import GameDetails from '@pbl/pbl-react-web-components/lib/games/GameDetails';
import GameTabs from '@pbl/pbl-react-web-components/lib/games/GameTabs';
import RelatedGames from '@pbl/pbl-react-web-components/lib/games/RelatedGames';
import RelatedGamesDialog from '@pbl/pbl-react-web-components/lib/games/RelatedGamesDialog';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import styles from 'assets/jss/modules/games/GamesScreenStyle';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import { selectPromotion } from 'redux/reducers/draws/actions';
import {
  fetchGame,
  fetchPrizes,
  fetchPromotionsForGame,
  fetchRelatedGames,
  getAllRelatedGames,
  resetGameState
} from 'redux/reducers/games/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  gameId: string;
}

export interface IGameDetailScreenProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    WithStyles<typeof styles>,
    RouteComponentProps<IMatchParams> {
  theme: Theme;
}

interface IGameDetailScreenState {
  showModal: boolean;
  gameId: number;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ games: { selectedGame, loading, relatedGames, allRelatedGames, gamePrizes, draws, drawsPage } }) => ({
  selectedGame,
  loading,
  relatedGames,
  allRelatedGames,
  drawsPage,
  draws,
  gamePrizes
});

const mapDispatchToProps = {
  fetchGame,
  fetchPrizes,
  fetchPromotionsForGame,
  fetchRelatedGames,
  getAllRelatedGames,
  resetGameState,
  showMessageBar,
  selectPromotion
};

class GameDetailScreen extends React.Component<IGameDetailScreenProps, IGameDetailScreenState> {
  constructor(props: IGameDetailScreenProps) {
    super(props);
    this.state = {
      showModal: false,
      gameId: 0
    };
  }

  public componentDidMount = async () => {
    document.title = 'Game Details';
    const {
      match: { params }
    } = this.props;
    if (params.gameId.length > 0) {
      scrollToTheTop();
      await this.loadGame(params.gameId);
    } else {
      await this.showInvalidGameError();
    }
  };

  public componentWillUnmount = async () => {
    await this.props.resetGameState();
  };

  public selectGame = async (game: IGame) => {
    scrollToTheTop();

    this.props.history.push(`/games/${game.id}`);
    await this.props.resetGameState();
    await this.loadGame(game.id);
  };

  public render(): React.ReactNode {
    const { classes, history, location, t } = this.props;
    const { selectedGame, loading, relatedGames, allRelatedGames } = this.props;
    const onNavigate = () => {
      history.push('/games');
    };
    const navigateToTicketEntry = () => {
      history.push('/ticket-entry', { from: location.pathname });
    };
    if (!selectedGame || loading) {
      return <AppSpinner label={'Loading Game...'} />;
    }
    return (
      <div className={classes.root}>
        <Grid container={true}>
          {loading && <AppSpinner label={'Loading Game...'} />}
          <RelatedGamesDialog
            title={'Related Games'}
            relatedGames={allRelatedGames}
            selectGame={this.selectGame}
            handleClose={this.onModalClose}
            openDialog={this.state.showModal}
          />
          <DetailTitle
            title={selectedGame.name}
            linkText={'Games'}
            navigateTo={onNavigate}
            buttonText={'Enter Now'}
            description={t('games.topPrizeType.' + selectedGame.topPrizeType) + selectedGame.getTopPrize()}
            onPress={navigateToTicketEntry}
          />
          <GameDetails selectedGame={selectedGame} />
          <GameTabs
            prizes={this.props.gamePrizes}
            draws={this.props.draws}
            drawsPage={this.props.drawsPage}
            onDrawClicked={this.onDrawClicked}
            getNextPage={this.getNextDrawsPage}
          />
          <RelatedGames
            relatedGames={relatedGames}
            selectGame={this.selectGame}
            showRelatedGames={this.onModalOpen}
            title={this.props.t('gameDetails.relatedGamesTitle')}
          />
        </Grid>
      </div>
    );
  }

  private loadGame = async gameId => {
    await this.setState({ gameId });
    await this.props.fetchGame(gameId);
    await this.props.fetchPrizes(gameId);
    await this.props.fetchPromotionsForGame(gameId, 0);
    await this.props.fetchRelatedGames(gameId);
    await this.props.getAllRelatedGames(gameId);
  };

  public getNextDrawsPage = async (pageNumber: number) => {
    await this.props.fetchPromotionsForGame(this.state.gameId, pageNumber);
  };

  private showInvalidGameError = async () => {
    await this.navigateToGames({
      message: `This game is not available.`,
      type: 'warning',
      messageTimeout: 3000
    });
  };

  private navigateToGames = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/games', { message });
  };
  private onModalOpen = () => {
    this.setState({ showModal: true });
  };
  private onModalClose = () => {
    this.setState({ showModal: false });
  };

  // @ts-ignore
  private onDrawClicked = (promotion: IPromotion): void => {
    // this.props.selectPromotion(promotion);
    this.props.history.push('/promotion/' + promotion.id);
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTheme(withTranslation()(withRouter(withStyles(styles)(GameDetailScreen)))));
