import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flexGrow: 1,
      position: 'relative',
      minWidth: '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    page: {
      background: 'transparent',
      flex: '1 0 auto',
      minWidth: '100%',
      padding: 0,
      margin: 0,
      flexGrow: 1,
      order: 1,
      minHeight: '50%'
    }
  });

export default styles;
