import { GET_INFORMATION_ONLY_TILE_DETAILS, GET_INFORMATION_ONLY_TILE_LIST, IInformationOnlyTile, InformationOnlyTileState } from '@pbl/pbl-react-core/lib/models/information-only-tile/types';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';

const INITIAL_STATE: InformationOnlyTileState = {
  success: false,
  loading: false,
  failure: {},
  informationOnlyTiles: [] as IInformationOnlyTile[],
  informationOnlyTileDetails: {} as IInformationOnlyTile
};

export default (state: InformationOnlyTileState = INITIAL_STATE, action): InformationOnlyTileState => {
  switch (action.type) {
    case REQUEST(GET_INFORMATION_ONLY_TILE_LIST):
    case REQUEST(GET_INFORMATION_ONLY_TILE_DETAILS):
      return {
        ...state,
        loading: true,
        informationOnlyTileDetails: {} as IInformationOnlyTile
      };
    case SUCCESS(GET_INFORMATION_ONLY_TILE_LIST):
      return {
        ...state,
        success: true,
        loading: false,
        failure: {},
        informationOnlyTiles: action.payload
      };
    case SUCCESS(GET_INFORMATION_ONLY_TILE_DETAILS):
      return {
        ...state,
        success: true,
        loading: false,
        failure: {},
        informationOnlyTileDetails: action.payload
      };
    case FAILURE(GET_INFORMATION_ONLY_TILE_LIST):
    case FAILURE(GET_INFORMATION_ONLY_TILE_DETAILS):
    return {
      ...state,
      loading: false,
      failure: action.payload && action.payload.response && action.payload.response.data ? action.payload.response.data : action.payload,
      informationOnlyTiles: []
    };
    default:
      return state;
  }
};
