import { createStyles, Theme } from '@material-ui/core';

import { container } from 'assets/jss/material-kit-pro-react.jsx';

export default (theme: Theme) =>
  createStyles({
    container,
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center'
    },
    forgot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing()
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '100%',
      marginBottom: theme.spacing(3)
    },
    register: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4)
    },
    primaryText: {
      color: theme.palette.primary.main
    },
    buttonText: {
      color: theme.palette.common.white
    },
    description: {
      maxWidth: '100%'
    },
    resendButton: {
      marginTop: theme.spacing(4),
      width: '80%'
    }
  });
