import { CLAIM_FREE_ENTRY, IDrawPage } from '@pbl/pbl-react-core/lib/models/draws/types';
import {
  FETCH_ENTRIES,
  FETCH_FREE_ENTRIES,
  GET_ACTIVE_DRAW,
  GET_ALL_DRAWS,
  GET_REWARD,
  GET_REWARD_LIST,
  GET_REWARD_WITH_FREQUENCY_CAP,
  IRewardInitialState,
  REDEEM_REWARD,
  RESET_REWARD,
  RewardState
} from '@pbl/pbl-react-core/lib/models/reward/types';
import moment from 'moment-timezone';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IRewardInitialState = {
  loading: false,
  rewardsList: [],
  allDraws: [],
  selectedReward: null,
  activeDraw: null,
  page: {} as IDrawPage,
  errorMessage: null,
  entries: 0,
  freeEntries: 0,
  freeEntriesRequestSubmission: null
};

export default (state: RewardState = INITIAL_STATE, action: IDispatchAction): RewardState => {
  switch (action.type) {
    case REQUEST(GET_REWARD):
    case REQUEST(GET_REWARD_WITH_FREQUENCY_CAP):
    case REQUEST(GET_ACTIVE_DRAW):
    case REQUEST(GET_REWARD_LIST):
    case REQUEST(GET_ALL_DRAWS):
    case REQUEST(FETCH_ENTRIES):
    case REQUEST(FETCH_FREE_ENTRIES):
    case REQUEST(REDEEM_REWARD):
    case REQUEST(CLAIM_FREE_ENTRY):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_REWARD):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedReward: action.payload
      };
    case SUCCESS(GET_REWARD_WITH_FREQUENCY_CAP):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedReward: action.payload
      };
    case SUCCESS(GET_ACTIVE_DRAW):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activeDraw: action.payload
      };
    case SUCCESS(GET_REWARD_LIST):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        rewardsList: action.payload
      };
    case SUCCESS(GET_ALL_DRAWS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allDraws: action.payload.content,
        page: action.payload.page
      };
    case SUCCESS(FETCH_FREE_ENTRIES):
      return {
        ...state,
        loading: false,
        freeEntries: action.payload
      };
    case SUCCESS(REDEEM_REWARD):
      return {
        ...state,
        loading: false
      };
    case SUCCESS(CLAIM_FREE_ENTRY):
      return {
        ...state,
        loading: false,
        freeEntriesRequestSubmission: moment()
      };
    case RESET_REWARD:
      return {
        ...INITIAL_STATE
      };
    case FETCH_ENTRIES:
      return {
        ...state,
        loading: false,
        entries: action.payload
      };
    case FAILURE(GET_REWARD):
    case FAILURE(GET_REWARD_WITH_FREQUENCY_CAP):
    case FAILURE(GET_ACTIVE_DRAW):
    case FAILURE(GET_REWARD_LIST):
    case FAILURE(GET_ALL_DRAWS):
    case FAILURE(FETCH_ENTRIES):
    case FAILURE(FETCH_FREE_ENTRIES):
    case FAILURE(REDEEM_REWARD):
    case FAILURE(CLAIM_FREE_ENTRY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
