import { createStyles, Theme } from '@material-ui/core';
import color from 'color';

import { container } from 'assets/jss/material-kit-pro-react.jsx';

export default (theme: Theme) =>
  createStyles({
    container,
    resendActivationScreen: {
      backgroundColor: color(theme.palette.primary.main)
        .alpha(0.37)
        .rgb()
        .string(),
      height: '100%'
    },
    resendActivationCard: {
      marginTop: theme.spacing(4)
    },
    resendActivationCardContent: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing()
      }
    },
    resendActivationCardContentInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(2)
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(5)
      }
    },
    titleText: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 24,
        marginBottom: 16
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
        marginBottom: 32
      }
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(3)
    },
    closeText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center'
    },
    primaryText: {
      color: theme.palette.primary.main
    },
    buttonText: {
      color: theme.palette.common.white
    },
    linkButton: {
      marginBottom: theme.spacing(2),
      width: '60%'
    },
    mailIcon: {
      fontSize: '120px'
    }
  });
