import {
  AddressState,
  GET_CITIES,
  GET_COUNTRIES,
  GET_STATES,
  INITIAL_SCREEN_STATE,
  RESET_CITIES,
  RESET_STATES
} from '@pbl/pbl-react-core/lib/models/address';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

export default (state: AddressState = INITIAL_SCREEN_STATE, action: IDispatchAction): AddressState => {
  switch (action.type) {
    case REQUEST(GET_CITIES):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_COUNTRIES):
      return {
        ...state,
        countries: action.payload
      };
    case SUCCESS(GET_CITIES):
      return {
        ...state,
        loading: false,
        cities: action.payload
      };
    case SUCCESS(GET_STATES):
      return {
        ...state,
        states: action.payload,
        cities: []
      };
    case FAILURE(GET_COUNTRIES):
    case FAILURE(GET_STATES):
    case FAILURE(GET_CITIES):
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case RESET_CITIES:
      return {
        ...state,
        cities: []
      };
    case RESET_STATES:
      return {
        ...state,
        states: [],
        cities: []
      };
    default:
      return state;
  }
};
