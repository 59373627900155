import { Badge, Button, ClickAwayListener, Grow, List, ListItem, MenuItem, MenuList, Paper, Popper, Tooltip, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FeatureFlag } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/shared/components/menu/AppBarStyle';
import classNames from 'classnames';
import { RemoteConstants } from 'config/constants';
import { IMenuItem, INavItem } from 'config/menu';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface IMainMenuProps {
  mainMenu: INavItem[];
  isAuthenticated: boolean;
  authorizedFlags: string[];
  storeItems: number;
}

interface IProps extends IMainMenuProps, WithStyles<typeof styles>, RouteComponentProps {}

const MainMenu: React.FunctionComponent<IProps> = ({ mainMenu, classes, isAuthenticated, history, authorizedFlags, storeItems }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [gameSelected, setGameSelected] = React.useState(false);

  const navigate = (item: INavItem, isGameMenu) => {
    // @ts-ignore
    mainMenu.forEach(m => (m.isActive = !isGameMenu));
    setGameSelected(isGameMenu);
    history.push(item.route || '/');
  };

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setMenuOpen(false);
  };

  const handleMenuItemClick = (item, subMenuItem) => {
    item.isActive = true;
    navigate(subMenuItem, true);
    setMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const enableIfReloadedAndGameIsSelected = () => {
    if (history.location.pathname.indexOf('games/types') !== -1 && !gameSelected) {
      setGameSelected(true);
    }
  };

  const filterSubMenu = (subMenu: IMenuItem) => {
    if (subMenu.key === 'quick-draw') {
      return (authorizedFlags.includes('QUICKDRAW_V1'));
    }
    return true;
  };

  const renderMenuItem = (navItem: INavItem) => {
    const renderContent = (item: INavItem) => {
      const isGameDropdownEnabled = RemoteConstants.getBoolean('ENABLE_GAME_DROPDOWN');
      if (item.subMenu && isGameDropdownEnabled) {
        enableIfReloadedAndGameIsSelected();
        const menuItems = item.subMenu
          .filter(filterSubMenu)
          .map(s => (
            <MenuItem key={s.key} onClick={handleMenuItemClick.bind(this, item, s)}>
              <Tooltip title={s.title} disableFocusListener={true}>
              <Typography variant="body2">
              {s.title}
              </Typography>
              </Tooltip>
            </MenuItem>
            ));
        return (
          <ListItem className={item.showMobile ? classes.listItemMobile : classes.listItem} key={`${item.title}`} role={'menuitem'}>
            <Tooltip title={item.title} disableFocusListener={true}>
              <Button
                ref={anchorRef}
                aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color={item.iconColor ? item.iconColor : 'inherit'}
                className={classNames({
                  [classes.navLink]: true,
                  [classes.navLinkActive]: gameSelected
                })}
              >
                <Badge
                  badgeContent={menuItems.length ? menuItems.length : undefined}
                  invisible={!item.showBadge || !storeItems}
                  classes={{ badge: menuOpen ? classes.badgeInActive : classes.badgeActive }}
                >
                  <Icon className={classNames(item.icon, classes.navLinkIcon)}>{item.materialUiIcon ? item.icon : ' '}</Icon>
                </Badge>
                <span className={classes.navLinkText}>{item.title}</span>
                <ArrowDropDownIcon />
              </Button>
            </Tooltip>
            <Popper open={menuOpen} anchorEl={anchorRef.current} role={undefined} transition={true} disablePortal={true}>
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList role="button" aria-label={`Menu with ${menuItems.length} items`} autoFocusItem={menuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        {menuItems}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ListItem>
        );
      } else {
        const isLuckyLounge = window.location.pathname.includes('lucky-lounge');
        return (
          <MenuItem className={item.showMobile ? classes.listItemMobile : classes.listItem} key={`${item.title}-${item.route}`}>
            <Tooltip title={item.title} disableFocusListener={true}>
              <Button
                onClick={navigate.bind(this, item, false)}
                color={item.iconColor ? item.iconColor : 'inherit'}
                className={classNames({
                  [classes.navLink]: true,
                  [classes.navLinkActive]: !!item.isActive && !gameSelected && !isLuckyLounge,
                  [classes.navLinkActiveLL]: !!item.isActive && !gameSelected && isLuckyLounge
                })}
              >
                <Badge
                  badgeContent={storeItems > 0 ? storeItems : undefined}
                  invisible={!item.showBadge || !storeItems}
                  classes={{ badge: item.isActive ? classes.badgeInActive : classes.badgeActive }}
                >
                  <Icon className={classNames(item.icon, classes.navLinkIcon)}>{item.materialUiIcon ? item.icon : ' '}</Icon>
                </Badge>
                <span className={classes.navLinkText}>{item.title}</span>
              </Button>
            </Tooltip>
          </MenuItem>
        );
      }
    };

    return (
      <FeatureFlag
        key={navItem.key}
        flags={[navItem.feature ? navItem.feature : 'DEFAULT']}
        renderOff={
          navItem.featurePlaceholder
            ? !isAuthenticated && navItem.featurePlaceholder.hideOnUnAuth
              ? null
              : renderContent(navItem.featurePlaceholder)
            : null
        }
        authorizedFlags={authorizedFlags}
      >
        {renderContent(navItem)}
      </FeatureFlag>
    );
  };
  const menu = isAuthenticated ? mainMenu.filter(x => !x.hideOnAuth) : mainMenu.filter(x => !x.hideOnUnAuth);
  return <List className={classes.list} role="menu">{menu.map(item => renderMenuItem(item))}</List>;
};
export default withRouter(withStyles(styles)(MainMenu));
