import { createStyles, Theme } from '@material-ui/core';
import color from 'color';

import colors from 'assets/jss/colors';

export default (theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3),
      borderRadius: 5,
      backgroundColor: color(theme.palette.common.black)
        .alpha(0.04)
        .rgb()
        .string()
    },
    loadingBox: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: 250
    },
    icon: {
      fontSize: 70,
      alignSelf: 'center'
    },
    iconSuccess: {
      color: colors.themeColors.successColor.hex()
    },
    iconError: {
      color: colors.themeColors.dangerColor.hex()
    },
    gotoText: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: 'center'
    }
  });
