import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  AuthState,
  CLEAR_AUTHENTICATION_ERROR,
  GET_ACCOUNT,
  GET_USER,
  IAuthState,
  LOGIN,
  RESET_AUTHENTICATION,
  SEND_ACCOUNT_ACTIVATION_INSTRUCTIONS,
  SEND_PASSWORD_RESET_INSTRUCTIONS
} from '@pbl/pbl-react-core/lib/models/authentication';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IAuthState = {
  authenticating: false,
  loading: false,
  accessToken: null,
  refreshToken: null,
  account: null,
  errorMessage: '',
  XSRF_TOKEN: null,
  email: '',
  password: '',
  userData: {},
  showSuspendMessage: false
};

const AuthReducer = (state: AuthState = INITIAL_STATE, action: IDispatchAction): AuthState => {
  switch (action.type) {
    case REQUEST(LOGIN):
      return {
        ...state,
        authenticating: true
      };
    case REQUEST(GET_ACCOUNT):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(LOGIN):
      return {
        ...state,
        loading: false,
        authenticating: false,
        accessToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        showSuspendMessage: false
      };
    case SUCCESS(GET_ACCOUNT): {
      return {
        ...state,
        loading: false,
        account: action.payload
      };
    }
    case FAILURE(LOGIN):
      return {
        ...state,
        errorMessage: action.payload.message,
        authenticating: false,
        showSuspendMessage:
          action.payload.payload &&
          action.payload.payload.additionalInformation &&
          action.payload.payload.additionalInformation.message === 'error.uaaAuthentication.suspended'
      };
    case FAILURE(GET_ACCOUNT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case SEND_PASSWORD_RESET_INSTRUCTIONS:
      return {
        ...state
      };
    case SEND_ACCOUNT_ACTIVATION_INSTRUCTIONS:
      return {
        ...state
      };
    case RESET_AUTHENTICATION:
      return {
        ...INITIAL_STATE
      };
    case CLEAR_AUTHENTICATION_ERROR:
      return {
        ...state,
        errorMessage: '',
        showSuspendMessage: false
      };
    case GET_USER:
      return {
        ...state,
        userData: action.payload
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'authentication',
  storage,
  blacklist: ['authenticating', 'loading', 'errorMessage', 'XSRF_TOKEN', 'email', 'password']
};

export default persistReducer(persistConfig, AuthReducer);
