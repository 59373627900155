import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';
import color from 'color';

const styles = (theme: Theme) =>
  createStyles({
    container,
    gridContainer: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        paddingTop: 0
      }
    },
    centered: {
      display: 'flex',
      justifyContent: 'center'
    },
    buttonPts: {
      height: 8.2,
      width: 17.8
    },
    logo: {
      width: 150,
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3)
      }
    },
    header: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between'
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    balance: {
      position: 'absolute',
      right: 0,
      [theme.breakpoints.down('sm')]: {
        position: 'initial'
      }
    },
    signUpLogoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    signUpLogo: {
      height: 130,
      marginTop: theme.spacing(6),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.12)'
      }
    },
    backButton: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    detailContainer: {
      minHeight: '600px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      height: 'calc(100vh - 90px)',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 10px)'
      }
    },
    detailHeader: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    gradientHeader: {
      background: color(theme.palette.common.black).alpha(0.7).rgb().string(),
      boxShadow: '0px 22px 100px black',
      height: 96,
      [theme.breakpoints.down('sm')]: {
        height: 200
      }
    },
    tabs: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
      }
    },
    tab: {
      marginRight: theme.spacing(2),
      minWidth: 180,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        minWidth: '48%'
      }
    },
    spaceBetween: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    virtualTour: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    exploreLLBtn: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: theme.spacing(3)
      }
    },
    playLLBtn: {
      display: 'flex',
      width: '100%',
      justifyContent: 'end'
    },
    image: {
      width: '100%'
    },
    exploreTitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    exploreContent: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4)
    }
  });

export default styles;
