import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { CLEAR, REPLACE, UPDATE } from './filters.reducers-enhancer';

export const toggleFilter = (entityName: string, sectionKey: string, filterKey: string) => (dispatch: any) => {
  dispatch({
    type: UPDATE(entityName),
    payload: {
      sectionKey,
      filterKey
    }
  });
};

export const clearFilter = (entityName: string) => (dispatch: any) => {
  dispatch({
    type: CLEAR(entityName)
  });
};

export const replaceFilers = (entityName: string, filters: IFilters) => (dispatch: any) => {
  dispatch({
    type: REPLACE(entityName),
    payload: filters
  });
};
