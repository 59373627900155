import {
  DEBIT_ARCADE,
  END_DIGITAL_REVEAL_ARCADE,
  END_NGL_GAME,
  END_NGL_GAME_FUN,
  GET_ARCADE,
  GET_ARCADE_DENOMINATIONS,
  GET_ARCADE_GAMES,
  GET_DIGITAL_REVEAL_ARCADE,
  GET_NGL_GAME_DATA,
  PLAY_FOR_FUN,
  RESET_ARCADE,
  RESET_STATE,
  SET_NGL_TOKEN,
  START_NGL_GAME,
  START_NGL_GAME_FUN,
  START_NGL_GAME_SESSION,
  START_NGL_GAME_SESSION_FUN,
  UNAUTHENTICATED_USER_GAME_DATA
} from '@pbl/pbl-react-core/lib/models/arcades/types';
import { IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import { GameVendor } from '@pbl/pbl-react-core/lib/models/games/types';
import { ArcadeService, GamesService, InteractiveGameService, NGLService, SweepstakesService } from '@pbl/pbl-react-core/lib/services';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { GetStateMethod } from 'redux/reducers';
import { clearMessages, showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { fetchUserTokens } from 'redux/reducers/ledger/actions';
import * as FilterActions from 'shared/components/filters/filters.actions';

const dispatchNGLSuccessOrError = (response: any, action: string, payload: any, dispatch: any) => {
  if (!response?.error) {
    dispatch({
      type: SUCCESS(action),
      payload
    });
  } else {
    dispatch({
      type: FAILURE(action),
      payload: payload.error
    });
  }
};

export const fetchArcadeGames = (sort?: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_ARCADE_GAMES,
      payload: ArcadeService.getArcadeGames(sort)
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchArcadeGame = (gameId: string) => async (dispatch: any) => {
  try {
    const id = parseInt(gameId, 10);
    dispatch({
      type: REQUEST(GET_ARCADE)
    });
    const payload = await GamesService.getPublishedGame(id);
    dispatch({
      type: SUCCESS(GET_ARCADE),
      payload
    });
    if (payload.vendor === GameVendor.PBL) {
      await dispatch(fetchDenominations(payload.gameId));
    } else {
      await dispatch(getNGLGameData(payload.gameId));
    }
  } catch (error) {
    dispatch({
      type: FAILURE(GET_ARCADE),
      payload: error
    });
    console.error(error);
  }
};

export const debitArcadeGame = (denomination: number, gameId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(DEBIT_ARCADE)
    });
    const payload = await SweepstakesService.debitArcade(denomination, gameId);
    dispatch({
      type: SUCCESS(DEBIT_ARCADE),
      payload
    });
    await dispatch(fetchUserTokens());
    if (payload.prizes && payload.prizes.length > 0 && payload.prizes[0].digitalRevealToken) {
      await dispatch(getDigitalArcadeGameData(payload.prizes[0].digitalRevealToken));
    } else {
      dispatch(showMessageBar({ message: 'Error loading game, please try again', type: 'error' }));
    }
  } catch (error) {
    dispatch({
      type: FAILURE(DEBIT_ARCADE),
      payload: error
    });
    console.error(error);
  }
};

export const getDigitalArcadeGameData = (token: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_DIGITAL_REVEAL_ARCADE,
      payload: SweepstakesService.getArcadeGameData(token)
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchDenominations = (gameId: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_ARCADE_DENOMINATIONS,
      payload: SweepstakesService.getDenominations(gameId)
    });
  } catch (error) {
    console.error(error);
  }
};

export const getNGLGameData = (gameId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(GET_NGL_GAME_DATA)
    });
    const payload = await NGLService.getNGLGameData(gameId);
    dispatch({
      type: SUCCESS(GET_NGL_GAME_DATA),
      payload
    });
  } catch (error) {
    dispatch({
      type: FAILURE(GET_NGL_GAME_DATA),
      payload: error
    });
    console.error(error);
  }
};

export const getNGLToken = (sendToNGL: boolean) => async (dispatch: any) => {
  try {
    await dispatch(isAuthenticated());
    dispatch({
      type: REQUEST(SET_NGL_TOKEN)
    });
    const tokenResponse = await NGLService.getPBLToken();
    if (tokenResponse && sendToNGL) {
      await NGLService.setNGLToken(tokenResponse.subjectToken);
    }
    dispatch({
      type: SUCCESS(SET_NGL_TOKEN),
      payload: tokenResponse
    });
  } catch (error) {
    dispatch({
      type: FAILURE(SET_NGL_TOKEN),
      payload: error
    });
    console.error(error);
  }
};

export const startNGLGameSession = (gameId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(START_NGL_GAME_SESSION)
    });

    const payload = await NGLService.startNGLGameSession(gameId);
    dispatchNGLSuccessOrError(
      payload,
      START_NGL_GAME_SESSION,
      {
        clientRequestToken: payload.clientRequestToken,
        clientSessionId: payload.clientSessionId
      },
      dispatch
    );
  } catch (error) {
    dispatch({
      type: FAILURE(START_NGL_GAME_SESSION),
      payload: error
    });
    console.error(error);
  }
};

export const startNGLGame = (gameId: string, wager: number) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    dispatch({
      type: REQUEST(START_NGL_GAME)
    });
    const {
      arcades: { sessionDataNGL }
    } = getState();
    if (!sessionDataNGL) {
      dispatch({
        type: FAILURE(START_NGL_GAME),
        payload: 'No Session found for the game.'
      });
      return;
    }
    const payload = await NGLService.startNGLGame(gameId, wager, sessionDataNGL.clientSessionId, sessionDataNGL.clientRequestToken);

    dispatchNGLSuccessOrError(payload, START_NGL_GAME, payload, dispatch);
  } catch (error: any) {
    // if session has expired get new token and send it to NGL
    if (error && error.payload && error.payload.status === 403) {
      await dispatch(getNGLToken(true));
      await dispatch(startNGLGame(gameId, wager));
    } else {
      dispatch({
        type: FAILURE(START_NGL_GAME),
        payload: error
      });
    }
  }
};

export const endNGLGame = (gameId: string) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      arcades: { sessionDataNGL }
    } = getState();
    if (!sessionDataNGL) {
      dispatch({
        type: FAILURE(END_NGL_GAME),
        payload: 'No Session found for the game.'
      });
      return;
    }
    await dispatch({
      type: END_NGL_GAME,
      payload: NGLService.endNGLGame(gameId, sessionDataNGL.clientSessionId, sessionDataNGL.clientRequestToken)
    });
  } catch (error) {
    console.error(error);
  }
};

export const startNGLGameSessionFun = (gameId: string) => async (dispatch: any, getState: GetStateMethod) => {
  const {
    arcades: { sessionDataNGLFun }
  } = getState();
  if (!sessionDataNGLFun) {
    try {
      dispatch({
        type: REQUEST(START_NGL_GAME_SESSION_FUN)
      });
      const payload = await NGLService.startNGLGameSession(gameId, true);

      dispatchNGLSuccessOrError(
        payload,
        START_NGL_GAME_SESSION_FUN,
        {
          clientRequestToken: payload.clientRequestToken,
          clientSessionId: payload.clientSessionId
        },
        dispatch
      );
    } catch (error) {
      dispatch({
        type: FAILURE(START_NGL_GAME_SESSION_FUN),
        payload: error
      });
      console.error(error);
    }
  }
};

export const startNGLGameFun = (gameId: string, wager: number) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      arcades: { sessionDataNGLFun }
    } = getState();
    if (!sessionDataNGLFun) {
      dispatch({
        type: FAILURE(START_NGL_GAME_FUN),
        payload: 'No Session found for the game.'
      });
      return;
    }
    const payload = await NGLService.startNGLGame(
      gameId,
      wager,
      sessionDataNGLFun.clientSessionId,
      sessionDataNGLFun.clientRequestToken,
      true
    );

    dispatchNGLSuccessOrError(payload, START_NGL_GAME_FUN, payload, dispatch);
  } catch (error) {
    console.error(error);
  }
};

export const endNGLGameFun = (gameId: string) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      arcades: { sessionDataNGLFun }
    } = getState();
    if (!sessionDataNGLFun) {
      dispatch({
        type: FAILURE(START_NGL_GAME_FUN),
        payload: 'No Session found for the game.'
      });
      return;
    }
    await dispatch({
      type: END_NGL_GAME_FUN,
      payload: NGLService.endNGLGame(gameId, sessionDataNGLFun.clientSessionId, sessionDataNGLFun.clientRequestToken, true)
    });
  } catch (error) {
    console.error(error);
  }
};

export const endDigitalArcadeGameData = (token: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: END_DIGITAL_REVEAL_ARCADE,
      payload: InteractiveGameService.endGame(token)
    });
  } catch (error) {
    console.error(error);
  }
};

export const resetGame = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: RESET_ARCADE
  });
};

export const playForFun = (gameId: string) => async (dispatch: any) => {
  try {
    const payload = await SweepstakesService.playForFun(gameId);
    dispatch({
      type: SUCCESS(PLAY_FOR_FUN),
      payload
    });
    if (payload.prizes && payload.prizes.length > 0 && payload.prizes[0].digitalRevealToken) {
      await dispatch(getDigitalArcadeGameData(payload.prizes[0].digitalRevealToken));
    } else {
      dispatch(showMessageBar({ message: 'Error loading game, please try again', type: 'error' }));
    }
  } catch (error) {
    dispatch({
      type: FAILURE(PLAY_FOR_FUN),
      payload: error
    });
    console.error('playForFun Error: ', error);
  }
};

export const toggleFilter = (entityName: string, sectionKey: string, filterKey: string) => (dispatch: any) => {
  dispatch(FilterActions.toggleFilter(entityName, sectionKey, filterKey));
};

export const clearFilter = () => (dispatch: any) => {
  dispatch(FilterActions.clearFilter('arcade'));
};

export const replaceFilters = (filters: IFilters) => (dispatch: any) => {
  dispatch(FilterActions.replaceFilers('arcade', filters));
};

export const resetState = () => (dispatch: any) => {
  dispatch({
    type: RESET_STATE
  });
};
export const setUnAuthenticatedUserGameData = (gameData: any) => (dispatch: any) => {
  dispatch({
    type: UNAUTHENTICATED_USER_GAME_DATA,
    payload: {
      gameType: gameData.gameType,
      deferredPlay: gameData.deferredPlay,
      wagerIndex: gameData.wagerIndex,
      deferredGame: gameData.deferredGame
    }
  });
};
