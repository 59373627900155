import { Button, createStyles, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reactivateAccount } from 'redux/reducers/account-reactivation/actions';
interface IReactivateProps {
    email: string;
    show: boolean;
    successCalback: () => void;
}

const styles: any = (theme: Theme) =>
    createStyles({
        dialogActions: {
            padding: theme.spacing(2)
        },
        list: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
        },
        email: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        button: {
            width: '272px',
            height: '44px'
        }
    });

const AccountReactivateDialog: React.FC<IReactivateProps> = ({ email, show, successCalback }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const useStyles = makeStyles(styles);
    const classes = useStyles(theme);
    const dispatch = useDispatch();

    const cancel = () => {
        dispatch(reactivateAccount(false));
    };

    const success = () => {
        dispatch(reactivateAccount(false));
        successCalback();
    };

    return (
        <Dialog
            aria-labelledby="Account deactivation"
            open={show}
            className={'po-widget-dialog'}
        >
            <Grid>
                <DialogTitle id="alert-dialog-slide-title" disableTypography={true}>
                    <Typography variant="h6" id="modal-title" align={'center'}>{t('account.deactivate.accountDeactivated')}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle2" id="modal-title" align={'center'}>{t('account.deactivate.deactivationReason')}</Typography>
                    <ul className={classes.list}>
                        <li>
                            <Typography variant="body2" align={'center'}>{t('account.deactivate.deactivationReason1')}</Typography>
                        </li>
                        <li>
                            <Typography variant="body2" align={'center'}>{t('account.deactivate.deactivationReason2')}</Typography>
                        </li>
                    </ul>
                    <Typography variant="subtitle1" align={'center'}>{t('account.deactivate.toReactivate')}</Typography>
                    <Typography variant="body2" align={'center'}>{t('account.deactivate.reactivationInstructions')}</Typography>
                    <Typography variant="subtitle2" align={'center'} className={classes.email} >{email}</Typography>
                    <Typography variant="body2" align={'center'}>{t('account.deactivate.cancelReactivation')}</Typography>
                </DialogContent>

                <Grid container={true} className={classes.dialogActions} direction="column" alignItems="center">
                    <Button
                        color={'primary'}
                        size={'small'}
                        variant={'contained'}
                        onClick={success}
                        className={classes.button}
                    >
                        {t('account.deactivate.yesReactivate')}
                    </Button>
                    <Button
                        color={'primary'}
                        size={'small'}
                        variant={'text'}
                        onClick={cancel}
                        className={classes.button}
                    >
                        {t('account.deactivate.cancel')}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AccountReactivateDialog;
