import { Button, createStyles, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface IReactivateProps {
    email: string;
    show: boolean;
    okayCallback: () => void;
}

const styles: any = (theme: Theme) =>
    createStyles({
        dialogActions: {
            padding: theme.spacing(2)
        },
        email: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        button: {
            width: '164px',
            height: '44px'
        }
    });

const AccountReactivateEmailSentDialog: React.FC<IReactivateProps> = ({ email, show, okayCallback }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const useStyles = makeStyles(styles);
    const classes = useStyles(theme);

    const onOkClick = () => {
        okayCallback();
    };

    return (
        <Dialog
            aria-labelledby="Account deactivation"
            open={show}
            className={'po-widget-dialog'}
        >
            <Grid>
                <DialogTitle id="alert-dialog-slide-title" disableTypography={true}>
                    <Typography variant="h6" id="modal-title" align={'center'}>{t('account.deactivate.reactivationEmailSentTitle')}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" align={'center'}>{t('account.deactivate.reactivationEmailSentDescription')}</Typography>
                    <Typography variant="subtitle2" align={'center'} className={classes.email} >{email}</Typography>
                </DialogContent>

                <Grid container={true} className={classes.dialogActions} direction="column" alignItems="center">
                    <Button
                        color={'primary'}
                        size={'small'}
                        variant={'contained'}
                        onClick={onOkClick}
                        className={classes.button}
                    >
                        {t('account.deactivate.okay')}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AccountReactivateEmailSentDialog;
