import { IFilterItem, IFilters } from '@pbl/pbl-react-core/lib/models/filters';
import _ from 'lodash';
import { IDispatchAction } from 'redux/reducers';

export interface IFiltersState {
  filters: IFilters;
}

export const UPDATE = (entityName: string) => `${entityName}/filters/UPDATE_FILTER`;
export const REPLACE = (entityName: string) => `${entityName}/filters/CLEAR_FILTER`;
export const CLEAR = (entityName: string) => `${entityName}/filters/CLEAR_FILTER`;

const processFilters = (entityName: string, state: IFiltersState, action: IDispatchAction) => {
  switch (action.type) {
    case UPDATE(entityName):
      const { sectionKey, filterKey } = action.payload;

      const section = state.filters[sectionKey] || {};
      const filters = _.reduce(
        section.filters,
        (result, f) => {
          if (f.name === filterKey) {
            f.isSelected = !f.isSelected;
            if (f.customDateFilterType === 'custom' && !f.isSelected) {
              f.customDateRangeStart = null;
              f.customDateRangeEnd = null;
            }
          }
          result.push(f);
          return result;
        },
        [] as IFilterItem[]
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          [sectionKey]: {
            ...section,
            filters
          }
        }
      };
    case REPLACE(entityName):
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    case CLEAR(entityName):
      return {
        ...state,
        filters: _.map(_.keys(state.filters), key => {
          const filterCategory = state.filters[key];
          return {
            ...filterCategory,
            filters: _.map(filterCategory.filters, f => ({ ...f, value: false }))
          };
        })
      };
    default:
      return state;
  }
};

export default { processFilters };
