import { Theme } from '@material-ui/core';
import color from 'color';
import constants from 'config/constants';
import numeral from 'numeral';
import { defaultTheme } from 'shared/theme/default';
import { sweepstakesTheme } from 'shared/theme/sweepstakes';

declare global {
  interface Window {
    mGeoJS: any;
  }
  // tslint:disable-next-line:interface-name
  interface String {
    toColorObject(): color;

    getHighEmphasis(): string;

    getMediumEmphasis(): string;

    getGradientStartColor(): string;

    getGradientEndColor(): string;

    getLowEmphasis(): string;

    getWithAlpha(alpha: number): string;

    darken(ratio: number): string;

    lighten(ratio: number): string;
  }

  // tslint:disable-next-line:interface-name
  interface Number {
    toPointsFormat(): string;
    toPointsFormatWithUnit(): string;
    toPointsFormatWithLowerCaseUnit(): string;
    getPointsUnit(): string;
    getPtsUnit(): string;
    toMoneyFormat(): string;
    toCurrencyFormat(): string;
  }
}

Number.prototype.toPointsFormat = function(): string {
  return numeral(this).format(constants.POINTS_FORMAT);
};

Number.prototype.toPointsFormatWithUnit = function(): string {
  return `${this.toPointsFormat()} ${this.getPointsUnit()}`;
};

Number.prototype.toPointsFormatWithLowerCaseUnit = function(): string {
  return this.toPointsFormatWithUnit().toLowerCase();
};

Number.prototype.getPointsUnit = function(): string {
  return `Point${this.valueOf() !== 1 ? 's' : ''}`;
};

Number.prototype.getPtsUnit = function(): string {
  return `Pt${this.valueOf() !== 1 ? 's' : ''}`;
};

Number.prototype.toCurrencyFormat = function(): string {
  return numeral(this).format(constants.CURRENCY_FORMAT);
};

String.prototype.toColorObject = function(): color {
  const value: string = this.toString();
  return color(value);
};

String.prototype.getHighEmphasis = function(): string {
  return this.toColorObject()
    .alpha(0.87)
    .rgb()
    .string();
};
String.prototype.getMediumEmphasis = function(): string {
  return this.toColorObject()
    .alpha(0.66)
    .rgb()
    .string();
};
String.prototype.getLowEmphasis = function(): string {
  return this.toColorObject()
    .alpha(0.2)
    .rgb()
    .string();
};
String.prototype.getGradientStartColor = function(): string {
  return this.toColorObject()
    .alpha(0.4)
    .rgb()
    .string();
};
String.prototype.getGradientEndColor = function(): string {
  return this.toColorObject()
    .alpha(0)
    .rgb()
    .string();
};

String.prototype.getWithAlpha = function(alpha: number): string {
  return this.toColorObject()
    .alpha(alpha)
    .rgb()
    .string();
};

String.prototype.darken = function(ratio): string {
  return this.toColorObject()
    .darken(ratio)
    .rgb()
    .string();
};

String.prototype.lighten = function(ratio): string {
  return this.toColorObject()
    .lighten(ratio)
    .rgb()
    .string();
};

const getTheme = (theme?: string): Theme => themeMap[theme ? theme : 'defaultTheme'];
const themeMap: { [key: string]: Theme } = {
  defaultTheme,
  sweepstakesTheme
};
export default getTheme;
