import { APP_SHOW_CONFETTI, ConfettiState, IConfettiState } from '@pbl/pbl-react-core/lib/models/confetti';
import { IDispatchAction } from 'redux/reducers';

const INITIAL_STATE: IConfettiState = {
  showConfetti: false
};

export default (state: ConfettiState = INITIAL_STATE, action: IDispatchAction): ConfettiState => {
  switch (action.type) {
    case APP_SHOW_CONFETTI:
      return {
        showConfetti: action.payload
      };
    default:
      return state;
  }
};
