import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RewardList } from '@pbl/pbl-react-core/lib/models/loyalty';
import { IReward, IRewardCategory } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import NoResultsFound from '@pbl/pbl-react-web-components/lib/no-results/NoResultsFound';
import FeaturedRewardHeader from '@pbl/pbl-react-web-components/lib/rewards/FeaturedRewardHeader';
import RewardSection, { IRewardSectionState } from '@pbl/pbl-react-web-components/lib/rewards/RewardSection';
import Title from '@pbl/pbl-react-web-components/lib/title/Title';
import styles from 'assets/jss/modules/rewards/RewardsScreenStyle';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  fetchRewardCategories,
  fetchRewardEntries,
  fetchRewards,
  fetchSweepstakeDraws,
  fetchUserPoints,
  fetchUserRewards,
  setSelectedReward
} from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import utils from './utils';

export interface IRewardsScreenProps extends StateProps, DispatchProps, WithStyles<typeof styles>, WithTranslation, RouteComponentProps {
  theme: Theme;
}

interface IRewardsScreenState {
  categoryStates: Map<number, IRewardSectionState>;
  loading: boolean;
}

class RewardsScreen extends React.Component<IRewardsScreenProps, IRewardsScreenState> {
  constructor(props: IRewardsScreenProps) {
    super(props);
    this.state = {
      categoryStates: new Map([]),
      loading: false
    };
  }

  public async componentDidMount() {
    document.title = 'Rewards';
    this.setState({ loading: true });
    const { location, history } = this.props;
    // @ts-ignore
    if (location && location.state && location.state.message) {
      // @ts-ignore
      this.props.showMessageBar(location.state.message);
      // Clear route state after consuming the message.
      history.replace('/rewards', {});
    }
    await this.props.fetchSweepstakeDraws({ page: 0, size: constants.PAST_REWARDS_PER_PAGE });
    await this.props.fetchRewardCategories();
    if (this.props.loggedIn) {
      await this.props.fetchUserRewards();
      await this.props.fetchUserPoints();
    } else {
      await this.props.fetchRewards();
    }

    this.createSectionStates();
    this.setState({ loading: false });
  }

  public createSectionStates() {
    const { categoryItems, rewards } = this.props;
    const categoryStates: Map<number, IRewardSectionState> = new Map([]);
    const itemsPerPage = constants.REWARDS_PER_PAGE;

    categoryItems.forEach((category: IRewardCategory) => {
      const categoryRewards: RewardList = rewards && rewards.length > 0 ? rewards.filterByCategory(category) : new RewardList();
      categoryRewards.getSortedList();
      const totalPages =
        categoryRewards.length > 0
          ? Math.floor(categoryRewards.length / itemsPerPage) + (categoryRewards.length % itemsPerPage === 0 ? 0 : 1)
          : 1;
      const lastPage = totalPages - 1;
      const categoryState = categoryStates.has(category.id)
        ? (categoryStates.get(category.id) as IRewardSectionState)
        : {
          rewards: categoryRewards.getPage(itemsPerPage, 0),
          category,
          activePage: 0,
          itemsPerPage,
          firstPage: 0,
          lastPage,
          totalPages,
          getNextPage: this.getNextPage,
          categoryRewards,
          onRewardSelect: this.onRewardSelect
        };
      categoryStates.set(category.id, categoryState);
    });
    this.setState({ categoryStates });
  }

  public onCategorySelect = (data: IRewardCategory) => {
    window.location.href = `#${data.id}_section`;
  };

  public getCategorySlider = () => {
    const { classes, categoryItems, rewards } = this.props;
    return categoryItems && categoryItems.length > 0 ? (
      <Grid item={true} xs={12}>
        <Grid item={true} xs={12} className={classes.jumpText}>
          <Typography variant="body1" gutterBottom={true} color={'textSecondary'}>
            {'Jump to:'}
          </Typography>
        </Grid>
        <Grid item={true} xs={12} className={classes.categoryHeader} style={{ overflow: 'hidden' }}>
          {categoryItems.map(data => {
            return rewards.getNonFeatured().filterByCategory(data).length >= 1 ? (
              <Chip key={`${data.id}_chip`} label={data.name} className={classes.chip} onClick={this.onCategorySelect.bind(this, data)} />
            ) : null;
          })}
        </Grid>
      </Grid>
    ) : null;
  };

  public getRewardsHeader = (): JSX.Element => {
    const { featuredItems, categoryItems } = this.props;
    featuredItems.getSortedList();

    const featuredItemsByCategory: RewardList = new RewardList();

    categoryItems.forEach(category => {
      const featuredReward = featuredItems.filterByCategory(category)[0];
      if (featuredReward) {
        featuredItemsByCategory.push(featuredReward);
      }
    });

    const featured =
      constants.HIDE_REWARDS_CATEGORIES && featuredItems && featuredItems.length > 0
        ? new RewardList(featuredItems[0])
        : featuredItemsByCategory;
    return <FeaturedRewardHeader onRewardSelect={this.onRewardSelect} featuredRewards={featured} />;
  };

  public getRewardsList = (): JSX.Element => {
    const { categoryItems, rewards, t, loading } = this.props;
    if (!this.state.loading && !loading && (categoryItems.length === 0 || rewards.length === 0)) {
      return (
        <Grid item={true} xs={12} id={'rewards-section'} style={{ marginTop: '30px' }}>
          <NoResultsFound title={t('rewards.noResults')} />
        </Grid>
      );
    }
    return (
      <Grid item={true} xs={12} id={'rewards-section'} style={{ marginTop: '30px' }}>
        {categoryItems && rewards
          ? categoryItems.map(category => {
            return rewards.getNonFeatured().filterByCategory(category).length >= 1 ? this.renderSection(category) : null;
          })
          : null}
      </Grid>
    );
  };

  public getNextPage = async (pageNumber: number, category: IRewardCategory) => {
    const { rewards } = this.props;
    const { categoryStates } = this.state;
    const categoryState: IRewardSectionState = categoryStates.get(category.id) as IRewardSectionState;
    if (categoryState) {
      const categoryRewards: RewardList = rewards && rewards.length > 0 ? rewards.filterByCategory(category) : new RewardList();
      categoryRewards.getSortedList();
      const pageItems = categoryRewards.getPage(constants.REWARDS_PER_PAGE, pageNumber);

      categoryStates.set(category.id, { ...categoryState, ...{ activePage: pageNumber, rewards: pageItems } });
      this.setState({ categoryStates });
    }
  };

  public renderSection = (category: IRewardCategory): JSX.Element => {
    const { categoryStates } = this.state;
    if (!category) {
      return <div />;
    }
    const categoryState = categoryStates.get(category.id) as IRewardSectionState;
    if (!categoryState) return <div key={'no-categories' + category.id} />;
    const rewards =
      categoryState.rewards && categoryState.rewards.length >= 1 && categoryState.rewards[0].featured_order_num
        ? new RewardList(...categoryState.rewards.slice(1))
        : categoryState.rewards
          ? new RewardList(...categoryState.rewards)
          : new RewardList();

    return (
      <div id={`${category.id}_section`} key={category.id}>
        <RewardSection
          {...categoryState}
          rewards={rewards}
          hideCategoryName={constants.HIDE_REWARDS_CATEGORIES}
          showSoldOutTag={constants.SHOW_COUPON_SOLD_OUT_BANNER}
        />
      </div>
    );
  };

  public render() {
    const { classes, history, location, t } = this.props;
    const { loading } = this.state;

    const navigateToTicketEntry = () => {
      history.push('/ticket-entry', { from: location.pathname });
    };
    const sweepStakeDrawsCount =
      !!this.props.sweepStakeDraws && !!this.props.sweepStakeDraws.content ? this.props.sweepStakeDraws.content.length : 0;
    const onNavigate = () => {
      history.push('/past-draws');
    };
    if (loading) {
      return <AppSpinner label={'Loading...'} />;
    }
    return (
      <div className={classes.container}>
        <ScrollToTopOnMount />
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <div className={classes.header}>
              <Title
                icon={'icon-rewards'}
                title={'Rewards'}
                buttonText={t('games.enterTicket')}
                buttonTextTitle={t('games.ticketEntry')}
                titleColor={'textPrimary'}
                navigateToTicketEntry={navigateToTicketEntry}
              />
            </div>
          </Grid>
          {!!sweepStakeDrawsCount && (
            <Button onClick={onNavigate} component={'button'} aria-label={`Go back to View Past Rewards and Winners list`}>
              <Typography variant="subtitle2" color="primary" className={classes.centered} component={'div'}>
                {this.props.t('reward.viewPastRewards')}
              </Typography>
            </Button>
          )}
          <Grid />
          {constants.HIDE_REWARDS_CATEGORIES ? null : this.getCategorySlider()}
          {this.getRewardsHeader()}
          {this.getRewardsList()}
        </Grid>
      </div>
    );
  }

  private onRewardSelect = async (reward: IReward) => {
    const isNavigateSuccess = utils.navigateToRewardDetails(reward, this.props.history);

    if (isNavigateSuccess) {
      return;
    }
    const categoryName = this.props.categoryItems.find(obj => {
      return Number(obj.id) === reward?.category_id;
    });
    if (this.props.loggedIn) {
      this.props.fetchRewardEntries(reward.id);
    }
    await this.props.setSelectedReward(reward);
    this.props.history.push({ pathname: `/rewards/${reward.id}`, state: { category_name: categoryName?.name } });
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({
  authentication: { account, accessToken },
  loyalty: { rewards, userRewards, rewardCategories, sweepStakeDraws, loading },
  feature: { authorizedFlags }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;

  const currentRewards = loggedIn ? userRewards : rewards;

  let categoryItems: IRewardCategory[] = currentRewards ? currentRewards.filterCategories(rewardCategories) : [];
  categoryItems = utils.filterCategoriesByFeatureFlags(categoryItems, authorizedFlags);
  categoryItems = utils.orderCategories(categoryItems);

  const featuredItems: RewardList = currentRewards ? currentRewards.getFeatured() : new RewardList();

  return {
    categoryItems,
    featuredItems,
    rewards: currentRewards,
    sweepStakeDraws,
    loggedIn,
    loading
  };
};

const mapDispatchToProps = {
  fetchRewardCategories,
  fetchRewards,
  fetchSweepstakeDraws,
  fetchUserRewards,
  showMessageBar,
  setSelectedReward,
  isAuthenticated,
  fetchRewardEntries,
  fetchUserPoints
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withTheme(withStyles(styles)(RewardsScreen)))));
