import { Button, Grid, Icon, Typography, useMediaQuery, useTheme, WithStyles, withStyles } from '@material-ui/core';
import ScreenCard from '@pbl/pbl-react-web-components/lib/card/ScreenCard';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/account-activation/components/ActivationContentStyle';
import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import MDSpinner from 'react-md-spinner';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IReactivationMessage {
  title: string;
  message: string;
}

interface IReactivationMessages {
  [key: string]: IReactivationMessage;
}

interface IReactivationContentProps extends RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  type: string;
  loading: boolean;
}

const REACTIVATION_TYPES: IReactivationMessages = {
  success: {
    message: 'reactivation.success.message',
    title: 'reactivation.success.title'
  },
  error: {
    message: 'reactivation.error.message',
    title: 'reactivation.error.title'
  }
};

const ReactivationContent: React.FunctionComponent<IReactivationContentProps> = ({ type, loading, classes, history, t }) => {
  const theme = useTheme();
  const isUpLgMediaQuery = useMediaQuery(() => theme.breakpoints.up('lg'));

  let title: string;
  let displayBox;

  const navigateTo = path => () => {
    history.push(path);
  };

  if (!loading) {
    const pass = type === 'active' || type === 'success';
    const icon = pass ? 'done' : 'error_outline';

    const content = REACTIVATION_TYPES[type] || REACTIVATION_TYPES['error'];

    title = t(content.title);

    displayBox = (
      <React.Fragment>
        <Icon
          className={classNames({
            [classes.icon]: true,
            [classes.iconSuccess]: pass,
            [classes.iconError]: !pass
          })}
        >
          {icon}
        </Icon>
        <Typography variant="body1" className={classes.gotoText}>
          {t(content.message)}
        </Typography>
      </React.Fragment>
    );
  } else {
    title = t('reactivation.loadingTitle');

    displayBox = (
      <div className={classes.loadingBox}>
        <MDSpinner size={28} singleColor={colors.themeColors.warningColor.hex()} />
      </div>
    );
  }

  return (
    <ScreenCard title={title}>
      <Grid container={true} justify="center" alignItems="center" style={{ marginBottom: isUpLgMediaQuery ? '0' : theme.spacing(3) }}>
        <Grid item={true} xs={11} sm={9} lg={8}>
          <div className={classes.box}>{displayBox}</div>
        </Grid>
      </Grid>
      {isUpLgMediaQuery && (
        <Typography variant="body1" className={classes.gotoText}>
          {t('reactivation.content.goto')}
        </Typography>
      )}
      <Button
        size="large"
        variant={isUpLgMediaQuery ? 'outlined' : 'contained'}
        color="primary"
        aria-label={t('reactivation.content.btn')}
        onClick={navigateTo('/login')}
        style={{ paddingLeft: '4em', paddingRight: '4em' }}
      >
        {t('reactivation.content.btn')}
      </Button>
    </ScreenCard>
  );
};

export default withStyles(styles)(withRouter(withTranslation()(ReactivationContent)));
