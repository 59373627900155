import * as yup from 'yup';
import validation from '../../../config/validation';

export const firstNameValidator = yup
  .string()
  .required()
  .max(30)
  .matches(/.*\S.*/);

export const lastNameValidator = yup
  .string()
  .required()
  .max(30)
  .matches(/.*\S.*/);

export const dateOfBirthValidator = yup
  .date()
  .required()
  .min(validation.minDateOfBirth)
  .max(validation.maxDateOfBirth);

export const addressValidator = yup
  .string()
  .required()
  .max(255)
  .matches(/.*\S.*/);

export const zipCodeValidator = yup
  .string()
  .required()
  .max(255)
  .matches(/.*\S.*/);

export const cityValidator = yup
  .string()
  .required()
  .max(255);

export const countryValidator = yup
  .string()
  .required()
  .max(255);

export const stateValidator = yup
  .string()
  .required()
  .max(255);

export const phoneNumberValidator = yup.string().matches(validation.phoneValidationRegex);

export const billingPhoneNumberValidator = yup.string().required().matches(validation.phoneValidationRegex);

export const ssnValidator = yup
.string().matches(/^[0-9]*$/).max(4).min(4)
.nullable()
.transform((parsedValue, originalValue) => (originalValue === '' ? null : parsedValue));

export const profileSchema = yup.object().shape({
  firstName: firstNameValidator,
  lastName: lastNameValidator,
  dateOfBirth: dateOfBirthValidator,
  phone: phoneNumberValidator
});

export const addressSchema = yup.object().shape({
  address1: addressValidator,
  city: cityValidator,
  zipCode: zipCodeValidator,
  country: countryValidator,
  state: stateValidator
});
