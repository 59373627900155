import { now } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import constants from 'config/constants';
import PasswordValidator from 'password-validator';
import * as yup from 'yup';

const minDateOfBirth = now(constants.LOTTERY_TIME_ZONE).subtract(120, 'years').toDate();
const maxDateOfBirth = now(constants.LOTTERY_TIME_ZONE).subtract(21, 'years').toDate();

const passwordValidatorSchema = new PasswordValidator();

export type passwordFails = 'min' | 'max' | 'uppercase' | 'lowercase' | 'digits' | 'symbols';

export type footerPages = 'about_us' | 'faq' | 'terms_conditions' | 'privacy';

export const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const zipCodeValidator = yup
  .string()
  .required()
  .max(50)
  .matches(/.*\S.*/);

const holderNameValidator = yup
  .string()
  .required()
  .max(255)
  .matches(/.*\S.*/);

passwordValidatorSchema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(20) // Maximum length 20
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .symbols() // Must have symbols
  .has()
  .digits(); // Must have digits
const validation = {
  emailValidationRegex:
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneValidationRegex: /^$|^\d{10}$|^\([1-9]\d{2}\)\s(\d{3})-(\d{4})$/,
  passwordValidatorSchema,
  minDateOfBirth,
  maxDateOfBirth,
  maxNameLength: 30,
  maxApartmentNumberLength: 50,
  maxAddressLength: 255,
  maxSSNLength: 4,
  zipCodeValidator,
  holderNameValidator
};
export default validation;
