import { createStyles, Theme } from '@material-ui/core';
import color from 'color';

import { container } from 'assets/jss/material-kit-pro-react.jsx';

export default (theme: Theme) =>
  createStyles({
    container: {
      ...container,
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    screen: {
      backgroundColor: color(theme.palette.primary.main)
        .alpha(0.37)
        .rgb()
        .string(),
      minHeight: '100%',
      paddingBottom: theme.spacing(4)
    }
  });
