import { GET_SUPER_BONUS_DETAILS } from '@pbl/pbl-react-core/lib/models/super-bonus/types';

import { SuperBonusService } from '@pbl/pbl-react-core/lib/services/super-bonus-service';

export const getSuperBonusDetails = (key: string) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_SUPER_BONUS_DETAILS,
      payload: SuperBonusService.fetchSuperBonus(key)
    });
  } catch (e) {
    console.error(e);
  }
};
