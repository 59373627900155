import { Grid, Theme, withTheme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { IVerifyId, IVerifyStatus, VerificationStatus } from '@pbl/pbl-react-core/lib/models/profile/types';
import { PlayerDetails } from '@pbl/pbl-react-core/lib/models/store/types';
import InformationDialog from '@pbl/pbl-react-web-components/lib/dialog/InformationDialog';
import ArcadeMessagePopup from '@pbl/pbl-react-web-components/lib/message-popup/ArcadeMessagePopup';
import styles from 'assets/jss/modules/store/StoreScreenStyle';
import constants from 'config/constants';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { getAccount } from 'redux/reducers/authentication/actions';
import {
  getProfile,
  renewUrl,
  verifyIdentity,
  verifyStatus
} from 'redux/reducers/profile/actions';
import IdentityVerificationModal from './IdentityVerificationModal';

type PropsConnected = ConnectedProps<typeof connector>;

interface IProps extends PropsConnected, RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  theme: Theme;
  onFlowClosed: () => void;
  onIdentityVerified: () => void;
  onIdentityFailed: () => void;
}

interface IStore {
  openModal: boolean;
  verificationStatus: VerificationStatus;
  showAlreadyExistsError: boolean;
  openManualVerificationFailedMessage: boolean;
  openManualIframe: boolean;
  openGoToManualScanMessage: boolean;
}

class VerificationFlowComponent extends React.Component<IProps, IStore> {
  private verificationStatusInterval?: NodeJS.Timeout | null;
  constructor(props: IProps) {
    super(props);
    this.state = {
      openModal: false,
      verificationStatus: 'dataEntry',
      showAlreadyExistsError: false,
      openManualVerificationFailedMessage: false,
      openManualIframe: false,
      openGoToManualScanMessage: false
    };
  }

  public async componentDidMount() {
    await this.props.getProfile();
    const { userProfile } = this.props.profile;
    const attemptsLeft = (userProfile.idologyMaxAttempts || 1) - (userProfile.idologyAttempts || 0);
    const verificationStatus = this.getVerificationStatus(userProfile, attemptsLeft);
    if (attemptsLeft === 0) {
      this.setState({ openModal: false, openGoToManualScanMessage: true });
    } else {
      this.setState({ openModal: true, verificationStatus });
    }
  }

  private renewUrlSucceeded = async (response: IVerifyId) => {
    await this.props.getProfile();
    if (response.manualScanUrl) {
      this.setState({ openModal: false, openManualIframe: true });
      this.startIdologyStatusTimer();
    } else if (response.verified) {
      this.setState({ openModal: true, verificationStatus: 'verified', openManualIframe: false, openManualVerificationFailedMessage: false });
    } else {
      this.setState({ openModal: false, openGoToManualScanMessage: false, openManualVerificationFailedMessage: true });
    }
  };

  private renewUrlFailed = () => {
    this.setState({ openModal: false, openGoToManualScanMessage: false, openManualVerificationFailedMessage: true });
  };

  private stopIdologyStatusTimer = () => {
    if (this.verificationStatusInterval) {
      clearInterval(this.verificationStatusInterval);
    }
    this.verificationStatusInterval = null;
  };

  private startIdologyStatusTimer = () => {
    this.verificationStatusInterval = setInterval(this.idologyTimerCallback, constants.IDOLOGY_VERIFY_STATUS_POLLING_INTERVAL);
  };

  private idologyTimerCallback = async () => {
    await this.props.verifyStatus(this.onIdologyVerificationStatusChanged);
  };

  private onIdologyVerificationStatusChanged = async (status: IVerifyStatus) => {
    if (status.idScanResultKey !== 'id.scan.pending') {
      this.stopIdologyStatusTimer();
      this.setState({ openManualIframe: false });

      if (status.idScanSummaryResultKey === 'expectid.scan.id.success') {
        this.setState({ openModal: true, verificationStatus: 'verified', openManualIframe: false, openManualVerificationFailedMessage: false });
        if (status.profileUpdated) {
          this.props.showMessageBar({ message: 'profile.updated' });
          this.props.getAccount();
        }
      } else if (status.idScanResultKey !== 'result.id.scan.approved' || status.idScanSummaryResultKey === 'expectid.scan.id.failure') {
        this.setState({
          openModal: false,
          verificationStatus: 'manualScanError',
          openManualVerificationFailedMessage: true,
          openGoToManualScanMessage: false
        });
      }
    }
  };

  // @ts-ignore
  private onIdologyVerificationStatusCallFailed = async () => {
    this.props.onIdentityFailed();
  };

  public componentWillUnmount() {
    this.stopIdologyStatusTimer();
  }

  private getVerificationStatus = (userProfile, attemptsLeft) => {
    if (userProfile.idologyExpired) {
      return 'dataExpired';
    }
    return attemptsLeft === 0 ? 'maxAttemptsReached' : 'dataEntry';
  }
  private onCloseIdentityVerificationModal = () => {
    this.setState({ openModal: false });
    this.props.onFlowClosed();
  };

  private verifyIdentityPerformedFailed = async error => {
    await this.props.getProfile();
    if (error.payload?.errorKey === 'error.idology.profileAlreadyValidated') {
      this.setState({ verificationStatus: 'dataEntry', showAlreadyExistsError: true });
    } else {
      this.setState({ verificationStatus: 'error' });
    }
  };
  private verifyIdentityPerformedSucceed = async (response: IVerifyId) => {
    await this.props.getProfile();
    if (response.verified) {
      this.setState({ verificationStatus: 'verified' });
    } else if (response.remaingAttempts === 0) {
      this.setState({ openModal: false, openGoToManualScanMessage: true });
    } else {
      this.setState({ verificationStatus: 'error' });
    }
  };
  private onSubmitIdentityVerificationModal = async (playerDetails: PlayerDetails) => {
    const { verificationStatus } = this.state;
    const { userProfile } = this.props.profile;
    const attemptsLeft = (userProfile.idologyMaxAttempts || 1) - (userProfile.idologyAttempts || 0);
    switch (verificationStatus) {
      case 'dataEntry':
        this.setState({ verificationStatus: 'inProgress' });
        await this.props.verifyIdentity(playerDetails, response => this.verifyIdentityPerformedSucceed(response), e => this.verifyIdentityPerformedFailed(e));
      break;
      case 'error':
        if (attemptsLeft === 0) {
          this.setState({ openModal: false, openGoToManualScanMessage: true });
        } else {
          this.setState({ verificationStatus: 'dataEntry', openModal: attemptsLeft > 0 });
        }
        break;
      case 'dataExpired':
        this.setState({ verificationStatus: 'dataEntry', openModal: true });
        break;
      case 'verified':
        this.setState({ openModal: false });
        this.props.onIdentityVerified();
        break;
      case 'maxAttemptsReached':
        this.setState({ verificationStatus: 'manualEntry', openModal: true });
        break;
      default:
        this.setState({ verificationStatus: 'dataEntry', openModal: false });
    }
  };

  private showVerificationIframe = async () => {
    this.setState({ openGoToManualScanMessage: false, openManualVerificationFailedMessage: false, verificationStatus: 'maxAttemptsReached' });
    await this.props.renewUrl(response => this.renewUrlSucceeded(response), this.renewUrlFailed);
  };

  private closeManualVerificationIframe = () => {
    this.setState({ openManualIframe: false, openModal: true });
    this.props.onFlowClosed();
    this.stopIdologyStatusTimer();
  };

  private showManualVerificationIframe = (): React.ReactNode => {
    const { openManualIframe } = this.state;
    const { classes } = this.props;
    return (<>
      {(openManualIframe && this.props.profile?.verifyId?.manualScanUrl) && (
        <React.Fragment>
          <InformationDialog
            open={openManualIframe}
            onClose={this.closeManualVerificationIframe}
            hideButton={true}
            hideXButton={false}
            maxWidth={'xl'}
            disableBackdropClick={true}
            noSpaces={true}
            disableHorizontalScroll={true}
            mobileFullScreen={true}
            disableVerticalScroll={true}
          >
            <Grid container={true} spacing={2} className={classes.content}>
              <Grid item={true} xs={12}>
                <iframe
                  src={this.props.profile.verifyId.manualScanUrl}
                  allow="autoplay"
                  scrolling="yes"
                  id={'manualScanIframe'}
                  className={classes.iframe}
                  title="manual"
                />
              </Grid>
            </Grid>
          </InformationDialog>
        </React.Fragment>)
      }
    </>);
  };

  private showGoToManualScanMessage = (): React.ReactNode => {
    const { t } = this.props;
    return <ArcadeMessagePopup open={this.state.openGoToManualScanMessage}
      title={t('store.idVerificationModal.goToManualVerificationMessage.title')}
      text={t('store.idVerificationModal.goToManualVerificationMessage.description')}
      closeText={t('store.idVerificationModal.goToManualVerificationMessage.buttonText')}
      disableBackdropClick={true}
      onClose={this.showVerificationIframe} />;
    };

    private showManualScanFailedMessage = (): React.ReactNode => {
      const { t } = this.props;
      return <ArcadeMessagePopup open={this.state.openManualVerificationFailedMessage}
      title={t('store.idVerificationModal.manualScanFailedMessage.title')}
      text= {'manualScanError' === this.state.verificationStatus ? t('store.idVerificationModal.manualScanFailedMessage.description1') : t('store.idVerificationModal.manualScanFailedMessage.description')}
      closeText={'manualScanError' === this.state.verificationStatus ? t('store.idVerificationModal.manualScanFailedMessage.buttonTextContinue') : t('store.idVerificationModal.manualScanFailedMessage.buttonTextClose')}
      disableBackdropClick={true}
      onClose={'manualScanError' === this.state.verificationStatus ? this.showVerificationIframe : this.props.onIdentityFailed}
      hideXButton={false}
      onClickXButton={this.props.onFlowClosed}/>;
  };

  private showAutomaticVerificationModal = (): React.ReactNode => {
    const { idologyAttempts, idologyMaxAttempts } = this.props.profile.userProfile;
    const { verificationStatus, showAlreadyExistsError } = this.state;
    return (<>
    {this.state.openModal && (
      <IdentityVerificationModal
        open={this.state.openModal}
        onClose={this.onCloseIdentityVerificationModal}
        onSubmit={this.onSubmitIdentityVerificationModal}
        verificationStatus={verificationStatus}
        currentAttempts={idologyAttempts || 0}
        maxAttempts={idologyMaxAttempts || 3}
        showAlreadyExistsError={showAlreadyExistsError} />)
  }</>);
};

  public render() {
    return (
      <>
        {this.showAutomaticVerificationModal()}
        {this.showGoToManualScanMessage()}
        {this.showManualScanFailedMessage()}
        {this.showManualVerificationIframe()}
      </>
    );
  }
}

const mapStateToProps = ({
  authentication: { accessToken, account },
  store,
  address: { countries },
  ledger: { userTokens, transactionResponse },
  loyalty: { userPoints, selectedReward },
  activityState: { activity },
  profile
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  account,
  userTokens,
  store,
  countries,
  userPoints,
  activity,
  selectedReward,
  transactionResponse,
  profile
});

const mapDispatchToProps = {
  verifyIdentity,
  renewUrl,
  getProfile,
  getAccount,
  verifyStatus,
  showMessageBar
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(VerificationFlowComponent)))));
