import constants from 'config/constants';

const TIMEOUT = 60 * 1000;

const apiNGLConfiguration = {
  baseURL: constants.NGL_BASE_URL,
  timeout: TIMEOUT,
  withCredentials: true
};
const addNGLRequestTransform = (request: any) => {
  request.headers['X-CSRFToken'] = 'nocheck';
};
export { apiNGLConfiguration, addNGLRequestTransform };
