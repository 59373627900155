import { Button, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { assets } from '@pbl/pbl-react-web-components/lib/config/assets';
import { ArcadeCurrency } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/shared/components/menu/AppBarStyle';
import RecommendationDialog from 'modules/home/components/RecommendationDialog';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { fetchRecommendations } from 'redux/reducers/recommendations/actions';
import getPointsTokensDeepLink from 'utils/PointsTokensUtil';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  points: number;
  tokens: number;
  showTokens: boolean;
}

const UserBalanceButton: React.FunctionComponent<IProps> = ({ classes, history, points, tokens, showTokens }) => {
  const [pointsLink, setPointsDeeplink] = React.useState('');
  const [tokensLink, setTokensDeeplink] = React.useState('');
  const [forYouTitle, setTitle] = React.useState('');
  const dispatch = useDispatch();
  const [forYouSubTitle, setSubTitle] = React.useState('');
  const [isFirstNameEnabled, setShowFirstName] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const { pointsDeeplink, tokensDeeplink, title, showFirstName, subtitle } = await getPointsTokensDeepLink();
      setPointsDeeplink(pointsDeeplink);
      setTokensDeeplink(tokensDeeplink);
      setTitle(title);
      setSubTitle(subtitle);
      setShowFirstName(showFirstName);
    })();
  }, []);

  const handleClickOpen = async () => {
    await dispatch(fetchRecommendations());
    setOpen(true);
  };

  const navigate = (path: string) => {
    history.push(path || '/');
  };

  return (
    <div className={classes.buttonsContainer}>
      <Button
        variant={'contained'}
        size={'small'}
        color={'primary'}
        disabled={true}
        onClick={navigate.bind(this, pointsLink)}
        classes={{ disabled: classes.userPointBalanceBtn }}
      >
        <Typography color="inherit" style={{ color: 'white' }} variant="body2" component={'span'}>
          {points.toPointsFormat()}
        </Typography>
        <Typography color="inherit" variant="caption">
          &nbsp;
          <img alt={'coins'} src={assets.internalAssets.pointsEarnedImg} className={classes.pointsEarnedImg} />
        </Typography>
      </Button>
      {showTokens ? (
        <Button
          variant={'contained'}
          size={'small'}
          color={'primary'}
          disabled={true}
          onClick={navigate.bind(this, tokensLink)}
          classes={{ disabled: classes.userPointBalanceBtn }}
        >
          <Typography color="inherit" style={{ color: 'white' }} variant="body2" >
            {tokens.toPointsFormat()}
          </Typography>
          <Typography color="inherit" variant="caption">
            &nbsp;
            <ArcadeCurrency color={'primary'} size={20} style={{ marginTop: 5 }} />
          </Typography>
        </Button>
      ) : null}
      <Button variant={'contained'} size={'small'} color={'primary'} className={classes.forYouBtn} onClick={handleClickOpen}>
        <Typography color="inherit" variant="body2" style={{ textTransform: 'capitalize' }}>
          For You
        </Typography>
        <Typography color="inherit" variant="caption">
          &nbsp;
          <Icon className={classes.favoriteIcon}>favorite</Icon>
        </Typography>
      </Button>
      {open && (
        <RecommendationDialog
          open={true}
          clickOK={setOpen}
          forYouTitle={forYouTitle}
          forYouSubTitle={forYouSubTitle}
          activityLink={''}
          isFirstNameEnabled={isFirstNameEnabled}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(withRouter(UserBalanceButton));
