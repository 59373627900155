import { FETCH_SWEEPSTAKE_DRAWS, IDrawData } from '@pbl/pbl-react-core/lib/models/draws/types';
import { Activity, Reward } from '@pbl/pbl-react-core/lib/models/loyalty';
import {
  ActivityList,
  ACTIVITY_COMPLETED,
  ACTIVITY_DETAILS,
  CREDIT_USER_POINTS,
  FETCH_INTEREST_ACTIVITY_TO_ACTIVITIES,
  FETCH_REWARD_BARCODE_DATA,
  FETCH_REWARD_ENTRY_DETAILS,
  GET_ACTIVITY_MAPPING,
  GET_ACTIVITY_MAPPINGS,
  GET_CAMPAIGN,
  GET_INVITE_ACCEPTED_ACTIVITY,
  GET_INVITE_FRIENDS_ACTIVITY,
  GET_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY,
  ILoyaltyInitialState,
  LoyaltyState,
  REDEEM_REWARD,
  RESET_ACTIVITY_COMPLETED,
  RESET_ERROR,
  RESET_SELECTED_ACTIVITY,
  RESET_SELECTED_REWARD,
  RESET_STATE,
  REWARD,
  RewardList,
  REWARDS,
  REWARDS_CATEGORIES,
  SET_SELECTED_ACTIVITY,
  SET_SELECTED_REWARD,
  UPDATE_USER_POINTS,
  UPLOAD_PHOTO,
  USER_ACTIVITIES,
  USER_POINTS,
  USER_REWARDS
} from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: ILoyaltyInitialState = {
  loading: false,
  userPoints: {
    id: null,
    redeemablePoints: 0,
    totalPoints: 0,
    activities: []
  },
  userRewards: new RewardList(),
  rewardCategories: [],
  rewards: new RewardList(),
  rewardCodes: [],
  rewardDigitalDownload: null,
  selectedReward: null,
  userActivities: new ActivityList(),
  selectedActivity: null,
  errorMessage: null,
  currentCampaign: null,
  activityCompleted: null,
  awarded: null,
  redeeming: null,
  uploading: false,
  maximumLimitReached: false,
  activityMappings: null,
  activityMapping: null,
  sweepStakeDraws: {} as IDrawData,
  rewardEntries: 0,
  barcodeData: undefined,
  allRewards: new RewardList(),
  homeActivities: new ActivityList()
};
// tslint:disable:cyclomatic-complexity
export default (state: LoyaltyState = INITIAL_STATE, action: IDispatchAction): LoyaltyState => {
  switch (action.type) {
    case REQUEST(USER_POINTS):
    case REQUEST(REWARDS_CATEGORIES):
    case REQUEST(REWARDS):
    case REQUEST(USER_REWARDS):
    case REQUEST(USER_ACTIVITIES):
    case REQUEST(CREDIT_USER_POINTS):
    case REQUEST(GET_CAMPAIGN):
    case REQUEST(ACTIVITY_DETAILS):
    case REQUEST(GET_INVITE_ACCEPTED_ACTIVITY):
    case REQUEST(GET_INVITE_FRIENDS_ACTIVITY):
    case REQUEST(GET_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY):
    case REQUEST(GET_ACTIVITY_MAPPING):
    case REQUEST(GET_ACTIVITY_MAPPINGS):
    case REQUEST(FETCH_INTEREST_ACTIVITY_TO_ACTIVITIES):
    case REQUEST(FETCH_REWARD_BARCODE_DATA):
    case REQUEST(REWARD):
      return {
        ...state,
        loading: true
      };
    case REQUEST(REDEEM_REWARD):
      return {
        ...state,
        redeeming: true
      };
    case SUCCESS(USER_POINTS):
    case USER_POINTS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userPoints: action.payload
      };
    case SUCCESS(USER_REWARDS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userRewards: RewardList.getCombinedSortedList(Reward.JsonToRewards(action.payload), state.userRewards)
      };
    case SUCCESS(REWARDS_CATEGORIES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        rewardCategories: action.payload
      };
    case SUCCESS(REWARDS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        rewards: RewardList.getCombinedSortedList(Reward.JsonToRewards(action.payload), state.rewards)
      };
    case SUCCESS(REDEEM_REWARD):
      return {
        ...state,
        redeeming: false,
        errorMessage: null,
        rewardCodes: action.payload.codes,
        rewardDigitalDownload: action.payload.digital_download
      };
    case SUCCESS(FETCH_REWARD_BARCODE_DATA):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        barcodeData: action.payload
      };
    case SUCCESS(CREDIT_USER_POINTS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        awarded: action.payload
      };
    case SUCCESS(USER_ACTIVITIES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userActivities: ActivityList.getCombinedSortedList(Activity.JsonToActivities(action.payload), state.userActivities)
      };
    case SUCCESS(GET_CAMPAIGN):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        currentCampaign: action.payload
      };
    case SUCCESS(ACTIVITY_DETAILS):
      const maximumLimitReached =
        !!action.payload &&
        !!action.payload.freq_cap &&
        !!action.payload.user_num_completions &&
        action.payload.freq_cap <= action.payload.user_num_completions;
      return {
        ...state,
        loading: false,
        errorMessage: null,
        maximumLimitReached
      };
    case SUCCESS(GET_INVITE_FRIENDS_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        inviteFriendsActivity: action.payload
      };
    case SUCCESS(GET_INVITE_ACCEPTED_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        inviteAcceptedActivity: action.payload
      };
    case SUCCESS(GET_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        promotionsEmailActivity: action.payload
      };
    case SUCCESS(GET_ACTIVITY_MAPPING):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activityMapping: action.payload
      };
    case SUCCESS(FETCH_INTEREST_ACTIVITY_TO_ACTIVITIES):
      const currentUserActivities: ActivityList = state.userActivities;
      const interestActivity: Activity = Activity.JsonToActivity(action.payload);
      if (!currentUserActivities.find(activity => activity.id === interestActivity.id)) {
        currentUserActivities.push(interestActivity);
      }
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userActivities: currentUserActivities
      };
    case SUCCESS(GET_ACTIVITY_MAPPINGS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activityMappings: action.payload
      };
    case SUCCESS(FETCH_REWARD_ENTRY_DETAILS):
      return { ...state, loading: false, rewardEntries: action.payload ? action.payload : 0 };
    case SUCCESS(SET_SELECTED_ACTIVITY):
    case SET_SELECTED_ACTIVITY:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedActivity: Activity.JsonToActivity(action.payload)
      };
    case SET_SELECTED_REWARD:
    case SUCCESS(REWARD):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedReward: Reward.JsonToReward(action.payload)
      };
    case FAILURE(USER_REWARDS):
    case FAILURE(USER_ACTIVITIES):
    case FAILURE(CREDIT_USER_POINTS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        awarded: false
      };
    case FAILURE(REDEEM_REWARD):
      return {
        ...state,
        redeeming: false,
        errorMessage: action.payload.payload.errorDTO,
        rewardCodes: [],
        rewardDigitalDownload: null
      };
    case FAILURE(USER_POINTS):
    case FAILURE(GET_CAMPAIGN):
    case FAILURE(ACTIVITY_DETAILS):
    case FAILURE(GET_INVITE_ACCEPTED_ACTIVITY):
    case FAILURE(GET_INVITE_FRIENDS_ACTIVITY):
    case FAILURE(GET_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case FAILURE(GET_ACTIVITY_MAPPING):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        activityMapping: null
      };
    case FAILURE(GET_ACTIVITY_MAPPINGS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        activityMappings: null
      };
    case FAILURE(FETCH_REWARD_BARCODE_DATA):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        barcodeData: undefined
      };
    case FAILURE(REWARD):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        selectedReward: null
      };
    case RESET_SELECTED_REWARD:
      return {
        ...state,
        selectedReward: null,
        rewardEntries: 0,
        barcodeData: undefined
      };
    case RESET_SELECTED_ACTIVITY:
      return {
        ...state,
        selectedActivity: null,
        currentCampaign: null
      };
    case UPDATE_USER_POINTS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userPoints: action.payload
      };
    case FETCH_SWEEPSTAKE_DRAWS:
      return {
        ...state,
        errorMessage: null,
        sweepStakeDraws: action.payload
      };
    case ACTIVITY_COMPLETED:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activityCompleted: action.payload
      };
    case RESET_ACTIVITY_COMPLETED:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        activityCompleted: null
      };
    case REQUEST(UPLOAD_PHOTO):
      return {
        ...state,
        errorMessage: null,
        uploading: true
      };
    case SUCCESS(UPLOAD_PHOTO):
      return {
        ...state,
        uploading: false,
        errorMessage: null
      };
    case FAILURE(UPLOAD_PHOTO):
      return {
        ...state,
        uploading: false,
        errorMessage: action.payload && action.payload.errorKey ? action.payload.errorKey : 'error.media.uploadFailed'
      };
    case SUCCESS('authentication/LOGOUT'):
      return {
        ...state,
        userPoints: {
          id: null,
          redeemablePoints: 0,
          totalPoints: 0,
          activities: []
        },
        userRewards: new RewardList()
      };
    case RESET_STATE:
      return { ...INITIAL_STATE };
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: null
      };
    default:
      return state;
  }
};
