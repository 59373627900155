import {
  IPasswordResetState,
  PasswordResetState,
  RESET_PASSWORD_FINISH,
  RESET_PASSWORD_STATE
} from '@pbl/pbl-react-core/lib/models/password-reset';
import { SUCCESS } from 'redux/action-type.util';

const INITIAL_STATE: IPasswordResetState = {
  success: false,
  init: true
};

export default (state: PasswordResetState = INITIAL_STATE, action): PasswordResetState => {
  switch (action.type) {
    case SUCCESS(RESET_PASSWORD_FINISH):
      return {
        ...state,
        success: true,
        init: false
      };
    case RESET_PASSWORD_STATE:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};
