import { createStyles, Typography } from '@material-ui/core';
import CardMaterial from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { assets } from '@pbl/pbl-react-web-components/lib/config/assets';
import classNames from 'classnames';
import * as React from 'react';

interface IProps extends WithStyles<typeof styles> {
  image?: string;
  title: string;
  description: string;
  onPress: () => void;
}

const styles: any = (theme: Theme) =>
  createStyles({
    card: {
      borderRadius: 10,
      height: '90px',
      marginBottom: theme.spacing()
    },
    root: {
      display: 'flex',
      justifyContent: 'normal',
      alignItems: 'normal'
    },
    foryouCardContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        maxWidth: '200px'
      },
      ['@media (max-width:375px)']: {
        padding: theme.spacing()
      }
    },
    description: {
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.33px',
      transform: 'Titlecase',
      fontFamily: 'Jost-Regular',
      alignItems: 'center',
      width: 270,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical'
    },
    title: {
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      alignItems: 'center',
      width: 270,
      transform: 'Titlecase',
      fontFamily: 'Jost',
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      [theme.breakpoints.down('lg')]: {
        width: 180
      },
      [theme.breakpoints.down('md')]: {
        width: 179
      },
      [theme.breakpoints.down('sm')]: {
        width: 165
      },
      ['@media (max-width:375px)']: {
        width: 164
      },
      ['@media (max-width:320px)']: {
        width: 140
      }
    },
    media: {
      height: 92,
      width: 92,
      display: 'flex',
      position: 'relative',
      flexShrink: 0
    }
  });

const ForYouCard: React.FunctionComponent<IProps> = ({ image, title, description, onPress, classes }) => {
  const onClick = () => {
    onPress();
  };
  const imageUrl = !!image ? image : assets.internalAssets.noImage;
  return (
    <CardMaterial onClick={onClick} className={classNames('po-widget-card', classes.card)}>
      <CardActionArea className={classes.root}>
        <CardMedia className={classes.media} image={imageUrl} />
        <CardContent className={classes.foryouCardContainer}>
          <Typography color={'textPrimary'} variant="inherit" className={classes.title} component={'p'}>
            {title}
          </Typography>
          <div className={classes.description}>
            <Typography color={'textSecondary'} variant="caption" style={{ fontWeight: 600 }}>
              {description}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </CardMaterial>
  );
};
export default withStyles(styles)(ForYouCard);
