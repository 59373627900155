import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    field: {
      width: '100%',
      marginTop: theme.spacing(3)
    },
    button: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10)
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10)
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  });
