import { Button, Card, CardMedia, Grid, Hidden, LinearProgress, Theme, Typography, WithStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import { IPhoto } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { createMediaURL } from '@pbl/pbl-react-core/lib/utils/mediaUtil';
import BonusPoints from '@pbl/pbl-react-web-components/lib/bonus-points/BonusPoints';
import YoullEarnMsg from '@pbl/pbl-react-web-components/lib/bonus-points/YoullEarnMsg';
import { EarnPointsModal, ReadMore } from '@pbl/pbl-react-web-components/lib/package';
import DetailTitle from '@pbl/pbl-react-web-components/lib/title/DetailTitle';
import noImage from 'assets/img/no-image.svg';
import classNames from 'classnames';
import constants from 'config/constants';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import {
  creditUserPoints,
  fetchActivities,
  fetchUserActivities,
  resetActivityCompleted,
  resetSelectedActivity,
  setActivityCompleted,
  setSelectedUserActivity,
  uploadPhotoForActivity
} from 'redux/reducers/loyalty/actions';
import { convertMegabyteToByte } from 'utils/conversionUtils';
import { scrollToTheTop } from 'utils/htmlUtil';
import styles from '../../assets/jss/modules/earn/UploadPhotoScreenStyle';

interface IMatchParams {
  activityId: string;
}

type PropsConnected = ConnectedProps<typeof connector>;

export interface IUploadPhotoScreenProps
  extends PropsConnected,
    RouteComponentProps<IMatchParams>,
    WithStyles<typeof styles>,
    WithTranslation {
  theme: Theme;
}

interface IUploadPhotoScreenState {
  loading: boolean;
  photo: IPhoto | null;
  imgSrc?: (string | ArrayBuffer | null)[];
  fileError: boolean;
  uploadProgress: number;
  isCancelled: boolean;
  cancelToken: any;
}

class UploadPhotoScreen extends React.Component<IUploadPhotoScreenProps, IUploadPhotoScreenState> {
  constructor(props: IUploadPhotoScreenProps) {
    super(props);
    this.state = {
      loading: false,
      photo: null,
      imgSrc: undefined,
      fileError: false,
      uploadProgress: 0,
      isCancelled: false,
      cancelToken: {}
    };
  }

  public async componentDidMount() {
    document.title = 'Upload Photo Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.length > 0) {
      await this.getData(params.activityId);
    } else {
      await this.showInvalidActivityError();
    }
  }

  public componentWillUnmount() {
    this.props.resetSelectedActivity();
  }

  public async getData(id) {
    if (this.props.loggedIn) {
      await this.props.fetchUserActivities();
    } else {
      await this.props.fetchActivities();
    }
    const { userActivities } = this.props;
    const activity = userActivities.find(a => a.id === id);
    if (activity) {
      // @ts-ignore
      await this.props.setSelectedUserActivity(activity);
    } else {
      await this.showInvalidActivityError();
    }
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `This activity is not available.`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  public render() {
    const { classes, selectedActivity, uploading, errorMessage, activityCompleted, history, maximumLimitReached, t } = this.props;
    const { imgSrc, fileError, isCancelled } = this.state;
    const onNavigate = () => {
      history.push('/earn');
    };
    if (!selectedActivity) {
      return null;
    }

    let preview;
    if (!fileError && !!imgSrc) {
      preview = <CardMedia component="img" image={imgSrc.toString()} className={classes.uploadedImg} title="Preview of image to upload" />;
    } else {
      preview = (
        <Typography variant="body1" color="textPrimary">
          Select photo to preview
        </Typography>
      );
    }

    const image = !!selectedActivity.image ? createMediaURL(constants.BASE_URL, constants.MEDIA_API, selectedActivity.image) : noImage;
    const uploadButton =
      !uploading && !fileError && imgSrc ? (
        <div>
          <div className={classes.button} />
          <Button variant="contained" color="primary" className={classes.button} onClick={this.onUploadPressed}>
            Upload
          </Button>
        </div>
      ) : null;

    const browseChangeButton = !uploading ? (
      <Button variant={fileError || !imgSrc ? 'contained' : 'outlined'} color="primary" className={classes.button} component="label">
        {fileError || !imgSrc ? 'Browse to Select' : 'Change Photo'}
        <input type="file" style={{ display: 'none' }} accept="image/*" onChange={this.onFileSelected} />
      </Button>
    ) : null;

    const uploadText = !uploading ? (
      <Typography color="error" className={classes.progressText}>
        {fileError || !imgSrc ? '' : 'Click UPLOAD to earn points'}
      </Typography>
    ) : null;

    const cancelButton = uploading ? (
      <div>
        <LinearProgress variant="determinate" color="secondary" value={this.state.uploadProgress} />
        <Typography variant="body2" color="textPrimary" className={classes.progressText}>
          {this.state.uploadProgress}% Uploaded
        </Typography>
        <Typography variant="body2" color="textPrimary" className={classes.pointsText}>
          You will earn points once the upload finishes successfully
        </Typography>

        <div className={classes.button} />
        <Button variant="outlined" color="primary" className={classes.button} onClick={this.onCancelPressed}>
          Cancel
        </Button>
      </div>
    ) : null;

    const selectGrid = (
      <Grid className={classes.uploadGrid} container={true} spacing={2}>
        <Grid item={true} xs={6} className={classes.previewGridWrapper}>
          {!fileError && !!imgSrc ? (
            <Grid className={classes.previewTextWrapper}>
              <Typography variant="subtitle1" color="textPrimary" className={classes.previewText}>
                {' '}
                Photo Preview{' '}
              </Typography>
            </Grid>
          ) : null}
          <Grid className={classes.previewGrid}>
            <Grid>{preview}</Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={6} className={classes.selectGrid}>
          {fileError && (
            <div>
              <Typography variant="body2" color="error" className={classes.errorText}>
                There's some problem with the photo you selected.
              </Typography>
              <Typography variant="body2" color="error" className={classes.errorText}>
                Allowed formats jpg, png, gif, svg and maximum file size is {constants.UPLOAD_PHOTO_IMAGE_SIZE}MB.
              </Typography>
            </div>
          )}
          {!isCancelled && errorMessage && this.props.loggedIn && (
            <Typography variant="body2" color="error" className={classes.errorText}>
              {t(errorMessage)}
            </Typography>
          )}
          {isCancelled && (
            <Typography variant="body2" color="error" className={classes.cancelledText}>
              Upload cancelled
            </Typography>
          )}
          {browseChangeButton}
          {uploadButton}
          {uploadText}
          {cancelButton}
          <div className={classNames({ [classes.centered]: true, [classes.pointsText]: true })}>
            <YoullEarnMsg noneBonusPoints={selectedActivity.num_points} bonusPoints={selectedActivity.totalBonusPoints} />
          </div>
        </Grid>
      </Grid>
    );

    const pointsDescription = `EARN: ${selectedActivity.num_points.toPointsFormatWithUnit().toUpperCase()}`;
    return (
      <div>
        {activityCompleted !== null ? (
          <EarnPointsModal
            pointEarned={
              !!selectedActivity && selectedActivity.totalBonusPoints > 0
                ? selectedActivity.totalBonusPoints
                : activityCompleted.pointsEarned
            }
            isVisible={activityCompleted !== null}
            onClose={this.onDismissEarnPointsModal}
          />
        ) : null}
        <Grid className={classes.container} container={true} spacing={2}>
          <DetailTitle title={selectedActivity.title} bonusPoints={selectedActivity.totalBonusPoints}
                       noneBonusPoints={selectedActivity.num_points} description={pointsDescription}
                       linkText={'Earn'} navigateTo={onNavigate} />
          <Hidden mdDown={true}>
            <div className={classes.imageCard}>
              <CardMedia className={classes.image} image={image} >
                {selectedActivity.totalBonusPoints && selectedActivity.totalBonusPoints > 0 ? <BonusPoints /> : null}
              </CardMedia>
            </div>
            {maximumLimitReached ? (
              <Typography variant={'subtitle1'} color={'error'}>
                Maximum limit reached!
              </Typography>
            ) : (
              <div className={classes.uploadCard}>{selectGrid}</div>
            )}
          </Hidden>
          <Hidden lgUp={true}>
            <Grid item={true} xs={12} sm={8} lg={true}>
              <Card className={classes.imageCard}>
                <CardMedia className={classes.image} image={image} title={`${selectedActivity.title}`}>
                  {selectedActivity.totalBonusPoints && selectedActivity.totalBonusPoints > 0 ? <BonusPoints /> : null}
                </CardMedia>
              </Card>
              {maximumLimitReached ? (
                <Typography variant={'subtitle1'} color={'error'}>
                  Maximum limit reached!
                </Typography>
              ) : (
                <Card className={classes.uploadCard}>{selectGrid}</Card>
              )}
            </Grid>
          </Hidden>
          <Grid item={true} xs={12}>
            <ReadMore
              context={
                !!selectedActivity
                  ? !!selectedActivity.extra_data && !!selectedActivity.extra_data.additionalInformation
                    ? selectedActivity.extra_data.additionalInformation
                    : selectedActivity.description
                  : ''
              }
              maxLines={5}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    this.props.history.push('/earn');
  };

  private onFileSelected = event => {
    this.props.resetActivityCompleted(); // clear any error msgs.
    const types = ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml'];
    const selectedFiles = event.target.files;
    let photo = this.state.photo;
    let fileError = false;
    if (selectedFiles !== null && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      if (types.every(type => selectedFile.type !== type) || selectedFile.size > convertMegabyteToByte(constants.UPLOAD_PHOTO_IMAGE_SIZE)) {
        fileError = true;
        photo = null;
      } else {
        photo = {
          file: selectedFile
        };
      }

      this.setState({ fileError, photo, isCancelled: false });

      const reader = new FileReader();
      reader.onloadend = () => {
        if (!!photo && reader.result) {
          this.setState({
            imgSrc: [reader.result]
          });
        } else {
          this.setState({ fileError, photo: null, isCancelled: false });
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  private onUploadPressed = async () => {
    await this.props.isAuthenticated();
    if (!this.props.loggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    this.setState({ uploadProgress: 0, isCancelled: false });
    const { photo, cancelToken } = this.state;
    const { selectedActivity } = this.props;
    if (!!selectedActivity && !!photo) {
      this.props.uploadPhotoForActivity(
        selectedActivity.id,
        photo,
        cancelToken,
        this.onUploadProgress,
        this.onPhotoUploaded,
        this.onPhotoUploadFailed
      );
    }
  };

  private onCancelPressed = (): void => {
    const { cancelToken } = this.state;
    if (cancelToken) {
      cancelToken.cancel();
      this.setState({ isCancelled: true });
    }
  };

  private onUploadProgress = (progress: any): void => {
    const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
    this.setState({ uploadProgress });
  };

  private onPhotoUploaded = async (response: { mediaKey: string }) => {
    const { selectedActivity } = this.props;
    if (response && response.mediaKey && !this.state.isCancelled && !!selectedActivity) {
      await this.props.creditUserPoints({
        activityId: selectedActivity.id,
        headers: { media_key: response.mediaKey }
      });
    }
    if (!!selectedActivity && this.props.awarded) {
      this.props.setActivityCompleted({
        pointsEarned: selectedActivity.num_points,
        activity: selectedActivity
      });
    }
  };

  private onPhotoUploadFailed = (): void => {
    // console.error('onPhotoUploadFailed');
  };
}

const mapStateToProps = ({
  authentication: { account, accessToken },
  loyalty: { selectedActivity, uploading, errorMessage, activityCompleted, userActivities, awarded, maximumLimitReached }
}: IRootState) => {
  const loggedIn: boolean = !!accessToken && accessToken.length > 0 && !!account && !!account.email;
  return {
    selectedActivity,
    uploading,
    loggedIn,
    errorMessage,
    activityCompleted,
    userActivities,
    awarded,
    maximumLimitReached
  };
};

const mapDispatchToProps = {
  setSelectedUserActivity,
  uploadPhotoForActivity,
  setActivityCompleted,
  resetActivityCompleted,
  creditUserPoints,
  resetSelectedActivity,
  isAuthenticated,
  fetchActivities,
  fetchUserActivities
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(UploadPhotoScreen)))));
