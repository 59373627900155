import { Grid, Theme, WithStyles, withStyles, withTheme } from '@material-ui/core';
import { IPromotion } from '@pbl/pbl-react-core/lib/models/draws/types';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import { ProgressiveSection } from '@pbl/pbl-react-web-components/lib/package';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import DrawCard from 'modules/draws/components/DrawCard';
import SectionHeading from 'modules/home/components/SectionHeading';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IProps extends WithTranslation, WithStyles<typeof styles> {
  promotions: IPromotion[];
  progressive: IPromotion[];
  theme: Theme;
  onDrawCardClicked: any;
}

const DrawsSection: React.FunctionComponent<IProps> = ({ classes, promotions, progressive, theme, onDrawCardClicked, t }) => {
  const onCardClicked = promotion => {
    onDrawCardClicked(promotion);
  };
  const cornerIcon: ICornerIconProps = {
    icon: 'icon-draws',
    iconType: 'custom',
    contentColor: colors.themeColors.cornerColors.promotionContrast.hex(),
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor: colors.themeColors.cornerColors.promotion.hex(),
    triangleSize: 88
  };

  return (
    <div className={classNames(classes.section)}>
      <SectionHeading
        title={t('home.drawSectionTitle')}
        linkRoute="/promotion"
        color={theme.palette.primary.main}
        linkTitle={t('home.drawSectionLink')}
        linkLabel={t('home.drawSectionLinkLabel')}
      />
      <ProgressiveSection onPress={onCardClicked} promotions={progressive} cornerIcon={cornerIcon} />
      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {promotions.map(promotion => {
          return (
            <Grid key={promotion.id} xs={12} md={4} item={true} className={classes.sectionCard}>
              <DrawCard
                name={promotion.name}
                image={promotion.fullImage ? promotion.fullImage : ''}
                description={promotion.description}
                // @ts-ignore
                onClick={onCardClicked.bind(this, promotion)}
                iconName={'icon-draws'}
                banner={{
                  showBanner: !!promotion.getPromotionStatus(),
                  bannerText: t(`draws.drawStatus.${promotion.getPromotionStatus()}`),
                  styleType: promotion.isEnded() ? 'GrayedOut' : promotion.isEndingSoon() ? 'WhiteHighlight' : 'Active'
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default withTheme(withStyles(styles)(withTranslation()(DrawsSection)));
