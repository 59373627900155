import { IGameConfig } from '@pbl/pbl-react-core/lib/models/games/types';
import { TokenHistoryList } from '@pbl/pbl-react-core/lib/models/history';
import { ITokenPage } from '@pbl/pbl-react-core/lib/models/history/types';
import { Activity } from '@pbl/pbl-react-core/lib/models/loyalty';
import {
  FETCH_DIGITAL_REVEAL_PLAYABLE,
  GET_GAME_CONFIG,
  GET_PLAY_FOR_POINTS_ACTIVITY,
  UPDATE_GAME_CONFIG
} from 'redux/reducers/interactive-games/actions';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

interface IInteractiveGameConfig extends IGameConfig {
  [k: string]: any;
}

export interface IInteractiveGamesState {
  loading: boolean;
  gameConfig: IInteractiveGameConfig;
  errorMessage: any;
  playForPointsActivity: Activity | null;
  digitalRevealGames?: TokenHistoryList;
  digitalRevealGamesPaging: ITokenPage;
}

const INITIAL_STATE: IInteractiveGamesState = {
  loading: false,
  gameConfig: {} as IInteractiveGameConfig,
  errorMessage: null,
  playForPointsActivity: null,
  digitalRevealGamesPaging: {} as ITokenPage
};

export type InteractiveGamesState = Readonly<IInteractiveGamesState>;
export default (state: InteractiveGamesState = INITIAL_STATE, action: IDispatchAction): InteractiveGamesState => {
  switch (action.type) {
    case REQUEST(GET_GAME_CONFIG):
    case REQUEST(UPDATE_GAME_CONFIG):
    case REQUEST(GET_PLAY_FOR_POINTS_ACTIVITY):
    case REQUEST(FETCH_DIGITAL_REVEAL_PLAYABLE):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_GAME_CONFIG):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        gameConfig: action.payload ? action.payload.gameConfig : null,
        playForPointsActivity: action.payload ? action.payload.playForPointsActivity : null
      };
    case SUCCESS(UPDATE_GAME_CONFIG):
      return {
        ...state,
        loading: false,
        errorMessage: null
      };
    case SUCCESS(GET_PLAY_FOR_POINTS_ACTIVITY):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        playForPointsActivity: action.payload ? action.payload.playForPointsActivity : null
      };
    case SUCCESS(FETCH_DIGITAL_REVEAL_PLAYABLE):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        digitalRevealGames: action.payload.data,
        digitalRevealGamesPaging: action.payload.page
      };
    case FAILURE(UPDATE_GAME_CONFIG):
    case FAILURE(GET_GAME_CONFIG):
    case FAILURE(GET_PLAY_FOR_POINTS_ACTIVITY):
    case FAILURE(FETCH_DIGITAL_REVEAL_PLAYABLE):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
