import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { Activity } from '@pbl/pbl-react-core/lib/models/loyalty';
import { IActivity, LoyaltyState } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import FeaturedCard from '@pbl/pbl-react-web-components/lib/card/FeaturedCard';
import NoResultsFound from '@pbl/pbl-react-web-components/lib/no-results/NoResultsFound';
import Title from '@pbl/pbl-react-web-components/lib/title/Title';
import noImage from 'assets/img/no-image-featured.svg';
import styles from 'assets/jss/modules/earn/EarnScreenStyle';
import _ from 'lodash';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import {
  fetchActivities,
  fetchUserActivitiesWithInterests,
  getActivity,
  resetState,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import ActivityListItem from './components/ActivityListItem';
import utils from './utils';

export interface IEarnScreenProps extends StateProps, DispatchProps, RouteComponentProps, WithTranslation, WithStyles<typeof styles> {
  loyalty: LoyaltyState;
  theme: Theme;
}

interface IEarnScreenState {
  loading: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ loyalty: { loading, userActivities }, authentication: { accessToken, account } }: IRootState) => ({
  loading,
  userActivities,
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email
});

const mapDispatchToProps = {
  fetchUserActivitiesWithInterests,
  resetState,
  setSelectedUserActivity,
  showMessageBar,
  getActivity,
  fetchActivities
};

class EarnScreen extends React.Component<IEarnScreenProps, IEarnScreenState> {
  constructor(props: IEarnScreenProps) {
    super(props);
    this.state = { loading: false };
  }

  public async componentDidUpdate(prevProps: Readonly<IEarnScreenProps>): Promise<void> {
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      await this.getEntities();
    }
  }

  public async componentDidMount() {
    document.title = 'Earn';
    const { isLoggedIn, location } = this.props;
    // @ts-ignore
    if (location && location.state && location.state.message && isLoggedIn) {
      // @ts-ignore
      this.props.showMessageBar(location.state.message);
    }
    await this.getEntities();
  }

  private async getEntities() {
    await this.setState({ loading: true });
    this.props.resetState();
    if (this.props.isLoggedIn) {
      await this.props.fetchUserActivitiesWithInterests();
    } else {
      await this.props.fetchActivities();
    }
    await this.setState({ loading: false });
  }

  public componentWillUnmount() {
    this.props.resetState();
  }

  public getFeaturedActivity = (): JSX.Element => {
    const { userActivities } = this.props;
    const featuredActivity = userActivities.isFeatured();
    const description = !!featuredActivity?.extra_data?.preview_text
        ? featuredActivity.extra_data.preview_text
        : _.unescape(featuredActivity?.public_description);
    return (
      <React.Fragment>
        {!!featuredActivity && (
          <FeaturedCard
            // @ts-ignore
            onButtonClick={this.onListItemClicked.bind(this, featuredActivity)}
            buttonText="EARN POINTS NOW"
            image={featuredActivity.image}
            imageTitle={`${featuredActivity.public_title}`}
            noImageAsset={noImage}
            title={
              featuredActivity.num_points && featuredActivity.num_points > 0
                ? `Earn ${featuredActivity.num_points.toPointsFormatWithUnit()}`
                : 'Earn Points'
            }
            text={featuredActivity.public_title}
            textDescription={description}
            iconFooter={'icon-earn'}
            bonusPoints={featuredActivity.totalBonusPoints}
            noneBonusPoints={featuredActivity.num_points}
          />
        )}
      </React.Fragment>
    );
  };

  public getActivitiesList = (): JSX.Element => {
    const { userActivities } = this.props;
    const { loading } = this.state;
    const activities = userActivities.getActivitiesListSorted();

    if (!loading && userActivities.length === 0) {
      return <NoResultsFound title="Sorry, there doesn't appear to be any activities" />;
    }

    return (
      <React.Fragment>
        <Grid container={true} spacing={4}>
          {activities &&
            activities
              .filter(activity => activity.isVisibleOnWebListPage)
              .map(activity => (
                <Grid item={true} xs={12} sm={12} md={4} key={activity.id}>
                  <ActivityListItem activity={activity} onPress={this.onListItemClicked.bind(this, activity)} />
                </Grid>
              ))}
        </Grid>
      </React.Fragment>
    );
  };

  public render() {
    const { classes, history, location, t } = this.props;
    const { loading } = this.state;
    const navigateToTicketEntry = () => {
      history.push('/ticket-entry', { from: location.pathname });
    };
    return (
      <div className={classes.container}>
        {loading && <AppSpinner label={'Loading Activities'} />}
        {!loading && (
          <Grid container={true}>
            <ScrollToTopOnMount />
            <Grid item={true} xs={12}>
              <div className={classes.header}>
                <Title
                  title={'Earn'}
                  titleColor={'textPrimary'}
                  buttonText={t('games.enterTicket')}
                  buttonTextTitle={t('games.ticketEntry')}
                  icon={'icon-earn'}
                  navigateToTicketEntry={navigateToTicketEntry}
                />
              </div>
            </Grid>
            {this.getFeaturedActivity()}
            {this.getActivitiesList()}
          </Grid>
        )}
      </div>
    );
  }

  private onListItemClicked = (activity: IActivity | Activity): void => {
    if (!!activity.extra_data && activity.extra_data.is_information_only) {
      utils.navigateToActivityDetails(activity, this.props.history);
    } else {
      this.props.getActivity(activity.id);
      utils.navigateToActivityDetails(activity, this.props.history);
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withRouter(withTheme(withTranslation()(withStyles(styles)(EarnScreen)))));
