import { IFieldUpdateData } from '@pbl/pbl-react-core/lib/models/authentication';
import {
  CLEAR_ERROR,
  CLEAR_PRIZES,
  ENTER_TICKET,
  FETCH_DRAWS_BY_IDS,
  RESET,
  UPDATE_ATTR
} from '@pbl/pbl-react-core/lib/models/ticket/types';
import { GamesService } from '@pbl/pbl-react-core/lib/services';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { clearMessages } from 'redux/reducers/app/actions';
import { DispatchMethod, GetStateMethod } from '..';

export const enterTicket = (ticketNumber?: string, validationCode?: string) => async (dispatch: any) => {
  dispatch({
    type: REQUEST(ENTER_TICKET)
  });
  try {
    const ticketSubmissionDTO = await GamesService.enterTicketV2(ticketNumber as string, validationCode as string);

    dispatch({
      type: SUCCESS(ENTER_TICKET),
      payload: { graphicImageUrl: ticketSubmissionDTO.graphicImageUrl, prizes: ticketSubmissionDTO.prizes }
    });
  } catch (e) {
    dispatch({
      type: FAILURE(ENTER_TICKET),
      payload: e.payload ? e.payload : e
    });
  }
};

export const clearErrors = () => (dispatch: any) => {
  dispatch(clearMessages());
  dispatch({
    type: CLEAR_ERROR
  });
};

export const clearPrizes = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_PRIZES
  });
};

export const reset = () => (dispatch: any) => {
  dispatch({
    type: RESET
  });
};

export const updateFieldData = (payload: IFieldUpdateData) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_ATTR,
    payload
  });
};

export const fetchDrawsByIds = () => async (dispatch: any, getState: GetStateMethod) => {
  const {
    ticket: { prizes }
  } = getState();
  if (prizes && prizes.length > 0) {
    await dispatch({
      type: FETCH_DRAWS_BY_IDS,
      payload: GamesService.fetchDrawsByIdsForTicket(prizes)
    }).catch((error: Error) => {
      console.error(error);
    });
  }
};
