import {
  CHANGE_PASSWORD,
  CHECK_PASSWORD,
  CLEAR_CURRENT_PASSWORD_VALID,
  RESET_CHANGE_PASSWORD
} from '@pbl/pbl-react-core/lib/models/password';
import { AccountService } from '@pbl/pbl-react-core/lib/services';
import { clearLoading, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { DispatchMethod } from '../index';

export const changePassword = (currentPassword: string, newPassword: string) => async (dispatch: any) => {
  try {
    dispatch(toggleLoading({ spinning: true }));

    await dispatch({
      type: CHANGE_PASSWORD,
      payload: AccountService.changePassword(currentPassword, newPassword)
    });
    dispatch(showMessageBar({ message: 'password.updated' }));
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  } finally {
    dispatch(clearLoading());
  }
};

export const checkPassword = (password: string) => async (dispatch: DispatchMethod) => {
  try {
    await dispatch({
      type: CHECK_PASSWORD,
      payload: AccountService.checkPassword(password)
    });
  } catch (e) {
    console.error(e.message);
  }
};

export const clearCurrentPasswordValid = () => ({
  type: CLEAR_CURRENT_PASSWORD_VALID
});

export const resetChangePassword = () => ({
  type: RESET_CHANGE_PASSWORD
});
