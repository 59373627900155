import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    container,
    root: {
      paddingTop: theme.spacing(2),
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      minWidth: '100%'
    },
    backButton: {
      marginLeft: -theme.spacing(),
      '& span': {
        textTransform: 'none',
        color: theme.palette.text.secondary
      }
    },
    title: {
      marginTop: theme.spacing(2)
    },
    detailContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    detailCardWrapper: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(4)
      }
    },
    detailContainerCard: {
      padding: 0,
      width: 350,
      height: 350,
      [theme.breakpoints.only('md')]: {
        height: 320,
        width: 320
      },
      [theme.breakpoints.up('lg')]: {
        width: 350,
        height: 350
      },
      '& img': {
        objectFit: 'cover',
        width: 350,
        height: 350,
        [theme.breakpoints.only('md')]: {
          height: 320,
          width: 320
        },
        [theme.breakpoints.up('lg')]: {
          width: 350,
          height: 350
        }
      }
    },
    rewardDetailItem: {
      paddingBottom: theme.spacing(3)
    },
    rewardRedeemContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    rewardRedeemButton: {
      minWidth: '250px',
      minHeight: '50px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    rewardRedeemText: {
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center'
      }
    },
    largeIcon: {
      fontSize: '3rem'
    },
    // Modal
    redeemModal: {
      width: '28em',
      [theme.breakpoints.down('xs')]: {
        width: '18.8em'
      },
      [theme.breakpoints.down('xs')]: {
        width: '17.5em'
      }
    },
    redeemModalDigitalDownloadContainer: {
      padding: theme.spacing(2),
      borderRadius: 8,
      backgroundColor: theme.palette.grey[200],
      textAlign: 'center'
    },
    redeemModalDigitalDownloadImg: {
      maxHeight: 80
    },
    redeemModalTextPoints: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    redeemModalPoints: {
      marginBottom: theme.spacing()
    },
    redeemModalActions: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    codeContainer: {
      padding: theme.spacing(),
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
      margin: theme.spacing(),
      backgroundColor: theme.palette.common.black.getWithAlpha(0.05),
      borderRadius: 5,
      border: 1,
      borderColor: theme.palette.common.black.getWithAlpha(0.08)
    },
    code: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(),
      wordBreak: 'break-all'
    },
    copyText: {
      color: theme.palette.text.secondary,
      textTransform: 'none',
      wordBreak: 'break-all'
    },
    copyIcon: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      marginRight: theme.spacing()
    },
    redeemSuccessText: {
      marginTop: theme.spacing(2)
    },
    rewardRedeemDownloadButton: {
      minHeight: '50px',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      marginTop: theme.spacing(3)
    },
    redeemSuccessModalActions: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2)
    }
  });

export default styles;
