import { Button, Grid, Theme, Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  title: string;
  linkTitle?: string;
  linkLabel?: string;
  linkRoute: string;
  color?: string;
  theme: Theme;
}

const SectionHeading: React.FunctionComponent<IProps> = ({ classes, title, linkTitle, color, theme, linkLabel, linkRoute, history }) => {
  const navigate = () => {
    history.push(linkRoute);
  };
  const textStyle = !!color
    ? {
      color
    }
    : {
      color: theme.palette.primary.main
    };
  return (
    <Grid container={true} direction="row" justify="space-between" alignItems="center">
      <Grid item={true}>
        <Typography component={'h4'} variant="h4" color="textPrimary" style={textStyle}>
          {title}
        </Typography>
      </Grid>
      {!!linkLabel && <Grid item={true}>
        <Button component="a" title={linkLabel} onClick={navigate} color="primary" style={textStyle}>
          {linkTitle}
          <KeyboardArrowRight className={classes.rightIcon} />
        </Button>
      </Grid>}
    </Grid>
  );
};

export default withTheme(withStyles(styles)(withRouter(SectionHeading)));
