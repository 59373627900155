import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  WinningNumberLayout
} from '@pbl/pbl-react-core/lib/models/quickdraw/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import styles from 'assets/jss/modules/games/GamesScreenStyle';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { showMessageBar } from 'redux/reducers/app/actions';
import {
  getLatestQuickDrawWithWinnerResultByGame,
  getQuickDrawConfig,
  getQuickDrawJackpotResultsFromDrawDate
} from 'redux/reducers/quickdraw/actions';
import MessageBar from 'shared/components/message-bar/MessageBar';

interface IMatchParams {
  gameId: string;
}

export interface IDrawGameWidgetProps
  extends StateProps,
    DispatchProps,
    WithStyles<typeof styles>,
    RouteComponentProps<IMatchParams> {
}

interface IDrawGameWidgetState {
  gameId: number;
  showMessage: boolean;
}

class QuickDrawGameWidget extends React.Component<IDrawGameWidgetProps, IDrawGameWidgetState> {

  private GAME_NOT_AVAILABLE_MESSAGE = 'This game is not available.';

  constructor(props: IDrawGameWidgetProps) {
    super(props);
    this.state = {
      gameId: 0,
      showMessage: false
    };
  }

  public componentDidMount = async () => {
    document.title = 'Game Widget';
    this.registerGame();
    const {
      match: { params }
    } = this.props;
    if (params.gameId?.length > 0) {
      await Promise.all([
          this.loadQuickDrawResultsAndShowIFrame(params.gameId),
          this.props.getQuickDrawConfig(parseInt(params.gameId, 10))
        ]);
    } else {
      await this.showErrorMessage(this.GAME_NOT_AVAILABLE_MESSAGE);
    }
  };

  registerGame = () => {
    window.addEventListener('message', this.receiveMessage, false);
  };

  receiveMessage = async (event: any) => {
    try {
      const data = JSON.parse(event.data);
      if (!data || data.type !== 'poGame') return;

      switch (data.name) {
        case 'onGameReadyHandler': {
          const { quickDrawResult } = this.props.quickDraws;
          if (!quickDrawResult || quickDrawResult.length === 0) return;
          const gameData = this.getGameData(quickDrawResult);
          const iframe = document.getElementById('iframe');
          // @ts-ignore
          iframe?.contentWindow.postMessage(
            JSON.stringify({
              type: 'poGameParent',
              name: 'setGameConfig',
              data: gameData
            }),
            '*'
          );
          return console.warn('***** onGameReadyHandler');
        }
        default:
          return;
      }
    } catch {}
  };

  getGameData = quickDrawResult => {
    const { quickDrawConfig } = this.props.quickDraws;
    const isSingleLayout = quickDrawConfig?.winningNumberLayout === WinningNumberLayout.SINGLE;
    const gameData = isSingleLayout ? this.getGameDataForSingleBall(quickDrawResult) : this.getGameDataForMultipleBall(quickDrawResult);
    return gameData;
  };

  getGameDataForSingleBall = quickDrawResult => {
    const lastDrawResult = quickDrawResult[quickDrawResult.length - 1];
    const gameId = this.state.gameId;
    const drawId = lastDrawResult.externalId;
    const winningNumber = lastDrawResult['resultData'][0]['data'];
    const winningPrize = lastDrawResult.cashAmount;
    const availableDraws = this.getAvailableDrawsForSingleBalls(quickDrawResult);
    return {
      gameId,
      drawId,
      winningNumber,
      winningPrize,
      availableDraws
    };
  };

  getGameDataForMultipleBall = quickDrawResult => {
    const gameId = this.state.gameId;
    const lastDrawResult = quickDrawResult[quickDrawResult.length - 1];
    const drawId = lastDrawResult?.externalId;
    const drawResult = lastDrawResult?.resultData && lastDrawResult?.resultData?.length > 0 ? lastDrawResult?.resultData.map(d => parseInt(d.data, 10)) : [];
    const availableDraws = this.getAvailableDrawsForMultipleBalls(quickDrawResult);
    return {
      gameId,
      drawId,
      drawResult,
      availableDraws
    };
  }

  getAvailableDrawsForSingleBalls = quickDrawResult => {
    const availableDraws = {};
    quickDrawResult?.forEach(r => {
      if (r['resultData'] && r['resultData'].length > 0) {
        // @ts-ignore
        availableDraws[r.externalId] = {
          winningNumber: r['resultData'][0]['data'],
          winningPrize: r['cashAmount']
        };
      }
    });
    return availableDraws;
  };

  getAvailableDrawsForMultipleBalls = quickDrawResult => {
    const availableDraws = {};
    quickDrawResult?.forEach(r => {
      if (r['resultData'] && r['resultData'].length > 0) {
        // @ts-ignore
        availableDraws[r.externalId] = {
          drawResult: r['resultData'].map(d => parseInt(d.data, 10))
        };
      }
    });
    return availableDraws;
  };

  public render(): React.ReactNode {

    const { classes } = this.props;
    const { loading, quickDrawLatestResult } = this.props.quickDraws;
    return (
      <>
        { this.state.showMessage ? <MessageBar /> : null }
        { loading ? <AppSpinner label={'Loading game data, please wait...'}/> : null }
        { !loading && quickDrawLatestResult ? <iframe scrolling="no" src={quickDrawLatestResult?.gameUrl} id={'iframe'}
                                                      className={classes.iframe} allow="autoplay" title={'quickDraw'} /> : null}
      </>
    );
  }

  private loadQuickDrawResultsAndShowIFrame = async gameId => {
    await this.setState({ gameId });
    await this.props.getLatestQuickDrawWithWinnerResultByGame(gameId);
    const { quickDrawLatestResult } = this.props.quickDraws;
    if (!!quickDrawLatestResult && quickDrawLatestResult.drawDate) {
      const lastResult = new Date(quickDrawLatestResult.drawDate);
      lastResult.setTime(lastResult.getTime() - (24 * 3600000));
      await this.props.getQuickDrawJackpotResultsFromDrawDate(gameId, lastResult.toISOString(), 0);
    } else {
      await this.showErrorMessage(this.GAME_NOT_AVAILABLE_MESSAGE);
    }
  };

  private showErrorMessage = async message => {
    await this.setState({ showMessage: true });
    await this.props.showMessageBar({
      message,
      type: 'warning',
      messageTimeout: 300000
    });
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({ quickDraws }) => ({
  quickDraws
});

const mapDispatchToProps = {
  getQuickDrawJackpotResultsFromDrawDate,
  getLatestQuickDrawWithWinnerResultByGame,
  getQuickDrawConfig,
  showMessageBar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withRouter(withStyles(styles)(QuickDrawGameWidget)));
