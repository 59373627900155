import { Grid, WithStyles, withStyles } from '@material-ui/core';
import styles from 'assets/jss/modules/account-activation/AccountActivationScreenStyle';
import PasswordResetForm from 'modules/password-reset/components/PasswordResetForm';
import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { checkUnsuspendKey as checkUnsuspendKeyAction, reactivateAccountFinish as reactivateAccountFinishAction, reset as resetAction } from 'redux/reducers/account-reactivation/actions';
import { getUrlParameter } from 'utils/urlUtils';
import ReactivationContent from './components/ReactivationContent';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IAccountReactivationScreenProps
  extends RouteComponentProps,
    StateProps,
    DispatchProps,
    WithStyles<typeof styles>,
    WithTranslation {}

const AccountReactivationScreen: React.FC<IAccountReactivationScreenProps> = ({
  classes,
  success,
  t,
  reactivateAccountFinish,
  checkUnsuspendKey,
  unsuspendKeyValid,
  reset,
  loading
}) => {
  document.title = t('reactivation.title');

  const location = useLocation();
  const key = getUrlParameter('key', location.search);

  useEffect(() => {
    checkUnsuspendKey(key);

    return () => {
      reset();
    };
  }, [key, checkUnsuspendKey]);

  const onFormSubmit = (password: string) => () => {
    reactivateAccountFinish(key, password);
  };

  return (
    <div className={classes.screen}>
      <div className={classes.container}>
        <Grid container={true} justify="center" item={true} xs={12}>
          {unsuspendKeyValid && !success ? (
            <PasswordResetForm
              onSubmit={onFormSubmit}
              title={t('reactivation.form.title')}
              description={t('reactivation.form.description')}
            />
          ) : (
            <ReactivationContent type={success ? 'success' : 'error'} loading={loading} />
          )}
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = ({ accountReactivation: { success, unsuspendKeyValid, loading } }: IRootState) => ({
  unsuspendKeyValid,
  success,
  loading
});

const mapDispatchToProps = {
  reactivateAccountFinish : reactivateAccountFinishAction,
  checkUnsuspendKey: checkUnsuspendKeyAction,
  reset: resetAction
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(AccountReactivationScreen)));
