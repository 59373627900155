import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { Activity, ActivityList } from '@pbl/pbl-react-core/lib/models/loyalty';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import { IHomeEarnActivity } from 'config/homeScreenEarnActivities';
import ActivityListItem from 'modules/earn/components/ActivityListItem';
import EarnCard from 'modules/home/components/EarnCard';
import SectionHeading from 'modules/home/components/SectionHeading';
import * as React from 'react';

interface IProps extends WithStyles<typeof styles> {
  activities: IHomeEarnActivity[];
  earnActivities: ActivityList;
  onEarnItemClicked: any;
}

const EarnSection: React.FunctionComponent<IProps> = ({ classes, activities, earnActivities, onEarnItemClicked }) => {
  const activitiesToRender: Activity[] = earnActivities
    .filter(activity => activity.isVisibleOnWebListPage)
    .filter((_1, index) => index < constants.HOME_SCREEN_EARN_ACTIVITIES_COUNT);

  return (
    <div className={classNames(classes.section)}>
      <SectionHeading title="Earn Points" linkRoute="/earn" linkTitle="MORE WAYS TO EARN" linkLabel="More Ways To Earn" />

      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {activitiesToRender &&
          activitiesToRender.map(activity => (
            <Grid key={activity.id} xs={12} md={4} item={true} className={classes.sectionCard}>
              {
                // @ts-ignore
                <ActivityListItem activity={activity} onPress={onEarnItemClicked.bind(this, activity)} />
              }
            </Grid>
          ))}
      </Grid>

      <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
        {activities.map(activity => (
          <Grid key={activity.activityId} xs={12} md={4} item={true} className={classes.sectionCard}>
            {<EarnCard activity={activity} />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(EarnSection);
