import { Button, Grid, Theme, Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import { IPreferenceCategory, IPreferencesState } from '@pbl/pbl-react-core/lib/models/preferences';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/settings/preferences/PreferencesScreenStyle';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'redux/reducers';
import { getActivity, getPromotionsEmailActivity } from 'redux/reducers/loyalty/actions';
import {
  fetchMyPreferences,
  fetchOptions,
  loadMyPreferences,
  savePreferences,
  toggleAllPreference,
  togglePreference,
  updateSubCategory
} from 'redux/reducers/preferences/actions';
import Preference from './components/Preference';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface IPreferencesScreenProps extends StateProps, DispatchProps, WithStyles<typeof styles> {
  theme: Theme;
}

interface IPreferencesScreenState {
  activityCompleted: boolean;
}

const ALL = '__ALL__';

class PreferencesScreen extends React.Component<IPreferencesScreenProps, IPreferencesScreenState> {
  constructor(props: IPreferencesScreenProps) {
    super(props);
    this.state = { activityCompleted: false };
  }

  public async componentDidMount() {
    document.title = 'Preferences';
    await this.getData();
  }

  public render() {
    const { classes, preferences, loading, promotionsEmailActivity } = this.props;
    const { activityCompleted } = this.state;

    return (
      <Grid container={true} spacing={3} direction="column">
        {loading && <AppSpinner label={'Loading...'} />}

        {activityCompleted && !!promotionsEmailActivity ? (
          <EarnPointsModal
            pointEarned={promotionsEmailActivity.num_points}
            isVisible={activityCompleted}
            onClose={this.onDismissEarnPointsModal}
          />
        ) : null}

        <Grid container={true} direction="row" alignItems="center" className={classes.header}>
          <Typography variant="body2" className={classes.headerText}>
            Choose when you want to receive emails from us.
          </Typography>
        </Grid>
        <div>
          <Preference
            item={{
              key: ALL,
              booleanValue: preferences.all || false,
              label: 'Send me all email communications',
              subCategories: preferences.categories,
              options: preferences.options
            }}
            onToggle={this.onTogglePreference}
            handlePreferenceDropdownChange={this.handlePreferenceDropdownChange}
          />
        </div>
        <Grid item={true}>
          <Button size="large" variant="contained" color="primary" className={classes.button} onClick={this.onSave} aria-label="Save">
            Save
          </Button>
        </Grid>
      </Grid>
    );
  }

  private getData = async () => {
    await this.props.fetchMyPreferences();
    await this.props.loadMyPreferences();
    await this.props.fetchOptions();
    await this.props.getPromotionsEmailActivity();
  };

  private onDismissEarnPointsModal = (): void => {
    this.setState({ activityCompleted: false });
  };

  private onTogglePreference = (name: string) => {
    if (name === ALL) {
      this.props.toggleAllPreference();
    } else {
      this.props.togglePreference(name);
    }
  };

  public onSave = async () => {
    if (!!this.props.promotionsEmailActivity) {
      await this.props.getActivity(this.props.promotionsEmailActivity.id);
    }
    await this.props.savePreferences();

    if (this.isNewsEmailPreferenceToggledOn() && !this.state.activityCompleted && !this.props.maximumLimitReached) {
      this.setState({ activityCompleted: true });
    }
  };

  public isNewsEmailPreferenceToggledOn = (): boolean => this.props.preferences && !!this.props.preferences.categories.find(p => p.key === 'PROMOTIONS_EMAIL' && p.booleanValue);

  public handlePreferenceDropdownChange = (subCategory: IPreferenceCategory, value: number) => {
    const { categories } = this.props.preferences;
    if (subCategory.options) {
      const option = subCategory.options.find(opt => opt.id === value);
      // @ts-ignore
      subCategory.value = option;
      const category = categories.find((c: any) => {
        if (!_.isEmpty(c.subCategories)) {
          return _.map(c.subCategories, sub => {
            // @ts-ignore
            if (sub.MessageCategoryId === option.messageCategoryId) {
              sub.value = { ...sub.value, ...option };
              return c;
            }
          });
        }
        return undefined;
      });
      this.props.updateSubCategory(category);
    }
  };
}

const mapStateToProps = ({
  preferences: { tempState, fetching },
  loyalty: { promotionsEmailActivity, maximumLimitReached }
}: IRootState) => ({
  preferences: tempState || ({} as IPreferencesState),
  loading: fetching,
  promotionsEmailActivity,
  maximumLimitReached
});

const mapDispatchToProps = {
  toggleAllPreference,
  togglePreference,
  fetchMyPreferences,
  loadMyPreferences,
  fetchOptions,
  savePreferences,
  updateSubCategory,
  getActivity,
  getPromotionsEmailActivity
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(PreferencesScreen)));
