import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import { IDenomination } from '@pbl/pbl-react-core/lib/models/arcades/types';
import { GameVendor } from '@pbl/pbl-react-core/lib/models/games/types';
import { PrizeType } from '@pbl/pbl-react-core/lib/models/ticket/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import { ArcadeDetails, ArcadeHeader, ArcadeMessagePopup } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/arcade/ArcadesScreenStyle';
import constants from 'config/constants';
import ArcadeGame from 'modules/arcade/ArcadeGame';
import utils from 'modules/arcade/utils';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import {
  debitArcadeGame,
  endDigitalArcadeGameData,
  endNGLGame,
  endNGLGameFun,
  fetchArcadeGame,
  fetchDenominations,
  getNGLToken,
  playForFun,
  resetGame,
  setUnAuthenticatedUserGameData,
  startNGLGame,
  startNGLGameFun,
  startNGLGameSession,
  startNGLGameSessionFun
} from 'redux/reducers/arcade/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { fetchUserTokens } from 'redux/reducers/ledger/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  gameId: string;
}

export interface IArcadeDetailsScreenProps
  extends StateProps,
    DispatchProps,
    WithTranslation,
    WithStyles<typeof styles>,
    RouteComponentProps<IMatchParams> {
  theme: Theme;
}

interface IArcadeDetailsScreenState {
  loading: boolean;
  launchGame: boolean;
  errorModalVisible: boolean;
  selectedWagerIndex: number;
  gameCompleted: boolean;
  gameType: string;
  denominations: IDenomination[];
  windowWidth: number;
}

class ArcadeDetailsScreen extends React.Component<IArcadeDetailsScreenProps, IArcadeDetailsScreenState> {
  constructor(props: IArcadeDetailsScreenProps) {
    super(props);
    this.state = {
      loading: false,
      launchGame: false,
      errorModalVisible: false,
      selectedWagerIndex: constants.ARCADE_WAGER_DEFAULT_INDEX,
      gameCompleted: false,
      gameType: 'prize',
      denominations: [],
      windowWidth: window.innerWidth
    };
  }

  public async componentDidMount() {
    document.title = 'Lucky Lounge Details';
    const {
      match: { params },
      isLoggedIn
    } = this.props;
    // if user is not logged and the SECURE_LL_DETAILS_PAGE is set then redirecto the login page
    if (!isLoggedIn && (this.props.authorizedFlags?.includes('SECURE_LL_DETAILS_PAGE') ?? false)) {
      this.props.history.push('/login', { from: this.props.location });
    } else if (params.gameId) {
      scrollToTheTop();
      await this.setState({ loading: true });
      // If user is logged in, gets the the players' tokens. If it comes from the login page, it also set the
      // previous selected game and wager.
      if (isLoggedIn) {
        if (this.props.deferredPlay) {
          await this.setState({
            gameType: this.props.gameType ?? this.state.gameType,
            selectedWagerIndex: this.props.wagerIndex ?? this.state.selectedWagerIndex
          });
        }
        await this.props.fetchUserTokens();
      }

      // fetches the game, wager and denominations.
      await this.props.fetchArcadeGame(params.gameId);
      await this.updateWager();
      await this.getDenominations();
      await this.setState({ loading: false });
      // perform the next actions when the user is logged in.
      if (isLoggedIn) {
        if (this.props.selectedGame && this.props.selectedGame.vendor === GameVendor.NGLPBL) {
          await this.props.getNGLToken(true);
          await this.props.startNGLGameSession(this.props.selectedGame.gameId);
        }
        // If it comes from the login page, then try to lauch the game based on its type.
        if (this.props.deferredPlay && this.props.deferredGame === this.props.selectedGame?.gameId) {
          if (this.props.gameType === 'fun') {
            this.launchFunGame();
          } else if (this.props.gameType === 'prize') {
            const gameCostTokens = this.state.denominations[this.state.selectedWagerIndex].value;
            // check if player has enough tokens to lauch the game
            if (gameCostTokens <= this.props.userTokens.balance) {
              this.launchGame();
            }
          }
          // Reset the state to avoid playing again on reload.
          this.props.setUnAuthenticatedUserGameData({});
        }
      } else {
        if (this.props.deferredGame !== params.gameId) {
          this.props.setUnAuthenticatedUserGameData({});
        }
      }
    } else {
      await this.showInvalidGameError();
    }
    window.addEventListener('resize', this.handleWindowResize);
  }

  private handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  public componentWillUnmount() {
    this.props.resetGame();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  private showInvalidGameError = async () => {
    await this.navigateToArcades({
      message: `This game is not available.`,
      type: 'warning',
      messageTimeout: 3000
    });
  };

  private navigateToArcades = async (message?: IErrorParams) => {
    const { history } = this.props;
    await history.push('/lucky-lounge', { message });
  };

  private navigateToBuyScreen = (): void => {
    this.props.history.push('/lucky-lounge/store');
  };

  private navigateToHistory = (): void => {
    this.props.history.push('/lucky-lounge/history');
  };

  private launchGame = async () => {
    const { selectedGame, denominations, isLoggedIn } = this.props;

    if (!isLoggedIn) {
      // When the user is not logged in, it's redirected to the login page.
      // The game and player selection is saved to use when the page loads back
      this.props.history.push('/login', { from: this.props.location });
      this.props.setUnAuthenticatedUserGameData({
        deferredPlay: true,
        gameType: 'prize',
        wagerIndex: this.state.selectedWagerIndex,
        deferredGame: selectedGame?.gameId
      });
    } else if (selectedGame) {
      switch (selectedGame.vendor) {
        case GameVendor.PBL:
          if (denominations) {
            await this.props.debitArcadeGame(denominations[this.state.selectedWagerIndex].value, selectedGame.gameId);
          }
          break;
        case GameVendor.NGLPBL:
          await this.props.startNGLGame(selectedGame.gameId, this.state.denominations[this.state.selectedWagerIndex].value);
          break;
        case GameVendor.NGL:
          await this.props.getNGLToken(false);
          break;
        default:
          break;
      }
    }
    this.setState({
      launchGame: !this.props.showErrorLoadingGame,
      errorModalVisible: !!this.props.showErrorLoadingGame,
      gameType: 'prize'
    });
  };

  private launchFunGame = async () => {
    const { selectedGame, denominations } = this.props;
    if (selectedGame && selectedGame.vendor === GameVendor.PBL && denominations) {
      await this.props.playForFun(selectedGame.gameId);
    } else if (selectedGame && selectedGame.gameId && selectedGame.vendor === GameVendor.NGLPBL) {
      await this.props.startNGLGameSessionFun(selectedGame.gameId);
      await this.props.startNGLGameFun(selectedGame.gameId, this.state.denominations[this.state.denominations.length - 1].value);
      await this.props.endNGLGameFun(selectedGame.gameId);
    }
    this.setState({
      launchGame: !this.props.showErrorLoadingGame,
      errorModalVisible: !!this.props.showErrorLoadingGame,
      gameType: 'fun'
    });
  };

  private closeGame = async () => {
    await this.props.fetchUserTokens();
    this.setState({ launchGame: false });
    this.sendAnalyticsEvent(5, 'CloseGame', 'Click on "Close Game" buton closes the game and returns to the game lobby.');
  };

  private closeErrorModal = () => this.setState({ errorModalVisible: false });

  private launchErrorModal = (): React.ReactNode => {
    const { t } = this.props;
    return (
      <ArcadeMessagePopup
        open={this.state.errorModalVisible}
        title={t('arcade.gameLaunchError.title')}
        text={t('arcade.gameLaunchError.text')}
        closeText={t('arcade.gameLaunchError.close')}
        onClose={this.closeErrorModal}
        disableBackdropClick={true}
      />
    );
  };

  private sendAnalyticsEvent = (ActionID: number, Action: string, ActionDescription: string) => {
    // @ts-ignore
    window.dataLayer.push({
      event: constants.IN_GAME_EVENT,
      ActionID,
      Action,
      ActionDescription,
      ActionLabel: '',
      GameID: this.props.selectedGame?.gameId,
      GameEngine: constants.GAME_ENGINE,
      PlayMode: this.state.gameType === 'prize' ? 'Token' : 'Demo',
      TicketCostPlayed: this.state.gameType === 'prize' ? this.getDenominations()?.[this.state.selectedWagerIndex].value : '',
      Lottery: constants.LOTTERY,
      Resolution: window.screen.height + 'x' + window.screen.width,
      Orientation: constants.ORIENTATION,
      DesignType: constants.DESIGN_TYPE,
      GameSupplier: constants.GAME_SUPPLIER,
      GameDeveloper: constants.GAME_DEVELOPER,
      TicketType: constants.TICKET_TYPE,
      ActionEmitter: constants.ACTION_EMITTER_PLAYER,
      IsNative: false,
      Platform: utils.getPlatform(),
      Device: utils.getDevice()
    });
  };
  private getSpinResultsFromExtraData = (extraData: string) => {
    try {
      if (!!extraData) {
        const obj = JSON.parse(extraData);
        if (!!obj.spinResults) {
          return JSON.stringify(obj.spinResults);
        }
      }
    } catch (e) {
      return undefined;
    }
  };
  private updateWager = async (index?: number) => {
    if (this.state.selectedWagerIndex === index) return;
    if (index || index === 0) {
      this.setState({ selectedWagerIndex: index });
    } else {
      if (this.state.denominations && this.state.denominations.length === 1) {
        this.setState({ selectedWagerIndex: 0 });
      }
    }
  };
  private gameReplay = async () => {
    await this.gameEnded();
    await this.launchFunGame();
  };
  private onRelaunch = async () => {
    await this.setState({ launchGame: false });
    await this.props.isAuthenticated();
  };
  private endNGLGame = async () => {
    if (
      !this.props.selectedGame ||
      (this.props.selectedGame && this.props.selectedGame.vendor === GameVendor.PBL) ||
      this.state.gameType === 'fun'
    ) {
      return;
    }
    await this.props.endNGLGame(this.props.selectedGame.gameId);
    await this.props.fetchUserTokens();
  };
  private gameEnded = async () => {
    await this.props.fetchUserTokens();
    await this.setState({ loading: true });
    if (this.state.gameCompleted) {
      if (
        this.props.sweepstakesGameConfig &&
        this.props.sweepstakesGameConfig.prizes &&
        this.props.sweepstakesGameConfig.prizes.length > 0 &&
        this.props.sweepstakesGameConfig.prizes[0].digitalRevealToken
      ) {
        await this.props.endDigitalArcadeGameData(this.props.sweepstakesGameConfig.prizes[0].digitalRevealToken);
      }
    }
    await this.setState({ loading: false });
  };
  private gameCompleted = async () => {
    await this.props.fetchUserTokens();
    this.setState({ gameCompleted: true });
  };
  private getDenominations = (): IDenomination[] => {
    const { selectedGame, denominations, selectedNGLGame } = this.props;
    if (!selectedGame) return [];
    if (denominations && denominations.length > 0) {
      this.setState({ denominations });
      return denominations;
    } else if (selectedNGLGame && selectedNGLGame.wagers && selectedNGLGame.wagers.length) {
      this.setState({ denominations: selectedNGLGame.wagers });
      return selectedNGLGame.wagers;
    } else {
      const data = [
        {
          value: selectedGame.retailPrice,
          topPrizeValue: selectedGame.topPrize,
          topPrizeType: PrizeType.TOKENS
        }
      ];
      this.setState({ denominations: data });
      return data;
    }
  };
  public render() {
    const {
      classes,
      userTokens,
      history,
      selectedGame,
      loading,
      digitalRevealTokenDetails,
      sweepstakesGameConfig,
      playDataNGLGame,
      launchToken,
      selectedNGLGame
    } = this.props;
    const { gameType, windowWidth } = this.state;
    const onNavigate = () => {
      history.push('/lucky-lounge');
    };
    if (!selectedGame) {
      return null;
    }
    let spinResults;
    const gamePoints =
      sweepstakesGameConfig && sweepstakesGameConfig.prizes && sweepstakesGameConfig.prizes.length > 0
        ? sweepstakesGameConfig.prizes[0].amount
        : 0;

    if (
      sweepstakesGameConfig &&
      sweepstakesGameConfig.prizes &&
      sweepstakesGameConfig.prizes.length > 0 &&
      !!sweepstakesGameConfig.prizes[0].extraData
    ) {
      spinResults = this.getSpinResultsFromExtraData(sweepstakesGameConfig.prizes[0].extraData);
    }
    const topPrize =
      gameType === 'fun'
        ? selectedGame.topPrize
        : this.state.denominations.length > 1
        ? this.state.denominations[this.state.selectedWagerIndex].topPrizeValue
        : this.state.denominations.length === 1
        ? this.state.denominations[0].topPrizeValue
        : selectedGame.topPrize;
    const wager =
      this.state.denominations.length > 1
        ? gameType === 'fun'
          ? this.state.denominations[this.state.denominations.length - 1].value
          : this.state.denominations[this.state.selectedWagerIndex].value
        : this.state.denominations.length === 1
        ? this.state.denominations[0].value
        : selectedGame.retailPrice;

    const imageUrl = windowWidth >= 650 ? selectedGame.ticketFullImage : selectedGame.ticketFullImageMobile;
    return (
      <div
        style={{
          backgroundImage: `url(${imageUrl})`
        }}
        className={classes.detailContainer}
      >
        <ScrollToTopOnMount />
        {loading ? <AppSpinner label={'Loading Game...'} /> : null}
        {this.launchErrorModal()}
        <ArcadeGame
          isLoggedIn={this.props.isLoggedIn}
          game={selectedGame}
          launchGame={this.state.launchGame}
          onCloseGame={this.closeGame}
          gameData={digitalRevealTokenDetails}
          gamePoints={gamePoints}
          spinResults={spinResults}
          isPBLGame={selectedGame.vendor === null || selectedGame.vendor === GameVendor.PBL || selectedGame.vendor === GameVendor.NGLPBL}
          onGameExitHandler={this.gameEnded}
          onGameEndHandler={this.gameCompleted}
          onGameReplayHandler={this.gameReplay}
          onGameStartHandler={this.endNGLGame}
          onRelaunch={this.onRelaunch}
          gameType={gameType}
          topPrize={topPrize}
          wager={wager}
          gameNGL={playDataNGLGame}
          selectedNGLGame={selectedNGLGame}
          selectedIndex={this.state.selectedWagerIndex}
          launchToken={launchToken}
        />
        <Grid container={true} className={classes.gridContainer}>
          <Grid item={true} xs={12} className={classes.gradientHeader}>
            <ArcadeHeader
              className={classes.container}
              navigateToBuyScreen={this.navigateToBuyScreen}
              navigateToHistory={this.navigateToHistory}
              onNavigate={onNavigate}
              balance={userTokens.balance}
              title={selectedGame.name}
              isLoggedIn={this.props.isLoggedIn}
            />
          </Grid>
          {this.state.denominations.length > 0 ? (
            <ArcadeDetails
              selectedWagerIndex={this.state.denominations.length > 1 ? this.state.selectedWagerIndex : 0}
              redeemablePoints={userTokens.balance}
              denominations={this.state.denominations}
              launchGame={this.launchGame}
              launchFunGame={this.launchFunGame}
              updateWager={this.updateWager}
              playForFun={!!selectedGame.playForFun}
              howToPlayText={selectedGame.description}
              logo={selectedGame.logo}
              isLoggedIn={this.props.isLoggedIn}
            />
          ) : null}
        </Grid>
      </div>
    );
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mapStateToProps = ({
  authentication: { accessToken, account },
  ledger: { userTokens },
  arcades: {
    loading,
    selectedGame,
    sweepstakesGameConfig,
    digitalRevealTokenDetails,
    denominations,
    selectedNGLGame,
    playDataNGLGame,
    launchToken,
    showErrorLoadingGame,
    gameType,
    wagerIndex,
    deferredPlay,
    deferredGame
  },
  feature: { authorizedFlags }
}: IRootState) => ({
  userTokens,
  loading,
  selectedGame,
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  sweepstakesGameConfig,
  digitalRevealTokenDetails,
  denominations,
  selectedNGLGame,
  playDataNGLGame,
  launchToken,
  showErrorLoadingGame,
  gameType,
  wagerIndex,
  deferredPlay,
  deferredGame,
  authorizedFlags
});

const mapDispatchToProps = {
  showMessageBar,
  isAuthenticated,
  fetchUserTokens,
  fetchArcadeGame,
  debitArcadeGame,
  fetchDenominations,
  endDigitalArcadeGameData,
  resetGame,
  playForFun,
  startNGLGame,
  endNGLGame,
  startNGLGameSession,
  startNGLGameSessionFun,
  startNGLGameFun,
  endNGLGameFun,
  getNGLToken,
  setUnAuthenticatedUserGameData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation()(withRouter(withTheme(withStyles(styles)(ArcadeDetailsScreen)))));
