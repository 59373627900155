import * as yup from 'yup';

export const feedbackSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    .required()
    .email(),
  msgSubject: yup.string().required(),
  msgBody: yup.string().required()
});
