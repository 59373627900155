import { Grid, Link, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/shared/components/footer/FooterStyles';
import classNames from 'classnames';
import { IMenuItem } from 'config/menu';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

export interface IMainMenuProps {
  mainMenu: IMenuItem[];
  isAuthenticated: boolean;
}

interface IProps extends IMainMenuProps, WithStyles<typeof styles> {}

const MainMenu: React.FunctionComponent<IProps> = ({ mainMenu, classes, isAuthenticated }) => {
  const history = useHistory();

  const renderMenuItem = (item: IMenuItem) => {
    const onClickItem = () => {
      if (item.route) {
        history.push(item.route);
      }
    };

    return (
      <Grid className={classes.listItem} key={`${item.title}-${item.route}`}>
        <Tooltip title={item.title} aria-label={item.title}>
        <Link
          component="button"
          onClick={onClickItem}
          className={classNames({
            [classes.navLink]: true,
            [classes.navLinkActive]: !!item.isActive
          })}
          color="primary"
        >
          <span className={classes.navLinkText}>{item.title}</span>
        </Link>
      </Tooltip>
      </Grid>
    );
  };
  const menu = isAuthenticated ? mainMenu.filter(x => !x.hideOnAuth) : mainMenu.filter(x => !x.hideOnUnAuth);
  return (
    <Grid container={true} item={true} alignItems="center" className={classes.list}>
      {menu.map(item => renderMenuItem(item))}
    </Grid>
  );
};
export default withStyles(styles)(MainMenu);
