import { CardMedia, Grid, Hidden, WithStyles, withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import classNames from 'classnames';
import constants from 'config/constants';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SectionHeading from './SectionHeading';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  onCardClicked: any;
}

const LuckyLoungeSection: React.FunctionComponent<IProps> = ({ classes, onCardClicked, t }) => (
  <div className={classNames(classes.luckyLoungeSection)}>
    <div className={classNames(classes.sectionHeading)}>
      <SectionHeading
        title={t('home.luckyLoungeSectionTitle')}
        linkRoute="/lucky-lounge"
        linkTitle={t('home.luckyLoungeSectionLink')}
        linkLabel={t('home.luckyLoungeSectionLinkLabel')}
      />
    </div>
    <Grid container={true} direction="row" alignItems="center" className={classes.sectionList}>
      <Grid item={true} xs={12}>
        <Hidden xsDown={true}>
          <Link tabIndex={0} component="button" onClick={onCardClicked}>
            <CardMedia
              component="img"
              image={constants.LUCKY_LOUNGE_WEB_IMAGE}
              className={classNames(classes.luckLoungeMedia)}
              alt="Explore all lucky lounge"
            />
          </Link>
        </Hidden>
        <Hidden smUp={true}>
          <Link tabIndex={0} component="button" onClick={onCardClicked}>
            <CardMedia
              component="img"
              image={constants.LUCKY_LOUNGE_MOBILE_IMAGE}
              className={classNames(classes.luckLoungeMedia)}
              alt="Explore all lucky lounge"
            />
          </Link>
        </Hidden>
      </Grid>
    </Grid>
  </div>
);

export default withTranslation()(withStyles(styles)(LuckyLoungeSection));
