import {
  CHECK_UNSUSPEND_KEY,
  IReactivationState,
  REACTIVATION_FINISH,
  REACTIVATION_STATE
} from '@pbl/pbl-react-core/lib/models/account-reactivation';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { REACTIVATE_ACCOUNT } from './actions';

const INITIAL_STATE: IReactivationState = {
  show: false,
  success: false,
  unsuspendKeyValid: false,
  loading: true
};

export default (state = INITIAL_STATE, action): IReactivationState => {
  switch (action.type) {
    case REACTIVATE_ACCOUNT:
      return {
        ...state,
        show: action.payload
      };
    case REQUEST(CHECK_UNSUSPEND_KEY):
    case REQUEST(REACTIVATION_FINISH):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(REACTIVATION_FINISH):
      return {
        ...state,
        success: true,
        loading: false
      };
    case SUCCESS(CHECK_UNSUSPEND_KEY):
      return {
        ...state,
        unsuspendKeyValid: true,
        loading: false
      };
    case FAILURE(CHECK_UNSUSPEND_KEY):
      return {
        ...state,
        loading: false,
        unsuspendKeyValid: false
      };
    case FAILURE(REACTIVATION_FINISH):
      return {
        ...state,
        loading: false,
        success: false
      };
    case REACTIVATION_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
