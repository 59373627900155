import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import Poll from '@pbl/pbl-react-web-components/lib/poll/Poll';
import styles from 'assets/jss/modules/poll/PollScreenStyle';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import {
  fetchActivities,
  fetchUserActivities,
  resetActivityCompleted,
  resetState,
  setActivityCompleted,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import { fetchPoll, reset, resetError, submitPoll } from 'redux/reducers/poll/action';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  activityId: string;
}

interface IPollScreenProps extends RouteComponentProps<IMatchParams>, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

interface IPollScreenState {
  displayImage: boolean;
  answerId: number | null;
  loading: boolean;
}

type Props = ConnectedProps<typeof connector> & IPollScreenProps;

class PollScreen extends React.Component<Props, IPollScreenState> {
  constructor(props: Props) {
    super(props);
    this.state = { displayImage: false, loading: true, answerId: null };
  }

  public async componentDidMount() {
    document.title = 'Poll Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.trim().length > 0) {
      await this.getData();
    } else {
      await this.showInvalidActivityError();
    }
  }

  public componentWillUnmount() {
    this.props.reset();
    this.props.setIsFormDirty(false);
  }

  public async getData() {
    const {
      match: {
        params: { activityId }
      }
    } = this.props;

    await this.props.resetState();
    if (this.props.isLoggedIn) {
      await this.props.fetchUserActivities();
    } else {
      await this.props.fetchActivities();
    }

    const selectedActivity = this.props.userActivities.find(activity => activity.id.toString() === activityId);
    if (selectedActivity) {
      await this.props.setSelectedUserActivity(selectedActivity);
      await this.props.fetchPoll(selectedActivity.id);
    } else {
      await this.showInvalidActivityError();
    }
    this.setState({ loading: false });
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `Something went wrong. Try again!`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  public onChange = (answer: any) => {
    this.setState({ answerId: answer.id });
    this.props.setIsFormDirty(true);
  };

  public postPoll = async () => {
    const { isLoggedIn, selectedActivity } = this.props;
    const { answerId } = this.state;
    if (!isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    if (answerId && selectedActivity) {
      await this.props.submitPoll(answerId, selectedActivity.id);
    }

    const {
      poll: { pollPostResponse, errorMessage }
    } = this.props;
    if (errorMessage) {
      return;
    }
    if (pollPostResponse && selectedActivity) {
      this.props.setActivityCompleted({
        pointsEarned: selectedActivity.num_points,
        activity: selectedActivity
      });
      this.props.setIsFormDirty(false);
    } else {
      this.props.history.push('/earn', {
        message: { message: `This activity is not available.`, type: 'warning', messageTimeout: 3000 }
      });
    }
  };

  public getContent() {
    const {
      poll: { poll, pollPostResponse },
      selectedActivity,
      formMetadata
    } = this.props;

    const bonusPoints = selectedActivity ? selectedActivity.totalBonusPoints : 0;
    return poll ? (
      <Grid item={true} xs={12}>
        {poll ? (
          <Poll
            poll={poll}
            pollPostResponse={pollPostResponse}
            onChange={this.onChange}
            onSave={this.postPoll}
            isLoading={this.props.poll.loading || this.props.loading}
            bonusPoints={bonusPoints}
            isDirty={formMetadata.isFormDirty}
            playersAnswerId={this.state.answerId}
          />
        ) : null}
      </Grid>
    ) : null;
  }

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    // this.props.history.push('/earn');
  };

  private navigateTo = (): void => {
    this.props.history.push('/earn');
  };

  public render() {
    const {
      poll: { poll },
      classes,
      activityCompleted,
      selectedActivity
    } = this.props;
    const isLoadingData = this.state.loading;
    return (
      <div>
        {activityCompleted !== null ? (
          <EarnPointsModal
            pointEarned={
              !!selectedActivity && selectedActivity.totalBonusPoints > 0
                ? selectedActivity.totalBonusPoints
                : activityCompleted.pointsEarned
            }
            isVisible={activityCompleted !== null}
            onClose={this.onDismissEarnPointsModal}
          />
        ) : null}
        {poll && (
          <div className={classes.container}>
            {isLoadingData && this.props.poll.loading ? (
              <AppSpinner label={'Loading Activities'} />
            ) : (
              <Grid className={classes.container} container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <EarnActivityHeader
                    data={{
                      image: poll.image_url,
                      title: !!selectedActivity?.extra_data?.display_name ? selectedActivity.extra_data.display_name : poll.title,
                      description:
                        !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.additionalInformation
                          ? selectedActivity.extra_data.additionalInformation
                          : poll.description,
                      points: poll.num_points,
                      preview_url: !!poll.extra_data && !!poll.extra_data.preview_url ? poll.extra_data.preview_url : undefined,
                      isCoverImage: true
                    }}
                    bonusPoints={!!selectedActivity ? selectedActivity.totalBonusPoints : 0}
                    navigateTo={this.navigateTo}
                  />
                  {this.getContent()}
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  poll,
  loyalty: { selectedActivity, loading, userPoints, activityCompleted, userActivities },
  authentication: { accessToken, account },
  formMetadata
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  poll,
  selectedActivity,
  loading,
  userPoints,
  accessToken,
  activityCompleted,
  userActivities,
  formMetadata
});

const mapDispatchToProps = {
  fetchPoll,
  resetError,
  submitPoll,
  setActivityCompleted,
  resetActivityCompleted,
  resetState,
  reset,
  setSelectedUserActivity,
  fetchActivities,
  fetchUserActivities,
  isAuthenticated,
  showMessageBar,
  setIsFormDirty
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withStyles(styles)(withTheme(withTranslation()(PollScreen))));
