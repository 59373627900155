import { Grid, LinearProgress, Typography, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import Icon from '@pbl/pbl-react-web-components/lib/icon/Icon';
import styles from 'assets/jss/shared/components/password/PasswordStrengthIndicatorStyle';

interface IPasswordStrengthIndicatorProps extends WithStyles<typeof styles> {
  password?: string;
  passwordErrors: any[];
}

interface IPasswordErrorMessage {
  pass: boolean;
  text: string;
}

const PasswordStrengthIndicator: React.FunctionComponent<IPasswordStrengthIndicatorProps> = ({ password, passwordErrors, classes }) => {
  if (!password || password.length === 0) {
    return null;
  }

  const passMinLength = !passwordErrors.includes('min');
  const passCase = !passwordErrors.includes('lowercase') && !passwordErrors.includes('uppercase');
  const passSpecialChar = !passwordErrors.includes('symbols') && !passwordErrors.includes('digits');

  let strengthText: string;
  let strength: number;
  if (passwordErrors.length <= 0 || (passwordErrors.length === 1 && passwordErrors.includes('max'))) {
    strengthText = 'Strong';
    strength = 100;
  } else if (passwordErrors.length <= 2 && passwordErrors.length > 0) {
    strengthText = 'Medium';
    strength = 65;
  } else {
    strengthText = 'Low';
    strength = 35;
  }

  const passwordErrorMessages: IPasswordErrorMessage[] = [
    {
      pass: passMinLength,
      text: `Minimum of 8 characters.`
    },
    {
      pass: passCase,
      text: `At least one lower and upper case alphabet.`
    },
    {
      pass: passSpecialChar,
      text: `At least one number and one special character.`
    }
  ];

  const unCheckIcon = <Icon iconName={'remove'} iconType="material" aria-label={'Condition not met'} color={'error'} />;
  const checkIcon = <Icon iconName={'check'} iconType="material" aria-label={'Condition met'} className={classes.errorCheckbox}/>;

  return (
    <Grid item={true} xs={12} container={true}>
      <Grid item={true} container={true} xs={12} alignItems="center" className={classes.header}>
        <Typography variant="body2">Password Strength:</Typography>
        <Typography variant="body2" className={classes.strength}>
          &nbsp;{strengthText}
        </Typography>
      </Grid>
      <Grid item={true} container={true} xs={12} alignItems="center" className={classes.header}>
        <LinearProgress
          variant="determinate"
          value={strength}
          style={{ flex: 1, width: '100%' }}
          classes={{
            barColorPrimary: classNames({
              [classes.weak]: passwordErrors.length > 2,
              [classes.medium]: passwordErrors.length <= 2 && passwordErrors.length > 0,
              [classes.strong]: passwordErrors.length === 0
            }),
            colorPrimary: classNames({
              [classes.weakBar]: passwordErrors.length > 2,
              [classes.mediumBar]: passwordErrors.length <= 2 && passwordErrors.length > 0,
              [classes.strongBar]: passwordErrors.length === 0
            })
          }}
        />
      </Grid>

      {passwordErrorMessages.map(passwordErrorMessage => {
        return (
          <Grid item={true} container={true} xs={12} alignItems="center" key={passwordErrorMessage.text}>
            {passwordErrorMessage.pass ? checkIcon : unCheckIcon}
            <Typography color="textSecondary" variant="caption">
              {passwordErrorMessage.text}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withStyles(styles)(PasswordStrengthIndicator);
