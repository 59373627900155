import { CREDIT, DISMISS_DAILY_BONUS, GET_ACTIVITY, GET_ACTIVITY_WITH_FREQ_CAP, IActivityState } from '@pbl/pbl-react-core/lib/models/activity/types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SUCCESS } from 'redux/action-type.util';
import { IDispatchAction } from '../index';

const INITIAL_STATE: IActivityState = {} as IActivityState;

const ActivityStateReducer = (state: IActivityState = INITIAL_STATE, action: IDispatchAction): IActivityState => {
    switch (action.type) {
      case SUCCESS(GET_ACTIVITY):
      case SUCCESS(GET_ACTIVITY_WITH_FREQ_CAP):
      case SUCCESS(CREDIT):
        return {
          ...state,
          activities: action.payload.activities
        };
      case DISMISS_DAILY_BONUS:
        return {
          ...state,
          dailyBonusRedemption: action.payload.dailyBonusRedemption
        };
      default:
        return state;
    }
  };

  const persistConfig = {
    key: 'activity-state',
    storage,
    blacklist: []
  };

export default persistReducer(persistConfig, ActivityStateReducer);
