import { Button, Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

import { IField } from '@pbl/pbl-react-core/lib/models/forms/types';
import ScreenCard from '@pbl/pbl-react-web-components/lib/card/ScreenCard';
import { FieldRenderer } from '@pbl/pbl-react-web-components/lib/package';
import styles from 'assets/jss/modules/password-reset/components/PasswordResetFormStyle';
import PasswordStrengthIndicator from 'shared/components/password/PasswordStrengthIndicator';
import { confirmPasswordValidation, passwordValidation, passwordValidatorSchema } from '../validations';

interface IActivationContentProps extends WithStyles<typeof styles> {
  onSubmit: (password: string) => () => void;
  title?: string;
  description?: string;
}

const PasswordResetForm: React.FunctionComponent<IActivationContentProps> = ({ onSubmit, classes, title, description }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = (value: any) => {
    setPassword(value);
  };

  const handleConfirmPasswordChange = (value: any) => {
    setConfirmPassword(value);
  };

  const passwordErrors = passwordValidatorSchema.validate(password, {
    list: true
  });

  const activeButton = typeof passwordErrors !== 'boolean' && passwordErrors && passwordErrors.length === 0 && password === confirmPassword;

  const passwordField: IField<string> = {
    type: 'password',
    key: 'password',
    label: 'Password',
    get placeholder(): string {
      return this.label;
    },
    value: password,
    isRequired: true,
    showPassword: true,
    secureTextEntry: true,
    get fieldValue() {
      return this.value;
    },
    validationSchema: passwordValidation
  };

  const confirmPasswordField: IField<string> = {
    type: 'password',
    key: 'confirmPassword',
    label: 'Confirm Password',
    get placeholder(): string {
      return this.label;
    },
    value: confirmPassword,
    isRequired: true,
    showPassword: true,
    secureTextEntry: true,
    get fieldValue() {
      return this.value;
    },
    validationSchema: confirmPasswordValidation
  };

  return (
    <ScreenCard title={title || 'Reset Your Password'}>
      {!!description && <Typography variant="body1">{description}</Typography>}
      <Grid container={true} justify="center" alignItems="center">
        <Grid item={true} xs={11} sm={9} lg={8}>
          <FieldRenderer
            field={passwordField}
            onChange={handlePasswordChange}
            className={classes.field}
            inputProps={{
              'aria-label': `Password.`,
              autoComplete: `password`,
              fullWidth: true,
              variant: 'filled'
            }}
          />

          <PasswordStrengthIndicator
            password={password}
            // @ts-ignore
            passwordErrors={passwordErrors}
          />
        </Grid>
        <Grid item={true} xs={11} sm={9} lg={8}>
          <FieldRenderer
            field={confirmPasswordField}
            onChange={handleConfirmPasswordChange}
            className={classes.field}
            inputProps={{
              'aria-label': `Confirm password.`,
              autoComplete: `password`,
              fullWidth: true,
              variant: 'filled'
            }}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={10}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          aria-label="Save"
          onClick={onSubmit(password)}
          disabled={!activeButton}
          className={classes.button}
        >
          Save
        </Button>
      </Grid>
    </ScreenCard>
  );
};

export default withStyles(styles)(PasswordResetForm);
