import {
  GET_JACKPOT_GAME_RESULTS_GROUP,
  GET_LAST_24H_QUICK_DRAW_RESULT,
  GET_LATEST_QUICK_DRAW_RESULT,
  GET_QUICK_DRAW_CONFIG
} from '@pbl/pbl-react-core/lib/models/quickdraw/types';
import { QuickDrawService } from '@pbl/pbl-react-core/lib/services/quick-draw-service';

export const getQuickDrawJackpotResultsGrouped = (gameId: number, page: number, size: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_JACKPOT_GAME_RESULTS_GROUP,
    payload: QuickDrawService.getQuickDrawJackpotResultsGrouped(gameId, page, size)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const getLatestQuickDrawWithWinnerResultByGame = (gameId: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_LATEST_QUICK_DRAW_RESULT,
    payload: QuickDrawService.getLatestQuickDrawWithWinnerResultByGame(gameId)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const getQuickDrawJackpotResultsFromDrawDate = (gameId: number, drawDateFrom: string, page: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_LAST_24H_QUICK_DRAW_RESULT,
    payload: QuickDrawService.getQuickDrawJackpotResultsFromDrawDate(gameId, drawDateFrom, page, 999)
  }).catch((error: Error) => {
    console.error(error);
  });
};

export const getQuickDrawConfig = (quickDrawId: number) => async (dispatch: any) => {
  await dispatch({
    type: GET_QUICK_DRAW_CONFIG,
    payload: QuickDrawService.getQuickDrawConfig(quickDrawId)
  }).catch((error: Error) => {
    console.error(error);
  });
};
