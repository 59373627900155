import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

export default (theme: Theme) =>
  createStyles({
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    field: {
      width: '100%'
    },
    bottomSpacing: {
      marginBottom: theme.spacing(3)
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2)
    },
    buttonSpacing: {
      marginRight: theme.spacing(),
      marginLeft: theme.spacing()
    }
  });
