import { CHECK_UNSUSPEND_KEY, REACTIVATION_FINISH, REACTIVATION_STATE } from '@pbl/pbl-react-core/lib/models/account-reactivation';
import { AccountService } from '@pbl/pbl-react-core/lib/services';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { showMessageBar } from '../app/actions';

export const REACTIVATE_ACCOUNT = 'reactivate/REACTIVATE_ACCOUNT';

export const reactivateAccount = (show: boolean) => ({
  type: REACTIVATE_ACCOUNT,
  payload: show
});

export const reactivateAccountFinish = (key: string, newPassword: string) => async (dispatch: any) => {
  try {
    dispatch({ type: REQUEST(REACTIVATION_FINISH) });

    await AccountService.reactivateAccount(key, newPassword);

    dispatch({ type: SUCCESS(REACTIVATION_FINISH) });
  } catch (e) {
    // TODO: Error handling
    dispatch({ type: FAILURE(REACTIVATION_FINISH) });

    dispatch(showErrorMessage('Something went wrong while reactivating account'));
  }
};

export const checkUnsuspendKey = (key: string) => async (dispatch: any) => {
  if (!key || key.length < 32) {
    dispatch({ type: FAILURE(CHECK_UNSUSPEND_KEY) });
  }

  try {
    dispatch({ type: REQUEST(CHECK_UNSUSPEND_KEY) });

    await AccountService.checkUnsuspendKey(key);

    dispatch({
      type: SUCCESS(CHECK_UNSUSPEND_KEY)
    });
  } catch (e) {
    dispatch({ type: FAILURE(CHECK_UNSUSPEND_KEY) });
  }
};

export const reset = () => ({
  type: REACTIVATION_STATE
});

const showErrorMessage = (message: string) =>
  showMessageBar({
    message,
    type: 'error'
  });
