import { IReward } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import ListCard from '@pbl/pbl-react-web-components/lib/card/ListCard';
import colors from 'assets/jss/colors';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IBanner {
  showBanner?: boolean;
  bannerText?: string;
  styleType?: string;
}

export interface IRewardCardProps extends WithTranslation, RouteComponentProps {
  reward: IReward;
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
  banner?: IBanner;
  categoryName?: string;
}

const RewardCard: React.FunctionComponent<IRewardCardProps> = ({ t, reward, history, banner, categoryName }) => {
  const cornerIcon: ICornerIconProps = {
    icon: 'icon-rewards',
    iconType: 'custom',
    contentColor:
      banner && banner.styleType && banner.styleType === 'GrayedOut'
        ? colors.themeColors.black.alpha(0.35).toString()
        : colors.themeColors.cornerColors.rewardContrast.hex(),
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor:
      banner && banner.showBanner ? colors.themeColors.white.toString() : colors.themeColors.cornerColors.reward.hex(),
    triangleSize: 88
  };
  const navigateToReward = () => {
    history.push(`/rewards/${reward.id}`);
    history.push({ pathname: `/rewards/${reward.id}`, state: { category_name: categoryName } });
  };

  return (
    <ListCard
      onClick={navigateToReward}
      image={reward.image}
      imageTitle={reward.title}
      text={reward.title}
      textFooter={!!reward.extra_data && !!reward.extra_data.preview_text ? reward.extra_data.preview_text : reward.description}
      title={`${t('rewards.redeemFor')}: ${reward.num_points.toPointsFormatWithUnit()}`}
      titleColor={'primary'}
      cornerIcon={cornerIcon}
      showBanner={banner && banner.showBanner}
      bannerText={banner && banner.bannerText ? banner.bannerText : undefined}
      bannerStyleType={banner && banner.styleType ? banner.styleType : undefined}
    />
  );
};

export default (withTranslation()(withRouter(RewardCard)));
