import { WithStyles, withStyles } from '@material-ui/core';
import { IPrize } from '@pbl/pbl-react-core/lib/models/draws/types';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import ListCard from '@pbl/pbl-react-web-components/lib/card/ListCard';
import colors from 'assets/jss/colors';
import styles from 'assets/jss/modules/home/HomeScreenStyle';
import React from 'react';

interface IBanner {
  showBanner?: boolean;
  bannerText?: string;
  styleType?: string;
}

export interface IDrawCardProps extends WithStyles<typeof styles> {
  image: string;
  name: string;
  description;
  iconName: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  banner?: IBanner;
  showYellowBackground?: boolean;
  promotionType?: string;
  promotionProgressivePrize?: IPrize | null;
}

const DrawCard: React.FunctionComponent<IDrawCardProps> = ({
  image,
  description,
  name,
  iconName,
  onClick,
  classes,
  banner,
  showYellowBackground,
  promotionType,
  promotionProgressivePrize
}) => {
  const cornerIcon: ICornerIconProps = {
    icon: iconName,
    iconType: 'custom',
    contentColor:
      banner && banner.styleType && banner.styleType === 'GrayedOut'
        ? colors.themeColors.black.alpha(0.35).toString()
        : showYellowBackground
        ? colors.themeColors.cornerColors.rewardContrast.hex()
        : colors.themeColors.cornerColors.promotionContrast.hex(),
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor: showYellowBackground
      ? colors.themeColors.cornerColors.reward.hex()
      : colors.themeColors.cornerColors.promotion.hex(),
    triangleSize: 88
  };

  return (
    <ListCard
      image={image}
      imageTitle={name}
      text={name}
      textFooter={description}
      onClick={onClick}
      cornerIcon={cornerIcon}
      contentClass={classes.cardContent}
      showBanner={banner && banner.showBanner}
      bannerText={banner && banner.bannerText ? banner.bannerText : undefined}
      bannerStyleType={banner && banner.styleType ? banner.styleType : undefined}
      promotionType={promotionType}
      promotionProgressivePrize={promotionProgressivePrize}
    />
  );
};

export default withStyles(styles)(DrawCard);
