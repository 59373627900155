import {
  DECREMENT_QTY,
  GET_STORE_CATALOG,
  GET_THRESHOLD_STATUS,
  INCREMENT_QTY,
  IOrder,
  IShoppingCartItem,
  STORE_CLEAR_DATA,
  STORE_LOAD_DATA,
  STORE_REMOVE_ITEM,
  SUBMIT_ORDER
} from '@pbl/pbl-react-core/lib/models/store/types';
import { StoreService } from '@pbl/pbl-react-core/lib/services/store-service';
import _ from 'lodash';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { GetStateMethod } from '..';

export const submitOrder = (order: IOrder, callback: () => void) => async (dispatch: any) => {
  try {
    dispatch({
      type: REQUEST(SUBMIT_ORDER)
    });
    const payload = await StoreService.submitOrder(order);
    if (payload.error) {
      dispatch({
        type: FAILURE(SUBMIT_ORDER),
        payload
      });
    } else {
      dispatch({
        type: SUCCESS(SUBMIT_ORDER),
        payload
      });
    }
    callback();
  } catch (error) {
    dispatch({
      type: FAILURE(SUBMIT_ORDER),
      payload: error
    });
    callback();
  }
};

export const getCatalogItems = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_STORE_CATALOG,
      payload: StoreService.getStoreCatalog()
    });
  } catch (error) {
    console.error(error);
  }
};

export const getThresholdStatus = (amount: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_THRESHOLD_STATUS,
      payload: StoreService.getThresholdStatus(amount)
    });
  } catch (error) {
    console.error(error);
  }
};

export const incrementQty = (value: IShoppingCartItem) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const { store, authentication } = getState();

    const items = store.selectedItems;
    const existing = store.selectedItems.find(x => x.id === value.id);
    if (existing) {
      existing.quantity++;
      existing.total = existing.quantity * existing.price;
    } else {
      value.quantity = 1;
      value.total = value.quantity * value.price;
      items.push(value);
    }
    await dispatch({
      type: INCREMENT_QTY,
      payload: saveDataToLocalStore(items, authentication.account)
    });
  } catch (error) {
    console.error(error);
  }
};

export const decrementQty = (value: IShoppingCartItem) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const { store, authentication } = getState();
    let items = store.selectedItems;
    const existing = items.find(x => x.id === value.id);
    if (!!existing && existing.quantity > 1) {
      existing.quantity--;
      existing.total = existing.quantity * existing.price;
    } else {
      items = _.remove(store.selectedItems, (x: IShoppingCartItem) => x.key !== value.key);
    }
    await dispatch({
      type: DECREMENT_QTY,
      payload: saveDataToLocalStore(items, authentication.account)
    });
  } catch (error) {
    console.error(error);
  }
};

export const removeProduct = (value: IShoppingCartItem) => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const { store, authentication } = getState();
    const items = _.remove(store.selectedItems, (x: IShoppingCartItem) => x.key !== value.key);
    await dispatch({
      type: STORE_REMOVE_ITEM,
      payload: saveDataToLocalStore(items, authentication.account)
    });
  } catch (error) {
    console.error(error);
  }
};

export const loadStorage = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const { authentication } = getState();
    await dispatch({
      type: STORE_LOAD_DATA,
      payload: loadDataFromLocalStore(authentication.account)
    });
  } catch (error) {
    console.error(error);
  }
};

export const clearStorage = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const { authentication } = getState();
    await dispatch({
      type: STORE_CLEAR_DATA,
      payload: clearDataFromLocalStore(authentication.account)
    });
  } catch (error) {
    console.error(error);
  }
};

const loadDataFromLocalStore = (account?: any) => {
  const itemKey = account ? 'cartState-' + account.login : 'cartState';
  const cartState = localStorage.getItem(itemKey);
  return cartState ? JSON.parse(cartState) : [];
};

const saveDataToLocalStore = (items: IShoppingCartItem[], account?: any): any => {
  const itemKey = account ? 'cartState-' + account.login : 'cartState';
  localStorage.setItem(itemKey, JSON.stringify(items));
  return items;
};

const clearDataFromLocalStore = (account?: any): any => {
  const itemKey = account ? 'cartState-' + account.login : 'cartState';
  localStorage.setItem(itemKey, '');
  return [];
};
