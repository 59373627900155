import { createStyles, Theme } from '@material-ui/core';
import { container, coloredShadow } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    container,
    // @ts-ignore
    coloredShadow,
    centered: {
      display: 'flex',
      justifyContent: 'center'
    },
    earnHeader: {
      marginTop: theme.spacing(4)
    },
    optionImage: {
      height: 145,
      width: 145
    },
    surveyCard: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4)
    },
    surveyInput: {
      outline: 'auto',
      backgroundColor: theme.palette.common.white
    },
    surveyBtn: {
      width: '40%',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(4)
      }
    }
  });

export default styles;
