import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { IQuizAnswer, IQuizResponseDTO } from '@pbl/pbl-react-core/lib/models/quiz/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal, QuizPointsModal, QuizRetryModal } from '@pbl/pbl-react-web-components/lib/package';
import Quiz from '@pbl/pbl-react-web-components/lib/quiz/Quiz';
import styles from 'assets/jss/modules/quiz/QuizScreenStyle';
import _ from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { isAuthenticated } from 'redux/reducers/authentication/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import {
  fetchActivities,
  fetchUserActivities,
  resetActivityCompleted,
  resetState,
  setActivityCompleted,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import { fetchQuiz, reset, resetError, submitQuiz, updateQuizPostResponse } from 'redux/reducers/quiz/action';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  activityId: string;
}

interface IQuizScreenProps extends RouteComponentProps<IMatchParams>, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

interface IQuizScreenState {
  displayImage: boolean;
  loading: boolean;
  quizRetryResponse: IQuizResponseDTO[];
  showQuizRetryDialog: boolean;
}

type Props = ConnectedProps<typeof connector> & IQuizScreenProps;

class QuizScreen extends React.Component<Props, IQuizScreenState> {
  constructor(props: Props) {
    super(props);
    this.state = { displayImage: false, loading: true, quizRetryResponse: [], showQuizRetryDialog: false };
  }

  public async componentDidMount() {
    document.title = 'Quiz Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.trim().length > 0) {
      await this.getData();
    } else {
      await this.showInvalidActivityError();
    }
  }

  public componentWillUnmount() {
    this.props.reset();
    this.props.setIsFormDirty(false);
  }

  public async getData() {
    const {
      match: {
        params: { activityId }
      }
    } = this.props;

    await this.props.resetState();
    if (this.props.isLoggedIn) {
      await this.props.fetchUserActivities();
    } else {
      await this.props.fetchActivities();
    }

    const selectedActivity = this.props.userActivities.find(activity => activity.id.toString() === activityId);
    if (selectedActivity) {
      await this.props.setSelectedUserActivity(selectedActivity);
      await this.props.fetchQuiz(selectedActivity.id);
    } else {
      await this.showInvalidActivityError();
    }
    this.setState({ loading: false });
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `Something went wrong. Try again!`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  public onChange = (answer: IQuizAnswer) => {
    const { selectedActivity } = this.props;
    if (selectedActivity && answer) {
      this.props.updateQuizPostResponse(answer, selectedActivity.id);
      this.props.setIsFormDirty(true);
    }
  };

  public postQuiz = async () => {
    const { isLoggedIn, selectedActivity } = this.props;
    if (!isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    if (!!this.props.quiz.quiz && !!this.props.quiz.quizPostResponse) {
      const currentResponse = this.props.quiz.quizPostResponse;
      const quizResponse: IQuizResponseDTO[] = [];
      currentResponse.responses.forEach((response: IQuizAnswer) => {
        const answer = !!response.value ? response.value.split('|') : null;
        if (this.props.quiz.quiz && answer) {
          const selectedQuestion = this.props.quiz.quiz.questions.find((x: { id: any }) => x.id === response.id.toString());
          const answerObject: any = [];
          const correctAnswer: any = !Array.isArray(selectedQuestion?.correct_answer)
            ? Array.from(String(selectedQuestion?.correct_answer), Number)
            : selectedQuestion?.correct_answer;

          answer.forEach(a => {
            if (selectedQuestion) {
              const selectedAnswer = selectedQuestion.answer_options.find((x: { value: string | null | undefined }) => x.value === a);
              if (selectedAnswer) {
                answerObject.push(selectedAnswer.id);
              }
            }
          });
          quizResponse.push({ question_id: _.toNumber(response.id), selected_answers: answerObject, correct_answers: correctAnswer });
        }
      });
      const isRetryQuiz = this.props.quiz.quiz.extra_data?.is_quiz_retriable || false;
      const validCount = this.checkRetry(quizResponse);

      if (isRetryQuiz === 'true' && this.props.quiz.quiz.questions.length !== validCount) {
        this.setState({ quizRetryResponse: quizResponse });
        this.setState({ showQuizRetryDialog: true });
        return;
      } else {
        this.setState({ quizRetryResponse: [] });
      }

      if (selectedActivity) {
        await this.props.submitQuiz(quizResponse, selectedActivity.id);
        const {
          quiz: { quizPostResponse, errorMessage }
        } = this.props;
        if (errorMessage) {
          return;
        }
        if (quizPostResponse && selectedActivity) {
          this.props.setActivityCompleted({
            pointsEarned: selectedActivity.num_points,
            activity: selectedActivity
          });
          this.props.setIsFormDirty(false);
        } else {
          this.props.history.push('/earn', {
            message: { message: `This activity is not available.`, type: 'warning', messageTimeout: 3000 }
          });
        }
      }
    }
  };

  private checkRetry(quizResponse: IQuizResponseDTO[]) {
    const { quiz } = this.props;
    let count = 0;
    !!quiz.quiz &&
      quiz.quiz.questions.forEach(question => {
        const questObj = quizResponse.find(q => q.question_id.toString() === question.id.toString());
        const correctAnswer: number[] = !Array.isArray(question.correct_answer)
          ? Array.from(String(question.correct_answer), Number)
          : question.correct_answer;

        if (_.isEqual(questObj?.selected_answers?.sort(), correctAnswer.sort())) {
          count++;
        }
      });
    return count;
  }

  public getContent() {
    const {
      quiz: { quiz, quizPostResponse, quizPostSuccess },
      selectedActivity,
      formMetadata
    } = this.props;

    const bonusPoints = selectedActivity ? selectedActivity.totalBonusPoints : 0;
    return quiz ? (
      <Grid item={true} xs={12}>
        {quiz ? (
          <Quiz
            quiz={quiz}
            quizPostResponse={quizPostResponse}
            quizPostSuccess={quizPostSuccess}
            onChange={this.onChange}
            onSave={this.postQuiz}
            isLoading={this.props.quiz.loading || this.props.loading}
            bonusPoints={bonusPoints}
            isDirty={formMetadata.isFormDirty}
            quizRetryResponse={this.state.quizRetryResponse}
          />
        ) : null}
      </Grid>
    ) : null;
  }

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    // this.props.history.push('/earn');
  };

  private navigateTo = (): void => {
    this.props.history.push('/earn');
  };

  private onInformationDialogClose = (): void => {
    this.setState({ showQuizRetryDialog: false });
  };

  public render() {
    const {
      quiz: { quiz, quizPostSuccess },
      classes,
      activityCompleted,
      selectedActivity,
      t
    } = this.props;
    const isLoadingData = this.state.loading;
    const isAnsweredCorrect = !!quizPostSuccess && !!quiz && quizPostSuccess.score >= quiz.minimum_score;
    return (
      <div>
        <QuizRetryModal
          title={t('quiz.quizRetryTitle')}
          isVisible={this.state.showQuizRetryDialog}
          onClose={this.onInformationDialogClose}
          retryDescription={t('quiz.quizRetryMessage')}
          closeBtnText={t('quiz.retry')}
        />
        {activityCompleted !== null ? (
          !!quizPostSuccess ? (
            <QuizPointsModal
              pointEarned={
                !!selectedActivity && selectedActivity.totalBonusPoints > 0
                  ? selectedActivity.totalBonusPoints
                  : activityCompleted.pointsEarned
              }
              isVisible={activityCompleted !== null}
              onClose={this.onDismissEarnPointsModal}
              title={isAnsweredCorrect ? 'quiz.quizTitle' : 'quiz.quizIncorrectCompleted'}
              pointsEarnedText={isAnsweredCorrect ? 'quiz.quizPointsText' : undefined}
              subTitle2={isAnsweredCorrect ? undefined : 'quiz.noPointsText'}
              hideIcon={!isAnsweredCorrect}
            />
          ) : (
            <EarnPointsModal
              pointEarned={
                !!selectedActivity && selectedActivity.totalBonusPoints > 0
                  ? selectedActivity.totalBonusPoints
                  : activityCompleted.pointsEarned
              }
              isVisible={activityCompleted !== null}
              onClose={this.onDismissEarnPointsModal}
            />
          )
        ) : null}

        {quiz && (
          <div className={classes.container}>
            {isLoadingData && this.props.quiz.loading ? (
              <AppSpinner label={'Loading Activities'} />
            ) : (
              <Grid className={classes.container} container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <EarnActivityHeader
                    data={{
                      image: quiz.image_url,
                      title: !!selectedActivity?.extra_data?.display_name ? selectedActivity.extra_data.display_name : quiz.title,
                      description:
                        !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.additionalInformation
                          ? selectedActivity.extra_data.additionalInformation
                          : quiz.description,
                      points: quiz.num_points,
                      preview_url:
                        !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.preview_url
                          ? quiz.extra_data.preview_url
                          : undefined,
                      isCoverImage: true
                    }}
                    bonusPoints={!!selectedActivity ? selectedActivity.totalBonusPoints : 0}
                    navigateTo={this.navigateTo}
                  />
                  {this.getContent()}
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  quiz,
  loyalty: { selectedActivity, loading, userPoints, activityCompleted, userActivities },
  authentication: { accessToken, account },
  formMetadata
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  quiz,
  selectedActivity,
  loading,
  userPoints,
  accessToken,
  activityCompleted,
  userActivities,
  formMetadata
});

const mapDispatchToProps = {
  fetchQuiz,
  resetError,
  submitQuiz,
  setActivityCompleted,
  resetActivityCompleted,
  resetState,
  reset,
  setSelectedUserActivity,
  fetchActivities,
  fetchUserActivities,
  isAuthenticated,
  showMessageBar,
  setIsFormDirty,
  updateQuizPostResponse
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withStyles(styles)(withTheme(withTranslation()(QuizScreen))));
