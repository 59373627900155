import { colors, createStyles, Theme } from '@material-ui/core';
import color from 'color';

import { container } from 'assets/jss/material-kit-pro-react.jsx';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    menu: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        flexDirection: 'row'
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    menuHeader: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      marginLeft: theme.spacing(2)
    },
    menuSelectSection: {
      width: '100%',
      padding: 8,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    menuSelect: {
      width: '100%'
    },
    grid: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.only('md')]: {
        flexDirection: 'row',
        marginBottom: theme.spacing(10),
      },
      [theme.breakpoints.between('md', 'lg')]: {
        marginBottom: theme.spacing(12),
      },
      [theme.breakpoints.between('sm', 'md')]: {
        marginBottom: theme.spacing(12),
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginBottom: theme.spacing(1),
      }
    },
    pageHeader: {
      marginBottom: theme.spacing()
    },
    greenText: {
      color: colors.green.A700
    },
    page: {
      height: '100%',
      padding: theme.spacing(3)
    },
    selectedListItem: {
      backgroundColor: `${color(theme.palette.primary.main)
        .mix(color(theme.palette.common.white), 0.9)
        .string()} !important`
    }
  });

export default styles;
