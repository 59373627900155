import { Button, Grid, Theme, Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import InformationDialog from '@pbl/pbl-react-web-components/lib/dialog/InformationDialog';
import styles from 'assets/jss/modules/settings/account/AccountScreenStyle';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { deactivateAccount } from 'redux/reducers/authentication/actions';

type PropsConnected = ConnectedProps<typeof connector>;

interface IDeactivateAccountScreenProps extends RouteComponentProps, PropsConnected, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

interface IDeactivateAccountScreenState {
  showDeactivationPopup: boolean;
}

class DeactivateAccountScreen extends React.Component<IDeactivateAccountScreenProps, IDeactivateAccountScreenState> {
  public state: IDeactivateAccountScreenState = {
    showDeactivationPopup: false
  };

  public componentDidMount() {
    document.title = 'Deactivate Account';
  }

  public render(): React.ReactNode {
    const { t, account, classes } = this.props;
    return (
      <div>
        <InformationDialog
          open={this.state.showDeactivationPopup}
          onClose={this.onCancel}
          maxWidth={'xs'}
          hideButton={true}
          hideXButton={true}
        >
          <Typography
            variant="h6"
            component="h1"
            color={'textPrimary'}
            gutterBottom={true}
            align={'center'}
            className={classes.bottomSpacing}
          >
            {t('account.deactivate.confirmationTitle')}
          </Typography>
          <Typography
            variant="body2"
            component="h1"
            color={'textPrimary'}
            gutterBottom={true}
            align={'center'}
            className={classes.bottomSpacing}
          >
            {t('account.deactivate.confirmationText')}
          </Typography>
          <Typography
            variant="body2"
            component="h1"
            color={'textPrimary'}
            gutterBottom={true}
            align={'center'}
            className={classes.bottomSpacing}
          >
            {account.email}
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button
              size="large"
              color={'primary'}
              aria-label="Cancel account deactivation"
              onClick={this.onCancel}
              variant={'outlined'}
              className={classes.buttonSpacing}
            >
              {'Cancel'}
            </Button>
            <Button
              size="large"
              color={'primary'}
              aria-label="Cancel account deactivation"
              onClick={this.onConfirm}
              variant={'outlined'}
              className={classes.buttonSpacing}
            >
              {'Deactivate'}
            </Button>
          </div>
        </InformationDialog>

        <Grid container={true} spacing={3} direction="column">
          <Grid item={true}>
            <Typography color="textPrimary" variant="h6">
              {t('account.deactivate.title')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="body2">
              {t('account.deactivate.note')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="subtitle2">
              {t('account.deactivate.firstQuestion')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="body2">
              {t('account.deactivate.firstAnswer')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="subtitle2">
              {t('account.deactivate.secondQuestion')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="body2">
              {t('account.deactivate.secondAnswer')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="subtitle2">
              {t('account.deactivate.thirdQuestion')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" variant="body2">
              <Trans i18nKey="account.deactivate.thirdAnswer">
                a
                <a href="/" target="_blank">
                  https://azplayersclub.com/
                </a>
                a
              </Trans>
            </Typography>
          </Grid>
          <Grid item={true} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button size="large" variant="contained" color="primary" onClick={this.onRequestToDeactivate} aria-label="Deactivate Account">
              {t('account.deactivateAccount')}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  private onRequestToDeactivate = () => {
    this.setState({ showDeactivationPopup: true });
  };

  private onCancel = () => {
    this.setState({ showDeactivationPopup: false });
  };

  private onConfirm = () => {
    this.setState({ showDeactivationPopup: false });
    this.props.deactivateAccount();
  };
}

const mapStateToProps = ({ authentication: { account } }: IRootState) => ({ account });

const mapDispatchToProps = {
  deactivateAccount
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(withTheme(withTranslation()(DeactivateAccountScreen)))));
