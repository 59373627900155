import { createTicketPriceFilter, filters } from '@pbl/pbl-react-core/lib/models/games/filters';
import {
  ALL_RELATED_GAMES,
  FETCH_GAME_META,
  GamesList,
  GamesState,
  GAME_BY_ID,
  GAME_DRAWS,
  GAME_PRIZES,
  GET_FEATURED_GAME,
  GET_JACKPOT_RESULTS_FOR_GAME,
  GET_NEXT_JACKPOT_RESULT,
  IGame,
  IGamePage,
  IGamesInitialState,
  IGameTag,
  IGameTagType,
  IGameType,
  IJackpotGameResult,
  IPrize,
  IRelatedGame,
  PUBLISHED_GAMES,
  RELATED_GAMES,
  RESET_GAME,
  RESET_STATE
} from '@pbl/pbl-react-core/lib/models/games/types';
import FiltersEnhancer from 'shared/components/filters/filters.reducers-enhancer';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

const INITIAL_STATE: IGamesInitialState = {
  loading: false,
  games: new GamesList(),
  featuredGame: {} as IGame,
  selectedGame: null,
  relatedGames: new Array<IRelatedGame>(),
  allRelatedGames: new Array<IRelatedGame>(),
  gamePrizes: new Array<IPrize>(),
  draws: [],
  drawsPage: {} as IGamePage,
  errorMessage: null,
  page: {} as IGamePage,
  jackpotPage: {} as IGamePage,
  jackpotResults: [],
  nextJackpotResult: {} as IJackpotGameResult,
  latestJackpotResult: {} as IJackpotGameResult,
  filters
};

export default (state: GamesState = INITIAL_STATE, action: any): GamesState => {
  switch (action.type) {
    case REQUEST(PUBLISHED_GAMES):
    case REQUEST(GAME_BY_ID):
    case REQUEST(GAME_PRIZES):
    case REQUEST(GAME_DRAWS):
    case REQUEST(RELATED_GAMES):
    case REQUEST(ALL_RELATED_GAMES):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(PUBLISHED_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        page: action.payload.page,
        games: action.payload.parsedGames
      };
    case SUCCESS(GET_FEATURED_GAME):
      return {
        ...state,
        featuredGame: action.payload.parsedGame
      };
    case SUCCESS(GAME_BY_ID):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        selectedGame: action.payload
      };
    case SUCCESS(GAME_PRIZES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        gamePrizes: action.payload
      };
    case SUCCESS(GAME_DRAWS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        draws: action.payload.content,
        drawsPage: action.payload.page
      };
    case SUCCESS(RELATED_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        relatedGames: action.payload
      };
    case SUCCESS(ALL_RELATED_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allRelatedGames: action.payload
      };
    case SUCCESS(GET_NEXT_JACKPOT_RESULT):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        nextJackpotResult: action.payload
      };
    case SUCCESS(GET_JACKPOT_RESULTS_FOR_GAME):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        jackpotPage: action.payload.page,
        jackpotResults: action.payload.content,
        latestJackpotResult: action.payload.recentDraw
      };
    case FAILURE(GET_NEXT_JACKPOT_RESULT):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        nextJackpotResult: {} as IJackpotGameResult
      };
    case FAILURE(GET_JACKPOT_RESULTS_FOR_GAME):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        jackpotPage: {} as IGamePage,
        jackpotResults: [],
        latestJackpotResult: {} as IJackpotGameResult
      };
    case FAILURE(GAME_PRIZES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        gamePrizes: new Array<IPrize>()
      };
    case FAILURE(GAME_DRAWS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        draws: []
      };
    case FAILURE(RELATED_GAMES):
    case FAILURE(ALL_RELATED_GAMES):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        relatedGames: new Array<IRelatedGame>(),
        allRelatedGames: new Array<IRelatedGame>()
      };
    case FAILURE(PUBLISHED_GAMES):
    case FAILURE(GAME_BY_ID):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case RESET_STATE:
      return {
        ...INITIAL_STATE,
        draws: []
      };
    case RESET_GAME:
      return {
        ...state,
        loading: false,
        selectedGame: null,
        relatedGames: new Array<IRelatedGame>(),
        allRelatedGames: new Array<IRelatedGame>(),
        gamePrizes: new Array<IPrize>(),
        draws: [],
        drawsPage: {} as IGamePage,
        errorMessage: null,
        jackpotPage: {} as IGamePage,
        jackpotResults: [],
        nextJackpotResult: {} as IJackpotGameResult,
        latestJackpotResult: {} as IJackpotGameResult
      };
    case FETCH_GAME_META:
      const {
        gameRetailPrices
      }: {
        gameTypes: IGameType[];
        gameTags: IGameTag[];
        gameTagTypes: IGameTagType[];
        gameRetailPrices: number[];
      } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          TicketPrice: createTicketPriceFilter(gameRetailPrices)
        },
        loading: false,
        errorMessage: null
      };
    default:
      return FiltersEnhancer.processFilters('game', state, action) as IGamesInitialState;
  }
};
