import { Button, createStyles, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import NoResultsFound from '@pbl/pbl-react-web-components/lib/no-results/NoResultsFound';
import { Icon } from '@pbl/pbl-react-web-components/lib/package';
import { container } from 'assets/jss/material-kit-pro-react';
import color from 'color';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { getInformationOnlyTileDetails } from 'redux/reducers/information-only-tile/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import InformationOnlyTile, { IInformationOnlyTileProps } from './InformationOnlyTile';

const styles = (theme: Theme) =>
  createStyles({
    informationContainer: {
      ...container,
      display: 'flex',
      flexGrow: 1
    },
    informationHeader: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    headerButton: {
      padding: '0px'
    },
    toolTip: {
      display: 'flex',
      alignItems: 'center',
      color: color(theme.palette.common.black).alpha(0.8).rgb().string(),
      textTransform: 'none'
    },
    headerText: {
      paddingBottom: theme.spacing()
    }
  });

const useStyles = makeStyles(styles);

const InformationOnlyDetailScreen: React.FC = () => {
  const { id } = useParams() as { id: string | undefined };
  const [informationTileDetail, setInformationTileDetail] = React.useState<IInformationOnlyTileProps | undefined>(undefined);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const informationOnlyTileObj = useSelector((state: IRootState) => state.informationOnlyTile);

  React.useEffect(() => {
    if (id) dispatch(getInformationOnlyTileDetails(parseInt(id, 10)));
  }, [dispatch, id]);

  React.useEffect(() => {
    const selectedInformationOnlyTile = informationOnlyTileObj.informationOnlyTileDetails;
    setInformationTileDetail(selectedInformationOnlyTile);
  }, [id, informationOnlyTileObj]);

  const onNavigate = () => {
    history.push('/information-list');
  };

  if (!informationOnlyTileObj?.loading && informationTileDetail === undefined) {
    return <NoResultsFound title={t('informationOnlyTile.noResults')} />;
  }
  if (informationOnlyTileObj?.loading) {
    return <AppSpinner label={'Loading...'} />;
  }
  return (
    <Grid className={classes.informationContainer} direction="column">
      <ScrollToTopOnMount />
      <Grid container={true} className={classes.informationHeader}>
        <Button onClick={onNavigate} component={'button'} aria-label={`Go back to Information Only Tile`} className={classes.headerButton}>
          <Tooltip title={`Special Offers!`}>
            <Typography variant="caption" className={classes.toolTip}>
              {t('informationOnlyTile.didYouKnow')} <Icon color="inherit" iconType={'material'} iconName={'chevron_right'} />
            </Typography>
          </Tooltip>
        </Button>
      </Grid>
      <Grid container={true} direction="column">
        <Grid direction="column">{informationTileDetail ? <InformationOnlyTile {...informationTileDetail} /> : null}</Grid>
      </Grid>
    </Grid>
  );
};

export default InformationOnlyDetailScreen;
