export const HISTORY_PAGE_SIZE = 'history/PAGE_SIZE';
export const HISTORY_RESET_PAGING = 'history/RESET_PAGING';
export const HISTORY_RESET_PAGING_ALL = 'history/RESET_PAGING_ALL';

export const DEFAULT_PAGE = 0;
export const DEFAULT_ACTIVITIES_PER_PAGE = 10;

export const updatePaging = (key: string, pageNumber: number, pageSize: number) => ({
  type: HISTORY_PAGE_SIZE,
  payload: {
    key,
    pageSize,
    pageNumber: pageNumber || DEFAULT_PAGE
  }
});

export const resetPaging = (key?: string) => {
  if (key) {
    return {
      type: HISTORY_RESET_PAGING,
      payload: key
    };
  }

  return {
    type: HISTORY_RESET_PAGING_ALL
  };
};
