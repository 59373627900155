import {
  ERROR_QUIZ_RESET,
  FETCH_QUIZ_DETAILS,
  IQuizAnswer,
  IQuizResponseDTO,
  QUIZ_RESET,
  SUBMIT_QUIZ,
  UPDATE_QUIZ_POST_RESPONSE
} from '@pbl/pbl-react-core/lib/models/quiz/types';
import { LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import { clearMessages, showMessageBar } from '../app/actions';
import { DispatchMethod } from '../index';

export const fetchQuiz = (activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());

    await dispatch({
      type: FETCH_QUIZ_DETAILS,
      payload: LoyaltyService.getQuiz(activityId)
    });
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const updateQuizPostResponse = (answer: IQuizAnswer, activityId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_QUIZ_POST_RESPONSE,
      payload: { quizId: activityId, answer }
    });
  } catch (exception) {
    dispatch(showMessageBar({ message: exception.payload.title, type: 'error' }));
  }
};

export const submitQuiz = (quizResponse: IQuizResponseDTO[], activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());
    await dispatch({
      type: SUBMIT_QUIZ,
      payload: LoyaltyService.submitQuiz(quizResponse, activityId)
    });
  } catch (exception) {
    dispatch(showMessageBar({ message: exception.payload.title, type: 'error' }));
  }
};

export const resetError = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: ERROR_QUIZ_RESET,
    payload: null
  });
};

export const reset = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: QUIZ_RESET,
    payload: null
  });
};
