import * as yup from 'yup';

export const loginEmailSchema = yup
  .string()
  .required()
  .email();
export const loginPasswordSchema = yup.string().required().min(8); // Minimum length of 8
export const loginRememberMeSchema = yup.boolean().notRequired();

export const loginSchema = yup.object().shape({
  email: loginEmailSchema,
  password: loginPasswordSchema,
  rememberMe: loginRememberMeSchema
});
