import { Theme, withTheme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import InviteFriendsScreen from '@pbl/pbl-react-web-components/lib/invite/InviteScreen';
import styles from 'assets/jss/modules/earn/EarnScreenStyle';
import constants from 'config/constants';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from 'redux/reducers';
import { toggleLoading } from 'redux/reducers/app/actions';
import { getInviteAcceptedActivity, getInviteFriendsActivity } from 'redux/reducers/loyalty/actions';
import { scrollToTheTop } from 'utils/htmlUtil';

type PropsConnected = ConnectedProps<typeof connector>;

interface IInterestsScreenProps extends PropsConnected, WithStyles<typeof styles> {
  theme: Theme;
}

class InviteScreen extends React.Component<IInterestsScreenProps> {
  public state = {
    inviteCode: '',
    inviteUrl: ''
  };

  componentDidMount(): void {
    document.title = 'Invite Friends';
    scrollToTheTop();
    const { account } = this.props;
    const additionalInfo = account.additionalInfo || {};
    const crowdTwist = additionalInfo['crowd-twist'] || {};
    const inviteCode = crowdTwist.referral_code;
    const inviteUrl = `${constants.WEB_APP_URL}/register/${inviteCode || ''}`;
    this.setState({ inviteCode, inviteUrl });
    this.props.getInviteFriendsActivity();
    this.props.getInviteAcceptedActivity();
  }

  public render() {
    const { inviteCode, inviteUrl } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <InviteFriendsScreen
          inviteCode={inviteCode}
          inviteUrl={inviteUrl}
          inviteFriendsActivity={this.props.loyalty.inviteFriendsActivity || null}
          inviteAcceptedActivity={this.props.loyalty.inviteAcceptedActivity || null}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ authentication: { account }, loyalty }: IRootState) => ({
  account,
  loyalty
});

const mapDispatchToProps = {
  toggleLoading,
  getInviteAcceptedActivity,
  getInviteFriendsActivity
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withStyles(styles)(withTheme(InviteScreen)));
