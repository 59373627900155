import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

class RedirectBack extends React.Component<RouteComponentProps> {
  public render(): React.ReactNode {
    let pathname = '/';
    if (this.props.location && this.props.location.state) {
      // @ts-ignore
      const { redirect } = this.props.location.state;
      if (!!redirect && redirect !== 'redirect-back' && typeof redirect === 'string') {
        pathname = redirect;
      }
    }

    return (
      <Redirect
        to={{
          pathname
        }}
      />
    );
  }
}

export default RedirectBack;
