import {
  GET_SURVEY_DETAILS,
  GET_USER_RESPONSE,
  ISurveyPost,
  ISurveyQuestionResponse,
  RESET_ERROR,
  RESET_SURVEY,
  SUBMIT_SURVEY_RESPONSE,
  UPDATE_SURVEY_RESPONSE
} from '@pbl/pbl-react-core/lib/models/survey/v2/types';
import { LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import { clearMessages, showMessageBar } from '../../app/actions';
import { DispatchMethod, GetStateMethod } from '../../index';

export const fetchSurvey = (activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());

    await dispatch({
      type: GET_SURVEY_DETAILS,
      payload: LoyaltyService.getSurveyV2(activityId)
    });
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const updateSurveyPostResponse = (answer: ISurveyQuestionResponse) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_SURVEY_RESPONSE,
    payload: answer
  });
};

export const fetchUserSurveyResponse = (activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());

    await dispatch({
      type: GET_USER_RESPONSE,
      payload: LoyaltyService.getSurveyResultV2(activityId)
    });
  } catch (e) {
    if (e.payload.status !== 404) {
      dispatch(showMessageBar({ message: e.payload.errorKey, type: 'error' }));
    }
  }
};

export const resetError = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: RESET_ERROR,
    payload: null
  });
};

export const reset = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: RESET_SURVEY,
    payload: null
  });
};

export const postSurvey = () => async (dispatch: any, getState: GetStateMethod) => {
  resetError();
  try {
    dispatch(clearMessages());
    const {
      surveyV2: { surveyPost }
    } = getState();
    await dispatch({
      type: SUBMIT_SURVEY_RESPONSE,
      payload: LoyaltyService.submitSurveyV2(surveyPost as ISurveyPost)
    });
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.errorKey, type: 'error' }));
  }
};
