import { Theme, withTheme } from '@material-ui/core';
import SurveyContent from '@pbl/pbl-react-web-components/lib/survey/v2/SurveyContent';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { fetchUserPoints } from 'redux/reducers/loyalty/actions';
import {
  postSurvey as postSurveyV2,
  reset as resetV2,
  resetError as resetErrorV2,
  updateSurveyPostResponse as updateSurveyPostResponseV2
} from 'redux/reducers/survey/v2/action';

type PropsConnected = ConnectedProps<typeof connector>;

interface IInterestsScreenProps extends PropsConnected, RouteComponentProps {
  theme: Theme;
}

class InterestsContent extends React.Component<IInterestsScreenProps> {
  constructor(props: IInterestsScreenProps) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    // TODO: remove once qa finish te testing
    console.warn('************** componentDidMount v2', this.props.surveyV2.surveyPost);
  }

  componentWillUnmount() {
    this.props.resetV2();
    this.props.setIsFormDirty(false);
  }

  public onChange = (answer: any) => {
    this.props.updateSurveyPostResponseV2(answer);
    this.props.setIsFormDirty(true);
  };

  public postSurvey = async () => {
    await this.props.postSurveyV2();
    const {
      surveyV2: { surveyPostResult }
    } = this.props;
    if (surveyPostResult) {
      this.props.setIsFormDirty(false);
      this.props.showMessageBar({ message: 'interests.updated' });
      await this.props.fetchUserPoints();
    }
  };

  public render() {
    const { surveyV2 } = this.props;
    return (
      <div>
        {surveyV2 && surveyV2.survey ? (
          <SurveyContent
            onSave={this.postSurvey}
            onChange={this.onChange}
            survey={surveyV2.survey}
            surveyPostResponse={surveyV2.surveyPost ? surveyV2.surveyPost : null}
            isLoading={surveyV2.loading || this.props.loyalty.loading}
            isSystemSurvey={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ loyalty, formMetadata, surveyV2 }: IRootState) => ({
  surveyV2,
  loyalty,
  formMetadata
});

const mapDispatchToProps = {
  toggleLoading,
  showMessageBar,
  setIsFormDirty,
  resetV2,
  postSurveyV2,
  updateSurveyPostResponseV2,
  resetErrorV2,
  fetchUserPoints
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withTheme(InterestsContent)));
