import PasswordValidator from 'password-validator';
import * as Yup from 'yup';

export const passwordValidatorSchema = new PasswordValidator();

passwordValidatorSchema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(20) // Maximum length 20
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .symbols() // Must have symbols
  .has()
  .digits(); // Must have digits

export const passwordValidation = Yup.string()
  .test('password-validator', 'Enter  valid password', value => !!value && !!passwordValidatorSchema.validate(value))
  .required()
  .max(20);

export const confirmPasswordValidation = Yup.string().required();
