import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';

interface IEnvConstant {
  [key: string]: string | undefined;
}

interface IConstants {
  BASE_URL: string;
  WEB_APP_URL: string;
  CLIENT_ID: string;
  CLIENT_SECRET: string;
  GOOGLE_PLACES_API_KEY: string;
  GOOGLE_PLACES_API_LANGUAGE: string;
  CONSOLE_DEBUG: boolean;
  LOTTERY_TIME_ZONE: string;
  LOTTERY_ID: number;
  GAME_NUMBER_LENGTH: number;
  BOOK_NUMBER_LENGTH: number;
  TICKET_NUMBER_LENGTH: number;
  VALIDATION_CODE_LENGTH: number;
  ENDING_GAME_PERIOD: number;
  NEW_GAME_PERIOD: number;
  UPCOMING_GAME_PERIOD: number;
  NUM_RELATED_GAMES: number;
  NUM_DRAW_ELIGIBLE_GAMES: number;
  DRAW_NEW_PERIOD: number;
  DRAW_ENDING_SOON_PERIOD: number;
  SAMPLE_SCRATCH_TICKET_KEY: string;
  SAMPLE_FAST_PLAY_TICKET_KEY: string;
  SAMPLE_DRAW_TICKET_KEY: string;
  SAMPLE_DRAW_QUICK_DRAW_TICKET_KEY: string;
  SAMPLE_QUICK_CARD_TICKET_KEY: string;
  REWARDS_MAXIMUM: number;
  HISTORY_FILTER_START_DAYS: number;
  SESSION_TIMEOUT_IN_SECONDS: number;
  GAMES_PER_PAGE: number;
  REWARDS_PER_PAGE: number;
  DRAWS_PER_PAGE: number;
  GAME_DRAWS_PER_PAGE: number;
  HISTORY_ITEMS_PER_PAGE: number;
  PAST_DRAWS_PER_PAGE: number;
  SHOW_PAGINATION_IN_REWARDS: boolean;
  HOME_SCREEN_FETCH_DRAW_INTERVAL: number;
  HOME_SCREEN_PROGRESSIVE_DRAW_COUNT: number;
  HOME_SCREEN_DRAW_COUNT: number;
  HOME_SCREEN_REWARD_COUNT: number;
  HOME_SCREEN_EARN_ACTIVITIES_COUNT: number;
  DEFAULT_PAGE_TITLE: string;
  HOME_FEATURED_CONTENT_KEY: string;
  INVITE_FRIENDS_ACTIVITY_KEY: string;
  INVITE_ACCEPTED_ACTIVITY_KEY: string;
  POINTS_FORMAT: string;
  CURRENCY_FORMAT: string;
  CURRENCY_FORMAT_WITH_DECIMAL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
  GOOGLE_RECAPTCHA_SITE_KEY_V2: string;
  INTERESTS_SURVEY: {
    ACTIVITY_KEY: string;
    SURVEY_ID: string;
  };
  DATE_FORMAT: string;
  DEFAULT_DATE_FORMAT: string;
  DATE_TIME_FORMAT: string;
  GOOGLE_ANALYTICS_CODE: string;
  GOOGLE_ANALYTICS_DEBUG: boolean;
  GOOGLE_TAG_MANAGER_ID: string;
  FACEBOOK_APP_ID: string;
  FACEBOOK_PIXEL_ID: string;
  HOME_PROMO_CODE_ACTIVITY_KEY: string;
  MEDIA_API: string;
  INSTAGRAM_CLIENT_ID: string;
  PHONE_MASK: object;
  ABOUT_US_PAGE: string;
  FAQ_PAGE: string;
  TERMS_PAGE: string;
  TERMS_PAGE_LL: string;
  FAQ_PAGE_LL: string;
  PRIVACY_PAGE: string;
  LOTTERY_URL: string;
  PAST_REWARDS_PER_PAGE: number;
  INSTANT_GAMES_TYPE_ID: number;
  HIDE_REWARDS_CATEGORIES: boolean;
  DEFAULT_GAME_TYPE: string;
  SHOW_GAME_TYPE_FILTER: boolean;
  SHOW_DRAW_DATE: boolean;
  COUPON_BACKGROUND: string;
  SHOW_USER_POINTS: boolean;
  SHOW_REWARD_TYPE_COLUMN: boolean;
  HIDE_REWARDS_INFO: boolean;
  SHOW_REWARD_COLUMN: boolean;
  INTERESTS_SURVEY_IS_LEGACY: boolean;
  BASE_IMAGE_URL: string;
  REWARD_CATEGORIES_SORTING: string;
  ADDRESS_URL: string;
  PROMOTIONS_EMAIL_OPT_IN_ACTIVITY_KEY: string;
  JACKPOT_RESULTS_PER_PAGE: number;
  PROMO_COMING_SOON_PERIOD: number;
  SHOW_CONTACT_INFO: boolean;
  FEATURES: string;
  ARCADE_GAME_TYPE: number;
  PAYSAFE_API_KEY: string;
  PAYSAFE_ENV: string;
  SHOW_TICKETCARDVIEW: boolean;
  ARCADE_WAGER_DEFAULT_INDEX: number;
  REDEEM_TOKENS_REWARD: string;
  REDEEM_TOKENS_ACTIVITY: string;
  REDEEM_DAILY_BONUS_ACTIVITY: string;
  LUCKY_LOUNGE_WEB_IMAGE: string;
  LUCKY_LOUNGE_MOBILE_IMAGE: string;
  NGL_BASE_URL: string;
  QUICK_DRAW_SHOW_NEXT_DRAW_CONTAINER: boolean;
  UPLOAD_PHOTO_IMAGE_SIZE: number;
  IOS_DOWNLOAD_URL: string;
  ANDROID_DOWNLOAD_URL: string;
  INFORMATION_ONLY_WEB_IMAGE: string;
  INFORMATION_ONLY_MOBILE_IMAGE: string;
  IDOLOGY_VERIFY_STATUS_POLLING_INTERVAL: number;
  SHOW_COUPON_SOLD_OUT_BANNER: boolean;
  SHOW_TICKETDISCLAIMER: boolean;
  USE_PROMOTION_SERVICE_FOR_DRAW_HISTORY: boolean;
  USE_LOCAL_STORAGE_TO_LOGIN: boolean;
  LOTTERY: string;
  DESIGN_TYPE: string;
  GAME_SUPPLIER: string;
  GAME_DEVELOPER: string;
  TICKET_TYPE: string;
  ACTION_EMITTER_PLAYER: string;
  GAME_ENGINE: string;
  ORIENTATION: string;
  GAME_TYPE: string;
  IN_GAME_EVENT: string;
  OUTAGE_BANNER_CONFIG: string;
  HOME_PAGE_DESCRIPTION: string;
  OUTAGE_CONFIG: string;
  SHOW_DIGITAL_SUMMARY_AT_END: boolean;
  SHOW_TITLE_ABOVE_IMAGE: boolean;
  PURCHASE_TOKEN_BUNDLE_COMING_SOON: string;
  SHOW_SUPER_BONUS_DIALOG: boolean;
  SHOW_BONUS_POINTS: boolean;
  SKIP_GENDER: boolean;
  SKIP_PHONE: boolean;
}

// @ts-ignore
const windowConstants: IEnvConstant = !!window._env_ ? window._env_ : {};
const processConstants: IEnvConstant = !!process.env ? process.env : {};

function lookup(key: CONST_VARS): string {
  return (windowConstants.hasOwnProperty(key)
    ? windowConstants[key]
    : processConstants.hasOwnProperty(key)
    ? processConstants[key]
    : '') as string;
}

function lookupBoolean(key: CONST_VARS): boolean {
  return lookup(key) === 'true';
}

function lookupNumber(key: CONST_VARS, radix: number): number {
  return parseInt(lookup(key), radix);
}

enum CONST_VARS {
  REACT_APP_BASE_URL = 'REACT_APP_BASE_URL',
  REACT_APP_WEB_APP_URL = 'REACT_APP_WEB_APP_URL',
  REACT_APP_CLIENT_ID = 'REACT_APP_CLIENT_ID',
  REACT_APP_CLIENT_SECRET = 'REACT_APP_CLIENT_SECRET',
  REACT_APP_GOOGLE_PLACES_API_KEY = 'REACT_APP_GOOGLE_PLACES_API_KEY',
  REACT_APP_GOOGLE_PLACES_API_LANGUAGE = 'REACT_APP_GOOGLE_PLACES_API_LANGUAGE',
  REACT_APP_CONSOLE_DEBUG = 'REACT_APP_CONSOLE_DEBUG',
  REACT_APP_LOTTERY_TIME_ZONE = 'REACT_APP_LOTTERY_TIME_ZONE',
  REACT_APP_LOTTERY_ID = 'REACT_APP_LOTTERY_ID',
  REACT_APP_GAME_NUMBER_LENGTH = 'REACT_APP_GAME_NUMBER_LENGTH',
  REACT_APP_BOOK_NUMBER_LENGTH = 'REACT_APP_BOOK_NUMBER_LENGTH',
  REACT_APP_TICKET_NUMBER_LENGTH = 'REACT_APP_TICKET_NUMBER_LENGTH',
  REACT_APP_VALIDATION_CODE_LENGTH = 'REACT_APP_VALIDATION_CODE_LENGTH',
  REACT_APP_ENDING_GAME_PERIOD = 'REACT_APP_ENDING_GAME_PERIOD',
  REACT_APP_NEW_GAME_PERIOD = 'REACT_APP_NEW_GAME_PERIOD',
  REACT_APP_UPCOMING_GAME_PERIOD = 'REACT_APP_UPCOMING_GAME_PERIOD',
  REACT_APP_NUM_RELATED_GAMES = 'REACT_APP_NUM_RELATED_GAMES',
  REACT_APP_NUM_DRAW_ELIGIBLE_GAMES = 'REACT_APP_NUM_DRAW_ELIGIBLE_GAMES',
  REACT_APP_DRAW_ENDING_SOON_PERIOD = 'REACT_APP_DRAW_ENDING_SOON_PERIOD',
  REACT_APP_DRAW_NEW_PERIOD = 'REACT_APP_DRAW_NEW_PERIOD',
  REACT_APP_SAMPLE_SCRATCH_TICKET_KEY = 'REACT_APP_SAMPLE_SCRATCH_TICKET_KEY',
  REACT_APP_SAMPLE_DRAW_TICKET_KEY = 'REACT_APP_SAMPLE_DRAW_TICKET_KEY',
  REACT_APP_SAMPLE_FAST_PLAY_TICKET_KEY = 'REACT_APP_SAMPLE_FAST_PLAY_TICKET_KEY',
  REACT_APP_SAMPLE_DRAW_QUICK_DRAW_TICKET_KEY = 'REACT_APP_SAMPLE_DRAW_QUICK_DRAW_TICKET_KEY',
  REACT_APP_REWARDS_MAXIMUM = 'REACT_APP_REWARDS_MAXIMUM',
  REACT_APP_SAMPLE_QUICK_CARD_TICKET_KEY = 'REACT_APP_SAMPLE_QUICK_CARD_TICKET_KEY',
  REACT_APP_HISTORY_FILTER_START_DAYS = 'REACT_APP_HISTORY_FILTER_START_DAYS',
  REACT_APP_SESSION_TIMEOUT_IN_SECONDS = 'REACT_APP_SESSION_TIMEOUT_IN_SECONDS',
  REACT_APP_GAMES_PER_PAGE = 'REACT_APP_GAMES_PER_PAGE',
  REACT_APP_REWARDS_PER_PAGE = 'REACT_APP_REWARDS_PER_PAGE',
  REACT_APP_DRAWS_PER_PAGE = 'REACT_APP_DRAWS_PER_PAGE',
  REACT_APP_GAME_DRAWS_PER_PAGE = 'REACT_APP_GAME_DRAWS_PER_PAGE',
  REACT_APP_HISTORY_ITEMS_PER_PAGE = 'REACT_APP_HISTORY_ITEMS_PER_PAGE',
  REACT_APP_PAST_DRAWS_PER_PAGE = 'REACT_APP_PAST_DRAWS_PER_PAGE',
  REACT_APP_SHOW_PAGINATION_IN_REWARDS = 'REACT_APP_SHOW_PAGINATION_IN_REWARDS',
  REACT_APP_HOME_SCREEN_FETCH_DRAW_INTERVAL = 'REACT_APP_HOME_SCREEN_FETCH_DRAW_INTERVAL',
  REACT_APP_HOME_SCREEN_PROGRESSIVE_DRAW_COUNT = 'REACT_APP_HOME_SCREEN_PROGRESSIVE_DRAW_COUNT',
  REACT_APP_HOME_SCREEN_DRAW_COUNT = 'REACT_APP_HOME_SCREEN_DRAW_COUNT',
  REACT_APP_HOME_SCREEN_REWARD_COUNT = 'REACT_APP_HOME_SCREEN_REWARD_COUNT',
  REACT_APP_HOME_SCREEN_EARN_ACTIVITIES_COUNT = 'REACT_APP_HOME_SCREEN_EARN_ACTIVITIES_COUNT',
  REACT_APP_DEFAULT_PAGE_TITLE = 'REACT_APP_DEFAULT_PAGE_TITLE',
  REACT_APP_HOME_FEATURED_CONTENT_KEY = 'REACT_APP_HOME_FEATURED_CONTENT_KEY',
  REACT_APP_INVITE_FRIENDS_ACTIVITY_KEY = 'REACT_APP_INVITE_FRIENDS_ACTIVITY_KEY',
  REACT_APP_INVITE_ACCEPTED_ACTIVITY_KEY = 'REACT_APP_INVITE_ACCEPTED_ACTIVITY_KEY',
  REACT_APP_POINTS_FORMAT = 'REACT_APP_POINTS_FORMAT',
  REACT_APP_CURRENCY_FORMAT = 'REACT_APP_CURRENCY_FORMAT',
  REACT_APP_CURRENCY_FORMAT_WITH_DECIMAL = 'REACT_APP_CURRENCY_FORMAT_WITH_DECIMAL',
  REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY = 'REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY',
  REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_V2 = 'REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_V2',
  REACT_APP_INTERESTS_SURVEY_ACTIVITY_KEY = 'REACT_APP_INTERESTS_SURVEY_ACTIVITY_KEY',
  REACT_APP_INTERESTS_SURVEY_SURVEY_ID = 'REACT_APP_INTERESTS_SURVEY_SURVEY_ID',
  REACT_APP_DATE_FORMAT = 'REACT_APP_DATE_FORMAT',
  REACT_APP_DEFAULT_DATE_FORMAT = 'REACT_APP_DEFAULT_DATE_FORMAT',
  REACT_APP_DATE_TIME_FORMAT = 'REACT_APP_DATE_TIME_FORMAT',
  REACT_APP_GOOGLE_ANALYTICS_CODE = 'REACT_APP_GOOGLE_ANALYTICS_CODE',
  REACT_APP_GOOGLE_ANALYTICS_DEBUG = 'REACT_APP_GOOGLE_ANALYTICS_DEBUG',
  REACT_APP_GOOGLE_TAG_MANAGER_ID = 'REACT_APP_GOOGLE_TAG_MANAGER_ID',
  REACT_APP_FACEBOOK_APP_ID = 'REACT_APP_FACEBOOK_APP_ID',
  REACT_APP_FACEBOOK_PIXEL_ID = 'REACT_APP_FACEBOOK_PIXEL_ID',
  REACT_APP_HOME_PROMO_CODE_ACTIVITY_KEY = 'REACT_APP_HOME_PROMO_CODE_ACTIVITY_KEY',
  REACT_APP_INSTAGRAM_CLIENT_ID = 'REACT_APP_INSTAGRAM_CLIENT_ID',
  REACT_APP_PHONE_MASK = 'REACT_APP_PHONE_MASK',
  REACT_APP_ABOUT_US_PAGE = 'REACT_APP_ABOUT_US_PAGE',
  REACT_APP_FAQ_PAGE = 'REACT_APP_FAQ_PAGE',
  REACT_APP_TERMS_PAGE = 'REACT_APP_TERMS_PAGE',
  REACT_APP_TERMS_PAGE_LL = 'REACT_APP_TERMS_PAGE_LL',
  REACT_APP_FAQ_PAGE_LL = 'REACT_APP_FAQ_PAGE_LL',
  REACT_APP_PRIVACY_PAGE = 'REACT_APP_PRIVACY_PAGE',
  REACT_APP_LOTTERY_URL = 'REACT_APP_LOTTERY_URL',
  REACT_APP_PAST_REWARDS_PER_PAGE = 'REACT_APP_PAST_REWARDS_PER_PAGE',
  REACT_APP_INSTANT_GAMES_TYPE_ID = 'REACT_APP_INSTANT_GAMES_TYPE_ID',
  REACT_APP_HIDE_REWARDS_CATEGORIES = 'REACT_APP_HIDE_REWARDS_CATEGORIES',
  REACT_APP_DEFAULT_GAME_TYPE = 'REACT_APP_DEFAULT_GAME_TYPE',
  REACT_APP_SHOW_GAME_TYPE_FILTER = 'REACT_APP_SHOW_GAME_TYPE_FILTER',
  REACT_APP_SHOW_DRAW_DATE = 'REACT_APP_SHOW_DRAW_DATE',
  REACT_APP_COUPON_BACKGROUND = 'REACT_APP_COUPON_BACKGROUND',
  REACT_APP_SHOW_USER_POINTS = 'REACT_APP_SHOW_USER_POINTS',
  REACT_APP_SHOW_REWARD_TYPE_COLUMN = 'REACT_APP_SHOW_REWARD_TYPE_COLUMN',
  REACT_APP_HIDE_REWARDS_INFO = 'REACT_APP_HIDE_REWARDS_INFO',
  REACT_APP_SHOW_REWARD_COLUMN = 'REACT_APP_SHOW_REWARD_COLUMN',
  REACT_APP_INTERESTS_SURVEY_IS_LEGACY = 'REACT_APP_INTERESTS_SURVEY_IS_LEGACY',
  REACT_APP_BASE_IMAGE_URL = 'REACT_APP_BASE_IMAGE_URL',
  REACT_APP_REWARD_CATEGORIES_SORTING = 'REACT_APP_REWARD_CATEGORIES_SORTING',
  REACT_APP_ADDRESS_URL = 'REACT_APP_ADDRESS_URL',
  REACT_APP_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY_KEY = 'REACT_APP_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY_KEY',
  REACT_APP_JACKPOT_RESULTS_PER_PAGE = 'REACT_APP_JACKPOT_RESULTS_PER_PAGE',
  REACT_APP_PROMO_COMING_SOON_PERIOD = 'REACT_APP_PROMO_COMING_SOON_PERIOD',
  REACT_APP_SHOW_CONTACT_INFO = 'REACT_APP_SHOW_CONTACT_INFO',
  REACT_APP_FEATURES = 'REACT_APP_FEATURES',
  REACT_APP_ARCADE_GAME_TYPE = 'REACT_APP_ARCADE_GAME_TYPE',
  REACT_APP_SHOW_TICKETCARDVIEW = 'REACT_APP_SHOW_TICKETCARDVIEW',
  REACT_APP_SHOW_TICKETDISCLAIMER = 'REACT_APP_SHOW_TICKETDISCLAIMER',
  REACT_APP_ARCADE_WAGER_DEFAULT_INDEX = 'REACT_APP_ARCADE_WAGER_DEFAULT_INDEX',
  REACT_APP_REDEEM_TOKENS_REWARD = 'REACT_APP_REDEEM_TOKENS_REWARD',
  REACT_APP_REDEEM_TOKENS_ACTIVITY = 'REACT_APP_REDEEM_TOKENS_ACTIVITY',
  REACT_APP_REDEEM_DAILY_BONUS_ACTIVITY = 'REACT_APP_REDEEM_DAILY_BONUS_ACTIVITY',
  REACT_APP_LUCKY_LOUNGE_WEB_IMAGE = 'REACT_APP_LUCKY_LOUNGE_WEB_IMAGE_URL',
  REACT_APP_LUCKY_LOUNGE_MOBILE_IMAGE = 'REACT_APP_LUCKY_LOUNGE_MOBILE_IMAGE_URL',
  REACT_APP_NGL_BASE_URL = 'REACT_APP_NGL_BASE_URL',
  REACT_APP_UPLOAD_PHOTO_IMAGE_SIZE = 'REACT_APP_UPLOAD_PHOTO_IMAGE_SIZE',
  REACT_APP_IOS_DOWNLOAD_URL = 'REACT_APP_IOS_DOWNLOAD_URL',
  REACT_APP_ANDROID_DOWNLOAD_URL = 'REACT_APP_ANDROID_DOWNLOAD_URL',
  REACT_APP_INFORMATION_ONLY_WEB_IMAGE = 'REACT_APP_INFORMATION_ONLY_WEB_IMAGE_URL',
  REACT_APP_INFORMATION_ONLY_MOBILE_IMAGE = 'REACT_APP_INFORMATION_ONLY_MOBILE_IMAGE_URL',
  REACT_APP_IDOLOGY_VERIFY_STATUS_POLLING_INTERVAL = 'REACT_APP_IDOLOGY_VERIFY_STATUS_POLLING_INTERVAL',
  REACT_APP_SHOW_COUPON_SOLD_OUT_BANNER = 'REACT_APP_SHOW_COUPON_SOLD_OUT_BANNER',
  REACT_APP_LOTTERY = 'REACT_APP_LOTTERY',
  REACT_APP_DESIGN_TYPE = 'REACT_APP_DESIGN_TYPE',
  REACT_APP_GAME_SUPPLIER = 'REACT_APP_GAME_SUPPLIER',
  REACT_APP_GAME_DEVELOPER = 'REACT_APP_GAME_DEVELOPER',
  REACT_APP_TICKET_TYPE = 'REACT_APP_TICKET_TYPE',
  REACT_APP_ACTION_EMITTER_PLAYER = 'REACT_APP_ACTION_EMITTER_PLAYER',
  REACT_APP_GAME_ENGINE = 'REACT_APP_GAME_ENGINE',
  REACT_APP_ORIENTATION = 'REACT_APP_ORIENTATION',
  REACT_APP_GAME_TYPE = 'REACT_APP_GAME_TYPE',
  REACT_APP_IN_GAME_EVENT = 'REACT_APP_IN_GAME_EVENT',
  REACT_APP_SHOW_DIGITAL_SUMMARY_AT_END = 'REACT_APP_SHOW_DIGITAL_SUMMARY_AT_END',
  REACT_APP_SHOW_TITLE_ABOVE_IMAGE = 'REACT_APP_SHOW_TITLE_ABOVE_IMAGE',
  REACT_APP_PURCHASE_TOKEN_BUNDLE_COMING_SOON = 'REACT_APP_PURCHASE_TOKEN_BUNDLE_COMING_SOON',
  REACT_APP_SHOW_SUPER_BONUS_DIALOG = 'REACT_APP_SHOW_SUPER_BONUS_DIALOG',
  REACT_APP_SHOW_BONUS_POINTS = 'REACT_APP_SHOW_BONUS_POINTS',
  REACT_APP_SKIP_GENDER = 'REACT_APP_SKIP_GENDER',
  REACT_APP_SKIP_PHONE = 'REACT_APP_SKIP_PHONE'
}

const constants: IConstants = {
  BASE_URL: lookup(CONST_VARS.REACT_APP_BASE_URL),
  WEB_APP_URL: lookup(CONST_VARS.REACT_APP_WEB_APP_URL),
  CLIENT_ID: lookup(CONST_VARS.REACT_APP_CLIENT_ID),
  CLIENT_SECRET: lookup(CONST_VARS.REACT_APP_CLIENT_SECRET),
  GOOGLE_PLACES_API_KEY: lookup(CONST_VARS.REACT_APP_GOOGLE_PLACES_API_KEY),
  GOOGLE_PLACES_API_LANGUAGE: lookup(CONST_VARS.REACT_APP_GOOGLE_PLACES_API_LANGUAGE),
  CONSOLE_DEBUG: lookupBoolean(CONST_VARS.REACT_APP_CONSOLE_DEBUG),
  LOTTERY_TIME_ZONE: lookup(CONST_VARS.REACT_APP_LOTTERY_TIME_ZONE),
  LOTTERY_ID: lookupNumber(CONST_VARS.REACT_APP_LOTTERY_ID, 10),
  GAME_NUMBER_LENGTH: lookupNumber(CONST_VARS.REACT_APP_GAME_NUMBER_LENGTH, 10),
  BOOK_NUMBER_LENGTH: lookupNumber(CONST_VARS.REACT_APP_BOOK_NUMBER_LENGTH, 10),
  TICKET_NUMBER_LENGTH: lookupNumber(CONST_VARS.REACT_APP_TICKET_NUMBER_LENGTH, 10),
  VALIDATION_CODE_LENGTH: lookupNumber(CONST_VARS.REACT_APP_VALIDATION_CODE_LENGTH, 10),
  ENDING_GAME_PERIOD: lookupNumber(CONST_VARS.REACT_APP_ENDING_GAME_PERIOD, 10),
  NEW_GAME_PERIOD: lookupNumber(CONST_VARS.REACT_APP_NEW_GAME_PERIOD, 10),
  UPCOMING_GAME_PERIOD: lookupNumber(CONST_VARS.REACT_APP_UPCOMING_GAME_PERIOD, 10),
  NUM_RELATED_GAMES: lookupNumber(CONST_VARS.REACT_APP_NUM_RELATED_GAMES, 10),
  NUM_DRAW_ELIGIBLE_GAMES: lookupNumber(CONST_VARS.REACT_APP_NUM_DRAW_ELIGIBLE_GAMES, 10),
  DRAW_NEW_PERIOD: lookupNumber(CONST_VARS.REACT_APP_DRAW_NEW_PERIOD, 10),
  DRAW_ENDING_SOON_PERIOD: lookupNumber(CONST_VARS.REACT_APP_DRAW_ENDING_SOON_PERIOD, 10),
  SAMPLE_SCRATCH_TICKET_KEY: lookup(CONST_VARS.REACT_APP_SAMPLE_SCRATCH_TICKET_KEY),
  SAMPLE_FAST_PLAY_TICKET_KEY: lookup(CONST_VARS.REACT_APP_SAMPLE_FAST_PLAY_TICKET_KEY),
  SAMPLE_DRAW_TICKET_KEY: lookup(CONST_VARS.REACT_APP_SAMPLE_DRAW_TICKET_KEY),
  SAMPLE_DRAW_QUICK_DRAW_TICKET_KEY: lookup(CONST_VARS.REACT_APP_SAMPLE_DRAW_QUICK_DRAW_TICKET_KEY),
  SAMPLE_QUICK_CARD_TICKET_KEY: lookup(CONST_VARS.REACT_APP_SAMPLE_QUICK_CARD_TICKET_KEY),
  REWARDS_MAXIMUM: lookupNumber(CONST_VARS.REACT_APP_REWARDS_MAXIMUM, 10),
  HISTORY_FILTER_START_DAYS: lookupNumber(CONST_VARS.REACT_APP_HISTORY_FILTER_START_DAYS, 10),
  SESSION_TIMEOUT_IN_SECONDS: lookupNumber(CONST_VARS.REACT_APP_SESSION_TIMEOUT_IN_SECONDS, 10),
  GAMES_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_GAMES_PER_PAGE, 10),
  REWARDS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_REWARDS_PER_PAGE, 10),
  DRAWS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_DRAWS_PER_PAGE, 10),
  GAME_DRAWS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_GAME_DRAWS_PER_PAGE, 10),
  HISTORY_ITEMS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_HISTORY_ITEMS_PER_PAGE, 10),
  PAST_DRAWS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_PAST_DRAWS_PER_PAGE, 10),
  SHOW_PAGINATION_IN_REWARDS: lookupBoolean(CONST_VARS.REACT_APP_SHOW_PAGINATION_IN_REWARDS),
  HOME_SCREEN_FETCH_DRAW_INTERVAL: lookupNumber(CONST_VARS.REACT_APP_HOME_SCREEN_FETCH_DRAW_INTERVAL, 10),
  HOME_SCREEN_PROGRESSIVE_DRAW_COUNT: lookupNumber(CONST_VARS.REACT_APP_HOME_SCREEN_PROGRESSIVE_DRAW_COUNT, 10),
  HOME_SCREEN_DRAW_COUNT: lookupNumber(CONST_VARS.REACT_APP_HOME_SCREEN_DRAW_COUNT, 10),
  HOME_SCREEN_REWARD_COUNT: lookupNumber(CONST_VARS.REACT_APP_HOME_SCREEN_REWARD_COUNT, 10),
  HOME_SCREEN_EARN_ACTIVITIES_COUNT: lookupNumber(CONST_VARS.REACT_APP_HOME_SCREEN_EARN_ACTIVITIES_COUNT, 10),
  DEFAULT_PAGE_TITLE: lookup(CONST_VARS.REACT_APP_DEFAULT_PAGE_TITLE),
  HOME_FEATURED_CONTENT_KEY: lookup(CONST_VARS.REACT_APP_HOME_FEATURED_CONTENT_KEY),
  INVITE_FRIENDS_ACTIVITY_KEY: lookup(CONST_VARS.REACT_APP_INVITE_FRIENDS_ACTIVITY_KEY),
  INVITE_ACCEPTED_ACTIVITY_KEY: lookup(CONST_VARS.REACT_APP_INVITE_ACCEPTED_ACTIVITY_KEY),
  POINTS_FORMAT: lookup(CONST_VARS.REACT_APP_POINTS_FORMAT),
  CURRENCY_FORMAT: lookup(CONST_VARS.REACT_APP_CURRENCY_FORMAT),
  CURRENCY_FORMAT_WITH_DECIMAL: lookup(CONST_VARS.REACT_APP_CURRENCY_FORMAT_WITH_DECIMAL),
  GOOGLE_RECAPTCHA_SITE_KEY: lookup(CONST_VARS.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY),
  GOOGLE_RECAPTCHA_SITE_KEY_V2: lookup(CONST_VARS.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_V2),
  INTERESTS_SURVEY: {
    ACTIVITY_KEY: lookup(CONST_VARS.REACT_APP_INTERESTS_SURVEY_ACTIVITY_KEY),
    SURVEY_ID: lookup(CONST_VARS.REACT_APP_INTERESTS_SURVEY_SURVEY_ID)
  },
  DATE_FORMAT: lookup(CONST_VARS.REACT_APP_DATE_FORMAT),
  DEFAULT_DATE_FORMAT: lookup(CONST_VARS.REACT_APP_DEFAULT_DATE_FORMAT),
  DATE_TIME_FORMAT: lookup(CONST_VARS.REACT_APP_DATE_TIME_FORMAT),
  GOOGLE_ANALYTICS_CODE: lookup(CONST_VARS.REACT_APP_GOOGLE_ANALYTICS_CODE),
  GOOGLE_ANALYTICS_DEBUG: lookupBoolean(CONST_VARS.REACT_APP_GOOGLE_ANALYTICS_DEBUG),
  GOOGLE_TAG_MANAGER_ID: lookup(CONST_VARS.REACT_APP_GOOGLE_TAG_MANAGER_ID),
  FACEBOOK_APP_ID: lookup(CONST_VARS.REACT_APP_FACEBOOK_APP_ID),
  FACEBOOK_PIXEL_ID: lookup(CONST_VARS.REACT_APP_FACEBOOK_PIXEL_ID),
  HOME_PROMO_CODE_ACTIVITY_KEY: lookup(CONST_VARS.REACT_APP_HOME_PROMO_CODE_ACTIVITY_KEY),
  MEDIA_API: '/media/api/file/',
  INSTAGRAM_CLIENT_ID: lookup(CONST_VARS.REACT_APP_INSTAGRAM_CLIENT_ID),
  PHONE_MASK: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  ABOUT_US_PAGE: lookup(CONST_VARS.REACT_APP_ABOUT_US_PAGE),
  FAQ_PAGE: lookup(CONST_VARS.REACT_APP_FAQ_PAGE),
  FAQ_PAGE_LL: lookup(CONST_VARS.REACT_APP_FAQ_PAGE_LL),
  TERMS_PAGE: lookup(CONST_VARS.REACT_APP_TERMS_PAGE),
  TERMS_PAGE_LL: lookup(CONST_VARS.REACT_APP_TERMS_PAGE_LL),
  PRIVACY_PAGE: lookup(CONST_VARS.REACT_APP_PRIVACY_PAGE),
  LOTTERY_URL: lookup(CONST_VARS.REACT_APP_LOTTERY_URL),
  PAST_REWARDS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_PAST_REWARDS_PER_PAGE, 10),
  INSTANT_GAMES_TYPE_ID: lookupNumber(CONST_VARS.REACT_APP_INSTANT_GAMES_TYPE_ID, 10),
  HIDE_REWARDS_CATEGORIES: lookupBoolean(CONST_VARS.REACT_APP_HIDE_REWARDS_CATEGORIES),
  DEFAULT_GAME_TYPE: lookup(CONST_VARS.REACT_APP_DEFAULT_GAME_TYPE),
  SHOW_GAME_TYPE_FILTER: lookupBoolean(CONST_VARS.REACT_APP_SHOW_GAME_TYPE_FILTER),
  SHOW_DRAW_DATE: lookupBoolean(CONST_VARS.REACT_APP_SHOW_DRAW_DATE),
  COUPON_BACKGROUND: lookup(CONST_VARS.REACT_APP_COUPON_BACKGROUND),
  SHOW_USER_POINTS: lookupBoolean(CONST_VARS.REACT_APP_SHOW_USER_POINTS),
  SHOW_REWARD_TYPE_COLUMN: lookupBoolean(CONST_VARS.REACT_APP_SHOW_REWARD_TYPE_COLUMN),
  HIDE_REWARDS_INFO: lookupBoolean(CONST_VARS.REACT_APP_HIDE_REWARDS_INFO),
  SHOW_REWARD_COLUMN: lookupBoolean(CONST_VARS.REACT_APP_SHOW_REWARD_COLUMN),
  SHOW_TITLE_ABOVE_IMAGE: lookupBoolean(CONST_VARS.REACT_APP_SHOW_TITLE_ABOVE_IMAGE),
  SHOW_SUPER_BONUS_DIALOG: lookupBoolean(CONST_VARS.REACT_APP_SHOW_SUPER_BONUS_DIALOG),
  SHOW_BONUS_POINTS: lookupBoolean(CONST_VARS.REACT_APP_SHOW_BONUS_POINTS),
  INTERESTS_SURVEY_IS_LEGACY: lookupBoolean(CONST_VARS.REACT_APP_INTERESTS_SURVEY_IS_LEGACY),
  BASE_IMAGE_URL: lookup(CONST_VARS.REACT_APP_BASE_IMAGE_URL),
  REWARD_CATEGORIES_SORTING: lookup(CONST_VARS.REACT_APP_REWARD_CATEGORIES_SORTING),
  ADDRESS_URL: lookup(CONST_VARS.REACT_APP_ADDRESS_URL),
  PROMOTIONS_EMAIL_OPT_IN_ACTIVITY_KEY: lookup(CONST_VARS.REACT_APP_PROMOTIONS_EMAIL_OPT_IN_ACTIVITY_KEY),
  JACKPOT_RESULTS_PER_PAGE: lookupNumber(CONST_VARS.REACT_APP_JACKPOT_RESULTS_PER_PAGE, 10),
  PROMO_COMING_SOON_PERIOD: lookupNumber(CONST_VARS.REACT_APP_PROMO_COMING_SOON_PERIOD, 10),
  SHOW_CONTACT_INFO: lookupBoolean(CONST_VARS.REACT_APP_SHOW_CONTACT_INFO),
  FEATURES: lookup(CONST_VARS.REACT_APP_FEATURES),
  ARCADE_GAME_TYPE: lookupNumber(CONST_VARS.REACT_APP_ARCADE_GAME_TYPE, 10),
  PAYSAFE_API_KEY: '',
  PAYSAFE_ENV: '',
  SHOW_TICKETCARDVIEW: lookupBoolean(CONST_VARS.REACT_APP_SHOW_TICKETCARDVIEW),
  SHOW_TICKETDISCLAIMER: lookupBoolean(CONST_VARS.REACT_APP_SHOW_TICKETDISCLAIMER),
  ARCADE_WAGER_DEFAULT_INDEX: lookupNumber(CONST_VARS.REACT_APP_ARCADE_WAGER_DEFAULT_INDEX, 10),
  REDEEM_TOKENS_REWARD: lookup(CONST_VARS.REACT_APP_REDEEM_TOKENS_REWARD),
  REDEEM_TOKENS_ACTIVITY: lookup(CONST_VARS.REACT_APP_REDEEM_TOKENS_ACTIVITY),
  REDEEM_DAILY_BONUS_ACTIVITY: lookup(CONST_VARS.REACT_APP_REDEEM_DAILY_BONUS_ACTIVITY),
  LUCKY_LOUNGE_WEB_IMAGE: lookup(CONST_VARS.REACT_APP_LUCKY_LOUNGE_WEB_IMAGE),
  LUCKY_LOUNGE_MOBILE_IMAGE: lookup(CONST_VARS.REACT_APP_LUCKY_LOUNGE_MOBILE_IMAGE),
  NGL_BASE_URL: lookup(CONST_VARS.REACT_APP_NGL_BASE_URL),
  QUICK_DRAW_SHOW_NEXT_DRAW_CONTAINER: true,
  UPLOAD_PHOTO_IMAGE_SIZE: lookupNumber(CONST_VARS.REACT_APP_UPLOAD_PHOTO_IMAGE_SIZE, 10),
  IOS_DOWNLOAD_URL: lookup(CONST_VARS.REACT_APP_IOS_DOWNLOAD_URL),
  ANDROID_DOWNLOAD_URL: lookup(CONST_VARS.REACT_APP_ANDROID_DOWNLOAD_URL),
  INFORMATION_ONLY_WEB_IMAGE: lookup(CONST_VARS.REACT_APP_INFORMATION_ONLY_WEB_IMAGE),
  INFORMATION_ONLY_MOBILE_IMAGE: lookup(CONST_VARS.REACT_APP_INFORMATION_ONLY_MOBILE_IMAGE),
  IDOLOGY_VERIFY_STATUS_POLLING_INTERVAL: lookupNumber(CONST_VARS.REACT_APP_IDOLOGY_VERIFY_STATUS_POLLING_INTERVAL, 10),
  SHOW_COUPON_SOLD_OUT_BANNER: lookupBoolean(CONST_VARS.REACT_APP_SHOW_COUPON_SOLD_OUT_BANNER),
  USE_PROMOTION_SERVICE_FOR_DRAW_HISTORY: false,
  USE_LOCAL_STORAGE_TO_LOGIN: true,
  LOTTERY: lookup(CONST_VARS.REACT_APP_LOTTERY),
  DESIGN_TYPE: lookup(CONST_VARS.REACT_APP_DESIGN_TYPE),
  GAME_SUPPLIER: lookup(CONST_VARS.REACT_APP_GAME_SUPPLIER),
  GAME_DEVELOPER: lookup(CONST_VARS.REACT_APP_GAME_DEVELOPER),
  TICKET_TYPE: lookup(CONST_VARS.REACT_APP_TICKET_TYPE),
  ACTION_EMITTER_PLAYER: lookup(CONST_VARS.REACT_APP_ACTION_EMITTER_PLAYER),
  GAME_ENGINE: lookup(CONST_VARS.REACT_APP_GAME_ENGINE),
  ORIENTATION: lookup(CONST_VARS.REACT_APP_ORIENTATION),
  GAME_TYPE: lookup(CONST_VARS.REACT_APP_GAME_TYPE),
  IN_GAME_EVENT: lookup(CONST_VARS.REACT_APP_IN_GAME_EVENT),
  OUTAGE_CONFIG: '{}',
  OUTAGE_BANNER_CONFIG: '{}',
  SHOW_DIGITAL_SUMMARY_AT_END: lookupBoolean(CONST_VARS.REACT_APP_SHOW_DIGITAL_SUMMARY_AT_END),
  PURCHASE_TOKEN_BUNDLE_COMING_SOON: lookup(CONST_VARS.REACT_APP_PURCHASE_TOKEN_BUNDLE_COMING_SOON),
  HOME_PAGE_DESCRIPTION: '{}',
  SKIP_GENDER: lookupBoolean(CONST_VARS.REACT_APP_SKIP_GENDER),
  SKIP_PHONE: lookupBoolean(CONST_VARS.REACT_APP_SKIP_PHONE)
};

class RemoteConstantsInternal {
  private remoteConfig: firebase.remoteConfig.RemoteConfig;
  private firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
  };

  public constructor() {
    firebase.initializeApp(this.firebaseConfig);
    this.remoteConfig = firebase.remoteConfig();

    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: 1800000, // maximum age in milliseconds of an entry in the config cache before it is considered stale
      fetchTimeoutMillis: 10000 // maximum amount of milliseconds to wait for a response when fetching configuration from the Remote Config server
    };

    // set default values
    const defaultConfig = {};
    Object.keys(constants).forEach(key => {
      defaultConfig[key] = constants[key];
    });

    this.remoteConfig.defaultConfig = defaultConfig;
  }

  private async fetch(): Promise<void> {
    return this.remoteConfig.fetch();
  }

  public getValue(key: string): any {
    return this.remoteConfig.getValue(key);
  }

  public getBoolean(key: string): boolean {
    return this.remoteConfig.getBoolean(key);
  }

  public getNumber(key: string): any {
    return this.remoteConfig.getNumber(key);
  }

  public getString(key: string): any {
    return this.remoteConfig.getString(key);
  }

  public async getValueSync(key: string): Promise<any> {
    await this.fetch();
    return this.remoteConfig.getValue(key);
  }

  public async getStringSync(key: string): Promise<string> {
    return this.remoteConfig.fetchAndActivate()
      .then(() => this.remoteConfig.getString(key));
  }

  public async sync(): Promise<void> {
    await this.fetch();
  }

  public async syncConfig(): Promise<Boolean> {
    return this.remoteConfig.fetchAndActivate();
  }
}

export const RemoteConstants = new RemoteConstantsInternal();
export default constants;
