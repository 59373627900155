import { Button, CardMedia, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import color from 'color';
import { useTranslation } from 'react-i18next';

export interface CollectThemAllProps {
  title: string | undefined;
  description: string | undefined;
  imageUrl: string | undefined;
  rulesDocument: string | undefined;
  remainingEntries?: string;
  totalEntries: string;
  startDate: string;
  endDate: string;
  navigateToTicketEntry: any;
}

const styles = (theme: Theme) =>
  createStyles({
    description: {
      color: color(theme.palette.common.black).rgb().string(),
      paddingTop: theme.spacing(1)
    },
    imageConatiner: {
      overflow: 'hidden',
      maxHeight: 480,
      height: 445,
      maxWidth: 480,
      width: 445,
      borderRadius: '6px',
      border: 'solid 1px #979797',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        maxHeight: 450,
        maxWidth: 450
      },
      [theme.breakpoints.down('md')]: {
        height: 346,
        maxHeight: 400,
        width: 346,
        maxWidth: 400
      },
      [theme.breakpoints.down('xs')]: {
        height: 346,
        maxHeight: 360,
        width: 346,
        maxWidth: 360
      }
    },
    gridPadding: {
      paddingTop: theme.spacing(4)
    }
  });

const useStyles = makeStyles(styles);

const CollectThemAll: React.FC<CollectThemAllProps> = ({ description, imageUrl, navigateToTicketEntry }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderTicketButton = () => {
    return (
      <Grid item={true}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          aria-label="Enter Ticket to Get Drawing Entry"
          onClick={navigateToTicketEntry}
        >
          {t('drawDetails.enterTicketForPoints')}
        </Button>
      </Grid>
    );
  };

  return (
    <Grid container={true} direction="row">
      <Grid lg={12} item={true} container={true} spacing={2}>
        <Grid item={true}>
          <CardMedia className={classes.imageConatiner} component="img" image={imageUrl} />
        </Grid>
      </Grid>
      <Grid container={true}>
        <Typography variant="subtitle1">{t('collectThemAll.subTitle')}</Typography>
      </Grid>
      <Grid container={true}>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: `${description}` }} className={classes.description} />
      </Grid>
      <Grid className={classes.gridPadding} container={true}>
        {renderTicketButton()}
      </Grid>
    </Grid>
  );
};

export default CollectThemAll;
