import { GET_INFORMATION_ONLY_TILE_DETAILS, GET_INFORMATION_ONLY_TILE_LIST } from '@pbl/pbl-react-core/lib/models/information-only-tile/types';
import { InformationOnlyTileService } from '@pbl/pbl-react-core/lib/services';
// import { clearLoading, showMessageBar, toggleLoading } from 'redux/reducers/app/actions';

export const getAllInformationOnlyTiles = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_INFORMATION_ONLY_TILE_LIST,
      payload: InformationOnlyTileService.GetAllInformationOnlyTiles()
    });
  } catch (e) {
    console.error(e);
  }
};

export const getInformationOnlyTileDetails = (id: number) => async (dispatch: any) => {
  try {
    await dispatch({
      type: GET_INFORMATION_ONLY_TILE_DETAILS,
      payload: InformationOnlyTileService.GetAllInformationOnlyTileDetail(id)
    });
  } catch (e) {
    console.error(e);
  }
};
