import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flex: '0 0 auto',
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      background: 'transparent',
      alignSelf: 'center',
      order: 2
    },
    toolBar: {
      ...container,
      display: 'inline-flex',
      padding: 0,
      alignItems: 'center'
    },
    grow: {
      flexGrow: 1
    },
    logo: {
      height: '43px',
      width: '132px',
      marginTop: theme.spacing(2)
    },
    applePlayStore: {
      height: '80px',
      width: '165px',
      [theme.breakpoints.down('xs')]: {
        width: '175px'
      }
    },
    googlePlayStore: {
      height: '80px',
      width: '200px'
    },
    list: {
      WebkitBoxAlign: 'center',
      MsFlexAlign: 'center',
      alignItems: 'center',
      WebkitBoxOrient: 'horizontal',
      WebkitBoxDirection: 'normal',
      MsFlexDirection: 'row',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {},
      marginTop: '0px',
      display: 'flex',
      paddingLeft: theme.spacing(),
      marginBottom: '0',
      listStyle: 'none',
      padding: '0'
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        textAlign: 'center'
      }
    },
    listItemSocial: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      padding: '0'
    },
    navLink: {
      color: 'inherit',
      position: 'relative',
      paddingLeft: theme.spacing(3),
      textDecoration: 'none',
      margin: '0px',
      marginLeft: theme.spacing() / 2,
      display: 'inline-flex',
      '&:hover,&:focus': {
        color: 'inherit',
        textDecoration: 'none'
      }
    },
    navLinkIcon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing() / 4,
      fontSize: '18px',
      width: 'auto',
      [theme.breakpoints.down('md')]: {
        marginRight: 0
      },
      '&:hover,&:focus': {
        color: theme.palette.primary.main
      }
    },
    navLinkText: {
      ...theme.typography.body1,
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.primary.main,
      '&:hover,&:focus': {
        color: theme.palette.primary.main
      }
    },
    navLinkActive: {
      '&, &:hover, &:focus,&:active ': {
        color: 'inherit',
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
      }
    },
    navLinkDisabled: {
      '&, &:hover, &:focus,&:active ': {
        color: 'inherit',
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      }
    },
    parentContainer: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(11)
      }
    },
    staticFooter: {
      width: '100%',
      paddingTop: theme.spacing(3)
    },
    bottomSpace: {
      paddingBottom: theme.spacing(2)
    },
    staticFooterItems: { width: '100%', overflow: 'hidden', marginBottom: theme.spacing(4) },
    menuItem: {
      paddingTop: '20px',
      [theme.breakpoints.down('md')]: {
        paddingTop: '0px',
        marginLeft: '70px'
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px',
        marginLeft: '0px'
      }
    },
    socialItem: {
      textAlign: 'right',
      margin: '0px 0px 0px auto',
      paddingTop: '12px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '12px'
      }
    },
    mediaView: {
      ...container,
      fontSize: theme.typography.body2.fontSize,
      fontFamily: theme.typography.body2.fontFamily
    }
  });

export default styles;
