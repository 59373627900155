import color from 'color';

const themeColors: {
  primaryColor: color;
  secondaryColor: color;
  warningColor: color;
  dangerColor: color;
  successColor: color;
  infoColor: color;
  roseColor: color;
  grayColor: color;
  white: color;
  black: color;
  accessibilityWarningColor: color;
  cornerColors: {
    activity: color;
    activityContrast: color;
    reward: color;
    rewardContrast: color;
    promotion: color;
    promotionContrast: color;
    game: color;
    gameContrast: color;
  };
  boxShadowColor: color;
} = {
  primaryColor: color('#0A548B'),
  secondaryColor: color('#FEC524'),
  warningColor: color('#E6A03A'),
  dangerColor: color('#B43236'),
  successColor: color('#459B64'),
  infoColor: color('#00acc1'),
  roseColor: color('#e91e63'),
  grayColor: color('#6E7C8D'),
  white: color('#ffffff'),
  black: color('#000000'),
  accessibilityWarningColor: color('#d82a00'),
  cornerColors: {
    activity: color('#0A548B'),
    activityContrast: color('#FFFFFF'),
    reward: color('#FECC36'),
    rewardContrast: color('#000000'),
    promotion: color('#FFFFFF'),
    promotionContrast: color('#0A548B'),
    game: color('#36B1E6'),
    gameContrast: color('#000000')
  },
  boxShadowColor: color('#1b75b9')
};

const themeSweepstakesColors: {
  primaryColor: color;
  secondaryColor: color;
  textPrimaryColor: color;
  textSecondaryColor: color;
  dangerColor: color;
  white: color;
  black: color;
  warningColor: color;
  backgroundColor: color;
} = {
  primaryColor: color('#FDB71C'),
  secondaryColor: color('#eed494'),
  textPrimaryColor: color('#FFFFFF'),
  textSecondaryColor: color('#f3f4c5'),
  dangerColor: color('#FF4832'),
  white: color('#ffffff'),
  black: color('#000000'),
  warningColor: color('#FEC524'),
  backgroundColor: color('#36322B')
};

const appColors = {
  themeColors,
  themeSweepstakesColors
};

export default appColors;
