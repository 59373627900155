import { GET_FEATURED_FLAGS } from '@pbl/pbl-react-core/lib/models/feature-flagging';
import constants, { RemoteConstants } from 'config/constants';
import _ from 'lodash';
import { GetStateMethod } from 'redux/reducers';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';

export const getFeatureFlags = () => async (dispatch: any, getState: GetStateMethod) => {
  try {
    const {
      feature: { firstLoad }
    } = getState();
    if (!firstLoad) {
      const remoteFlags = await RemoteConstants.getStringSync('FEATURE_FLAGS');
      console.warn('FEATURE_FLAGS', remoteFlags);
      const flags = remoteFlags + ',' + constants.FEATURES;
      await dispatch({
        type: GET_FEATURED_FLAGS,
        payload: flags ? _.uniq(flags.split(',')) : []
      });
    } else {
      await dispatch({
        type: REQUEST(GET_FEATURED_FLAGS)
      });
      const remoteFlags = await RemoteConstants.getStringSync('FEATURE_FLAGS');
      console.warn('FEATURE_FLAGS', remoteFlags);
      const flags = remoteFlags + ',' + constants.FEATURES;
      await dispatch({
        type: SUCCESS(GET_FEATURED_FLAGS),
        payload: flags ? _.uniq(flags.split(',')) : []
      });
    }
  } catch (e) {
    await dispatch({
      type: FAILURE(GET_FEATURED_FLAGS),
      payload: e
    });
    throw e;
  }
};
