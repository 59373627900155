import { Activity } from '@pbl/pbl-react-core/lib/models/loyalty';
import { IActivity } from '@pbl/pbl-react-core/lib/models/loyalty/types';
import { ICornerIconProps } from '@pbl/pbl-react-web-components/lib/card/CornerIcon';
import ListCard from '@pbl/pbl-react-web-components/lib/card/ListCard';
import { assets } from '@pbl/pbl-react-web-components/lib/config/assets';
import colors from 'assets/jss/colors';
import React from 'react';

export interface IGameCardProps {
  activity: IActivity | Activity;
  onPress?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ActivityListItem: React.FunctionComponent<IGameCardProps> = ({ activity, onPress }) => {
  const { isInformationOnly, extra_data } = activity;
  const isPlayForFunOnly =
    activity.extra_data && activity.extra_data.is_play_for_fun_only && activity.extra_data.is_play_for_fun_only === 'true';
  const cardTitle = extra_data?.card_title;

  const cornerIcon: ICornerIconProps = {
    icon: isInformationOnly ? undefined : 'icon-earn',
    iconType: 'custom',
    contentColor: colors.themeColors.cornerColors.activityContrast.hex(),
    trianglePosition: { top: 0, left: 0 },
    triangleType: 'topLeft',
    triangleBackgroundColor: isInformationOnly ? colors.themeColors.white.hex() : colors.themeColors.cornerColors.activity.hex(),
    triangleSize: 88,
    image: isInformationOnly ? assets.internalAssets.starImg : undefined
  };

  let title = 'EARN POINTS';
  if (isPlayForFunOnly) {
    title = 'EARN: 0 POINTS';
  } else if (activity.num_points && activity.num_points > 0) {
    title = `EARN: ${activity.num_points.toPointsFormatWithUnit().toUpperCase()}`;
  } else if (isInformationOnly) {
    title = `SPECIAL OFFERS!`;
  }

  // Allow customize title
  title = cardTitle ? cardTitle : title;

  return (
    <ListCard
      image={activity.image}
      imageTitle={`${activity.public_title}`}
      text={!!activity?.extra_data?.display_name ? activity.extra_data.display_name : activity.public_title}
      title={title}
      textFooter={!!activity.extra_data && !!activity.extra_data.preview_text ? activity.extra_data.preview_text : activity.description}
      titleColor={'primary'}
      cornerIcon={cornerIcon}
      onClick={onPress}
      bonusPoints={activity.totalBonusPoints}
      noneBonusPoints={activity.num_points}
    />
  );
};

export default ActivityListItem;
