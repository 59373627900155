import { createStyles, Theme } from '@material-ui/core';
import { container } from 'assets/jss/material-kit-pro-react.jsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...container,
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    formRow: {
      padding: 12
    },
    field: {
      width: '100%'
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      paddingTop: 10,
      paddingBottom: 10,
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10)
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3)
    },
    addressContent: {
      paddingRight: theme.spacing() + 4,
      paddingLeft: theme.spacing() + 4
    }
  });

export default styles;
