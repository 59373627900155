const exploreConfig = [
  {
    id: 1,
    header: 'Welcome to Lucky Lounge!',
    body:
      'Play fun digital games and earn tokens to enter exclusive Lucky Lounge Sweepstakes drawings for a chance to win exciting cash prizes up to $10,000!' +
      '<br/><br/>' +
      'Explore all the ways to play and win. Access Lucky Lounge by selecting the PLAY button on the navigation bar.',
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-1.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-1.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-1.png').default
  },
  {
    id: 2,
    header: 'Lucky Lounge Games',
    body:
      'Whether you’re playing for fun or playing for keeps, there is a Lucky Lounge game for everyone. Select a game to play from the Lucky Lounge home page!' +
      '<br/><br/>' +
      'You can see how many tokens it takes to play and how many tokens you can earn. Scroll to learn more about tokens.',
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-2.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-2.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-2.png').default
  },
  {
    id: 3,
    header: 'Introducing Tokens',
    body:
      'Tokens are the currency of Lucky Lounge! Tokens are used to play games and enter drawings for cash prizes.' +
      '<br/><br/>' +
      'You can play games to earn more tokens, purchase tokens in Lucky Lounge, or exchange your Players Club Points for tokens! Log in daily and get 50 free tokens! Learn more about how to purchase tokens on the next screen.',
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-3.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-3.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-3.png').default
  },
  {
    id: 4,
    header: 'Purchase Tokens',
    body:
      'Tokens are available for purchase at any time in Lucky Lounge by clicking the ADD TOKENS button. Token bundles start at $5 for 5,000 tokens. Add the bundle you would like to purchase to your cart and complete your purchase securely online.' +
      '<br/><br/>' +
      'Learn more about how to exchange Players Club Points for tokens on the next screen.',
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-4.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-4.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-4.png').default
  },
  {
    id: 5,
    header: 'Convert Points to Tokens',
    body: `Players Club Points can be exchanged for tokens in Lucky Lounge. The exchange rate is 100 points for 10 tokens. Type in the number of points or use the slider to select the number of points you would like to convert to tokens.
      <br/><br/>Once you have exchanged your points for tokens, Lucky Lounge Tokens cannot be converted back to Players Club Points. Complete your exchange by visiting the ADD TOKENS page any time in the Lucky Lounge!`,
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-5.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-5.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-5.png').default
  },
  {
    id: 6,
    header: 'Sweepstakes Drawings',
    body: `With more ways to play comes more ways to win. Use your tokens to enter sweepstakes drawings for exclusive cash prizes!
      <br/><br/>Lucky Lounge offers weekly, bi-weekly, monthly, and quarterly drawings for a chance to win up to $10,000! Select a drawing to enter for your chance to win. You can redeem up to 1,440 tokens each day.`,
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-6.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-6.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-6.png').default
  },
  {
    id: 7,
    header: 'Lucky Lounge History',
    body: `You can access your Lucky Lounge History any time from the Profile Menu.<br/><br/>
        Your History will show all points exchanges, game plays, and drawings entered. You can also see your points exchanges in your Players Club History. Your token balance will appear on every screen in Lucky Lounge.
        <br/><br/>Play, earn tokens, and win sweepstakes today!`,
    image: require('../../src/assets/img/sweepstakes/explore/explore-desktop-7.png').default,
    imageTablet: require('../../src/assets/img/sweepstakes/explore/explore-tablet-7.png').default,
    imageMobile: require('../../src/assets/img/sweepstakes/explore/explore-mobile-7.png').default
  }
];

export default exploreConfig;
