import { nowUtc } from '@pbl/pbl-react-core/lib/utils/TimeUtil';
import { RemoteConstants } from 'config/constants';
import * as _ from 'lodash';
import moment from 'moment';
import colors from '../assets/jss/colors';

interface IProfileProps {
  firstName: string;
  birthDate: string;
  registrationDate: string;
}

const displayGreetingsMessage = async (props: IProfileProps) => {
  let showFirstName = true;
  let message = '';
  let fontColor = colors.themeColors.secondaryColor.hex();
  let fontSize = 24;
  let fontFamily = 'Jost-Medium';
  await RemoteConstants.sync();
  const GREETINGS_MESSAGE_CONFIG = RemoteConstants.getString('GREETINGS_MESSAGE_CONFIG');
  const isBirthDate = moment(new Date()).format('MM-DD') === moment(props.birthDate).format('MM-DD');
  const isRegistrationDate =
    moment(new Date()).format('MM-DD') === moment(props.registrationDate).format('MM-DD') &&
    moment(new Date()).format('YYYY') !== moment(props.registrationDate).format('YYYY');

  if (!!GREETINGS_MESSAGE_CONFIG) {
    const greetingsMessageConfig = JSON.parse(GREETINGS_MESSAGE_CONFIG);
    showFirstName = greetingsMessageConfig.showFirstName;

    if (greetingsMessageConfig.fontColor) {
      fontColor = greetingsMessageConfig.fontColor;
    }
    if (greetingsMessageConfig.fontSize) {
      fontSize = greetingsMessageConfig.fontSize;
    }
    if (greetingsMessageConfig.fontFamily) {
      fontFamily = greetingsMessageConfig.fontFamily;
    }
    if (!isBirthDate && !isRegistrationDate && !!greetingsMessageConfig.messageArrays) {
      message = getMessage(filterMessage(greetingsMessageConfig.messageArrays));
    }
    if (isRegistrationDate) {
      message = greetingsMessageConfig.anniversaryMessage;
    }
    if (isBirthDate) {
      message = greetingsMessageConfig.birthdayMessage;
    }
    if (!message) {
      message = getDynamicMessage(greetingsMessageConfig);
    }
  }

  if (!message) {
  message = getDynamicMessage();
  }

  return { message, showFirstName, fontColor, fontSize, fontFamily };
};

const getDynamicMessage = (greetingsMessageConfig?): string => {
  let message;
  const myDate = new Date();
  const hours = myDate.getHours();
    message = 'Good ';
    if (hours < 12) {
      message = !!greetingsMessageConfig && greetingsMessageConfig.morningText ? greetingsMessageConfig.morningText : message + 'Morning ';
    } else if (hours >= 12 && hours <= 17) {
      message = !!greetingsMessageConfig && greetingsMessageConfig.afternoonText ? greetingsMessageConfig.afternoonText : message + 'Afternoon ';
    } else if (hours >= 17 && hours <= 24) {
      message = !!greetingsMessageConfig && greetingsMessageConfig.eveningText ? greetingsMessageConfig.eveningText : message + 'Evening ';
    }
  return message;
};

const getMessage = (messages: string[]): string => {
  const currentTime: number = moment().hour();
  return messages[currentTime % messages.length];
};

const filterMessage = (messageArrays: any) => {
  let messages = [];
  const staticMessages = messageArrays.filter(obj => obj.type.toString().toLowerCase() === 'static' && checkValidity(obj.startDate, obj.endDate));
  if (!!staticMessages && staticMessages.length > 0) {
    messages = staticMessages.map(obj => obj.messageText);
  } else {
    const randomMessages = messageArrays.filter(obj => obj.type.toString().toLowerCase() === 'random' && checkValidity(obj.startDate, obj.endDate));
    messages = randomMessages.map(obj => obj.messageText);
  }

  return messages;
};

const checkValidity = (start, end): boolean => {
  let isValidStart = true;
  let isValidEnd = true;
  const now = nowUtc().unix();

  if (!!start && start.length > 0) {
    const startDate = moment.utc(start).unix();
    isValidStart = now >= startDate;
  }
  if (
    !!start &&
    !!end &&
    start.length > 0 &&
    end.length > 0
  ) {
    const startDate = moment.utc(start).unix();
    const endDate = moment.utc(end).unix();
    isValidEnd = now >= startDate && now <= endDate;
  }

  const isValidDate = isValidStart && isValidEnd;

  return isValidDate;
};

export const formatTemplate = (account: any, clonedContent: string): string => {
  const listOfValue = clonedContent ? clonedContent.match(/\{{([^}]+)\}}/g) : [];

  _.forEach(listOfValue, (parameter: string) => {
    const templateKey = parameter.replace('{{', '').replace('}}', '');
    if (account[templateKey] !== undefined) {
      let value = '';
      if (account[templateKey] !== null) {
        value = account[templateKey];
      }
      clonedContent = clonedContent.replace(parameter, value);
    }
  });
  return clonedContent;
};

export default displayGreetingsMessage;
