import { Card, CardContent, Grid, Hidden, Typography, withStyles, WithStyles } from '@material-ui/core';
import { IErrorParams } from '@pbl/pbl-react-core/lib/models/app';
import styles from 'assets/jss/modules/login/LoginScreenStyle';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { dismissDailyBonus } from 'redux/reducers/activity/actions';
import { showMessageBar } from 'redux/reducers/app/actions';
import { resetErrors } from 'redux/reducers/authentication/actions';
import ScrollToTopOnMount from 'shared/components/routes/ScrollToTopOnMount';
import { scrollToTheTop } from 'utils/htmlUtil';
import displayOutageBanner from 'utils/outageUtils';
import LoginForm from './components/LoginForm';

type PropsConnected = ConnectedProps<typeof connector>;

interface ILoginScreenProps extends WithStyles<typeof styles>, PropsConnected, RouteComponentProps {}

class LoginScreen extends React.Component<ILoginScreenProps> {
  public async componentDidMount(): Promise<void> {
    document.title = 'Sign In';
    scrollToTheTop();

    const { showOutageBanner, body, displayTime } = await displayOutageBanner();
    if (showOutageBanner) {
      this.props.showMessageBar({
        type: 'outage_warning',
        message: body,
        messageTimeout: displayTime
      });
    }
    const { location } = this.props;
    this.props.resetErrors();
    const locationState: any = location && location.state ? location.state : {};

    if (locationState.message) {
      this.props.showMessageBar(locationState.message);
    }
  }

  public render(): React.ReactNode {
    const { classes, showSuspendMessage } = this.props;
    return (
      <div className={classes.loginScreen}>
        <div className={classes.container}>
          <Grid container={true} justify="center" item={true} xs={12}>
            <Grid item={true} xs={11} sm={11} md={6}>
              <ScrollToTopOnMount />
              <Card raised={true} className={classes.loginCard}>
                <CardContent className={classes.loginCardContent}>
                  <div className={classes.loginCardContentInner}>
                    <Hidden xsDown={true}>
                      <Typography component="h1" color="primary" variant="h4" className={classes.titleText}>
                        Sign In
                      </Typography>
                    </Hidden>
                    <Hidden smUp={true}>
                      <Typography component="h1" color="primary" variant="h6" className={classes.titleText}>
                        Sign In
                      </Typography>
                    </Hidden>
                    {showSuspendMessage ? this.renderSuspendMessage() : null}
                    <LoginForm successCallback={this.onSuccessLogin} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  private renderSuspendMessage = () => {
    const { classes } = this.props;
    return (
      <Grid container={true} justify="center" item={true} xs={12} className={classes.errorText}>
        <Typography color="error" variant="body1">
          Unfortunately, your account has been suspended. Please contact <a href="/feedback">support</a>.
        </Typography>
      </Grid>
    );
  };

  private onSuccessLogin = () => {
    const { location } = this.props;

    this.props.dismissDailyBonus(false);
    let path = '/';
    if (location && location.state) {
      path = (location.state || ({} as any))?.from || '/';
    }
    this.props.history.replace(path);
  };
}

const mapStateToProps = ({ authentication: { showSuspendMessage } }: IRootState) => ({
  showSuspendMessage
});

const mapDispatchToProps = dispatch => ({
  showMessageBar: (params: IErrorParams = {}) => dispatch(showMessageBar(params)),
  resetErrors: () => dispatch(resetErrors()),
  dismissDailyBonus: (dismiss: boolean) => dispatch(dismissDailyBonus(dismiss))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withStyles(styles)(LoginScreen)));
