import { Theme, withTheme } from '@material-ui/core';
import { ISurveyAnswer } from '@pbl/pbl-react-core/lib/models/survey/types';
import UserSurvey from '@pbl/pbl-react-web-components/lib/survey/UserSurvey';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar, toggleLoading } from 'redux/reducers/app/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import { postSurvey, reset, resetError, updateSurveyPostResponse } from 'redux/reducers/survey/action';

type PropsConnected = ConnectedProps<typeof connector>;

interface IInterestsScreenProps extends PropsConnected, RouteComponentProps {
  theme: Theme;
}

class InterestsContent extends React.Component<IInterestsScreenProps> {
  componentWillUnmount() {
    this.props.reset();
    this.props.setIsFormDirty(false);
  }

  public onChange = (answer: ISurveyAnswer) => {
    this.props.updateSurveyPostResponse(answer);
    this.props.setIsFormDirty(true);
  };

  public postSurvey = async () => {
    await this.props.postSurvey();
    const {
      survey: { surveyPostSuccess }
    } = this.props;
    if (surveyPostSuccess) {
      this.props.setIsFormDirty(false);
      this.props.showMessageBar({ message: 'interests.updated' });
    }
  };

  public render() {
    const {
      survey: { survey, surveyPostResponse }
    } = this.props;
    return (
      <div>
        {survey && surveyPostResponse ? (
          <UserSurvey
            onSave={this.postSurvey}
            onChange={this.onChange}
            survey={survey}
            surveyPostResponse={surveyPostResponse}
            isLoading={this.props.survey.loading || this.props.loyalty.loading}
            isSystemSurvey={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ survey, loyalty, formMetadata }: IRootState) => ({
  survey,
  loyalty,
  formMetadata
});

const mapDispatchToProps = {
  resetError,
  updateSurveyPostResponse,
  postSurvey,
  toggleLoading,
  showMessageBar,
  reset,
  setIsFormDirty
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(withTheme(InterestsContent)));
