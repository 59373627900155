import {
  CLAIM_FREE_ENTRY,
  CLAIM_FREE_ENTRY_FOR_GUEST,
  FETCH_DETAILS_PAGE_PROGRESSIVE_PRIZES,
  FETCH_DRAWS,
  FETCH_DRAWS_BY_PROMOTION,
  FETCH_HOME_PAGE_PROGRESSIVE_PRIZES,
  FETCH_HOME_PAGE_PROGRESSIVE_PROMOTIONS,
  FETCH_HOME_PAGE_PROMOTIONS,
  FETCH_PROGRESSIVE_PRIZES,
  FETCH_PROMOTIONS,
  FETCH_PROMOTION_DETAILS,
  IDraw,
  IDrawPage,
  IPromotion,
  LOAD_MORE,
  RESET_PROMOTION,
  SELECT_PROMOTION
} from '@pbl/pbl-react-core/lib/models/draws/types';
import { FAILURE, REQUEST, SUCCESS } from '../../action-type.util';
import { IDispatchAction } from '../index';

export interface IDrawsState {
  promotions: IPromotion[];
  promotionPage: IDrawPage;
  featuredPromotion: IPromotion;
  selectedPromotion: IPromotion;
  loading: boolean;
  errorMessage: any;
  draws: IDraw[];
  homePromotions: IPromotion[];
  homeProgressivePromotions: IPromotion[];
}

const INITIAL_STATE: IDrawsState = {
  promotions: [],
  promotionPage: {} as IDrawPage,
  featuredPromotion: {} as IPromotion,
  selectedPromotion: {} as IPromotion,
  loading: false,
  errorMessage: null,
  draws: [],
  homePromotions: [],
  homeProgressivePromotions: []
};
export type DrawsState = Readonly<IDrawsState>;

export default (state: DrawsState = INITIAL_STATE, action: IDispatchAction) => {
  switch (action.type) {
    case REQUEST(FETCH_PROMOTIONS):
    case REQUEST(FETCH_PROMOTION_DETAILS):
    case REQUEST(FETCH_DRAWS_BY_PROMOTION):
    case REQUEST(CLAIM_FREE_ENTRY):
    case REQUEST(CLAIM_FREE_ENTRY_FOR_GUEST):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(FETCH_HOME_PAGE_PROMOTIONS):
      return {
        ...state,
        homePromotions: action.payload && action.payload.content && action.payload.content.length > 0 ? action.payload.content : []
      };
    case SUCCESS(FETCH_HOME_PAGE_PROGRESSIVE_PROMOTIONS):
      return {
        ...state,
        homeProgressivePromotions:
          action.payload && action.payload.content && action.payload.content.length > 0 ? action.payload.content : []
      };
    case SUCCESS(FETCH_HOME_PAGE_PROGRESSIVE_PRIZES):
      return {
        ...state,
        homeProgressivePromotions: action.payload
      };
    case SUCCESS(FETCH_PROGRESSIVE_PRIZES):
      return {
        ...state,
        promotions: action.payload.promotions,
        featuredPromotion: action.payload.featuredPromotion
      };
    case SUCCESS(FETCH_DETAILS_PAGE_PROGRESSIVE_PRIZES):
      return {
        ...state,
        selectedPromotion: action.payload
      };
    case SUCCESS(FETCH_PROMOTIONS):
      return {
        ...state,
        loading: false,
        promotions: action.payload && action.payload.content && action.payload.content.length > 0 ? action.payload.content.slice(1) : [],
        featuredPromotion: action.payload && action.payload.content && action.payload.content.length > 0 ? action.payload.content[0] : {},
        promotionPage: action.payload.page
      };
    case SUCCESS(FETCH_PROMOTION_DETAILS):
      return {
        ...state,
        loading: false,
        selectedPromotion: action.payload
      };
    case SUCCESS(FETCH_DRAWS_BY_PROMOTION):
      return {
        ...state,
        loading: false,
        draws: action.payload
      };
    case SUCCESS(LOAD_MORE): {
      return {
        ...state,
        promotions:
          action.payload.page && action.payload.page.number === 0 && action.payload.content && action.payload.content.length > 0
            ? action.payload.content.slice(1)
            : action.payload.content,
        promotionPage: action.payload.page
      };
    }
    case SUCCESS(FETCH_DRAWS): {
      return {
        ...state,
        homePromotions: action.payload.content
      };
    }
    case SUCCESS(CLAIM_FREE_ENTRY):
    case SUCCESS(CLAIM_FREE_ENTRY_FOR_GUEST):
      return {
        ...state,
        loading: false
      };
    case SELECT_PROMOTION: {
      return {
        ...state,
        selectedPromotion: action.payload
      };
    }
    case RESET_PROMOTION: {
      return {
        ...state,
        selectedPromotion: {} as IPromotion,
        loading: false,
        errorMessage: null,
        draws: []
      };
    }
    case FAILURE(FETCH_PROMOTIONS):
    case FAILURE(FETCH_PROMOTION_DETAILS):
    case FAILURE(FETCH_DRAWS_BY_PROMOTION):
    case FAILURE(FETCH_DRAWS):
    case FAILURE(CLAIM_FREE_ENTRY):
    case FAILURE(CLAIM_FREE_ENTRY_FOR_GUEST):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};
