import { CardMedia, createStyles, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import Title from '@pbl/pbl-react-web-components/lib/title/Title';
import color from 'color';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface IInformationOnlyTileProps {
  title: string;
  summary: string;
  webImageUrl: string;
  mobileImageUrl: string;
  viewDetails?: any;
  description?: string;
  webDescription?: string;
  buttonTextWeb?: string;
  buttonTargetWeb?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    titleContainer: {
      justifyContent: 'space-between'
    },
    informationContainer: {
      maxWidth: '100%',
      marginBottom: theme.spacing(3)
    },
    informationImage: {
      maxWidth: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    informationButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(1),
      width: '160px',
      height: '44px'
    },
    informationDescription: {
      color: color(theme.palette.common.black).rgb().string(),
      paddingTop: theme.spacing(2)
    },
    summaryDescription: {
      color: color(theme.palette.common.black).rgb().string(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },
    title: {
      color: color(theme.palette.common.black).alpha(0.8).rgb().string(),
      paddingBottom: theme.spacing(3)
    },
    imageConatiner: {
      overflow: 'hidden',
      maxHeight: 480,
      height: 445,
      borderRadius: '6px',
      border: 'solid 1px #979797',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        maxHeight: 450
      },
      [theme.breakpoints.down('md')]: {
        height: 346,
        maxHeight: 400
      },
      [theme.breakpoints.down('xs')]: {
        height: 346,
        maxHeight: 360
      }
    },
    headerText: {
      paddingBottom: theme.spacing()
    }
  });

const useStyles = makeStyles(styles);

const InformationOnlyTile: React.FC<IInformationOnlyTileProps> = ({
  title,
  summary,
  webImageUrl,
  mobileImageUrl,
  webDescription,
  buttonTextWeb,
  buttonTargetWeb
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const navigateToTicketEntry = () => {
    if (buttonTargetWeb) {
      history.push(buttonTargetWeb, { from: location.pathname });
    }
  };
  return (
    <Grid container={true} direction="column">
      <Grid container={true} direction="row" className={classes.titleContainer}>
        <Grid item={true}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item={true}>
          {!!buttonTextWeb && !!buttonTargetWeb && (
            <Grid item={true} xs={12}>
              <div className={classes.headerText}>
                <Title buttonText={buttonTextWeb} buttonTextTitle={buttonTextWeb} navigateToTicketEntry={navigateToTicketEntry} />
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid lg={12} item={true} container={true} spacing={2}>
        <Grid item={true} lg={5} sm={6} xs={12}>
          <Hidden xsDown={true}>
            <CardMedia className={classes.imageConatiner} component="img" image={webImageUrl} />
          </Hidden>
          <Hidden smUp={true}>
            <CardMedia className={classes.imageConatiner} component="img" image={mobileImageUrl} />
          </Hidden>
        </Grid>
        <Grid item={true} lg={7} sm={6} xs={12}>
          {!!summary && (
            <Grid>
              <Typography variant="h5">{t('informationOnlyTile.informationSummaryTitle')}</Typography>
              <Typography variant="body1" dangerouslySetInnerHTML={{ __html: `${summary}` }} className={classes.summaryDescription} />{' '}
            </Grid>
          )}
        </Grid>
      </Grid>
      {!!webDescription && (
        <Grid>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: `${webDescription}` }}
            className={classes.informationDescription}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InformationOnlyTile;
