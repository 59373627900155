import { Button, Grid, Theme, Typography, withTheme } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IAlreadyLoginInvitationProps extends RouteComponentProps {
  theme: Theme;
}

const AlreadyLoginInvitation: React.FunctionComponent<IAlreadyLoginInvitationProps> = ({ theme, history }) => {
  const onNavigate = (route: string) => () => {
    history.push(route);
  };

  return (
    <Grid container={true} justify="center">
      <Grid item={true} xs={12} sm={6} md={6} lg={5} xl={4}>
        <Grid container={true} direction="column">
          <Grid item={true}>
            <Typography
              variant="h6"
              style={{
                textAlign: 'center',
                color: theme.palette.text.primary.getHighEmphasis(),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(4)
              }}
            >
              Sorry, this invitation is valid for only new customers
            </Typography>
          </Grid>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={onNavigate('/')}
              style={{ margin: theme.spacing() / 2, width: '40%' }}
            >
              Home
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={onNavigate('/register')}
              style={{ margin: theme.spacing() / 2, width: '40%' }}
            >
              Register
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={onNavigate('/login')}
              style={{ margin: theme.spacing() / 2, width: '40%' }}
            >
              Sign In
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTheme(withRouter(AlreadyLoginInvitation));
