import {
  ERROR_RESET,
  FETCH_USER_REPONSE,
  ISurveyAnswer,
  ISurveyPostResponse,
  RESET,
  SUBMIT_SURVEY,
  UPDATE_SURVEY_POST_RESPONSE,
  USER_SURVEY
} from '@pbl/pbl-react-core/lib/models/survey/types';
import { LoyaltyService } from '@pbl/pbl-react-core/lib/services';
import constants from 'config/constants';
import { clearMessages, showMessageBar } from '../app/actions';
import { DispatchMethod, GetStateMethod } from '../index';
import { fetchSurvey as fetchV2Survey, fetchUserSurveyResponse as fetchUserSurveyResponseV2 } from './v2/action';

export const fetchSurvey = (activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());

    await dispatch({
      type: USER_SURVEY,
      payload: LoyaltyService.getSurvey(activityId)
    });
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const updateSurveyPostResponse = (answer: ISurveyAnswer) => (dispatch: DispatchMethod) => {
  dispatch({
    type: UPDATE_SURVEY_POST_RESPONSE,
    payload: answer
  });
};

export const fetchUserSurveyResponse = (activityId: string) => async (dispatch: any) => {
  resetError();
  try {
    dispatch(clearMessages());

    await dispatch({
      type: FETCH_USER_REPONSE,
      payload: LoyaltyService.getSurveyResponse(activityId)
    });
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};

export const fetchInterests = () => async (dispatch: any) => {
  dispatch(clearMessages());
  const interestsSurveyActivity = await LoyaltyService.getActivityByKey(constants.INTERESTS_SURVEY.ACTIVITY_KEY);
  const interestsSurveyId = await LoyaltyService.getActivityMapping(constants.INTERESTS_SURVEY.SURVEY_ID);
  if (!!interestsSurveyActivity && !!interestsSurveyId) {
    try {
      if (interestsSurveyActivity.extra_data && interestsSurveyActivity.extra_data.legacy_survey) {
        await dispatch(fetchSurvey(interestsSurveyActivity.id));
        await dispatch(fetchUserSurveyResponse(interestsSurveyId.activityId));
      } else {
        await dispatch(fetchV2Survey(interestsSurveyActivity.id));
        await dispatch(fetchUserSurveyResponseV2(interestsSurveyActivity.id));
      }
    } catch (e) {
      dispatch(showMessageBar({ message: 'error.general', type: 'error' }));
    }
  } else {
    dispatch(showMessageBar({ message: 'error.general', type: 'error' }));
  }
};

export const resetError = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: ERROR_RESET,
    payload: null
  });
};

export const reset = () => (dispatch: DispatchMethod) => {
  dispatch({
    type: RESET,
    payload: null
  });
};

export const postSurvey = () => async (dispatch: any, getState: GetStateMethod) => {
  resetError();
  try {
    dispatch(clearMessages());
    const {
      survey: { surveyPostResponse }
    } = getState();
    await dispatch({
      type: SUBMIT_SURVEY,
      payload: LoyaltyService.postSurveyV1(surveyPostResponse as ISurveyPostResponse)
    });
  } catch (e) {
    dispatch(showMessageBar({ message: e.payload.error, type: 'error' }));
  }
};
