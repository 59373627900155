import { Button, Grid, Menu, MenuItem, MenuList, Tooltip, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { ArrowDropUp } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import { RemoteConstants } from 'config/constants';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from 'assets/jss/shared/components/menu/PopupMenuStyle';
import { IMenuItem, INavItem } from 'config/menu';

export interface IPopupMenuProps {
  popupMenu: IMenuItem[];
  isAuthenticated: boolean;
  menuName: string;
  icon: string;
  title: string;
  authorizedFlags: string[];
}

interface IProps extends IPopupMenuProps, WithStyles<typeof styles>, RouteComponentProps {}

const PopupMenu: React.FunctionComponent<IProps> = ({
  classes,
  popupMenu,
  title,
  isAuthenticated,
  icon,
  menuName,
  history,
  authorizedFlags
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menu = isAuthenticated ? popupMenu.filter(x => !x.hideOnAuth) : popupMenu.filter(x => !x.hideOnUnAuth);

  React.useEffect(() => {
    return history.listen(() => {
      if (history.action === 'PUSH' || history.action === 'POP') {
        handleClose();
      }
    });
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(!!anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [menuOpen, setMenuOpen] = React.useState(false);

  const navigate = (item: INavItem) => {
    // @ts-ignore
    history.push(item.route || '/');
  };

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  const handleMenuItemClick = (item, subMenuItem) => {
    item.isActive = true;
    navigate(subMenuItem);
    setMenuOpen(false);
    handleClose();
  };

  const prevOpen = React.useRef(menuOpen);
  React.useEffect(() => {
    if (prevOpen.current && !menuOpen) {
      anchorRef.current!.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const renderMenuItem = (_item: IMenuItem, _index: number) => {
    const item = _item.feature
      ? authorizedFlags.includes(_item.feature)
        ? _item
        : _item.featurePlaceholder
        ? !isAuthenticated && _item.featurePlaceholder.hideOnUnAuth
          ? null
          : _item.featurePlaceholder
        : null
      : _item;
    if (!item) return null;
    const navigateTo = () => {
      if (!item.subMenu) {
        handleClose();
        if (item.route) {
          history.push(item.route);
        } else if (item.onClick) {
          item.onClick();
        }
      }
    };

    const iconRight = item.position && item.position === 'right' && (
      <Icon fontSize="small" color="inherit" className={classNames(item.icon)} />
    );

    const iconLeft =
      !item.position || (item.position === 'left' && <Icon fontSize="small" color="inherit" className={classNames(item.icon)} />);

    const hasSubTitle = !!item.subTitle;
    let menuContent;
    if (hasSubTitle) {
      menuContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle2" style={{ marginBottom: 4 }} component={'span'}>
            {item.key === 'profile' ? item.title : item.title.toUpperCase()}
          </Typography>
          <Typography variant="caption" color="primary">
            {item.subTitle?.toUpperCase()}
          </Typography>
        </div>
      );
    } else {
      menuContent = <Typography variant="body2">{item.title}</Typography>;
    }

    const isGameDropdownEnabled = RemoteConstants.getBoolean('ENABLE_GAME_DROPDOWN');

    if (!!item.subMenu && isGameDropdownEnabled) {
      const menuItems = item.subMenu.filter(filterSubMenu).map(s => (
        <MenuItem key={s.key} onClick={handleMenuItemClick.bind(this, item, s)}>
          <Typography variant="body2">{s.title}</Typography>
        </MenuItem>
      ));

      menuContent = (
        <div className={classes.menuWithSubmenu} key={`${item.title}`} style={{ overflow: 'inherit' }}>
          <Tooltip title={item.title} disableFocusListener={true} aria-label={item.title}>
            <Button ref={anchorRef} aria-controls={menuOpen ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle}>
              {menuContent}
              {menuOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />}
            </Button>
          </Tooltip>
          <div style={{ display: menuOpen ? 'block' : 'none' }}>
            <MenuList autoFocusItem={menuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              {menuItems}
            </MenuList>
          </div>
        </div>
      );
    }

    return (
      <MenuItem
        key={`${item.key}_fragment`}
        onClick={navigateTo}
        component="a"
        className={classNames({
          [classes.menuItemWithSubTitle]: hasSubTitle,
          [classes.dividerTop]: item.divider === 'top',
          [classes.dividerBottom]: item.divider === 'bottom'
        })}
      >
        <Grid container={true} justify={item.position === 'right' ? 'flex-end' : 'flex-start'}>
          <Grid item={true} xs={item.position ? 6 : 12}>
            <Tooltip title={item.title} aria-label={item.title} key={`${item.title}-${item.route}`}>
              <div className={classes.content}>
                {iconLeft}
                {menuContent}
                {iconRight}
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </MenuItem>
    );
  };

  const filterSubMenu = (subMenu: IMenuItem) => {
    if (subMenu.key === 'quick-draw') {
      return authorizedFlags.includes('QUICKDRAW_V1');
    }
    return true;
  };

  return (
    <React.Fragment>
      <Button
        aria-owns={anchorEl ? menuName : undefined}
        aria-haspopup="true"
        className={classes.navLink}
        color="inherit"
        aria-label={title}
        onClick={handleMenuOpen}
      >
        <Icon color="inherit" className={classNames(icon, classes.navLinkIcon)} />
      </Button>
      <Menu
        aria-label={`Menu with ${menu.length} items`}
        keepMounted={true}
        id={menuName}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.popupMenu}
        disableAutoFocusItem={false}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {menu.map((navItem, index) => renderMenuItem(navItem, index))}
      </Menu>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles)(PopupMenu));
