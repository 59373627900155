import {
  DECREMENT_QTY,
  GET_STORE_CATALOG,
  GET_THRESHOLD_STATUS,
  INCREMENT_QTY,
  IStoreInitialState,
  StoreState,
  STORE_CLEAR_DATA,
  STORE_LOAD_DATA,
  STORE_REMOVE_ITEM,
  SUBMIT_ORDER
} from '@pbl/pbl-react-core/lib/models/store/types';
import _ from 'lodash';
import { FAILURE, REQUEST, SUCCESS } from 'redux/action-type.util';
import { IDispatchAction } from 'redux/reducers';

const INITIAL_STATE: IStoreInitialState = {
  loading: false,
  selectedItems: [],
  catalog: [],
  total: 0,
  response: null,
  errorMessage: null,
  thresholdStatus: null
};

export default (state: StoreState = INITIAL_STATE, action: IDispatchAction): StoreState => {
  switch (action.type) {
    case REQUEST(GET_STORE_CATALOG):
    case REQUEST(SUBMIT_ORDER):
    case REQUEST(GET_THRESHOLD_STATUS):
      return {
        ...state,
        loading: true
      };
    case SUCCESS(GET_STORE_CATALOG):
      return {
        ...state,
        loading: false,
        catalog: action.payload
      };
    case SUCCESS(SUBMIT_ORDER):
      return {
        ...state,
        loading: false,
        response: action.payload
      };
    case SUCCESS(GET_THRESHOLD_STATUS):
      return {
        ...state,
        loading: false,
        thresholdStatus: action.payload
      };
    case FAILURE(GET_STORE_CATALOG):
    case FAILURE(SUBMIT_ORDER):
    case FAILURE(GET_THRESHOLD_STATUS):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message
      };
    case INCREMENT_QTY:
    case DECREMENT_QTY:
    case STORE_REMOVE_ITEM:
    case STORE_LOAD_DATA:
      return {
        ...state,
        total: _.sumBy(action.payload, 'total'),
        selectedItems: action.payload
      };
    case STORE_CLEAR_DATA:
      return {
        ...state,
        total: 0,
        selectedItems: []
      };
    default:
      return state;
  }
};
