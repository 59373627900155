import { createStyles, Theme } from '@material-ui/core';
import { container, coloredShadow } from 'assets/jss/material-kit-pro-react.jsx';
import colors from 'assets/jss/colors';
const styles = (theme: Theme) =>
  createStyles({
    container,
    // @ts-ignore
    coloredShadow,
    centered: {
      display: 'flex',
      justifyContent: 'center'
    },
    header: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    imageContainer: {
      overflow: 'hidden',
      maxHeight: 480,
      [theme.breakpoints.down('lg')]: {
        maxHeight: 450
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 400
      },
      [theme.breakpoints.down('xs')]: {
        maxHeight: 360
      }
    },
    carouselContainer: {
      '& .slick-dots': {
        marginBottom: '3.5rem',
        [theme.breakpoints.down('md')]: {
          marginBottom: '6.5rem'
        },
        [theme.breakpoints.down('xs')]: {
          marginBottom: '-0.8rem'
        },
        '& li ': {
          '& button::before': {
            backgroundColor: theme.palette.primary.light
          }
        }
      }
    },
    rewardCarousel: {
      '& .slick-dots': {
        marginBottom: '3.5rem',
        [theme.breakpoints.down('md')]: {
          marginBottom: '6.5rem'
        },
        [theme.breakpoints.down('xs')]: {
          marginBottom: '-2.8rem'
        },
        '& li ': {
          '& button::before': {
            backgroundColor: theme.palette.grey[500]
          }
        }
      },
      '& .slick-slide': {
        padding: '.5rem'
      }
    },
    carouselImage: {
      height: '650px',
      objectFit: 'cover',
      [theme.breakpoints.down('xs')]: {
        height: '412px'
      }
    },
    cardBody: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    chip: {
      margin: theme.spacing(),
      '&:first-child': {
        marginLeft: 0
      },
      '&:focus': {
        boxShadow: `0 0 0px 2px white, 0 0 0 6px ${colors.themeColors.boxShadowColor.hex()}`
      }
    },
    jumpText: {
      paddingLeft: '6px'
    },
    categoryHeader: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
      flexWrap: 'wrap'
    },
    categoryCarousel: {
      '& .slick-slide': {
        padding: '.5rem'
      }
    },
    divider: {
      backgroundColor: theme.palette.text.secondary,
      marginBottom: theme.spacing(5)
    },
    sectionStyle: {
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing()
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing()
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(5)
      }
    },
    secondaryColor: {
      color: theme.palette.text.secondary
    }
  });

export default styles;
