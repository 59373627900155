import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IDispatchAction } from '../index';

interface IVirtualTourState {
  skippedTour?: Date;
  completedTour?: Date;
}

const INITIAL_STATE: IVirtualTourState = {
  skippedTour: undefined,
  completedTour: undefined
};

export type VirtualTourState = Readonly<IVirtualTourState>;

const VirtualTourReducer = (state: IVirtualTourState = INITIAL_STATE, action: IDispatchAction): VirtualTourState => {
  switch (action.type) {
    case 'SET_TOUR_INFO':
      return {
        ...state,
        completedTour: action.payload.completedTour,
        skippedTour: action.payload.skippedTour
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'virtualtour',
  storage,
  blacklist: []
};

export default persistReducer(persistConfig, VirtualTourReducer);
