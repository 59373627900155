import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles, withTheme } from '@material-ui/core/styles';
import { ISurveyQuestionResponse } from '@pbl/pbl-react-core/lib/models/survey/v2/types';
import AppSpinner from '@pbl/pbl-react-web-components/lib/app-spinner/AppSpinner';
import EarnActivityHeader from '@pbl/pbl-react-web-components/lib/earn/earn-activity-header';
import { EarnPointsModal } from '@pbl/pbl-react-web-components/lib/package';
import SurveyContent from '@pbl/pbl-react-web-components/lib/survey/v2/SurveyContent';
import styles from 'assets/jss/modules/survey/SurveyScreenStyle';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { showMessageBar } from 'redux/reducers/app/actions';
import { setIsFormDirty } from 'redux/reducers/forms-metadata/actions';
import {
  fetchActivities,
  fetchUserActivitiesWithInterests,
  fetchUserPoints,
  resetActivityCompleted,
  resetState,
  setActivityCompleted,
  setSelectedUserActivity
} from 'redux/reducers/loyalty/actions';
import { getProfile } from 'redux/reducers/profile/actions';
import { fetchSurvey, fetchUserSurveyResponse, postSurvey, reset, updateSurveyPostResponse } from 'redux/reducers/survey/v2/action';
import { scrollToTheTop } from 'utils/htmlUtil';

interface IMatchParams {
  activityId: string;
}
interface ISurveyScreenProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<IMatchParams>,
    WithStyles<typeof styles>,
    WithTranslation {
  theme: Theme;
}

interface ISurveyScreenState {
  displayImage: boolean;
  loading: boolean;
  isAnswered: boolean;
  surveyUpdated: boolean;
}

type Props = StateProps & DispatchProps & ISurveyScreenProps;

class SurveyScreen extends React.Component<Props, ISurveyScreenState> {
  constructor(props: Props) {
    super(props);
    this.state = { displayImage: false, loading: true, isAnswered: false, surveyUpdated: false };
  }

  public async componentDidMount() {
    document.title = 'Survey Activity';
    scrollToTheTop();
    const {
      match: { params }
    } = this.props;
    if (params.activityId.trim().length > 0) {
      await this.getData();
    } else {
      await this.showInvalidActivityError();
    }
  }

  public componentWillUnmount() {
    this.props.reset();
    this.props.setIsFormDirty(false);
  }

  public async getData() {
    const {
      match: {
        params: { activityId }
      }
    } = this.props;

    await this.props.resetState();
    if (this.props.isLoggedIn) {
      await this.props.fetchUserActivitiesWithInterests();
    } else {
      await this.props.fetchActivities();
    }
    const selectedActivity = this.props.userActivities.find(activity => activity.id.toString() === activityId);
    if (selectedActivity) {
      await this.props.setSelectedUserActivity(selectedActivity);
      await this.props.fetchSurvey(selectedActivity.id);

      // For a authenticated user fetch existing response for the survey
      if (selectedActivity && this.props.isLoggedIn) {
        await this.props.fetchUserSurveyResponse(selectedActivity.id);
        if (this.props.survey.surveyResults) {
          this.setState({ isAnswered: true });
        }
      }

      if (selectedActivity && selectedActivity.isSystemSurvey && this.props.isLoggedIn) {
        await this.setupInterests();
      }
    } else {
      if (this.props.isLoggedIn) {
        await this.showInvalidActivityError();
      }
    }
    this.setState({ loading: false });
  }

  private showInvalidActivityError = async () => {
    const { history } = this.props;
    await history.push('/earn', {
      message: {
        message: `Something went wrong. Try again!`,
        type: 'error',
        messageTimeout: 10000
      }
    });
  };

  public onChange = (answer: ISurveyQuestionResponse) => {
    this.props.updateSurveyPostResponse(answer);
    this.props.setIsFormDirty(true);
  };

  public async setupInterests() {
    // check if survey is answered so we do not show prize modal a 2nd time
    await this.props.getProfile();
    const {
      survey: { surveyPost }
    } = this.props;
    if (this.props.isLoggedIn) {
      const isAnswered = surveyPost && surveyPost.survey_response && surveyPost.survey_response.length !== 0;
      this.setState({ isAnswered: !!isAnswered });
    } else {
      this.setState({ isAnswered: false });
    }
  }

  public postSurvey = async () => {
    const { isLoggedIn } = this.props;
    const { isAnswered } = this.state;
    if (!isLoggedIn) {
      this.props.history.push('/login', { from: this.props.location });
      return;
    }
    await this.props.postSurvey();
    await this.props.fetchUserPoints();
    const {
      selectedActivity,
      survey: { surveyPostResult, errorMessage }
    } = this.props;
    if (surveyPostResult && selectedActivity && !isAnswered) {
      this.props.setActivityCompleted({
        pointsEarned: selectedActivity.num_points,
        activity: selectedActivity
      });
      this.props.setIsFormDirty(false);
      if (selectedActivity.isSystemSurvey) {
        this.props.showMessageBar({ message: 'interests.updated' });
      } else {
        this.props.showMessageBar({ message: 'survey.submitted' });
      }
    } else if (surveyPostResult && selectedActivity && isAnswered) {
      this.props.setIsFormDirty(false);
      setTimeout(async () => {
        if (selectedActivity.isSystemSurvey) {
          await this.props.showMessageBar({ message: 'interests.updated' });
        } else {
          this.props.showMessageBar({ message: 'survey.updated' });
        }
      }, 250);
      this.navigateTo();
    } else if (surveyPostResult && selectedActivity) {
      this.setState({
        surveyUpdated: true
      });
    } else if (!errorMessage) {
      this.props.history.push('/earn', {
        message: { message: `This activity is not available.`, type: 'warning', messageTimeout: 3000 }
      });
    }
  };

  public getContent() {
    const {
      survey: { survey, surveyPost },
      selectedActivity
    } = this.props;

    const bonusPoints = selectedActivity ? selectedActivity.totalBonusPoints : 0;
    return survey ? (
      <Grid item={true} xs={12}>
        {survey ? (
          <SurveyContent
            onSave={this.postSurvey}
            onChange={this.onChange}
            survey={survey}
            surveyPostResponse={surveyPost ? surveyPost : null}
            isLoading={this.props.survey.loading || this.props.loading}
            isSystemSurvey={!!(selectedActivity && selectedActivity.isSystemSurvey)}
            bonusPoints={bonusPoints}
            questionLayout={
              !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.question_layout
                ? selectedActivity.extra_data.question_layout
                : null
            }
          />
        ) : null}
      </Grid>
    ) : null;
  }

  private onDismissEarnPointsModal = (): void => {
    this.props.resetActivityCompleted();
    this.props.history.push('/earn');
  };

  private navigateTo = (): void => {
    this.props.history.push('/earn');
  };

  public render() {
    const {
      survey: { survey, surveyResults, errorMessage },
      classes,
      // loading,
      activityCompleted,
      selectedActivity
    } = this.props;
    const { isAnswered } = this.state;
    const isLoadingData = this.state.loading;
    return (
      <div>
        {this.props.isLoggedIn && !errorMessage && (
          <Prompt when={this.props.formMetadata.isFormDirty} message={this.props.t('interests.promptMessage')} />
        )}
        {!surveyResults && activityCompleted !== null ? (
          <EarnPointsModal
            pointEarned={
              !!selectedActivity && selectedActivity.totalBonusPoints > 0
                ? selectedActivity.totalBonusPoints
                : activityCompleted.pointsEarned
            }
            isVisible={activityCompleted !== null}
            onClose={this.onDismissEarnPointsModal}
          />
        ) : null}
        {survey && (
          <div className={classes.container}>
            {isLoadingData && this.props.survey.loading ? (
              <AppSpinner label={'Loading Activities'} />
            ) : (
              <Grid className={classes.container} container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <EarnActivityHeader
                    data={{
                      // @ts-ignore
                      image: survey.image_url.replace('100/100', 'w/h'),
                      title: !!selectedActivity?.extra_data?.display_name ? selectedActivity.extra_data.display_name : survey.title,
                      description:
                        !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.additionalInformation
                          ? selectedActivity.extra_data.additionalInformation
                          : survey.description,
                      points: survey.num_points,
                      preview_url:
                        !!selectedActivity && !!selectedActivity.extra_data && !!selectedActivity.extra_data.preview_url
                          ? selectedActivity.extra_data.preview_url
                          : undefined,
                      isCoverImage: true
                    }}
                    bonusPoints={!!selectedActivity ? selectedActivity.totalBonusPoints : 0}
                    limitReached={isAnswered}
                    navigateTo={this.navigateTo}
                  />
                  {this.getContent()}
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  surveyV2,
  loyalty: { selectedActivity, loading, userPoints, activityCompleted, userActivities },
  authentication: { accessToken, account },
  profile: { userProfile },
  formMetadata
}: IRootState) => ({
  isLoggedIn: !!accessToken && accessToken.length > 0 && !!account && !!account.email,
  survey: surveyV2,
  selectedActivity,
  loading,
  userPoints,
  accessToken,
  activityCompleted,
  userActivities,
  userProfile,
  formMetadata
});

const mapDispatchToProps = {
  fetchSurvey,
  updateSurveyPostResponse,
  postSurvey,
  fetchUserPoints,
  setActivityCompleted,
  resetActivityCompleted,
  resetState,
  fetchUserActivitiesWithInterests,
  reset,
  setSelectedUserActivity,
  getProfile,
  fetchUserSurveyResponse,
  showMessageBar,
  fetchActivities,
  setIsFormDirty
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTheme(withTranslation()(withStyles(styles)(SurveyScreen))));
