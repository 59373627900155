import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { container } from 'assets/jss/material-kit-pro-react.jsx';
import { popupButtonStyle } from 'shared/theme/sweepstakes';

const styles = (theme: Theme) =>
  createStyles({
    container,
    catalogItem: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0 !important'
      }
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    detailHeader: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    backButton: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    centered: {
      display: 'flex',
      justifyContent: 'center'
    },
    center: {
      display: 'flex',
      alignContent: 'center'
    },
    tokensContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    tokens: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    tokensTxt: {
      marginLeft: theme.spacing()
    },
    historyBtn: {
      marginLeft: theme.spacing(3)
    },
    historyIcon: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing()
    },
    card: {
      backgroundColor: theme.palette.primary.dark.getWithAlpha(0.7),
      marginTop: theme.spacing(2.5)
    },
    billing: {
      backgroundColor: theme.palette.common.white,
      marginTop: theme.spacing(2.5),
      textAlign: 'center'
    },
    checkBox: {
      color: theme.palette.secondary.main
    },
    addressContent: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    fieldClass: {
      flexBasis: '45.5%',
      color: theme.palette.common.black,
      margin: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        flexBasis: '45.3%'
      },
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%'
      }
    },
    fieldAutocompleteClass: {
      flexBasis: '45.5%',
      margin: theme.spacing(2),
      padding: 0,
      [theme.breakpoints.up('md')]: {
        flexBasis: '45.3%'
      },
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%'
      }
    },
    fieldContainer: {
      padding: theme.spacing(2)
    },
    field: {
      width: '100%'
    },
    colorBlackforSecondary: {
      color: theme.palette.common.black,
      fontStyle: 'italic'
    },
    colorBlack: {
      color: theme.palette.common.black
    },
    proceedButton: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(3.5)
    },
    logo: {
      width: 150,
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3)
      }
    },
    popupButtonStyle,
    textStyle: {
      color: theme.palette.grey['900']
    },
    iframe: {
      height: '500px',
      width: '100%',
      maxWidth: '100%',
      border: 0
    },
    content: {
      padding: theme.spacing(),
      height: '500px'
    },
    purchaseComingSoonContainer: {
      position: 'relative'
    },
    purchaseComingSoon: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 99,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.6)',
      borderRadius: 10
    },
    bundles: {
      padding: theme.spacing(2)
    }
  });

export default styles;
