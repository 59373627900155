import { IFeedbackRequestBody, SEND_FEEDBACK } from '@pbl/pbl-react-core/lib/models/feedback';
import { MessagingService } from '@pbl/pbl-react-core/lib/services';
import { detect } from 'detect-browser';
import { showMessageBar } from 'redux/reducers/app/actions';

export const sendFeedback = (messageBody: IFeedbackRequestBody, recaptcha: string) => async (dispatch: any) => {
  try {
    const browser = detect();

    if (!browser) return;
    const additionalInfo = `Received from WEB app ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}, Browser info: ${browser.name} ${browser.version}, ${browser.os}`;

    await dispatch({
      type: SEND_FEEDBACK,
      payload: MessagingService.sendFeedback(messageBody, additionalInfo, recaptcha)
    });
    dispatch(showMessageBar({ message: 'feedback.sent' }));
  } catch (e) {
    dispatch(showMessageBar({ message: 'feedback.failed', type: 'error' }));
  }
};
