import { Button, Grid, Theme, Typography, withStyles, WithStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';

import TextFieldValidator from '@pbl/pbl-react-web-components/lib/field/TextFieldValidator';
import styles from 'assets/jss/modules/settings/account/AccountScreenStyle';

interface IUpdateEmailConfirmFormProps extends WithStyles<typeof styles> {
  theme: Theme;
  oldEmail: string;
  newEmail: string;
  onCancel: () => void;
  onConfirm: (password: string) => (event) => void;
  validationSchema: any;
}

const UpdateEmailConfirmForm: React.FunctionComponent<IUpdateEmailConfirmFormProps> = ({
  classes,
  theme,
  oldEmail,
  newEmail,
  validationSchema,
  onConfirm,
  onCancel
}) => {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [password, setPassword] = useState('');

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleBlur = () => {
    setSubmitEnabled(validationSchema.isValidSync(password));
  };

  return (
    <Grid container={true} spacing={3} direction="column">
      <Grid item={true}>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          Are you sure you want to change your current email?
        </Typography>
        <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
          {oldEmail}
        </Typography>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          to
        </Typography>
        <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
          {newEmail}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant="body1" style={{ textAlign: 'center', marginBottom: theme.spacing(2) }}>
          Once changed, use your new email to sign in to your account and to receive email notifications and updates from us.
        </Typography>
      </Grid>
      <Grid item={true}>
        <TextFieldValidator
          variant="filled"
          id="currentPassword"
          label="Current Password"
          aria-label="Current Password"
          className={classes.field}
          value={password}
          type="password"
          onChange={handlePasswordChange}
          validationSchema={validationSchema}
          onBlur={handleBlur}
        />
      </Grid>

      <Grid
        item={true}
        style={{
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(4)
        }}
      >
        <Grid container={true} justify="center" spacing={2}>
          <Grid item={true}>
            <Button size="large" variant="outlined" color="primary" aria-label="No" onClick={onCancel}>
              No
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              aria-label="Yes, Change Email"
              onClick={onConfirm(password)}
              disabled={!submitEnabled}
            >
              Yes, Change Email
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTheme(withStyles(styles)(UpdateEmailConfirmForm));
