import {
  Card,
  FilledInput,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  withTheme
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import idVerificationVerified from 'assets/img/sweepstakes/id-verification/graphics-id-verfication-id-verifed.svg';
import idVerificationNotVerified from 'assets/img/sweepstakes/id-verification/graphics-id-verfication-id-verification-inprogress.svg';
import styles from 'assets/jss/modules/settings/SettingsScreenStyle';
import classNames from 'classnames';
import { IMenuItem } from 'config/menu';
import _ from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { IRootState } from 'redux/reducers';
import { getProfile } from 'redux/reducers/profile/actions';
import settingsRoutes from 'routes/settings-routes';
import CustomRoute from 'shared/components/routes/CustomRoute';
import menuItems from './menu';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

interface ISettingsProps extends StateProps, DispatchProps, RouteComponentProps<any>, WithStyles<typeof styles>, WithTranslation {
  theme: Theme;
}

class SettingsScreen extends React.Component<ISettingsProps> {
  public componentDidMount(): void {
    const currentRoute = this.getCurrentRoute(this.props.location.pathname);
    const props = currentRoute || {};
    this.props.getProfile();
    this.setState({
      currentRoute: props.path,
      currentRouteName: props.name
    });
  }

  public render() {
    const { classes, t } = this.props;
    const { currentRoute, currentRouteName } = this.props.location.state || ({} as any);

    const { idologyValid } = this.props.profile?.userProfile || ({} as any);

    const highlightedItem = this.getHighlightedRoute() || ({} as IMenuItem);

    return (
      <div className={classes.root}>
        <Grid container={true} spacing={3} className={classes.grid}>
          <Grid item={true} xs={12} sm={8} md={4} lg={4}>
            <Card>
              <Hidden smDown={true}>
                <List
                  component="nav"
                  subheader={
                    <Typography variant="h6" component="h1" className={classes.menuHeader}>
                      Settings
                    </Typography>
                  }
                >
                  {_.map(menuItems, (menuItem, index) => this.renderListItem(menuItem, highlightedItem, index))}
                </List>
              </Hidden>

              <Hidden mdUp={true}>
                <div className={classes.menu}>
                  <Typography variant="h6" className={classes.menuHeader}>
                    Settings
                  </Typography>
                  <div className={classes.menuSelectSection}>
                    <Select
                      className={classes.menuSelect}
                      value={highlightedItem && highlightedItem.route ? highlightedItem.route : currentRoute}
                      onChange={this.handleRouteSelectChange}
                      input={<FilledInput name="menu-item" />}
                    >
                      {_.map(menuItems, menuItem => (
                        <MenuItem key={menuItem.route} value={menuItem.route}>
                          {menuItem.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </Hidden>
            </Card>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={8} lg={8}>
            <Grid container={true} spacing={2} direction="row">
            <Grid item={true} >
              {currentRouteName === 'Profile' && this.props.authorizedFlags?.includes('SWEEPSTAKES_V1')
                && (<img width= {'53'} height={'45'} alt="verified" src={idologyValid ? idVerificationVerified : idVerificationNotVerified} />)}
            </Grid>
            <Grid item={true} >
            <Typography variant="h5" component="span" className={classes.pageHeader}>
              {currentRouteName}
            </Typography>
            { currentRouteName === 'Profile' && idologyValid && this.props.authorizedFlags?.includes('SWEEPSTAKES_V1') && (<Typography display="block" variant="body2" component="span" className={classes.greenText}>
              {t('profile.accountVerified')}
            </Typography>) }
            { currentRouteName === 'Profile' && !idologyValid && this.props.authorizedFlags?.includes('SWEEPSTAKES_V1') && (<Typography display="block" variant="body2" component="span" color={'error'}>
              {t('profile.accountNotVerified')}
            </Typography>) }
            { currentRouteName === 'Profile' && !idologyValid && (<Typography display="block" variant="body2" component="span">
              {t('profile.accountNotVerifiedMsg')}
            </Typography>) }
            </Grid>
            </Grid>
            <h1 style={{ display: 'none' }}>{currentRouteName}</h1>
            <Card className={classes.page}>
              <Switch>
                {settingsRoutes.map((prop, key) => (
                  <CustomRoute key={key} {...prop} />
                ))}
              </Switch>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  private renderListItem = (menuItem, highlightedItem, index, indent = 0) => {
    const { route, title, subMenu } = menuItem;
    const { classes } = this.props;

    const isSelected = highlightedItem && highlightedItem.route === route;
    return (
      <React.Fragment key={`${route}_fragment_side`}>
        <ListItem
          key={route}
          button={true}
          selected={isSelected}
          onClick={this.handleRouteClick.bind(this, route, title)}
          className={classNames({
            [classes.selectedListItem]: isSelected
          })}
        >
          <ListItemText
            primaryTypographyProps={{
              style: {
                marginLeft: indent * 8
              },
              variant: isSelected ? 'subtitle2' : 'body2'
            }}
            primary={title}
          />
          {isSelected && <ChevronRight role="img" aria-label={`menuItem${index}${indent}`} />}
        </ListItem>
        {_.map(subMenu, (item, i) => this.renderListItem(item, highlightedItem, i, 2))}
      </React.Fragment>
    );
  };

  private handleRouteClick = (path, name) => {
    this.props.history.push(path, {
      currentRoute: path,
      currentRouteName: name
    });
  };

  private handleRouteSelectChange = event => {
    const currentRoute = this.getCurrentRoute(event.target.value);
    if (currentRoute && currentRoute.route) {
      this.props.history.push(currentRoute.route, {
        currentRoute: currentRoute.route,
        currentRouteName: currentRoute.title
      });
    }
  };

  private getCurrentRoute = routePath => _.find(menuItems, ({ route }) => route === routePath) || ({} as any);

  private getHighlightedRoute = () => {
    const { currentRoute } = this.props.location.state || ({} as any);

    const items = _.reduce(
      _.cloneDeep(menuItems),
      (result, item) => {
        return [...result, item, ...(item.subMenu || [])];
      },
      [] as IMenuItem[]
    );

    const currentRouteInItems = _.findIndex(items, menuItem => menuItem.route === currentRoute);

    return currentRouteInItems >= 0
      ? items[currentRouteInItems]
      : _.find(items, menuItem => (currentRoute || '').indexOf(menuItem.route) >= 0);
  };
}
const mapStateToProps = ({ profile, feature: { authorizedFlags } }: IRootState) => ({
  profile,
  authorizedFlags
});

const mapDispatchToProps = {
  getProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
// @ts-ignore
)(withTranslation()(withRouter(withTheme(withStyles(styles)(SettingsScreen)))));
